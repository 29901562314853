import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Vendors from '../../utilities/Vendors';


const Addpaper = ({editinfo,onCancel,reset,setReset}) => {

   
const [info, setInfo] = useState(editinfo)
const [vendors, setVendors] = useState()


useEffect(() => {
  const getVendors = async() => {
  await axios.get(`https://highgroundapi.herokuapp.com/vendor`)
  .then(res => setVendors(res.data))
  .catch(err => console.log(err))
  }
  getVendors()
      },[])


const submit = async(e) => {
    e.preventDefault()

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };
      const tr = {
         name:info.name,
         weight:info.weight,
         vendor:info.vendorname,
         color:info.color,
         coatstyle:info.coat,
         caliper:info.caliper,
         cwt:info.cwt,
         mwt:info.perm,
         kind:info.kind,
         category:info.category,
         divide:info.base,
         sheetpackage:info.sheetpackage,
         sheetcarton:info.sheetcarton,
         sheetskid:info.sheetskid,
         mustorder:info.mustorder,

  }
    console.log(info)
   


   await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addpaper.php`,tr,config)
    .then(res => { 
        const newinfo = {
            name:info.name,
            weight:info.weight,
            vendor:info.vendor,
            color:info.color,
            coatstyle:info.coat,
            caliper:info.caliper,
            perlbs:info.perlbs,
            cwt:info.cwt,
            perthou:info.perm,
            kind:info.kind,
            category:info.category,
            base:info.base,
            sheetpackage:info.sheetpackage,
            sheetcarton:info.sheetcarton,
            sheetskid:info.sheetskid,
            mustorder:info.mustorder,
            defaultorderby:info.defaultorderby,
            stockid:res.data
        }  
        const ty = async() => {
          await axios.post('https://highgroundapi.herokuapp.com/paper/add/one',newinfo)
           //await axios.post('http://localhost:5003/paper/add/one',newinfo)
            .then(res => {setReset(!reset);onCancel()})
            .catch(err => console.log(err))
        }
        ty()
    })
  .catch(err => console.log(err))

console.log(info)
   }


   useEffect(() => {
switch(info?.category){
  case "Offset / Text / Book" :
    setInfo({...info,base:950})
    break;
    case "Cover" :
      setInfo({...info,base:520})
      break;
      case "Bond / Writing" :
        setInfo({...info,base:374})
        break;
        case "Bristol" :
          setInfo({...info,base:641})
          break;
          case "Index" :
            setInfo({...info,base:778})
            break;
            case "Board / Tag" :
              setInfo({...info,base:864})
              break;
              case "Label" :
                setInfo({...info,base:950})
                break;
                case "Envelope" :
                  setInfo({...info,base:374})
                  break;
                  case "Roll" :
                    setInfo({...info,base:950})
                    break;
    default:
      setInfo({...info,base:null}) 
}
   },[info?.category]) 

console.log(vendors)

  return (
    <Wrap >

        <ToastContainer position="bottom-center" theme="dark"/>
        <form onSubmit={submit}>
    <Flexed gap="15px" color="#0E425A">
<Flex textAlign="left"  flex="2">  <Label>Paper Name</Label><br /><Input type="text" placeholder="Paper Name" value={info?.name} onChange={e => setInfo({...info,name:e.target.value})} required/></Flex>
<Flex flex=".5"><Label>Weight<br /></Label><Input type="number" onChange={e => setInfo({...info,weight:e.target.value})} required/></Flex>
<Flex textAlign="left" >
<Label>Category</Label><br />
<Selectbox value={info?.kind} onChange={e => setInfo({...info,kind:e.target.value})} required>
    <option value="" disabled={true} selected>Select Category</option>
        <option value="Sheet Stock">Sheet Stock</option>
        <option value="Roll Stock">Roll Stock</option>
        <option value="Envelope">Envelopes</option>
       
        </Selectbox>
</Flex>

<Flex textAlign="left"  >
<Label>Kind</Label><br />
    <Selectbox value={info?.category} onChange={e => setInfo({...info,category:e.target.value})} required>
    <option value="" disabled={true} selected>Select Kind</option>
        <option value="Offset / Text / Book">Offset / Text / Book -- (950)</option>
        <option value="Cover">Cover -- (520)</option>
        <option value="Bond / Writing">Bond / Writing -- (374)</option>
        <option value="Bristol">Bristol -- (641)</option>
        <option value="Index">Index -- (778)</option>
        <option value="Board / Tag">Board / Tag -- (864)</option>
        <option value="Label">Label -- (950)</option>
        <option value="Envelope">Envelopes -- (374)</option>
        <option value="Roll">Roll -- (950)</option>
       
        </Selectbox>
</Flex>
    </Flexed >


    <Flexed gap="15px" color="#0E425A">
<Flex textAlign="left"  flex="2"> 




</Flex>
<Flex ><Label>Color<br /></Label><Input type="text"  value={info?.color} onChange={e => setInfo({...info,color:e.target.value})}/></Flex>
<Flex textAlign="left" >
<Label>Coat Style</Label><br />
<Selectbox value={info?.coat} onChange={e => setInfo({...info,coat:e.target.value})} required>
    <option value="" disabled={true} selected>Select Coat Style</option>
        <option value="Gloss">Gloss</option>
        <option value="C1S">C1S</option>
        <option value="C2S">C2S</option>
        <option value="Matte">Matte</option>
       
        </Selectbox>
</Flex>
<Flex ><Label>Caliper<br /></Label><Input type="number" step=".0001" onChange={e => setInfo({...info,caliper:e.target.value})} required/></Flex>
<Flex ><Label>Base<br /></Label><Input value={info?.base} type="number" step="1"  onChange={e => setInfo({...info,base:e.target.value})} disabled/></Flex>

    </Flexed >

<Flexed color="#0E425A">
<Flex ><Label>Sheets/Package<br /></Label><Input type="number" step="1" onChange={e => setInfo({...info,package:e.target.value})}/></Flex>
<Flex ><Label>Sheets / Carton<br /></Label><Input type="number" step="1" onChange={e => setInfo({...info,carton:e.target.value})}/></Flex>
<Flex ><Label>Sheets / Skid<br /></Label><Input type="number" step="1" onChange={e => setInfo({...info,skid:e.target.value})}/></Flex>
<Flex ><Label>Must Order In<br /></Label><Input type="number" step="1" onChange={e => setInfo({...info,orderin:e.target.value})} /></Flex>
</Flexed>
<Flexed color="#0E425A">
<Flex ><Label>CWT<br /></Label><Input type="number" step=".0001" onChange={e => setInfo({...info,cwt:e.target.value})}/></Flex>
<Flex >{/*<Label>Per M<br /></Label><Input type="number" step=".0001" onChange={e => setInfo({...info,perm:e.target.value})}/>*/}</Flex>
<Flex >{/*<Label>Per #<br /></Label><Input type="number" step=".0001" onChange={e => setInfo({...info,perlbs:e.target.value})}/>*/}</Flex>
<Flex ><Label>Default Order In<br /></Label><Input type="text" onChange={e => setInfo({...info,defaultorderin:e.target.value})} /></Flex>
</Flexed>
<Flexed color="#0E425A"padding="20px 20px 0px 0px" justifyContent="space-bwtween" ><Flex textAlign="right"><Button style={{marginRight:"30px"}} type="button" onClick={onCancel} color="#0E425A">Cancel</Button><Button color="#0E425A" type="submit">Add Paper</Button></Flex></Flexed>

    </form>
    </Wrap>
  )
}

export default Addpaper








