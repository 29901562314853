import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Scroll, Selectbox, Textauto, Wrapper } from '../../../utilities/Shared'
import axios from 'axios';
import Collapse from '../../../utilities/Collapse';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { RiDeleteBack2Fill } from "react-icons/ri";
import JobticketContext from '../../../Context/JobticketContext';
import { colorchange } from '../../../utilities/Convertions';

const moment = require("moment");
const MailingTicket = ({jobinfo,forms,reset,setReset,job}) => {

  const[operations, setOperations] = useState([])
  const[newforms, setnewForms] = useState([])
  const[imposition, setImposition] = useState([])
  const[proofstotal, setProofstotal] = useState({hours:0,minutes:0})
 




  const[operationitems, setOperationitems] = useState([])
  const[prepress, setPrespress] = useState([])
  const[onprepresstimes, setOnprepresstimes] = useState({})
  const[offprepresstimes, setOffprepresstimes] = useState({})

  const[onplatingtimes, setOnplatingtimes] = useState({})
  const[offplatingtimes, setOffplatingtimes] = useState({})
  const[proofin, setProofin] = useState(false)
  const[proofout, setProofout] = useState(false)
  
  const [value, onChange] = useState(new Date());
  const [onTimes, setOnTimes] = useState(null);
  const [offTimes, setOffTimes] = useState(null);




  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };


  useEffect(() => {

    let r = {
        job:job,
    }
    const getops = async() => {
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
    .then(res => {setOperations(res.data);})
    .catch(err => console.log(err))
    }
    const getquote = async() => {
      let r = {
        quote:jobinfo?.quote,
      }
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getimposition.php`,r,config)
     .then(res => {setImposition(res.data);})
     .catch(err => console.log(err))
     }
    getops()
    getquote()
  },[reset])

  useEffect(() => {
    setPrepressValues()
  },[operations])


  console.log(forms)
const setPrepressValues = () => {
  let imp
  let ops = []
let proofing = []

let tabbinkingins = []
let  tabbinkingouts = []
let  tabbinkingindis = []
    let tabbingins = []
    let  tabbingouts = []
    let  tabbingindis = []
    let inkjettingins = []
    let  inkjettingouts = []
    let  inkjettingindis = []
    let insertingins = []
    let  insertingouts = []
    let insertingindis = []
    let mailhandworkins = []
    let  mailhandworkouts = []
    let mailhandworkindis = []
    let partofid
    let partof
    console.log(operations)
    //  imp = imposition?.filter(filter => filter.component === item.originalform)
    //  console.log(imp)

operations?.map((op,index) => {
  let diff = 0    
  if(op.name === "On Tabbing / Inkjetting"){tabbinkingins.push({id:op.id,time:op.changedon,status:"On Tabbing / Inkjetting"})}
    if(op.name === "On Tabbing"){tabbingins.push({id:op.id,time:op.changedon,status:"On Tabbing"})}
   if(op.name === "On Inkjetting"){inkjettingins.push({id:op.id,time:op.changedon,status:"On Inkjetting"})}
    if(op.name === "On Inserting"){insertingins.push({id:op.id,time:op.changedon,status:"On Inserting"})}
    if(op.name === "On Mail Handwork"){mailhandworkins.push({id:op.id,time:op.changedon,status:"On Mail Handwork"})}

    if(op.name === "Off Tabbing / Inkjetting"){tabbinkingouts.push({id:op.id,time:op.changedon,status:"Off Tabbing / Inkjetting"})} 
    if(op.name === "Off Tabbing"){tabbingouts.push({id:op.id,time:op.changedon,status:"Off Tabbing"})} 
  if(op.name === "Off Inkjetting"){inkjettingouts.push({id:op.id,time:op.changedon,status:"Off Inkjetting"})}
  if(op.name === "Off Inserting"){ insertingouts.push({id:op.id,time:op.changedon,status:"Off Inserting"})}
  if(op.name === "Off Mail Handwork"){mailhandworkouts.push({id:op.id,time:op.changedon,status:"Off Mail Handwork"})}

  if(op.name === "Incomplete Tabbing / Inkjetting"){tabbinkingouts.push({id:op.id,time:op.changedon,status:"Incomplete Tabbing / Inkjetting"})}
    if(op.name === "Incomplete Tabbing"){tabbingouts.push({id:op.id,time:op.changedon,status:"Incomplete Tabbing"})}
    if(op.name === "Incomplete Inkjetting"){inkjettingouts.push({id:op.id,time:op.changedon,status:"Incomplete Inkjetting"})}
    if(op.name === "Incomplete Inserting"){ insertingouts.push({id:op.id,time:op.changedon,status:"Incomplete Inserting"})} 
    if(op.name === "Incomplete Mail Handwork"){mailhandworkouts.push({id:op.id,time:op.changedon,status:"Incomplete Mail Handwork"})}

    partofid = op.partofid || `${op.partof}${index}`
    partof = op.partof || op.item
    })
   
    tabbinkingindis=(getdiff(tabbinkingins,tabbinkingouts))
    tabbingindis=(getdiff(tabbingins,tabbingouts))
    inkjettingindis=(getdiff(inkjettingins,inkjettingouts))
    insertingindis=(getdiff(insertingins,insertingouts))
    mailhandworkindis=(getdiff(mailhandworkins,mailhandworkouts))


    const y = {
      id:jobinfo.id,
      item:jobinfo?.id,
        name:jobinfo?.name,
        formid:jobinfo?.formid,
        partofid,
        partof,
        processtext:jobinfo?.processtext,
        tabbinking:
        {'ins':tabbinkingins,
          'out':tabbinkingouts,
          'indiv':tabbinkingindis,
        'estimatedtime':{hours:0,minutes:0},
        
  },
        tabbing:
          {'ins':tabbingins,
            'out':tabbingouts,
            'indiv':tabbingindis,
          'estimatedtime':{hours:0,minutes:0},
          
    },
    inkjetting:
    {'ins':inkjettingins,
      'out':inkjettingouts,
      'indiv':inkjettingindis,
      'estimatedtime':{hours:0,minutes:0},
    
},
inserting:
{'ins': insertingins,
  'out': insertingouts,
  'indiv': insertingindis,
  'estimatedtime':{hours:0,minutes:0},

},
mailhandwork:
{'ins': mailhandworkins,
  'out': mailhandworkouts,
  'indiv': mailhandworkindis,
  'estimatedtime':{hours:0,minutes:0},

}
    }
proofing.push(y)
setnewForms(proofing)
}



const getdiff = (inTimes,outTimes) => {

let tot =[]
let u


inTimes?.map((item,index) => {

      let time
        let intime = new Date(item?.time)
        let outtime = new Date(outTimes[index]?.time)
     time = Math.abs(outtime - intime)



   
  let y =timeConvert(time/1000/60)
  if(isNaN(y.hours)){y.hours = 0}
  if(isNaN(y.minutes)){y.minutes = 0}
   u = {hours:y.hours,minutes:y.minutes}
  tot.push(u)
})
return(tot)
}

const setesttime = (imp,item) => {
  let newitem

switch(item){
case "stitching":
//   if(parseInt(imp[0]?.numforms) > 1){
//       let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
  
//       newitem = (r / parseInt(imp[0]?.numforms))
//   } 
break;
}

let y = converttime(newitem)

return y
}



function timeConvert(n) { 

  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

 // if(isNaN(rhours)){rhours = 0}
  //if(isNaN(rminutes)){rminutes = 0}
  let t = {hours:proofstotal.hours + rhours,minutes:proofstotal.minutes + rminutes}

  return{hours:rhours,minutes:rminutes}
  } 

  const converttime = (item) => {

    let hours = 0
        let minutes = 0
        let myArray = []
    if(item !== undefined){
         myArray = item.toString()?.split(".");
       hours = parseInt(myArray[0])
       minutes = parseInt(myArray[1])

        let check = minutes/100
        if(check > 1){
         minutes = minutes % 100
       
        }
    }
   return({hours,minutes})
}


const getDiff = (intime,outtime,item) => {
   intime = new Date(intime)
   outtime = new Date(outtime)
     let  time = Math.abs(outtime - intime)

     let y = (timeConvert(time/1000/60))


     return `${y.hours}:${y.minutes}`
}








const setchangevalues = (item) => {
   
  let d
  let s
  let p
  let push
switch(item){
    case "On Tabbing / Inkjetting":
        d = 6
        s = 1
        p = 0
        push=0
        break;
        case "Off Tabbing / Inkjetting":
        d = 6
        s = 0
        p = 0
        push=0
        break;
        case "Incomplete Tabbing / Inkjetting":
        d = 6
        s = 0
        p = 0
        push=0
        break;
  case "On Tabbing":
d = 6
s = 1
p = 0
push=0
break;
case "Off Tabbing":
d = 6
s = 0
p = 0
push=0
break;
case "Incomplete Tabbing":
d = 6
s = 0
p = 0
push=0
break;
case "On Inkjetting":
    d = 6
    s = 1
    p = 3
    push=0
    break;
    case "Off Inkjetting":
    d = 6
    s = 0
    p = 3
    push=0
    break;
    case "Incomplete Inkjetting":
    d = 6
    s = 0
    p = 3
    push=0
    break;
    case "On Inserting":
        d = 6
        s = 1
        p = 4
        push=0
        break;
        case "Off Inserting":
        d = 6
        s = 0
        p = 4
        push=0
        break;
        case "Incomplete Inserting":
        d = 6
        s = 0
        p = 4
        push=0
        break;
        case "On Mail Handwork":
            d = 6
            s = 1
            p = 5
            push=0
            break;
            case "Off Mail Handwork":
            d = 6
            s = 0
            p = 5
            push=0
            break;
            case "Incomplete Mail Handwork":
            d = 6
            s = 0
            p = 5
            push=0
            break;
}
return {d,s,p,push}
}



const setpushes = (e,form) => {
  console.log(e.target.value)
let set = {}
  if(e.target.value === "Off Press"){
set.dept = 4           
set.push = 0
set.name = "Ready Bindery"
set.process=0
  }
  const z = {
    job:job,
    item:form.item,
    partof:form.partof,
    partofid:form.partofid,
    process:set.process,
    comp:form.name,
    changedby:sessionStorage.getItem('eid'),
  name:set.name,
  dept:set.dept,
  status:0,
  push:set.push
  }
  console.log(z)
  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )

 
  }
  savit()
}





const changestatus = (e,form,index) => {

let values = setchangevalues(e.target.value)

const z = {
  job:job,
  item:form.item,
  partof:form.partof,
  partofid:form.partofid,
  process:values.p,
	comp:"Mail",
name:e.target.value,
dept:values.d,
status:values.s,
changedby:sessionStorage.getItem('eid'),
push:values.push
}
console.log(z)
const savit = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
  .then(res => {setpushes(e,form)} )
  .catch(err => toast.error('Something went wrong and process was not saved') )
}
savit()
}







const changetime = (e,form) => {

  const z = {
    changedon:e.target.value,
id:form.id
  }

  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )
  }
 savit()
}


console.log(newforms)

  return (
    <Wrapper style={{height:"70vh",position:"relative",color:"black"}}>
    
  
    <Scroll height="100%">


    <div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Tabbing / Inkjetting</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex flex="4">
          {form.tabbinking.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.tabbinking.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.tabbinking.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.tabbinking.out[index].status)}} type="datetime-local" value={form.tabbinking.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.tabbinking.indiv[index].hours}:{form.tabbinking.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.tabbinking.estimatedtime.hours}:{form.tabbinking.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Tabbing / Inkjetting">On Tabbing / Inkjetting</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Tabbing / Inkjetting">Off Tabbing / Inkjetting</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Tabbing / Inkjetting">Incomplete Tabbing / Inkjetting</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Tabbing//////////////////////////////////////////////////////////////*/}

    <div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Tabbing</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">

<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex flex="4">
          {form.tabbing.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.tabbing.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.tabbing.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.tabbing.out[index].status)}} type="datetime-local" value={form.tabbing.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.tabbing.indiv[index].hours}:{form.tabbing.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.tabbing.estimatedtime.hours}:{form.tabbing.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Tabbing">On Tabbing</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Tabbing">Off Tabbing</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Tabbing">Incomplete Tabbing</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Inkjetting//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Inkjetting</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">

<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex flex="4">
          {form.inkjetting.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.inkjetting.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.inkjetting.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.inkjetting.out[index].status)}} type="datetime-local" value={form.inkjetting.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.inkjetting.indiv[index].hours}:{form.inkjetting.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.inkjetting.estimatedtime.hours}:{form.inkjetting.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Inkjetting">On Inkjetting</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Inkjetting">Off Inkjetting</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Inkjetting">Incomplete Inkjetting</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>


{/*/////////////////////////////////////////////////////////Inkjetting//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Inserting</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">

<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>
   
    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex flex="4">
          {form.inserting.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.inserting.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.inserting.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.inserting.out[index].status)}} type="datetime-local" value={form.inserting.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.inserting.indiv[index].hours}:{form.inserting.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.inserting.estimatedtime.hours}:{form.inserting.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Inserting">On Inserting</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Inserting">Off Inserting</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Inserting">Incomplete Inserting</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Inkjetting//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Hand Work</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">

<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>
   
    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex flex="4">
          {form.mailhandwork.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.mailhandwork.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.mailhandwork.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.mailhandwork.out[index].status)}} type="datetime-local" value={form.mailhandwork.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.mailhandwork.indiv[index].hours}:{form.mailhandwork.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.mailhandwork.estimatedtime.hours}:{form.mailhandwork.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Mail Handwork">On Mail Handwork</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Mail Handwork">Off Mail Handwork</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Mail Handwork">Incomplete Mail Handwork</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>





<br /> <br /> <br /> <br />
    </Scroll>
    

    
    </Wrapper>
  )
}

export default MailingTicket




