import React, { useEffect, useState } from 'react'
import { Input } from './Shared'

const Select = ({list,value,listid,setValue,placeholder,required,border,disabled,MB,e}) => {




    const setitem = async(e) => {
        var inputValue = e.target.value;
        console.log(inputValue)
       // setSelectedItem({...selectedItem, itemname: e.target.value})
        let id;
   
            var options = document.getElementById(listid).childNodes;

            for (var i = 0; i < options.length; i++) {
              if (options[i].value === inputValue) {
              id = (options[i].dataset.item)
              }}
              if(id){
                setValue({id,name:inputValue})
              }else{
                setValue({id:null,name:inputValue})
              }
            }


  return (
   <>
   <Input marginBottom={MB} type="text" border={border} placeholder={placeholder} value={value} list={listid} onChange={e => setitem(e)} disabled={disabled} required/>
   <datalist id={listid}>
{
    list?.map((item,index) => {
     return   <option key={index} data-item={item.id} value={item.stockid > 0 ? `${item.name} - ${item.stockid}` : item.name} /> 
    })
}
   </datalist>
   </>
  )
}

export default Select