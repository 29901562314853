import { Link, useParams,useNavigate,useSearchParams  } from 'react-router-dom'
import { Button, Datepick, Flex, Flexed, Img, Input, Label, PhoneNumber, Scroll, Wrap,Departchange, Selectbox, MainWrap } from '../../utilities/Shared'
import {useContext, useEffect, useState} from 'react'
import axios from 'axios'


import Select from 'react-select';
import { toast } from 'react-toastify';
import ChangePassword from './ChangePassword';
import Modal from '../../modals/Modal';
import UserContext from '../../Context/UserContext';
import { CSRReps } from '../../utilities/Reps';



const Employee = () => {


    const options = [
      { value: '300', label: 'Production' },
      { value: '111', label: 'Bindery' },
      { value: '101', label: 'Csr' },
      { value: '114', label: 'Digital' },
      { value: '110', label: 'Mailroom' },
      { value: '107', label: 'Management' },
      { value: '104', label: 'Pre-Press' },
      { value: '109', label: 'Press' },
      { value: '113', label: 'Receiving' },
      { value: '102', label: 'Sales' },
      { value: '116', label: 'Sales Chart' },
      { value: '112', label: 'Shipping' },
      { value: '115', label: 'Unlink Job' },
      { value: '106', label: 'Administration' },
      { value: '200', label: 'Chat' },
      { value: '117', label: 'Inventory' },
      { value: '201', label: 'Elections' },
      ]
      const authlevel = [
        { value: '600', label: 'Admin' },
        { value: '106', label: 'Manager' },
        { value: '104', label: 'Supervisor' },
        { value: '113', label: 'Limited' },
        { value: '109', label: 'None' }
      ]

const [departs, setDeparts] = useState([])
  
 const {userInfo, resetUser } = useContext(UserContext)

    const param = useParams()
 const [employee, setEmployee] = useState({});
 const [updateemployee, setUpdateemployee] = useState({});
 const [buttonshow, setButtonshow] = useState(false);
 const [birthdate, setBirthdate] = useState(null);
 const [hiredate, setHiredate] = useState(null);
 const [dismissdate, setDismissdate] = useState(null);
 const [reset, setReset] = useState(false);
 const [changepassword, setChangepassword] = useState(false);
 const [csr, setCsr] = useState(null);



 useEffect(() => {
axios.get(`https://highgroundapi.herokuapp.com/employee/item/${param.id}`)
.then(res => {setEmployee(res.data);setUpdateemployee(res.data);setBirthdate(new Date(res.data.birthDate));res.data?.hireDate && setHiredate(new Date(res.data?.hireDate));setDismissdate(new Date(res.data.disDate));setDeparts(res.data.departments)})
.catch(err => console.log(err))
 },[reset])

 useEffect(() =>{
   JSON.stringify(employee) === JSON.stringify(updateemployee) ? setButtonshow(false) : setButtonshow(true);
 },[updateemployee,birthdate,hiredate])

const updateDates = (date,field) => {
switch(field){
    case 'birthday':
        setBirthdate(date)
        setUpdateemployee({...updateemployee,birthDate:new Date(date)})
        break;
        case 'hire':
            setHiredate(date)
            setUpdateemployee({...updateemployee,hireDate:new Date(date)})
            break;
            case 'dismiss':
                setDismissdate(date)
                setUpdateemployee({...updateemployee,disDate:new Date(date)})
                break;
        default:
}
}
const changedepartments = selectedOptions => {
setDeparts(selectedOptions)
setUpdateemployee({...updateemployee,departments:selectedOptions})

}

const saveprofile = (e) => {
e.preventDefault()
axios.post('https://highgroundapi.herokuapp.com/employee/update',updateemployee)
//axios.post('http://localhost:5003/employee/update',updateemployee)
.then(res => {toast.success('Profile Saved');resetUser(sessionStorage.getItem('trackuser'));setReset(!reset)})
.catch(err => console.log(err))


}

const unlock = () => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
      

      const tr = {
       id:employee.empId
  } 

    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/unlock.php`,tr,config)
    .then(res => {toast.success('Account Unlocked')})
   .catch(err => toast.error(err))
}


  return (

    <MainWrap padding="20px" >
    <Modal show={changepassword} header={`Change Password`} onCancel={() => {setChangepassword(false)}} ><ChangePassword  onCancel={() => {setChangepassword(false)}}  reset={reset} setReset={setReset} id={employee._id} employyeid={employee.empId}/></Modal>
<Flexed>
    <Flex textAlign="left" padding="20px 10px" fontSize="calc(1.2rem + .4vw)" color="#0E425A"><Label fontSize="calc(1.4rem + .5vw)">{employee.preferredName > "" ? employee.preferredName : employee.firstName}'s Profile</Label></Flex>
</Flexed>
<Wrap>
    <form onSubmit={saveprofile}>
<Flexed bgColor="#8398AB" padding="5px" color="white"><Flex textAlign="left"><Label fontSize="calc(.7rem + .5vw)" >{employee.preferredName > "" ? employee.preferredName : employee.firstName} {employee.lastName}</Label></Flex></Flexed>
<Flexed gap="20px" padding="0px 0px" borderBottom="1px solid black" >
<Flex flex="1" padding="10px" flexDirection="column"><Img src={process.env.PUBLIC_URL + '/epp/'+employee.photo}  maxHeight="191px" minHeight="191px"/>{userInfo.auth?.includes('600') || userInfo.auth?.includes('106') ? <span style={{fontSize:"calc(.5rem + .5vw)",color:"#648DA7"}}>Upload Image</span> : null}</Flex>


<Flex flex="4">
<Flexed  gap="20px" margin="15px 0px 15px 0px">
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">First Name</Label><br /><Input type="text" height="30px" value={updateemployee.firstName} onChange={e => setUpdateemployee({...updateemployee,firstName:e.target.value})}/></Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Last Name</Label><br /><Input type="text" value={updateemployee.lastName} onChange={e => setUpdateemployee({...updateemployee,lastName:e.target.value})} /></Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Middle Name</Label><br /><Input type="text" value={updateemployee.middleName} onChange={e => setUpdateemployee({...updateemployee,middleName:e.target.value})} /></Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Preferred Name</Label><br /><Input type="text" value={updateemployee.preferredName} onChange={e => setUpdateemployee({...updateemployee,preferredName:e.target.value})} /></Flex>
</Flexed>
<Flexed  gap="20px" margin="0px 0px 15px 0px">
    <Flex> <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">Address 1</Label><br /><Input type="text" value={updateemployee.address1} onChange={e => setUpdateemployee({...updateemployee,address1:e.target.value})} /></Flex>
    <Flex> <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">Address 2</Label><br /><Input type="text" value={updateemployee.address2} onChange={e => setUpdateemployee({...updateemployee,address2:e.target.value})} /> </Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">City</Label><br /><Input type="text" value={updateemployee.city} onChange={e => setUpdateemployee({...updateemployee,city:e.target.value})} /></Flex>
    <Flex> <Flexed gap="20px" padding="0px 0px"> <Flex textAlign="left"><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">State</Label><br /><Input type="text" value={updateemployee.state} onChange={e => setUpdateemployee({...updateemployee,state:e.target.value})} /></Flex>
   <Flex textAlign="left"><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">Zip</Label><br /><Input type="text" value={updateemployee.zip} onChange={e => setUpdateemployee({...updateemployee,zip:e.target.value})} /></Flex></Flexed> </Flex>
</Flexed>
<Flexed  gap="20px" margin="0px 0px 15px 0px">
    <Flex> <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Phone</Label><br /><Input type="text"  value={PhoneNumber(updateemployee.cell)} onChange={e => setUpdateemployee({...updateemployee,cell:e.target.value.replace(/[^+\d]+/g, "")})} /></Flex>
    <Flex flex="2"><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">Email</Label><br /><Input type="text" height="30px"  value={updateemployee.email} onChange={e => setUpdateemployee({...updateemployee,email:e.target.value})} /> </Flex>
  
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Date of Birth</Label><br /><Datepick selected={updateemployee.birthDate !== null && birthdate} onChange={e => updateDates(e,'birthday')} /></Flex>
</Flexed>
</Flex>

</Flexed>

    <Flexed  gap="20px" padding="15px 0px">
    <Flex flex=".5"><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">Employee ID</Label><br /><Input type="text" height="30px"  value={updateemployee.empId} disabled/> </Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">FOB #</Label><br /><Input type="text" value={updateemployee.fob} onChange={e => setUpdateemployee({...updateemployee,fob:e.target.value})} disabled={userInfo.auth?.includes('600') || userInfo.auth?.includes('106')? false : true}/> </Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">PL#</Label><br /><Input type="text" disabled={userInfo.auth?.includes('600') || userInfo.auth?.includes('106')? false : true} /></Flex>
    <Flex flex=".5">  <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" >EXT</Label><br /><Input type="text" value={updateemployee.ext} onChange={e => setUpdateemployee({...updateemployee,ext:e.target.value})}/></Flex>
    <Flex> <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" >Work Email</Label><br /><Input type="text" value={updateemployee.workEmail} onChange={e => setUpdateemployee({...updateemployee,workEmail:e.target.value})} /></Flex>
    </Flexed> 

    <Flexed  gap="20px" padding="0px 0px" alignItems="center">
    <Flex ><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">Hire Date</Label><br /><Datepick selected={updateemployee.hiredate !== null && hiredate} onChange={e => updateDates(e,'hire')} disabled={userInfo.auth?.includes('600') ? false : true}/></Flex>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">Dismissal Date</Label><br /><Datepick selected={updateemployee.disDate !== null && dismissdate} onChange={e => updateDates(e,'dismiss')} disabled={userInfo.auth?.includes('600') || userInfo.auth?.includes('106')? false : true} /></Flex>
    {updateemployee.departments?.some(cat => cat.value === "102") && <Flex><Label>CSR</Label><CSRReps value={updateemployee?.csr} onChange={e => setUpdateemployee({...updateemployee,csr:e.target.value})} /></Flex>}
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" >Access Level</Label><br />
   <Selectbox value={updateemployee.auth} onChange={e => setUpdateemployee({...updateemployee,auth:[e.target.value]})} disabled={userInfo.auth?.includes('600') ? false : true}>
    {
        authlevel.map(item => {
            return <option value={item.value}>{item.label}</option>
        })
    }
   
   </Selectbox></Flex>
   <Flex> <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" >Status</Label><br />
   <Selectbox value={updateemployee.status} onChange={e => setUpdateemployee({...updateemployee,status:e.target.value})} disabled={userInfo.auth?.includes('600') || userInfo.auth?.includes('106')? false : true}>
    <option value="true">Active</option>
    <option value="false">Inactive</option>
   </Selectbox>
   </Flex>
    <Flex display="flex" alignItems="center" ><Button type="button" onClick={e => setChangepassword(true)}> Change Password</Button></Flex>
    </Flexed> 
<Flexed borderBottom="1px solid black" padding="15px 0px" margin="0px 0px 15px 0px">
    <Flex disabled={userInfo.auth?.includes('600') ? false : true}><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">Departments</Label><br />  <Select
                      onChange={changedepartments}
                        options={options}
                       value={departs}
                        isMulti
                        isDisabled={userInfo.auth?.includes('600') ? false : true}
                        /></Flex>
</Flexed>


<span style={{padding:"15px 0px",fontSize:"calc(1.2rem + .4vw)",color:"red"}}>E-Contact</span>
<Flexed>
    <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">Emergency Contact</Label><br /><Input type="text" height="30px" value={updateemployee.econtact?.name} onChange={e => setUpdateemployee({...updateemployee,econtact:{...updateemployee.econtact,name:e.target.value}})} /></Flex>
    <Flex>    <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Relationship</Label><br /><Input type="text"  value={updateemployee.econtact?.relationship} onChange={e => setUpdateemployee({...updateemployee,econtact:{...updateemployee.econtact,relationship:e.target.value}})} /></Flex>
    <Flex>
    <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Home #</Label><br /><Input type="text"  value={PhoneNumber(updateemployee.econtact?.phone)} onChange={e => setUpdateemployee({...updateemployee,econtact:{...updateemployee.econtact,phone:e.target.value.replace(/[^+\d]+/g, "")}})} /></Flex>
    <Flex>
    <Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Cell #</Label><br /><Input type="text" value={PhoneNumber(updateemployee.econtact?.cell)} onChange={e => setUpdateemployee({...updateemployee,econtact:{...updateemployee.econtact,cell:e.target.value.replace(/[^+\d]+/g, "")}})} /></Flex>
</Flexed>






{/*

/////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
<Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" padding="10px 0px">Change Password</Label><br /><Input type="text" />
<Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" >Confirm Password</Label><br /><Input type="text" />
////////////////////////////////////////////////////////////////////////////////////////////////
  */}

   <Flexed justifyContent="flex-end" margin="30px 0px 0px 0px" width="100%" >
    <Flex textAlign="left">{userInfo.auth?.includes('600') || userInfo.auth?.includes('106') ? <Button type="button" onClick={unlock}>Unlock</Button> : null}</Flex>
    <Flex textAlign="right"> <Button type="submit">Save Profile</Button></Flex>
   
   </Flexed>

</form>
</Wrap>

 <br /><vbr/> <br /><vbr/> <br /><vbr/> <br /><vbr/> <br /><vbr/>


    </MainWrap>
  )
}

export default Employee






