import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Textarea, currency } from '../../utilities/Shared'
import Select from '../../utilities/Select'
import {POTypes} from '../../utilities/Lists'
import axios from 'axios'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
const ReceiveItem = ({poinfo,item,reset,setReset,onCancel,ponum}) => {

console.log(poinfo)

  const [receive,setReceive] = useState({...item,outstanding:0})
  const [type,setType] = useState()
  const [itemslist,setItemslist] = useState([])
  const [selecteditem,setSelecteditem] = useState({id:poinfo?.id,name:poinfo?.name})
  const [paperslist, setPaperslist] = useState([])
  const [supplieslist, setSupplieslist] = useState([])
  const [sameitems, setSameitems] = useState([])



  useEffect(() => {
    const p = async() => {
      //await axios.get('https://highgroundapi.herokuapp.com/paper/all')
      await axios.get('https://highgroundapi.herokuapp.com/paper/all')
      .then(res => {setPaperslist(res.data);})
      .catch(err => console.log(err)) 
  }
  const pa = async() => {
      //await axios.get('https://highgroundapi.herokuapp.com/supplies')
      await axios.get('https://highgroundapi.herokuapp.com/supplies')
      .then(res => {setSupplieslist(res.data)})
      .catch(err => console.log(err)) 
  }
  const getsame = async(stockid) => {
    await axios.get(`https://highgroundapi.herokuapp.com/inv/same/${stockid}`)
    .then(res => {setSameitems(res.data)})
    .catch(err => console.log(err))
    } 
getsame(poinfo?.stockid)
  p()
  pa()

  },[poinfo?.stockid])

  const {userInfo} = useContext(UserContext);
  useEffect(() => {
   
switch(type?.name){
case "Stock":
    setItemslist(paperslist?.filter(paper => paper.kind === "Sheet Stock"))
    break;
    case "Roll Stock":
    setItemslist(paperslist?.filter(paper => paper.kind === "Roll Stock"))
    break;
    case "Envelopes":
        setItemslist(paperslist?.filter(paper => paper.kind === "Envelope"))
        break;
        case "Inks":
            setItemslist(supplieslist?.filter(paper => paper.category === "Ink"))
     break;
        case "Bindery":
         setItemslist(supplieslist?.filter(paper => paper.category === "Bindery"))
        break;
        case "Custodial":
            setItemslist(supplieslist?.filter(paper => paper.category === "Custodial"))
           break;
           case "Prepress":
            setItemslist(supplieslist?.filter(paper => paper.category === "Prepress"))
           break;
           case "Press":
            setItemslist(supplieslist?.filter(paper => paper.category === "Press"))
           break;
           case "Shipping":
            setItemslist(supplieslist?.filter(paper => paper.category === "Shipping"))
           break;
           case "Maintenance":
            setItemslist(supplieslist?.filter(paper => paper.category === "Maintenance"))
           break;
           case "Boxes":
            setItemslist(supplieslist?.filter(paper => paper.category === "Boxes"))
           break;
    default:
        setItemslist([]);
        break;

    }
},[type?.name])

useEffect( () => {
  
let r = parseInt(item?.quantity) - parseInt(receive.quantity)
console.log(r)
if(isNaN(r)){
r=poinfo?.quantity
}

 setReceive({...receive,outstanding:r})


},[receive.quantity])  




console.log(receive)
const receiveit = async() => {
 


  let z
  console.log(item)
  const y = {
    itemid:receive.itemid,
    recievedquantity:receive.quantity,
    recievedwidth:receive.width,
    recievedlength:receive.length,
    recievedpounds:receive.pounds,
    recievedoutstanding:receive.outstanding,
    recievedcost:receive.extprice,
    recievedstatus:1,
    recieveddate:new Date(),
    id:poinfo.ponum,
    type:poinfo.type
  }
  
  console.log(y)

await axios.post('https://highgroundapi.herokuapp.com/po/updaterecteitem',y)
//await axios.post('http://localhost:5003/po/updaterecteitem',y)
.then(res => {

   z = {
name:receive.name,
paperid:receive.stockid,
size:receive.width+'X'+receive.length,
bin:"",
location:getlocation(),
currentInventory:receive.quantity,
po:poinfo._id,
ponum:poinfo.ponum,
type:receive.type,
orderby:receive.orderby,
priceby:receive.priceby,
cost:receive.cost,
cwt:receive.cwt,
perm:receive.unitprice,
poitemid:receive.itemid,
mwt:receive.mwt,
forjobs:receive.forjobs,
history:{
  who:userInfo?.preferredName+" "+userInfo?.lastName.substr(0,1),
  reason:"Recieved From Po "+poinfo.ponum,
  was:0,
  isnow:receive.quantity,
  differ:0,
  date:new Date(),
  bin:""
}


  }
  if(item.type === "Stock" || item.type === "Roll Stock" || item.type === "Envelopes"){
addinv(z)
   console.log(z)
  }
  return res
})
.then(res => {toast.success('Item Recieved');setReset(!reset);onCancel()})
.catch(err => console.log(err))


}


const getlocation = () => {
  let a
switch(item.type){
case "Stock":
  a = "Paper Areas";
  break;
  case "Roll Stock":
  a = "Web Rolls";
  break;
  case "Envelopes":
  a = "Colormax";
  break;
}
return a
}


const addinv = async(z) => {
  console.log(z)

await axios.post('https://highgroundapi.herokuapp.com/inv/additem',z)
 .then(res => {toast.success('Paper Added To Inventory')})
.catch(err => toast.error('Paper WAS NOT added To Inventory'))
    }



console.log(poinfo)
console.log(item)
  return (
    <>
    <div>Ordered</div>
   <Flexed>
    
 <Flex>Quantity<br /><Input defaultValue={item?.quantity === undefined ? "" : item?.quantity} type="text" required disabled/></Flex>
 <Flex>Width<br /><Input defaultValue={item?.width === undefined ? "" : item?.width} type="text" required  disabled/></Flex>
 <Flex>Length<br /><Input defaultValue={item?.length === undefined ? "" : item?.length} type="text" required  disabled/></Flex>
 <Flex>Pounds<br /><Input defaultValue={item?.pounds === undefined ? "" : item?.pounds} type="text" required  disabled/></Flex>
 <Flex>Cost<br /><Input defaultValue={item?.extprice === undefined ? "" : currency(item?.extprice)} type="text" required  disabled/></Flex>
   </Flexed>
   <div>Receiving</div>
   <Flexed>
<Flex flex=".5">Item Type<br /><Select value={item?.type} list={POTypes?.map(rt => {return {"id":rt?._id,name:rt?.name};})} listid="potypes" setValue={setType}/></Flex>
<Flex>Item<br /><Select textvalue={item?.name} list={itemslist?.map(rt => {return {"id":rt?._id,'name':rt?.name};})} listid="itemslist" setValue={setSelecteditem}  value={item?.name} /></Flex>
 
</Flexed>
   <Flexed>
    
 <Flex>Quantity<br /><Input value={receive?.quantity === undefined ? "" : receive?.quantity} type="text" onChange={e => setReceive({...receive,quantity:e.target.value})} required /></Flex>
 <Flex>Width<br /><Input value={receive?.width === undefined ? "" : receive?.width} type="text" onChange={e => setReceive({...receive,width:e.target.value})} required /></Flex>
 <Flex>Length<br /><Input value={receive?.length === undefined ? "" : receive?.length} type="text" onChange={e => setReceive({...receive,length:e.target.value})} required /></Flex>
 <Flex>Pounds<br /><Input value={receive?.pounds === undefined ? "" : receive?.pounds} type="text" onChange={e => setReceive({...receive,pounds:e.target.value})} required /></Flex>
 <Flex>OutStanding<br /><Input style={{color:receive?.outstanding !== 0 ? "red" : "black"}} value={receive?.outstanding === undefined ? "" : receive?.outstanding} type="text"  required /></Flex>
 <Flex>Cost<br /><Input value={receive?.extprice === undefined ? "" : currency(receive?.extprice)} type="text" onChange={e => setReceive({...receive,extprice:e.target.value})} required /></Flex>
   </Flexed>
   <Flexed>
    <Flex>
    {sameitems.length > 0 ? 
   <>

   <div><u>Related Items</u></div>
   {
    sameitems.map(item => {
     return  <div>{item.size} - {item.currentInventory}  @ {item.bin}</div>
    })
   }</> 
   : ""}
    </Flex>

    <Flex flex="2">Notes:<br /><Textarea rows="3" value={receive.notes} onChange={e => setReceive({...receive,notes:e.target.value})}></Textarea>
    <br /> Condition Received<br /><select onChange={e => setReceive({...receive,condition:e.target.value})} required>
<option value="Good" >Good</option>
<option value="Fair" >Fair</option>
<option value="Poor" >Poor</option>
<option value="Returned" >Returned</option>
    </select> </Flex>
    <Flex flex="1.2"><br />This item is being automatically added to inventory. DO NOT ADD MANUALLY. Additionally, it will go into Unassigned Tab<br /><br /><Button onClick={receiveit}>Receive Item</Button></Flex>
   </Flexed>




   </>
  )
}

export default ReceiveItem