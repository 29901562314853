import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, Scroll, currency } from '../../utilities/Shared'
import axios from 'axios'
import Download from '../../utilities/Download'
import { useNavigate } from 'react-router-dom'
import { MdDoDisturb, MdSort } from 'react-icons/md'
import { CSVLink, CSVDownload } from "react-csv";
import { RiFileExcel2Fill } from 'react-icons/ri'
import Moment from 'react-moment'
import UserContext from '../../Context/UserContext'


const InvPanel = ({list,search,sortnum,sortit}) => {
  
  const [papers, setPapers] = useState()
  const [total, setTotal] = useState()
  const [csv, setCsv] = useState()
  const [avail, setAvail] = useState(0)


useEffect(() => {



  const getpaper = async() => { 

    await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    .then(res => {
      setPapers(res.data)
    })
    .catch(err => console.log(err))

    }
    getpaper()
  },[])
  


const getcost = (item) => {        
  let a
  let b
 switch(item?.priceby){
   
     case "CWT":
 b = (item.cwt * item.currentInventory) /1000
     a = (item.cwt * b) / 100
   break;
   case "Per M":
       a = (item.currentInventory / 1000) * item.perm
   break;
   case "Roll LBS":
     a = (item.cwt * item.currentInventory) / 100
   break;
   case "Each":
   case "LBS":
   case "Case":
   case "Box":
       a =item.currentInventory  * item.perm
     break;
     case "Lot":
             a = item.perm  * 1
       break;
   default:
      a = null
     break;
   }
  

return a
 }


useEffect(() => {
  let zz = 0
  let vv = []
let sd

  list?.map(item => {
 sd = {
  Id:item.invid,
  Name:item.name,
  Paper_ID:item.paperid,
  Size:item.size,
  Type:item.type,
  Bin:item.bin,
  Quantity:item.currentInventory,
  CWT:item.cwt,
  MWT:item.perm,
Value:getcost(item),
Last_Updated: new Date(item.updatedAt).toLocaleDateString()
}

 
     zz += getcost(item)
 
     vv?.push(sd)

  })
setCsv(vv.sort((a,b) => a.Id - b.Id))
  setTotal(zz)

},[list])

  



const forjobscheck = (item) => {
  
  let k = []
  let t = 0
  item.forjobs?.map(yu => {t += parseFloat(yu.quantity)})

return item.currentInventory - t
}







const nav = useNavigate()
  return (
  <>
 
<div style={{position:"absolute",right:"25px",top:"315px",zIndex:"100",fontSize:"calc(1.3rem + .4vw)",fontWeight:"bold"}} >
  <Button onClick={() => nav('allocated')} style={{position:"absolute",top:"-5px",right:"200%"}} >Allocated</Button>
<CSVLink data={csv ? csv : []}><RiFileExcel2Fill style={{position:"absolute",top:"-5px",right:"120%",color:"#7ABB43",fontSize:"2.5rem"}} /></CSVLink>
  {currency(total)}</div>
  <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}} className="hand">
<Flex onClick={() => sortnum('invid')}>Inv ID</Flex>
<Flex flex="4" onClick={() => sortit('name')}>Description</Flex>
<Flex onClick={() => sortnum('paperid')}>Paper ID</Flex>
<Flex flex="1.5" onClick={() => sortit('size')}>Size</Flex>
<Flex flex="3" onClick={() => sortit('type')}>Type</Flex>
<Flex onClick={() => sortit('bin')}>Bin#</Flex>
<Flex >Available</Flex>
<Flex onClick={() => sortnum('currentInventory')}>OnHand</Flex>

<Flex >CWT</Flex>
<Flex >Unit $</Flex>

<Flex flex="1.1">Value</Flex>

<Flex flex=".1"></Flex>
  </Flexed>
<Scroll height="500px">
{
    list?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search?.toLowerCase()) || item.invid?.toString().includes(search) || item.paperid?.toString().includes(search) || item.bin?.toString().toLowerCase().includes(search) || item.size?.toString().includes(search) || item.type?.toLowerCase().includes(search)}).map((item,index) => {
        return <Flexed key={index} className="hand mainrow_p" borderBottom="1px solid rgb(0,0,0,.3)" padding="4px" onClick={e => nav(`stock/${item._id}`)}>
        <Flex>{item.invid}</Flex>
        <Flex flex="4">{item.name}</Flex>
        <Flex>{item.paperid}</Flex>
        <Flex flex="1.5">{item.type === "Roll Stock" ? item.size.split('X')[0] : item.size || ""}</Flex>
        <Flex flex="3">{item.type || ""}</Flex>
        <Flex>{item.bin}</Flex>
        <Flex color={forjobscheck(item) <= 0 && "red"} fontWeight={forjobscheck(item) <= 0 && "bold"}>{forjobscheck(item)}</Flex>
        <Flex >{item.currentInventory}</Flex>
  
        <Flex>{item.cwt}</Flex>
           <Flex>{item.perm}</Flex>
        
        <Flex flex="1.1">{currency(getcost(item))}</Flex>

          </Flexed>
    }).reverse()
  } 
  </Scroll>


  </>
  )
}

export default InvPanel





