import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'





export const sendEmail = async() => {

    const r = {
        "name":"Testing one more time",
        "sendto":["bryan@plsprint.com"]
    }

const sendmail = async() => {
   
      
await axios.post(`https://plsemail-1a47a63616b9.herokuapp.com/email`,r)
//await axios.post(`http://localhost:5005/email`,r)
.then(res => {console.log(res.data);toast.success('Email Sent')})
.catch(err => console.log(err))
}
sendmail()
}
