import React from 'react'
import { Flex, Flexed, Input, MainWrap } from '../../utilities/Shared'
import { useState } from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import Unassigned from './Unassigned'
import useDebounce from '../../utilities/Debounce/Debounce'
import useActiveJobs from './getpresssesinfo'
import TaskCard from '../../utilities/TaskCard/Taskcard'



const NewSchedule = () => {

    const [search, setSearch] = useState("")



const debouncevalue = useDebounce(search)

const jobs = useActiveJobs()
console.log(jobs)
  return (
    <MainWrap>


<Flexed margin="20px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search For Job" onChange={e => setSearch(e.target.value)} /></Flex>
      </Flexed>
{debouncevalue}
<Tabs defaultIndex={localStorage.getItem('default01')}>
    <TabList >
    <Tab className="react-tabs__tab c" >Unassigned</Tab>
    <Tab className="react-tabs__tab c" >GL40</Tab>
    <Tab className="react-tabs__tab c" >M110</Tab>
    <Tab className="react-tabs__tab c"  >Digital</Tab>
    <Tab  className="react-tabs__tab c" >Colormax</Tab>
    <Tab  className="react-tabs__tab c" >Stitching</Tab>
    <Tab  className="react-tabs__tab c" >Mailing</Tab>
  </TabList>

  <TabPanel ><Unassigned /></TabPanel>
  <TabPanel >GL40</TabPanel>
  <TabPanel >M110</TabPanel>
  <TabPanel >Digital</TabPanel>
  <TabPanel >Colormax</TabPanel>
  <TabPanel >Stitching</TabPanel>
  <TabPanel >Mailing</TabPanel>
</Tabs>




    </MainWrap>
  )
}

export default NewSchedule