import axios from 'axios'
import React, { useState,useEffect } from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
import Vendors from '../../utilities/Vendors';

const Addpaper = ({editinfo,onCancel,reset,setReset}) => {

   
const [info, setInfo] = useState({...editinfo,vendorname:editinfo.vendor?.company?.name})
const [vendorlist, setVendorList] = useState()
useEffect(() => {
  const vendorlist = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/vendor')
    await axios.get('https://highgroundapi.herokuapp.com/vendor')
    .then(res => {setVendorList(res.data)})
    .catch(err => console.log(err))
    }
    vendorlist()
},[])

console.log(editinfo)


console.log(info)


const submit = (e) => {

 e.preventDefault()
    const del = async() => {
      await  axios.post(`https://highgroundapi.herokuapp.com/paper/update/allpaper`,info)
        .then(res =>  {
            console.log(res.data)
            const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin':'*' 
                }
              }
            const tr = {
            name:info.name,
            weight:info.weight,
            vendor:info.vendorname,
            color:info.color,
            coatstyle:info.coatstyle,
            caliper:info.caliper,
            cwt:info.cwt,
            mwt:info.perthou,
            kind:info.kind,
            category:info.category,
            divide:info.divide,
            sheetpackage:info.sheetpackage,
            sheetcarton:info.sheetcarton,
            sheetskid:info.sheetskid,
            mustorder:info.mustorder,
            stockid:info.stockid
            }
            axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatepaper.php`,tr,config)
            .then(res =>  { setReset(!reset);onCancel()})
           .catch(err => console.log(err))
            console.log(tr)
            /*setReset(!reset);onCancel()*/})
        .catch(err => console.log(err))
   
    }
 
 del() 
console.log(info)
}

useEffect(() => {
  switch(info?.category){
    case "Offset / Text / Book" :
      setInfo({...info,divide:950})
      break;
      case "Cover" :
        setInfo({...info,divide:520})
        break;
        case "Bond / Writing" :
          setInfo({...info,divide:374})
          break;
          case "Bristol" :
            setInfo({...info,divide:641})
            break;
            case "Index" :
              setInfo({...info,divide:778})
              break;
              case "Board / Tag" :
                setInfo({...info,divide:864})
                break;
                case "Label" :
                  setInfo({...info,divide:950})
                  break;
                  case "Envelope" :
                    setInfo({...info,divide:374})
                    break;
                    case "Roll" :
                      setInfo({...info,divide:950})
                      break;
      default:

  }
     },[info?.category]) 


    const setnewvendor = (id) => {
console.log(id)
let y = vendorlist.filter(item => item._id === id)
let n =y[0]
console.log(n)
setInfo({...info,vendor:n,vendorname:n.company.name})

     }


    
console.log(info)

  return (
    <Wrap bgcolor="#0E425A">

        <ToastContainer position="bottom-center" theme="dark"/>
        <form onSubmit={submit}>
    <Flexed gap="15px">
<Flex textAlign="left"  flex="2">  <Label>Paper Name</Label><br /><Input type="text" placeholder="Paper Name" value={info?.name} onChange={e => setInfo({...info,name:e.target.value})} required/></Flex>
<Flex flex=".5"><Label>Weight<br /></Label><Input value={info.weight} type="number" onChange={e => setInfo({...info,weight:e.target.value})} required/></Flex>
<Flex textAlign="left" >
<Label>Category</Label><br />
<Selectbox defaultValue={info?.kind} onChange={e => setInfo({...info,kind:e.target.value})} required>
    <option value="" disabled={true} selected>Select Category</option>
        <option value="Sheet Stock">Sheet Stock</option>
        <option value="Roll Stock">Roll Stock</option>
        <option value="Envelope">Envelopes</option>
       
        </Selectbox>
</Flex>

<Flex textAlign="left"  >
<Label>Kind</Label><br />
<Selectbox value={info?.category} onChange={e => setInfo({...info,category:e.target.value})} required>
    <option value="" disabled={true} selected>Select Kind</option>
        <option value="Offset / Text / Book">Offset / Text / Book -- (950)</option>
        <option value="Cover">Cover -- (520)</option>
        <option value="Bond / Writing">Bond / Writing -- (374)</option>
        <option value="Bristol">Bristol -- (641)</option>
        <option value="Index">Index -- (778)</option>
        <option value="Board / Tag">Board / Tag -- (864)</option>
        <option value="Label">Label -- (950)</option>
        <option value="Envelope">Envelopes -- (374)</option>
        <option value="Roll">Roll -- (950)</option>
       
        </Selectbox>
</Flex>
    </Flexed >


    <Flexed gap="15px">
<Flex textAlign="left"  flex="2">
<Label>Vendor</Label><br />
<Selectbox value={info.vendor?._id} onChange={e => setnewvendor(e.target.value)} required>
    <option value="" disabled={true} selected>Select Vendor</option>
{
  vendorlist?.map(item => {
  return  <option value={item._id} selected={item._id === info.vendor?._id ? "selected" : ""}>{item.company.name}</option>
  })
}
       
       
        </Selectbox>

</Flex>
<Flex ><Label>Color<br /></Label><Input type="text"  value={info?.color} onChange={e => setInfo({...info,color:e.target.value})}/></Flex>
<Flex textAlign="left" >
<Label>Coat Style</Label><br />
<Selectbox value={info?.coatstyle} onChange={e => setInfo({...info,coatstyle:e.target.value})} required>
    <option value="" disabled={true} selected>Select Coat Style</option>
        <option value="Gloss">Gloss</option>
        <option value="C1S">C1S</option>
        <option value="C2S">C2S</option>
        <option value="Matte">Matte</option>
       
        </Selectbox>
</Flex>
<Flex ><Label>Caliper<br /></Label><Input type="number" step=".0001"  value={info?.caliper} onChange={e => setInfo({...info,caliper:e.target.value})}/></Flex>
<Flex><Label>Base<br /></Label><Input value={info?.divide} type="number" step="1" onChange={e => setInfo({...info,divide:e.target.value})} required/></Flex>

    </Flexed >

<Flexed>
<Flex ><Label>Sheets/Package<br /></Label><Input value={info?.sheetpackage} type="number" step="1" onChange={e => setInfo({...info,sheetpackage:e.target.value})}/></Flex>
<Flex ><Label>Sheets / Carton<br /></Label><Input value={info?.sheetcarton} type="number" step="1" onChange={e => setInfo({...info,sheetcarton:e.target.value})}/></Flex>
<Flex ><Label>Sheets / Skid<br /></Label><Input value={info?.sheetskid} type="number" step="1" onChange={e => setInfo({...info,sheetskid:e.target.value})}/></Flex>
<Flex ><Label>Must Order In<br /></Label><Input value={info?.mustorder} type="number" step="1" onChange={e => setInfo({...info,mustorder:e.target.value})} /></Flex>
</Flexed>
<Flexed>
<Flex ><Label>CWT<br /></Label><Input value={info?.cwt} type="number" step=".0001" onChange={e => setInfo({...info,cwt:e.target.value})}/></Flex>
<Flex ><Label>Per M<br /></Label><Input value={info?.perthou} type="number" step=".0001" onChange={e => setInfo({...info,perthou:e.target.value})}/></Flex>
<Flex ><Label>Per #<br /></Label><Input value={info?.perlbs} type="number" step=".0001" onChange={e => setInfo({...info,perlbs:e.target.value})}/></Flex>
<Flex ><Label>Default Order In<br /></Label><Input value={info?.defaultorderby} type="text" onChange={e => setInfo({...info,defaultorderby:e.target.value})} /></Flex>
</Flexed>
<Flexed padding="20px 20px 0px 0px" justifyContent="space-bwtween" ><Flex textAlign="right"><Button style={{marginRight:"30px"}} type="button" onClick={onCancel} >Cancel</Button><Button type="submit">Edit Stock</Button></Flex></Flexed>

    </form>
    </Wrap>
  )
}

export default Addpaper
