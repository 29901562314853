import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import UserContext from '../../Context/UserContext'
import axios from 'axios'
import { toast } from 'react-toastify'
import Moment from 'react-moment'
import { sortdate, sortit, sortnum} from '../../utilities/Sort'
import PrintComponent from '../../Printing/Salescsrlist';
import { getsalesrepinfo } from '../../utilities/InfoStuff'
const SalesDash = () => {

const {userInfo } = useContext(UserContext)
const [csrjobs, setCsrjobs] = useState([])
const [salesjobs, setSalesjobs] = useState([])
const [salesreps, setSalesreps] = useState([])
const [order, setOrder] = useState("ASC")
useEffect(() => {

  const getjobs = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    };   
    
    const tr = {
      csr: sessionStorage.getItem('eid')   
}
console.log(tr)
 axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcsrjobs.php`,tr,config)
.then(res => {setCsrjobs(res.data)})
.catch(err => toast.error(err))
  }

  const getsalesjobs = () => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    };   
       
    const tr = {
      csr: sessionStorage.getItem('eid')   
}

 axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getsalesjobs.php`,tr,config)
.then(res => {console.log(res.data);setCsrjobs([...csrjobs, ...res.data]);getsalesreps()})
.catch(err => toast.error(err))
  }

  const getsalesreps = async() => {
    await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
    .then(res => setSalesreps(res.data))
    .catch(err => toast.error(err))
  }
  getjobs()
  getsalesjobs()
},[])



console.log(salesreps)
console.log(csrjobs)
  return (
    <MainWrap>
      <Flexed alignItems="center" >
<Flex style={{fontSize:"2rem",padding:"20px 0px"}}>
Welcome Back {userInfo.preferredName}
</Flex>
<Flex flex="0"  padding="0px 10px 0px 0px"><PrintComponent  salesreps={salesreps}  csrjobs={csrjobs}  title={`${userInfo.firstName} Open Jobs`}/></Flex>
</Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
  <Flex fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,csrjobs,setCsrjobs,setOrder)}>Job#</Flex>
  <Flex flex="1.5" fontSize="var(--headerMedium)" onClick={e => sortit('customer',order,csrjobs,setCsrjobs,setOrder)}>Customer</Flex>
  <Flex  flex="3" fontSize="var(--headerMedium)" onClick={e => sortit('jobname',order,csrjobs,setCsrjobs,setOrder)}>Job Name</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('origquantity',order,csrjobs,setCsrjobs,setOrder)}>Quantity</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('dateentered',order,csrjobs,setCsrjobs,setOrder)}>Entered</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('duedate',order,csrjobs,setCsrjobs,setOrder)}>Due</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('salesrep',order,csrjobs,setCsrjobs,setOrder)}>{userInfo.departments?.some(item => item.label === "CSR") && <>Sales Rep</>}</Flex>
</Flexed>
<div style={{border:"1px solid black"}}>
{
 csrjobs.map(job => {
    return <Flexed className="normalrow hand" >
      <Flex ><a href={`/jobticket/${job?.job}`}>{job.job}</a></Flex>
      <Flex flex="1.5">{job.customer}</Flex>
      <Flex flex="3">{job.jobname}</Flex>
      <Flex>{job.quantity}</Flex>
      <Flex><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Flex>
      <Flex><Moment format="MM/DD/YYYY">{job.duedate}</Moment></Flex>
      <Flex>{userInfo.departments.some(item => item.label === "CSR") && salesreps.filter(rep => rep.empId === job.salesrep).map(rep => (rep.firstName+" "+rep.lastName.substr(0,1)))}</Flex>
      </Flexed>
  })
}
</div>
<br /><br /><br />
    </MainWrap>
  )
}

export default SalesDash