import React, { useContext, useEffect,useState } from 'react'
import { Button, Datepick, Flex, Flexed, Input, MainWrap, Textarea, Wrap, currency } from '../../../utilities/Shared'
import { useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
//import Vendors from '../vendor/Vendors'
import "react-datepicker/dist/react-datepicker.css";
import {POTypes,PLSOrderbylist,PLStypelist} from '../../../utilities/Lists'
//import Dropdown from '../../../utilities/Dropdown'
import axios from 'axios'
import Select from '../../../utilities/Select'
import {  } from '../../../utilities/Lists'
import { IoMdAddCircle } from 'react-icons/io'
import { v4 as uuidv4 } from 'uuid';
import Moment from 'react-moment';
import InventoryBox from '../../../components/InventoryBox';
import Modal from '../../../modals/Modal';

import UserContext from '../../../Context/UserContext';
import PrintComponent from '../../../Printing/CFPPoSheet';
import { AiOutlineDelete } from "react-icons/ai";

const CFPPO = () => {

    const nav = useNavigate()

    const { userInfo } = useContext(UserContext)

    const [duedate, setDuedate] = useState(new Date())
    const [selectedvendor, setSelectedvendor] = useState({})
    const [selectedshipto, setSelectedshipto] = useState({id:"665779b86560608efb471577",name:"CFP"})
    const [forjobsshow, setForjobsshow] = useState(false)
    const [reset, setReset] = useState(false)
   const [type, setType] = useState()
   const [itemslist, setItemslist] = useState([])
   const [selected ,setSelected] = useState({})
   const [selectedItem ,setSelectedItem] = useState({})
   const [paperslist, setPaperslist] = useState({})
   const [supplieslist, setSupplieslist] = useState({})
   const [vendorList, setVendorList] = useState([])
   const [sameitems, setSameitems] = useState([])
   const [orderunit, setOrderunit] = useState({})
   const [priceby, setPriceby] = useState({})
   const [orderby, setOrderby] = useState({})
   const [savedpo, setSavedpo] = useState({})
   const [items, setItems] = useState([])
   const [forjoblist, setForjoblist] = useState([])
   const [q, setQ] = useState()
   const [j, setJ] = useState()
   const [invoice, setInvoice] = useState()
   const [poid, setPoid] = useState("")

   const param = useParams()
   const preset = {
    jobnum:"",
    name:null,
    stockid:null,
    type:null,
    quantity:null,
    orderby:"",
    width:null,
    length:null,
    unitprice:null,
    weight:null,
    pounds:null,
    divide:null,
    mwt:null,
    cwt:null,
    priceby:null,
    extprice:0.00,
    notes:"",
    forjobs:[],
    itemid:null,
    description:""
    }


   
useEffect(() => {

    const getpo = async() => {
        let y = {
            id:param.po
        }
        await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/pos/getpoinfo',y)
        //await axios.post(' http://localhost:5007/pos/getpoinfo',y)
         .then(res => {
          console.log(res.data)
            setSelectedvendor({id:res.data.vendor?._id,name:res.data.vendor.name})
            setSelectedshipto({id:res.data.shipto?._id,name:res.data.shipto.name})
            setInvoice(res.data?.invoice)
            setPoid(res.data.poid)
            setSavedpo(res.data)
            setDuedate(new Date(res.data.duedate))
setItems(res.data?.items)

        //     vendor:selectedshipto.id,
        //     shipto:selectedshipto.id,
        //     items,
        //  dueDate:duedate,
        //  status:0,
        //  invoice:invoice
  
         })
         .catch(err => console.log(err))
}
    getpo()
    
},[param.po,reset])

useEffect(() => {

    const getitems = async() =>{
        let y
      await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/products/getallproducts')
      .then(res => {console.log(res.data);setItemslist(res.data)})              
      .catch(err => console.log())
      }

      const getvendors = async() =>{
        let y
      await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getall')
      .then(res => {console.log(res.data);setVendorList(res.data)})              
      .catch(err => console.log())
      }
      


      getvendors()
      getitems()

    // const vendorlist = async() => {
    //     //await axios.get('https://highgroundapi.herokuapp.com/vendor')
    //     await axios.get('https://highgroundapi.herokuapp.com/vendor')
    //     .then(res => {setVendorList(res.data)})
    //     .catch(err => console.log(err))
    //     }
    //     const p = async() => {
    //         //await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    //         await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    //         .then(res => {setPaperslist(res.data)})
    //         .catch(err => console.log(err)) 
    //     }
    //     const pa = async() => {
    //         //await axios.get('https://highgroundapi.herokuapp.com/supplies')
    //         await axios.get('https://highgroundapi.herokuapp.com/supplies')
    //         .then(res => {setSupplieslist(res.data)})
    //         .catch(err => console.log(err)) 
    //     }
    //     pa()
    //     p()
    //     vendorlist()    
},[])


  
  
  
    useEffect(() => {
    console.log(selectedItem)
    console.log(itemslist)
    
        const newlyselected = itemslist?.filter(item => item._id === selectedItem?.id)
            if(type?.name !== "" || type?.name !== null){
              
              if(newlyselected.length === 0 ){
   setSelected({...selected,name:selectedItem.name,priceunit:"",unitprice:0,size:"",itemid:null})
              
          }else{
            setSelected({...selected,name:newlyselected[0]?.name,itemid:newlyselected[0]?.itemid,priceunit:newlyselected[0]?.priceunit,unitprice:newlyselected[0]?.unitprice,size:newlyselected[0]?.size})
          }
        }
          
          },[selectedItem])
  
          const getmweight = () => {
            setPriceby({id:0,name:""})
            let a = Math.ceil((parseFloat(selected.width) * parseFloat(selected.length) * 2 * parseInt(selected.weight)) / parseInt(selected.divide))
            let b = (a * selected.quantity) /1000
           const newweight =  {...selected,mwt:a,pounds:b}
           setSelected(newweight)
          }
          
         
          
          
          useEffect(() => {
          
            const getCost = () =>  {
          
              let a
            
            switch(priceby.name){
              case "CWT":
                case "Roll LBS":
              a = (selected.cwt * selected.quantity) / 100
            break;
            case "Per M":
             a = (selected.quantity / 1000) * selected.unitprice
            break;
            case "Each":
            case "LBS":
            case "Case":
            case "Lot":
            case "Box":
              a = selected.quantity  * selected.unitprice
              break;
            default:
             a = null
              break;
            }
        
            setSelected({...selected,extprice:a,priceby:priceby.name,orderby:priceby.name})
            }
            getCost()
          },[priceby])
          
          
          
          const addtolist = (e) => {
           
          e.preventDefault()
         console.log(selectedItem)
        const t = {...selected,itemaddid:uuidv4(),name:selectedItem.name}
          
         console.log(t)


          
          if(selected.itemaddid){
            const objIndex = items.findIndex((obj => obj.itemaddid === selected.itemaddid));
            items[objIndex] = t
            setItems(items)
            setSelectedItem({id:null,name:""})
            setSelected(preset)
            setType({id:null,name:""})
            setOrderby({id:null,name:""})
            setPriceby({id:null,name:""})
            setSameitems({})
            }else{
              setItems([...items,t])
              setSelectedItem({id:null,name:""})
                    setSelected(preset)
                    setType({id:null,name:""})
                    setOrderby({id:null,name:""})
                    setPriceby({id:null,name:""})
                    
          }
          
          
          }
          
          
          const setupdateitem = (item) => {
          
          console.log(item)
     
          const newselectedItem = {id:item.id,name:item.name}
          setSelectedItem(newselectedItem)
          setSelected(item)
          const neworder = {id:0,name:item.orderby}
          const newpriceby = {id:0,name:""}
          setOrderby(neworder)
          setPriceby(newpriceby)
          
          }



    console.log(selected) 

const savepo = async(e) => {
  e.preventDefault()
 const ship = vendorList.filter(filt => filt._id === selectedshipto.id)
 const vend = vendorList.filter(filt => filt._id === selectedvendor.id)

  const y = {
    createdBy:userInfo?._id,
        vendor:selectedshipto.id,
        shipto:selectedshipto.id,
        items,
     dueDate:duedate,
     status:0,
     invoice:invoice
    }

 console.log(y)  
 await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/pos/addpo',y)
//await axios.post('http://localhost:5009/pos/addpo',y)
   .then(res => {console.log(res.data)})
   .catch(err => console.log(err))



// console.log(y)
//  axios.post('https://highgroundapi.herokuapp.com/po/add',y)
//    //axios.post('http://localhost:5003/po/add',y)
//     .then(res => {console.log(res.data);toast.success('Po Added.');slip(res.data);nav(`/pos/ponew/${res.data._id}`, { replace: true });
// })
//     .catch(err => console.log(err))

}



const updatepo = async(e) => {
  e.preventDefault()
  const ship = vendorList.filter(filt => filt._id === selectedshipto.id)
 const vend = vendorList.filter(filt => filt._id === selectedvendor.id)

  const y = {
    id:param.po,
    createdBy:userInfo?._id,
        vendor:selectedshipto.id,
        shipto:selectedshipto.id,
        items,
     dueDate:duedate,
     status:0,
     invoice:invoice
    }
    console.log(y)

    await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/pos/updatepo',y)
       .then(res => {
        console.log(res.data);
        setReset(!reset)
       })
       .catch(err => console.log(err))

}









const removewholeitem = (selected) => {

  let h = items?.filter(item => item.itemaddid !== selected.itemaddid)
   console.log(h)
  setItems(h)
  setSelected(preset);
  setSelectedItem({id:null,name:""});
  setType({id:null,name:""});
  setSameitems([]);
  setOrderby({id:null,name:""})
  }



console.log(savedpo)


  return (


    <MainWrap >
         
        <Wrap>
        {/* <Modal show={forjobsshow} header={`Add jobs for this item`} onCancel={() => {setForjobsshow(false)}} ><ForJobs onCancel={() => {setForjobsshow(false)}}  reset={reset} setReset={setReset} forjoblist={forjoblist} setForjoblist={setForjoblist} setSelected={setSelected} selected={selected}/></Modal> */}
<Flexed>
    <Flex padding="25px" textAlign="center" fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="#0E425A">CFP Purchase Order # - {poid}</Flex>
</Flexed>
<form >
<Flexed>
    <Flex>Vendor:<br /><Select list={vendorList?.map(item => {return {"id":item._id,name:item?.name};})} listid="vendor" setValue={setSelectedvendor} value={selectedvendor?.name} required/></Flex>
    <Flex>Shipto:<br /><Select list={vendorList?.map(item => {return {"id":item._id,name:item?.name};})} listid="shipto" setValue={setSelectedshipto} value={selectedshipto.name} required/></Flex>
    <Flex flex=".5">PO Date:<br /><Datepick selected={new Date()} disabled/> </Flex>
    <Flex flex=".5">Due Date:<br /><Datepick selected={duedate}  onChange={(date) => setDuedate(date)} /> </Flex>
</Flexed>
<Flexed style={{marginTop:"10px"}} padding="10px 10px 10px 0px" alignItems="flex-end"><Flex>Vendor Invoice: <Input  value={invoice} onChange={e => setInvoice(e.target.value)}/></Flex><Flex flex="2"></Flex><Flex display="flex" alignItems="center" textAlign="right" >
  <PrintComponent savedpo={savedpo}/>
  {
    savedpo._id ?<Button onClick={e => updatepo(e)} >Update P.O.</Button> : <Button type="button" onClick={e => savepo(e)}>Save P.O.</Button>
  }
  </Flex></Flexed>
</form>
<InventoryBox items={items} setupdateitem={setupdateitem}/>



<hr />


<form onSubmit={addtolist}>

   <Flexed>
   <Flex flex="4">Item<br /><Select list={itemslist?.map(rt => {return {"id":rt?._id,'name':rt?.name,'stockid':rt.itemid};})} listid="itemslist" setValue={setSelectedItem}  value={selectedItem?.name}/></Flex>
   <Flex flex=".5"><Flex flex=".5">Product ID<br /><Input value={selected?.itemid !== null ? selected?.itemid : ""} type="text" disabled/></Flex>
    {/*Job #<br /><Input value={selected?.jobnum === undefined ? "" : selected?.jobnum} type="text" onChange={e => setSelected({...selected,jobnum:e.target.value})} />*/}</Flex>
   </Flexed>
 

  
   <Flexed  margin="15px 0px 0px 0px">
  
   <Flex>Quantity<br /><Input value={selected?.quantity !== null ? selected?.quantity : ""} type="text" onChange={e => setSelected({...selected,quantity:e.target.value})} required/></Flex>
   <Flex >Order By<br />   <select value={orderby.name}  onChange={e => {setOrderby({id:e.target.value,name:e.target.options[e.target.selectedIndex].text})}}>
    <option ></option>
   {PLSOrderbylist.map(rt => {return <option key={rt.name} value={rt.name}>{rt.name}</option>})} 

</select></Flex>
   <Flex >Width<br /><Input value={selected?.width !== null ? selected?.width : ""} type="text" onChange={e => {setSelected({...selected,width:e.target.value})}} /></Flex>
       <Flex >Length<br /><Input value={selected?.length !== null ? selected?.length : ""} type="text" onChange={e => setSelected({...selected,length:e.target.value})}  /></Flex>
       <Flex>Unit Price<br /><Input type="text" value={selected?.unitprice !== null ? selected?.unitprice : ""} onChange={e => setSelected({...selected,unitprice:e.target.value})} />{
   }</Flex>
       <Flex > <br />    <select border={orderby.name <= "" || orderby.name ===  undefined ? "1.5px solid red" : ""} value={priceby.name}  onChange={e => {console.log();setPriceby({id:e.target.value,name:e.target.options[e.target.selectedIndex].text})}}>
    <option ></option>
    {PLStypelist.map(rt => {return <option value={rt.name}>{rt.name}</option>})} 
    </select></Flex>
    
   </Flexed>
   <Flexed  margin="15px 0px 0px 0px">
   <Flex  flex="2">Description:<br /><Textarea rows="5" value={selected?.description !== null ? selected?.description : ""} onChange={e => setSelected({...selected,description:e.target.value})}></Textarea></Flex>
   <Flex flex=".5" style={{width:"100%"}} textAlign="right">
    <Flexed flexDirection="column">
      <Flex margin="10px 0px 0px 0px">Ext Price<br />${isNaN(selected?.extprice) ? 0 : `${currency(selected?.extprice)}`}</Flex>
      <Flex padding="10px 0px 0px 0px">  {selectedItem.id <= "" ? <Button>Add To Database</Button>: selectedItem.name > "" && <Button>Update Database</Button>}</Flex>
    </Flexed>
    
    
   </Flex> 
   </Flexed>

  
<Flexed>
   
<Flex padding="20px">
{selected.itemaddid > "" && <Button bgcolor="rgb(255,0,0,.6)" color="white" onClick={e => removewholeitem(selected)} >Remove Item</Button>}
</Flex>
  
  <Flex textAlign="right" padding="10px 0px"><Button style={{marginRight:"30px",position:"relative",top:"0"}} type="button" onClick={e => {setSelected(preset);setSelectedItem({id:null,name:""});setType({id:null,name:""});setSameitems([]);setOrderby({id:null,name:""})}}>Clear Item</Button><Button type="submit">{selected.itemaddid > "" ? "Update PO Item" : "Add To PO"}</Button></Flex>
</Flexed>
    

   </form>











<br />
<br />

<br />
        </Wrap>
    </MainWrap>

  )
}

export default CFPPO