import React, { useEffect, useState } from 'react'
import { Flex, Flexed, currency } from '../../utilities/Shared'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { sortit, sortnum } from '../../utilities/Sort'
import Collapse from '../../utilities/Collapse'
import Moment from 'react-moment'

const Posearch = ({term,csrreps,salesreps}) => {
    const [pos, SetPOS] = useState([])
    const [order, setOrder] = useState("ASC")

const params = useParams()
const nav = useNavigate()
useEffect(() => {

    const getcustomers =  async() => {
        const po  = {"po":parseInt(params.term)}
        console.log(po)
        await axios.post('https://highgroundapi.herokuapp.com/po/search/all',po)
        .then(res => {console.log(res.data);SetPOS(res.data)})
        .catch(err => console.log(err))
    }
    getcustomers()
},[])
   
console.log(pos)

  return (
    <>
    {pos?.length > 0 && 
    <div style={{border:"1px solid #303030",margin:"30px 0px"}}>
        <Flexed ><Flex  bgColor="#0E425A" color="white" fontSize="calc(1.5rem + .4vw)">PO RESULTS&nbsp;&nbsp;{pos.length}</Flex></Flexed>
      <Flexed margin="0" style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
      <Flex>PO</Flex>
      <Flex>Vendor</Flex>
      <Flex>Job #</Flex>
<Flex flex="3">Description</Flex>
<Flex>Quantity</Flex>

<Flex>Unit Cost</Flex>
<Flex>Total</Flex>
<Flex>Ordered</Flex>
<Flex>Due</Flex>
      </Flexed>
    {pos?.map(item => {
return   <>

 {item.items.length > 1 ?  <div className="hand" style={{background:"#CFCDCC",marginBottom:"8px",border:"1px solid #c0c0c0",padding:"4px 0px"}} ><Collapse margin="0" title={<>&nbsp;&nbsp;<span onClick={() => nav(`/pos/ponew/${item?._id}`)} >{item.ponum}</span>   --  {item.items?.length} <span >item(s)</span   >    ---  {item.vendor?.company?.name}<span style={{float:"right",marginRight:"20px"}}><Moment format="MM/DD/YYYY">{item.dueDate}</Moment></span> <span style={{float:"right",marginRight:"20px"}}><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></span></>} >
      <Flexed margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}}>
      <Flex></Flex>
    
      <Flex>Job #</Flex>
<Flex flex="3">Description</Flex>
<Flex>Quantity</Flex>

<Flex>Unit Cost</Flex>
<Flex>Total</Flex>

      </Flexed>
    
  {item.items.map(ite => {
      return   <Flexed  margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"white",color:"var(--plsBlue)",}}> 
  <Flex></Flex>

  <Flex> <a href={`/jobticket/${ite?.jobnum}`}>{ite?.jobnum}</a></Flex>
<Flex flex="3">{ite?.name}</Flex>

<Flex>{ite?.quantity}</Flex>
<Flex>{parseInt(ite?.unitprice)}&nbsp;{ite?.orderby}</Flex>
<Flex>{currency(ite?.extprice)}</Flex>

      </Flexed>
     /* <Flexed style={{border:"1px solid red"}}><Flex >
                  <Flex style={{border:"1px solid red"}}>{ite.jobnum}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.quantiy}</Flex>
                  <Flex style={{border:"1px solid red"}} flex="3">{ite.itemname}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.unitprice}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.total}</Flex>
                  </Flex>
                  </Flexed> */
     }) }
</Collapse> </div>  :
 <> 
 <Flexed style={{padding:"4px",marginBottom:"8px"}} bgColor="#CFCDCC" className="hand">
 <Flex onClick={() => nav(`/pos/ponew/${item?._id}`)}>{item?.ponum}</Flex>
            <Flex>{item.vendor?.company?.name}</Flex>
            <Flex><a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${item.items[0]?.jobnum}`}>{item.items[0]?.jobnum > 0 ? item.items[0]?.jobnum : ""}</a></Flex>

                  <Flex flex="3">{item.items[0]?.name}</Flex>
                  <Flex>{item.items[0]?.quantity}</Flex>
                  <Flex>{currency(item.items[0]?.unitprice)}&nbsp;{item.items[0]?.orderby}</Flex>
                  <Flex>{currency(item.items[0]?.extprice)}</Flex>
                  <Flex><Moment format="MM/DD/YYYY">{item?.createdAt}</Moment></Flex>
                  <Flex><Moment format="MM/DD/YYYY">{item?.dueDate}</Moment></Flex>
      </Flexed>
      </>
}





</>

    })
       
    }
    </div>}
    </>
  )
}

export default Posearch