import React, { useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Selectbox, Wrap } from '../../../utilities/Shared'
import axios from 'axios'
import { GiInfo } from 'react-icons/gi'

const Ospticket = ({onCancel,osp,job,setReset,reset}) => {


    const [vendorList, setVendorList] = useState([])
    const [info, setInfo] = useState({})
    const [newosp, setNewosp] = useState(osp)


useEffect(() => {
    const vendorlist = async() => {
        //await axios.get('https://highgroundapi.herokuapp.com/vendor')
        await axios.get('https://highgroundapi.herokuapp.com/vendor')
        .then(res => {setVendorList(res.data)})
        .catch(err => console.log(err))
        }
        vendorlist()
},[])
 
const change= (e) => {
    let g = vendorList.filter(filt => filt.company.name === e.target.value)
    if(g.length > 0){setInfo({...info,vendor:e.target.value})}else{setInfo({...info,vendor:null})} 
}


const newinfoset = (e) => {
    e.preventDefault()
   
  let uu = [...newosp,info]

    let r = {
        id:job,
        field:'osp',
         value:uu
       }
 
       const yy = async(r) => {
       await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
       //await axios.post(`http://localhost:5003/jobs/update`,r)
        .then(res => {setReset(!reset);onCancel()})
       .catch(err => console.log(err))
      
}
yy(r)
}

console.log(newosp)

  return (
    <Wrap padding="10px 20px 20px 20px">
         <form onSubmit={e => newinfoset(e)}>
           <Flexed >
            <Flex fontSize="calc(1.2rem + .2vw)" color="#0E425A">Vendor<br />
            <Input  type="text"  list='vendors' defaultValue={info.vendor} onChange={e => change(e)} required />
   <datalist id='vendors'>
{
    vendorList?.map((vend,index) => {
     return   <option key={index} data-item={vend.company.name} value={vend.company.name}  /> 
    })
}
   </datalist>
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" color="#0E425A">Vendor Quote<br /><Input type="text" onChange={e => setInfo({...info,cusquote:e.target.value})} required/></Flex>
        </Flexed>
        <Flexed padding="15px 0px 0px 0px">
            <Flex flex="4" fontSize="calc(1.2rem + .2vw)" color="#0E425A">Description<br /><Input type="text" onChange={e => setInfo({...info,description:e.target.value})} required/></Flex>
            <Flex fontSize="calc(1.2rem + .2vw)" color="#0E425A">Quantity<br /><Input type="text"onChange={e => setInfo({...info,quan1:e.target.value})} required/></Flex>
            <Flex fontSize="calc(1.2rem + .2vw)" color="#0E425A">PO #<br /><Input type="text" onChange={e => setInfo({...info,quote:e.target.value})} required/></Flex> 
        </Flexed>
     <Flexed padding="30px 0px 20px 20px">
        <Flex flex="4"></Flex>
        <Flex><Button color="#0E425A" textAlign="right" onClick={onCancel} type="button">Cancel</Button></Flex>
        <Flex><Button color="#0E425A" textAlign="right" disabled={info.vendor === null ? true : false } >Add Service</Button></Flex>
     </Flexed>
    </form>
    </Wrap>
  )
}

export default Ospticket