import React from 'react'
import { Flex, Flexed } from '../../../utilities/Shared'

const S44S2Est = () => {



  return (
    <>
    <Flexed color="black" padding="10px 0px">
    <Flex fontSize="calc(1rem + .2vw)">S44</Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
    <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
    <Flex fontSize="calc(1rem + .2vw)">$60</Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
    <Flex fontSize="calc(1rem + .2vw)"></Flex>
</Flexed>
 <Flexed color="black" padding="10px 0px">
 <Flex fontSize="calc(1rem + .2vw)">S2</Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
 <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
 <Flex fontSize="calc(1rem + .2vw)">$60</Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
 <Flex fontSize="calc(1rem + .2vw)"></Flex>
</Flexed>
</>
  )
}

export default S44S2Est