export const paperColumns  = [
    {
      id: 1,
      label:"Stockid",
      accessor:"stockid",
      sortable:true,
      colspan:"1"
    },
    {
        id: 2,
        label:"Description",
        accessor:"name",
        sortable:true,
        colspan:"4"
      },
      {
      id: 4,
      label:"Category",
      accessor:"category",
      sortable:true,
      colspan:"2"
    },
      {
      id: 5,
      label:"Kind",
      accessor:"kind",
      sortable:true,
      colspan:"1"
    },
      {
      id: 6,
      label:"Cwt",
      accessor:"cwt",
      sortable:true,
      colspan:"1"
    }
  ]
  
  