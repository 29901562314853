
import { createContext, useState, useEffect, useRef } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Cookies from 'js-cookie';
import { v4 as uuidv4 } from 'uuid';

export const POContext = createContext();



export const POProvider = ({children}) => {

 

  const [selectedItem ,setSelectedItem] = useState({})
  
  const [type ,setType] = useState({})

    const [selected ,setSelected] = useState({})
  const [itemslist, setItemslist] = useState([])
  const [paperslist, setPaperslist] = useState({})
  const [supplieslist, setSupplieslist] = useState({})
  const [vendorList, setVendorList] = useState([])
  const [sameitems, setSameitems] = useState([])
  const [orderunit, setOrderunit] = useState({})
  const [priceby, setPriceby] = useState({})
  const [orderby, setOrderby] = useState({})
  const [items, setItems] = useState([])
  const [forjoblist, setForjoblist] = useState([])

  const preset = {
    jobnum:"",
    name:null,
    stockid:null,
    type:null,
    quantity:null,
    orderby:"",
    width:null,
    length:null,
    unitprice:null,
    weight:null,
    pounds:null,
    divide:null,
    mwt:null,
    cwt:null,
    priceby:null,
    extprice:0.00,
    notes:"",
    forjobs:[]
    }
  

  useEffect(() => {

   

    const vendorlist = async() => {
        //await axios.get('https://highgroundapi.herokuapp.com/vendor')
        await axios.get('https://highgroundapi.herokuapp.com/vendor')
        .then(res => {setVendorList(res.data)})
        .catch(err => console.log(err))
        }
        const p = async() => {
            //await axios.get('https://highgroundapi.herokuapp.com/paper/all')
            await axios.get('https://highgroundapi.herokuapp.com/paper/all')
            .then(res => {setPaperslist(res.data)})
            .catch(err => console.log(err)) 
        }
        const pa = async() => {
            //await axios.get('https://highgroundapi.herokuapp.com/supplies')
            await axios.get('https://highgroundapi.herokuapp.com/supplies')
            .then(res => {setSupplieslist(res.data)})
            .catch(err => console.log(err)) 
        }
        pa()
        p()
        vendorlist()    
},[])

useEffect(() => {
  if(type?.name !== undefined){
switch(type.name){
case "Stock":
  setItemslist(paperslist?.filter(paper => paper.kind === "Sheet Stock"))
  break;
  case "Roll Stock":
  setItemslist(paperslist?.filter(paper => paper.kind === "Roll Stock"))
  break;
  case "Envelopes":
      setItemslist(paperslist?.filter(paper => paper.kind === "Envelope"))
      break;
      case "Inks":
          setItemslist(supplieslist?.filter(paper => paper.category === "Ink"))
   break;
      case "Bindery":
       setItemslist(supplieslist?.filter(paper => paper.category === "Bindery"))
      break;
      case "Custodial":
          setItemslist(supplieslist?.filter(paper => paper.category === "Custodial"))
         break;
         case "Prepress":
          setItemslist(supplieslist?.filter(paper => paper.category === "Prepress"))
         break;
         case "Press":
          setItemslist(supplieslist?.filter(paper => paper.category === "Press"))
         break;
         case "Shipping":
          setItemslist(supplieslist?.filter(paper => paper.category === "Shipping"))
         break;
         case "Maintenance":
          setItemslist(supplieslist?.filter(paper => paper.category === "Maintenance"))
         break;
         case "Boxes":
          setItemslist(supplieslist?.filter(paper => paper.category === "Boxes"))
         break;
  default:
      setItemslist([])
      break;
}
setSelected(preset)  
setSelectedItem({...selectedItem,id:null,name:""})
  }
},[type])



const getsame = async(stockid) => {
  await axios.get(`https://highgroundapi.herokuapp.com/inv/same/${stockid}`)
  .then(res => {setSameitems(res.data)})
  .catch(err => console.log(err))
  }   



  useEffect(() => {
  
        if(type?.name === "Stock" || type?.name === "Roll Stock" || type?.name === "Envelopes"){
            const newlyselected = paperslist.filter(item => item._id === selectedItem?.id)

            if(newlyselected[0]?._id){
              setSelected({...selected,name:newlyselected[0].name,stockid:newlyselected[0].stockid,width:null,length:null,unitprice:null,weight:newlyselected[0].weight,divide:newlyselected[0].divide,mwt:null,extprice:null,quantity:null})
            }else{
              setSelected({...selected,name:selectedItem.name,stockid:null,width:null,length:null,unitprice:null,weight:null,pounds:null,divide:null,mwt:null,extprice:null,divide:null,quantity:null})
     
            }
           
            
    getsame(newlyselected[0]?.stockid)
        }else{
          if(type?.name !== "" || type?.name !== null){
            const newlyselected = itemslist?.filter(item => item._id === selectedItem?.id)
            if(newlyselected.length === 0 ){
return     setSelected({...selected,name:selectedItem.name,stockid:null,width:null,length:null,unitprice:null,extprice:null})
            
        }else{
          setSelected({...selected,name:newlyselected[0]?.name,stockid:newlyselected[0]?.stockid,unitprice:newlyselected[0]?.price})
        }
      }
        }
        },[selectedItem])


   

/*
useEffect(() => {
 
   let b = a * selected.quantity /1000
   console.log(a)
   console.log(selected.quantity)
   console.log(b)
   setSelected({...selected,mwt:a,pounds:b})
},[selected.width,selected.length,selected.divide,selected.weight])
*/

useEffect(() => {
getmweight();


},[selected.quantity,selected.width,selected.length,selected.weight,selected.unitprice,selected.mwt])




const getmweight = () => {
  setPriceby({id:0,name:""})
  let a = Math.ceil((parseFloat(selected.width) * parseFloat(selected.length) * 2 * parseInt(selected.weight)) / parseInt(selected.divide))
  let b = (a * selected.quantity) /1000
 const newweight =  {...selected,mwt:a,pounds:b}
 setSelected(newweight)
}


useEffect(() => {
  const n = getsame(selected.stockid)
  setSameitems(n)
},[selected.itemid])


useEffect(() => {

  const getCost = () =>  {

    let a
  
  switch(priceby.name){
    case "CWT":
    a = (selected.unitprice * selected.pounds) / 100
  break;
  case "Per M":
   a = (selected.quantity / 1000) * selected.unitprice
  break;
  case "Each":
  case "LBS":
  case "Case":
  case "Lot":
  case "Box":
    a = selected.quantity  * selected.unitprice
    break;
  default:
   a = null
    break;
  }
  const newextprice =  {...selected,extprice:a}
  setSelected(newextprice)
  }
  getCost()
},[priceby])



const addtolist = (e) => {
 
e.preventDefault()

const t = {...selected,priceby:priceby.name,orderby:orderby.name,itemid:uuidv4(),type:type.name,forjobs:selected.forjobs}



if(selected.itemid){
  const objIndex = items.findIndex((obj => obj.itemid === selected.itemid));
  items[objIndex] = t
  setItems(items)
  setSelectedItem({id:null,name:""})
  setSelected(preset)
  setType({id:null,name:""})
  setOrderby({id:null,name:""})
  setPriceby({id:null,name:""})
  setSameitems({})
  }else{
    setItems([...items,t])
    setSelectedItem({id:null,name:""})
          setSelected(preset)
          setType({id:null,name:""})
          setOrderby({id:null,name:""})
          setPriceby({id:null,name:""})
          setSameitems({})
  }


}


const setupdateitem = (item) => {


type.name = item.type
const newselectedItem = {id:item.id,name:item.name}
setSelectedItem(newselectedItem)
setSelected(item)
const neworder = {id:0,name:item.orderby}
const newpriceby = {id:0,name:""}
setOrderby(neworder)
setPriceby(newpriceby)

}
  


   





  return (
    <POContext.Provider value={{preset,setType,type,setItemslist,setPaperslist,setVendorList,supplieslist,paperslist,vendorList,itemslist,sameitems,setSameitems,selected ,setSelected,selectedItem ,setSelectedItem,orderunit, setOrderunit,priceby, setPriceby,orderby, setOrderby,addtolist,items,setupdateitem,setItems,forjoblist, setForjoblist}}>
        {children}
    </POContext.Provider>
  )
}

export default POContext