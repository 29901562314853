import React, { useEffect, useState } from "react";
import { Chart } from "react-google-charts";
import { Flex, Flexed } from "../../utilities/Shared";
import axios from "axios";
import Modal from "../../modals/Modal";
import Editsaleschart from "./Editsaleschart";


export const options = {
   
    title : 'Weekly Sales',
    legend: {position: 'none'},
    isStacked: true,
    hAxis: {
        title: 'Weeks'},
    seriesType: 'bars',
   bars: 'vertical',
    series: {1: {type: 'line'}},
 
};

const SalesChart = () =>{

  const [salesreport, setSalesreport] = useState()
  const [reset, setReset] = useState(false)
  const [shoedit, setShowedit] = useState(false)
  const [sid, SetSid] = useState()


  useEffect(() => {
    const year = {
     year:new Date().getFullYear()
   //year:2023
    }
const getsales = async() => {
  await axios.post('https://highgroundapi.herokuapp.com/sales/getsales',year)
  .then(res =>{
    if(res.data[0]){
      SetSid(res.data[0]._id)
    setSalesreport(res.data[0].sales)
          }else{
     let setyear = [
    [`${year.year} Sales`,"Sales by Week",
        { "role": "style"}
    ],
    ["Week 1",0.00,"color: #0E425A"],
    ["Week 2",0.00,"color: #0E425A"],
    ["Week 3",0.00,"color: #0E425A"],
    ["Week 4",0.00,"color: #0E425A"],
    ["Week 5",0.00,"color: #0E425A"],
    ["Week 6",0.00,"color: #0E425A"],
    ["Week 7",0.00,"color: #0E425A"],
    ["Week 8",0.00,"color: #0E425A"],
    ["Week 9",0.00,"color: #0E425A"],
    ["Week 10",0.00,"color: #0E425A"],
    ["Week 11",0.00,"color: #0E425A"],
    ["Week 12",0.00,"color: #0E425A"],
    ["Week 13",0.00,"color: #0E425A"],
    ["Week 14",0.00,"color: #0E425A"],
    ["Week 15",0.00,"color: #0E425A"],
    ["Week 16",0.00,"color: #0E425A"],
    ["Week 17",0.00,"color: #0E425A"],
    ["Week 18",0.00,"color: #0E425A"],
    ["Week 19",0.00,"color: #0E425A"],
    ["Week 20",0.00,"color: #0E425A"],
    ["Week 21",0.00,"color: #0E425A"],
    ["Week 22",0.00,"color: #0E425A"],
    ["Week 23",0.00,"color: #0E425A"],
    ["Week 24",0.00,"color: #0E425A"],
    ["Week 25",0.00,"color: #0E425A"],
    ["Week 26",0.00,"color: #0E425A"],
    ["Week 27",0.00,"color: #0E425A"],
    ["Week 28",0.00,"color: #0E425A"],
    ["Week 29",0.00,"color: #0E425A"],
    ["Week 30",0.00,"color: #0E425A"],
    ["Week 31",0.00,"color: #0E425A"],
    ["Week 32",0.00,"color: #0E425A"],
    ["Week 33",0.00,"color: #0E425A"],
    ["Week 34",0.00,"color: #0E425A"],
    ["Week 35",0.00,"color: #0E425A"],
    ["Week 36",0.00,"color: #0E425A"],
    ["Week 37",0.00,"color: #0E425A"],
    ["Week 38",0.00,"color: #0E425A"],
    ["Week 39",0.00,"color: #0E425A"],
    ["Week 40",0.00,"color: #0E425A"],
    ["Week 41",0.00,"color: #0E425A"],
    ["Week 42",0.00,"color: #0E425A"],
    ["Week 43",0.00,"color: #0E425A"],
    ["Week 44",0.00,"color: #0E425A"],
    ["Week 45",0.00,"color: #0E425A"],
    ["Week 46",0.00,"color: #0E425A"],
    ["Week 47",0.00,"color: #0E425A"],
    ["Week 48",0.00,"color: #0E425A"],
    ["Week 49",0.00,"color: #0E425A"],
    ["Week 50",0.00,"color: #0E425A"],
    ["Week 51",0.00,"color: #0E425A"],
    ["Week 52",0.00,"color: #0E425A"]
]

const yearsave = async() => {
  let info = {
    year:year.year,
    sales:setyear
  }
 await axios.post('http://localhost:5003/sales/add',info)
 .then(res => {setReset(!reset)})
 .catch(err => console.log(err))
}
yearsave() 

          }
   
  })
  .catch(err => console.log(err))
}
getsales()

  },[reset])


  return (
<>
<Modal show={shoedit} header={`Edit Sales Chart`} onCancel={() => {setShowedit(false)}} ><Editsaleschart onCancel={() => {setShowedit(false)}}  reset={reset} setReset={setReset} salesreport={salesreport} setSalesreport={setSalesreport} sid={sid}/></Modal>
    <Chart
  
      chartType="Bar"
      width="100%"
      height="200px"
      data={salesreport}
      options={options}
    />
    <Flexed>{sessionStorage.getItem('eid') === '1537' || sessionStorage.getItem('eid') === '1648' ? <Flex textAlign="right" onClick={() => setShowedit(true)}>Edit Sales</Flex>:""}</Flexed>
    </>
  );
}

export default SalesChart










