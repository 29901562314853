import React, { useState } from 'react'
import { DropZone } from './TaskCardStyles'
import { Flex, Flexed } from '../Shared'

const Drop = () => {

    const [showdrop, setShowdrop] = useState(false)
  return (
   
    
        <section className={showdrop ? "dropzone" : "hide_dropzone"}
        onDragEnter={e => setShowdrop(true)} 
        onDragLeave={e => setShowdrop(false)}
        >
   Drop Here
        </section>
     
   
  
  )
}

export default Drop