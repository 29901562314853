import React, { useRef,useEffect ,useState, useContext} from "react";
import Moment from 'react-moment';
import { v4 as uuidv4 } from 'uuid';
import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Img, Label, Selectbox, Textauto, currency } from "../utilities/Shared";
import { colorchange, employeeConvert } from "../utilities/Convertions";
import axios from "axios";
import UserContext from "../Context/UserContext";
import { getemployeebynamefromempid } from '../utilities/Convertions'
import { TfiCommentsSmiley } from "react-icons/tfi";

export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;

text-transform:capitalize;
font-family: Arial, Helvetica, sans-serif;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
gap:${props => props.gap || ""};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "normal"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border || ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};
border-bottom: ${props => props.borderBottom || ""};
align-items:${props => props.alignItems || "flex-start"};
flex-wrap:wrap;


h2{
    margin:0;
    padding:0;
}
`;



export const Textarea = styled(TextareaAutosize)`

width:100%;
outline:none;
word-wrap: break-word;
display: inline-block;
white-space: pre-wrap;
border-radius: 5px 5px 0px 0px;
border: ${props => props.border || "1px solid lightggrey"};
font-size:${props => props.fontSize || "1.4rem"};
padding:${props => props.padding || "5px 15px 5px 5px"};
text-transform: ${props => props.textTransform || "capitalize"};
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function ShippingTicket({jobinfo,packlist,setReset,reset,billto,item,saveshipping,method}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)
const date = new Date()
const [count, setCount] = useState(0)
const [boxes, setBoxes] = useState(0)
const [shipid, setShipid] = useState()
const { employees } = useContext(UserContext)



useEffect(() => {
  const getshipid = async() => {
    await axios.post('https://highgroundapi.herokuapp.com/ship/getid')
    .then(res => {setShipid(res.data)})
    .catch(err => console.log(err))
              }
              getshipid() 

              const rt = () => {
                let u = 0
                let b = 0
          
                packlist.map(item => {
                  u += parseInt(item.sending)
                  b += parseInt(item.numtype)
                })
                setCount(u)
                setBoxes(b)
              }
  rt() 
},[packlist])



  return (
    <>
      <Flex id="print_component" style={{textAlign:"right",paddingTop:"15px"}}>
        {/* button to trigger printing of target component */}
        <ReactToPrint
        onBeforePrint={() => {
        
        

        }}
        onAfterPrint={() => {  
         saveshipping(shipid)
         
          }}
          trigger={() => <Button bgcolor="buttonface" padding="8px 25px" fontSize="calc(1.1rem + .3vw)" border="1px solid Black" disabled={packlist?.length === 0 || method <= "" ? true : false} >Save & Print</Button>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint  ref={(el) => (componentRef = el)} shipid={shipid} packlist={packlist} billto={billto} jobinfo={jobinfo} method={method} item={item} employees={employees} count={count} boxes={boxes}/>
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);


  }

  render() {
    return (
      
        <Wrapper >

        <Flexed margin="25px 0px 0px 0px">
            <Flex><Img src={LOGO} alt="Logo" style={{height:"110px",left:"0px",}}/></Flex>
            <Flex padding="0px 0px 0px 30px" fontSize="1.6rem"><>4250 118th Ave N<br />Clearwater, Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com</></Flex>
            <Flex flex="1.8" style={{fontSize:"2.2rem",textAlign:"right",top:"10px",right:"30px"}}>Packing List</Flex>
        </Flexed>
        <Flexed margin="30px 0px 0px 0px">
            <Flex flex="1.5" fontSize="1.6rem"><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Sold To</span><br />{this.props.billto[0]?.name}<br />{this.props.billto[0]?.address1}{this.props.billto[0]?.address2 ? <><br />{this.props.billto[0]?.address2}</> : ''} <br />{this.props.billto[0]?.city}, {this.props.billto[0]?.state} {this.props.billto[0]?.zip}</Flex>
            <Flex flex="1.5" fontSize="1.6rem"><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Ship To</span><br />{this.props.item?.locationname ? <>{this.props.item?.locationname}<br /></> : ""}{this.props.item?.address1}{this.props.item?.address2 ? <><br />{this.props.item?.address2}</> : ''} <br />{this.props.item?.city}, {this.props.item?.state} {this.props.item?.zip}</Flex>
          
            <Flex  fontSize="1.6rem" padding="0px 10px"><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Job No.</span><br />{this.props.jobinfo.job}<br /><br /><span style={{fontWeight:"bold",fontSize:"1.9rem",position:"relative",top:"-20px"}}>Ship Date</span><br /><Moment style={{fontSize:"1.6rem",position:"relative",top:"-20px"}} format="MM/DD/YYYY">{this.props.jobinfo?.shipdate || new Date()}</Moment></Flex>
            <Flex  flex="0" fontSize="1.6rem" padding="0px 10px"><span style={{fontWeight:"bold",fontSize:"1.9rem"}}>Shipping&nbsp;# </span><br />{this.props.shipid}</Flex>

        </Flexed>
       
        <Flexed padding="0px 0px 0px 0px" margin="15px 0px 0px 0px"><Flex fontSize="1.9rem">Job Title:{this.props.jobinfo.jobname}</Flex></Flexed>
        <Flexed margin="0px 0px 0px 0px" padding="10px 0px" style={{background:"rgb(0,0,0,.1)",border:"1px solid black",borderBottom:"none",fontWeight:"bold"}} >
            <Flex fontSize="1.7rem"  textAlign="center">Sales Rep</Flex>
            <Flex fontSize="1.7rem"  textAlign="center">P.O. #</Flex>
            <Flex fontSize="1.7rem"  textAlign="center">Order Date </Flex>
            <Flex fontSize="1.7rem"  textAlign="center">Terms</Flex>
            <Flex fontSize="1.7rem"  textAlign="center">Ship Via</Flex>
     
        </Flexed>
        <div style={{border:"1px solid black",position:"relative",width:"100%"}}>
        <Flexed padding="10px 0px" >
              <Flex fontSize="1.7rem"  textAlign="center">{getemployeebynamefromempid(this.props.jobinfo.salesrep,this.props.employees) }</Flex>
            <Flex fontSize="1.7rem"  textAlign="center">{this.props.jobinfo.custpo}</Flex>
            <Flex fontSize="1.7rem"  textAlign="center"><Moment style={{fontSize:"1.7rem"}} format="MM/DD/YYYY">{this.props.jobinfo.dateentered}</Moment></Flex>
            <Flex fontSize="1.7rem"  textAlign="center"> Net 30</Flex>
            <Flex fontSize="1.7rem"  textAlign="center">{this.props.method}</Flex>
        </Flexed>
        <Flexed padding="10px 0px" style={{background:"rgb(0,0,0,.1)",border:"1px solid black",fontWeight:"bold"}} borderBottom="1px solid black">
              <Flex fontSize="1.7rem"  textAlign="left" flex="3">Description</Flex>
            <Flex fontSize="1.7rem"  textAlign="left" flex=".7">Odered</Flex>
            <Flex fontSize="1.7rem"  flex=".7" textAlign="left">Shipped </Flex>
            <Flex fontSize="1.7rem"  textAlign="left" flex="2">Details</Flex>
        </Flexed>
     
          
{this.props.packlist?.map(item => {
 return <div style={{border:"1px solid lightgrey"}}><Flexed padding="10px 0px 0px 0px" margin="0px 0px 0px 0px" className="normalrow" >
 <Flex fontSize="1.7rem"  textAlign="left" flex="3" padding="5px 0px 0px 0px">{item.name}</Flex>
<Flex fontSize="1.7rem"  textAlign="left" flex=".7">{item.requested}</Flex>
<Flex fontSize="1.7rem"  flex=".7" textAlign="left">{item.sending} </Flex>
<Flex fontSize="1.7rem"  textAlign="left" flex="2">
<div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{item.description?.replace(/&amp;/g, "&")}</div>
</Flex>
</Flexed>
{item.notes > "" ? <><Flexed >
<div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{item.notes?.replace(/&amp;/g, "&")}</div>
</Flexed></> : null}
</div>
})

}
       
        </div>
  



        <Flexed>
            <Flex></Flex>
            <Flex flex="3"></Flex>
            <Flex fontSize="1.7rem" textAlign="center">Total Quantity: {this.props.count}</Flex>
            <Flex fontSize="1.7rem" textAlign="center">Total Piece(s): {this.props.boxes}</Flex>
            
            
        </Flexed>
        <Flexed >
            <Flex flex=".7" fontSize="1.7rem"><TfiCommentsSmiley style={{fontSize:"4rem"}}/><br />Thank you<br />We Appreciate<br/>Your Business</Flex>
            <Flex flex="2" fontSize="1.7rem" style={{borderBottom:"1px solid black",bottom:"-70px",margin:"0px 20px 0px 0px"}} >Recieved By:<br />Sign:<br /></Flex>
            <Flex flex="2" fontSize="1.7rem" style={{borderBottom:"1px solid black",bottom:"-70px",margin:"0px 20px 0px 0px"}}><br />Print:<br /></Flex>
        </Flexed>
        
          
        
        
        
        
        
            </Wrapper>
        

  
    );
  }
}



