import React, { useState, useContext, useEffect } from 'react'
import { Button, Flex, Flexed, Input, Selectbox } from '../../utilities/Shared'
import Modal from '../../modals/Modal'
import AddProduct from './AddProduct'
import Addpaper from './Addpaper'
import Dropdown from '../../utilities/Dropdown'
import { addinventorylist } from '../../utilities/Lists'
import Papers from '../../utilities/Papers'
import Select from '../../utilities/Select'
import POContext from '../../Context/POContext'
import { locationareas } from '../../utilities/Lists'
import {POTypes,PLSOrderbylist,PLStypelist,closeitem} from '../../utilities/Lists'
import axios from 'axios'
import { toast } from 'react-toastify'


const Addinventory = ({onCancel,reset, setReset}) => {

    const [openAddProduct, setOpenAddProduct] = useState(false)
 
    const [value, setValue] = useState()
    const [paper, setPaper] = useState()
    const [item, setItem] = useState()
    const [locationarea, setLocationarea] = useState({})
    const [priceby, setPriceby] = useState({})
    const [type, setType] = useState({})
    console.log(value)


 const {paperslist} = useContext(POContext)

useEffect(() => {
  let a = paperslist?.filter(item => item._id === paper?.value)
setItem(a[0])
},[paper])




useEffect(() => {
  let a
  let b
 a = (item?.width * item?.length * 2 * item?.weight) / item?.divide
 b = (a * item?.cwt) / 100 

 setItem({...item,perm:b.toFixed(2),pounds:Math.ceil(a)})
},[item?.width,item?.length])






const submitform = async(e) => {
e.preventDefault()
 let a = {
  name:paper?.label,
paperid:item.stockid,
stockid:item.stockid,
type:item.type,
location:locationarea.name,
size:item.width+"X"+item.length,
bin:item.bin,
history:{
  reason:item.reason,
  was:0,
  isnow:item.quantity,
  differ:0,
  date:new Date(),
  bin:item.bin
},
currentInventory:item.quantity,
priceby:priceby.name,
job:item.po,
cwt:item.cwt,
perm:item?.perm || 0.00,
 }





 const addinv = async(a) => {
  console.log(a)
await axios.post('https://highgroundapi.herokuapp.com/inv/additem',a)
//await axios.post('http://localhost:5003/inv/additem',a)
.then(res => {toast.success('Paper Added To Inventory');setReset(!reset);onCancel()})
.catch(err => console.log('Paper WAS NOT added To Inventory'))
    }

 
addinv(a)

}





  return (
    <>
    <form onSubmit={submitform}>
          <Modal show={openAddProduct} header='Add New Paper' onCancel={() => {setOpenAddProduct(false)}} ><Addpaper onCancel={() => {setOpenAddProduct(false)}} reset={reset} setReset={setReset} /> </Modal>
    <p style={{position:"relative"}}>To add to inventory paper must first be added as a stock! <Button style={{position:"absolute",right:"0",bottom:"-10px"}} onClick={e => setOpenAddProduct(true)}>Add Stock</Button> </p> <br />
   <Flexed>

   {/*} <Flex>Item Type<br /><Select value={type?.name} list={POTypes.map(rt => {return {"id":rt?._id,name:rt?.name};})} listid="potypes" setValue={setType} disabled={item?.itemid ? true : false}/></Flex>*/}
    <Flex flex="3">Paper<br /><Papers setValue={setPaper} value={paper} required/></Flex>

    <Flex flex=".3">Paper Id<br /><Input type="text" value={item?.stockid} disabled /></Flex>
    <Flex flex=".5">Width<br /><Input value={item?.width} onChange={e => setItem({...item,width:e.target.value})} required/></Flex>
    <Flex flex=".5">Length<br /><Input value={item?.length} onChange={e => setItem({...item,length:e.target.value})} required/></Flex>
    
    <Flex>Paper Type<br />
<Selectbox value={item?.type} onChange={e => setItem({...item,type:e.target.value})}>
    <option value=""  >Select Kind</option>
        <option value="Offset / Text / Book">Offset / Text / Book -- (950)</option>
        <option value="Cover">Cover -- (520)</option>
        <option value="Bond / Writing">Bond / Writing -- (374)</option>
        <option value="Bristol">Bristol -- (641)</option>
        <option value="Index">Index -- (778)</option>
        <option value="Board / Tag">Board / Tag -- (864)</option>
        <option value="Label">Label -- (950)</option>
        <option value="Envelope">Envelopes -- (374)</option>
        <option value="Roll">Roll -- (950)</option>
       
        </Selectbox>
</Flex>
   </Flexed>


   <Flexed padding="20px 0px 0px 0px">

      <Flex flex=".5">Quantity<br /><Input value={item?.quantity} onChange={e => setItem({...item,quantity:e.target.value})} required/></Flex>
      <Flex flex=".5">CWT<br /><Input value={item?.cwt} onChange={e => setItem({...item,cwt:e.target.value})} required/></Flex>
    <Flex flex=".5">Per M / Other<br /><Input value={item?.perm} onChange={e => setItem({...item,perm:e.target.value})} /></Flex>
    <Flex flex=".5">Pounds<br /><Input value={item?.pounds} onChange={e => setItem({...item,pounds:e.target.value})} required/></Flex>
      <Flex flex=".5" >Price By<br /><Select border={priceby?.name <= "" ? "1.5px solid red" : ""} list={PLStypelist.map(rt => {return {"id":null,name:rt?.name};})} listid="priceby"  setValue={setPriceby} value={priceby.name}   required/>{
   }</Flex>
   <Flex></Flex>
     </Flexed>


      <Flexed padding="20px 0px 0px 0px">
      <Flex>Bin<br /><Input value={item?.bin} onChange={e => setItem({...item,bin:e.target.value})} required/></Flex>
      <Flex>Location<br /><Select setValue={setLocationarea} listid="locats" value={locationarea?.name} list={locationareas?.map(item => ({id:item?.id,name:item.name}))} required/></Flex>
      <Flex flex="1.5">Reason<br /><Input value={item?.reason} onChange={e => setItem({...item,reason:e.target.value})} required/></Flex>
    <Flex>PO / Job<br /><Input value={item?.po} onChange={e => setItem({...item,po:e.target.value})}/></Flex>
     </Flexed>
     <Flexed><Flex textAlign="right"><Button type="submit">Add to Inventory</Button></Flex></Flexed>
     </form>
     </>
  )
}

export default Addinventory






