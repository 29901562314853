
import { Button, Flex, Flexed, MainWrap, Scroll,Input } from "../../utilities/Shared";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import Gl440 from "./Gl440";
import React, { useContext, useEffect, useRef, useState } from 'react'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'
import { FaCopy } from "react-icons/fa";
import { colorchange, convertSheetSize, getemployeebynamefromempid } from '../../utilities/Convertions'

import { toast } from 'react-toastify';
import UserContext from '../../Context/UserContext'




const Calendar = () => {

  const [activejobs, setActivejobs] = useState()
const [jobs, setJobs] = useState()
const [imp, setImp] = useState([])
const [newer, setNewer] = useState([])
const [ops, setOps] = useState([])
const [digops, setDigops] = useState([])
const [order, setOrder] = useState("ASC")
const [reset, setReset] = useState(false)
const [jobids, setJobids] = useState([])
const [est, setEst] = useState([])


const dragItem = useRef(null);
 const dragOverItem = useRef(null);

const { employees }  = useContext(UserContext)

const textAreaRef = useRef(null);

const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };
    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
        
          const getactive = async() => { 
            let r = {
              job:"123456"
            }
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getactivejobs.php`,r,config)
      .then(res => {
        let u = []
        res.data.map(item => {
u.push(item.job)
        })
        let ids = u
        setActivejobs(res.data)
        setJobids(u)
      })
      .catch(error => { console.log(error)})
          }
const getest = async() => {
  let r = {
    quote:"123456"
  }
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getallimpo.php`,r,config)
.then(res => {setEst(res.data)})
.catch(err => console.log(err))
}
getest()

          getactive()     
    },[reset])

useEffect(() => {
  getimp(jobids)
},[jobids])

    const getimp = async(ids) => { 
      let r ={ids:ids}
      let rr = []
    
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getgl40.php`,r,config)
.then(res => {
  let io =[]
  let newids = []
  res?.data?.map(item => {
   
if( parseInt(item.numforms) > 1){
for(let i=1;i <= parseInt(item?.numforms);i++){ 

io = {
...item,
partofid:`${item.id}${i}`,
partof:item.id,
name:`${i}) ${item.component}`,


} 
rr.push(io)     

}
}else{

io = {
...item,
partofid:0,
partof:item.id,
name:item.component,

} 
rr.push(io)
}
newids.push(item.job)
  })
  
  
  getops(newids,rr); 
}) 

.catch(error => { console.log(error)})
    }


    const getops= async(ids,rr) => { 
  
      let f ={ids:ids}
      let op = []
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoffpress.php`,f,config)
  .then(res => {
  
    
rr.map((item,index) => {

let u = res.data.filter(filt => parseInt(filt.partof) === parseInt(item.partof) && parseInt(filt.partofid) === parseInt(item.partofid))

let exists = u.find(el => el.name === "Off Press");
if(exists){

}else{

let uu = activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job))

let hh = {
...item,
stat:u[0]?.name || "Incoming",
impp:activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job)),
newquantity:getquantity(item),
customer:uu[0].customer,
salesrep:getemployeebynamefromempid(uu[0]?.salesrep,employees),
dateentered:uu[0].dateentered,
datedue:uu[0].duedate
}
op.push(hh)
}
sortnum('job',order,op,setJobs,setOrder);
})

   })
  .catch(error => { console.log(error)})
  
    }
  
  
  const getquantity = (item) => {
let a = parseInt(item.needed) + parseInt(item.mroverride)  + parseInt(item.additionalmr)
if(item.mroverride == 0){
a +=  parseInt(item.mr)
}
let b = a   / parseInt(item.numforms)
return b
  }


  const CopyAllToClipboard = () => {
    var tb = document.getElementById('gl40');
    tb.getElementsByTagName("thead")[0].style.display = "none";
    var range = document.createRange();
                 range.selectNode(document.getElementById('gl40'));
                 window.getSelection().removeAllRanges();
                 window.getSelection().addRange(range);
                 document.execCommand("copy");
                 toast.success(`Copied Job To Clipboard`)
                 tb.getElementsByTagName("thead")[0].style.display = "block";
         }

const CopyToClipboard = (containerid) => {
    var tb = document.getElementById(containerid);
 tb.getElementsByTagName("td")[0].style.display = "none";
  var range = document.createRange();
               range.selectNode(document.getElementById(containerid));
               window.getSelection().removeAllRanges();
               window.getSelection().addRange(range);
              
               document.execCommand("copy");
               tb.getElementsByTagName("td")[0].style.display = "none";
               toast.success(`Copied Job To Clipboard`)
               tb.getElementsByTagName("td")[0].style.display = "block";
         //showTH(containerid);
         }	
       
         
         const setpreview = (job) => {
          let uu
          let yy = est.filter(filt => filt.quote === job.quote && filt.component === job.component)

          if(parseInt(yy[0]?.press) > 7 ){

            uu = parseFloat(yy[0]?.digitaltime1) / parseInt(yy[0]?.numforms)
          }else{

          uu =  (parseFloat(yy[0]?.presstime1) + parseFloat(yy[0]?.presstime2)) / parseInt(yy[0]?.numforms)
          }
        
       return uu.toFixed(2)
         }


               //const handle drag sorting
      const handleSort = () => {
        //duplicate items
        let _fruitItems = [...jobs];
    
        //remove and save the dragged item content
        const draggedItemContent = _fruitItems.splice(dragItem.current, 1)[0];
    
        //switch the position
        _fruitItems.splice(dragOverItem.current, 0, draggedItemContent);
    
        //reset the position ref
        dragItem.current = null;
        dragOverItem.current = null;
    
        //update the actual array
        setJobs(_fruitItems);
      };



    // const [fruitItems, setFruitItems] = React.useState([
    //     {
    //         job:104209,
    //         customer:'American Fundraising Services',
    //         forms:'FORM',
    //         size:'25.000X38.000',
    //         hours:1
    //     },
    //     {
    //         job:104213,
    //         customer:'Direct Mail Systems, Inc.',
    //         forms:'FORM',
    //         size:'25.000X38.000',
    //         hours:2
    //     },
    //     {
    //         job:104208,
    //         customer:'Direct Mail Systems, Inc.',
    //         forms:'FORM',
    //         size:'25.000X38.000',
    //         hours:3
    //     }
    //   ]);
    //   const [newFruitItem, setNewFruitItem] = useState("");
    
    //   //save reference for dragItem and dragOverItem
    //   const dragItem = useRef(null);
    //   const dragOverItem = useRef(null);
    
    //   //const handle drag sorting
    //   const handleSort = () => {
    //     //duplicate items
    //     let _fruitItems = [...fruitItems];
    
    //     //remove and save the dragged item content
    //     const draggedItemContent = _fruitItems.splice(dragItem.current, 1)[0];
    
    //     //switch the position
    //     _fruitItems.splice(dragOverItem.current, 0, draggedItemContent);
    
    //     //reset the position ref
    //     dragItem.current = null;
    //     dragOverItem.current = null;
    
    //     //update the actual array
    //     setFruitItems(_fruitItems);
    //   };
    
    //   //handle name change
    //   const handleNameChange = (e) => {
    //     setNewFruitItem(e.target.value);
    //   };
    
    //   //handle new item addition
    //   const handleAddItem = () => {
    //     const _fruitItems = [...fruitItems];
    //     _fruitItems.push(newFruitItem);
    //     setFruitItems(_fruitItems);
    //   };
     console.log(jobids)
 
      return (   
        <MainWrap padding="90px 30px 10px 10px"  >


          <div >
          <Tabs style={{textAlign:"right"}}>
              <TabList style={{margin:"0"}}>
                <Tab style={{fontSize:"calc(1.1rem + .2vw)"}}>GL440</Tab>
                <Tab style={{fontSize:"calc(1.1rem + .2vw)"}}>GL540</Tab>
                <Tab style={{fontSize:"calc(1.1rem + .2vw)"}}>M110</Tab>
                </TabList>
   
       <TabPanel > <Gl440 jobs={jobs} dragItem={dragItem} dragOverItem={dragOverItem} handleSort={handleSort} est={est}/> </TabPanel>
       <TabPanel> 2 </TabPanel>
       <TabPanel> 3 </TabPanel>

</Tabs>

<Flexed padding="5px" border="1px solid lightgrey" fontSize="calc(1.1rem + .2vw)" bgColor="rgb(255,0,0,.3)"><Flex>Unscheduled Jobs - Awaiting approvals</Flex></Flexed>
<Scroll height="26vh">
{
          jobs?.map((item,index) => {
            return <Flexed padding="5px" fontSize="calc(1.1rem + .2vw)" alignItems="center" fontWeight="bold" color="#38761D" gap="6px" border="1px solid lightgrey"
            onDragStart={() => (dragItem.current = index)}
            onDragEnter={() => {(dragOverItem.current = index)}}
            onDragEnd={handleSort}
            onDragOver={(e) => e.preventDefault()} draggable>
            <Flex flex="1.3" fontSize="calc(.9rem + .2vw)" fontWeight="normal" color="black">{item?.job}</Flex>
            <Flex flex="2" fontSize="calc(.9rem + .2vw)" fontWeight="normal" color="black" whiteSpace="normal">{item?.customer}</Flex>
            <Flex flex="1.5"><Input border="0" type="text" value={item?.name} /></Flex>
            <Flex flex="1" fontSize="calc(.9rem + .2vw)" fontWeight="normal" color="black" whiteSpace="normal">{convertSheetSize(item.sheetl)} X {convertSheetSize(item.sheetw)}</Flex>
            <Flex><Input border="0" type="text" value={setpreview(item)} /></Flex>
            <Flex><Input border="0" type="text" value={item?.quantity} /></Flex>
            <Flex flex="2.3"><Input border="0" type="text" value={item?.method} /></Flex>
            <Flex flex="5"><Input border="0" type="text"  value={item?.impp[0]?.jobname} /></Flex>
            <Flex flex="1.7"><Input border="0" type="text" value={item?.salesrep} /></Flex>
            <Flex padding="5px 0px 0px 0px" ><Moment style={{fontSize:"calc(.9rem + .2vw)",color:"black",fontWeight:"normal"}} format="MM/DD/YY">{item?.dateentered}</Moment> </Flex>
            <Flex padding="5px 0px 0px 0px" ><Moment style={{fontSize:"calc(.9rem + .2vw)",color:"black",fontWeight:"normal"}} format="MM/DD/YY">{item?.datedue}</Moment></Flex>
            <Flex flex="2" fontSize="calc(.9rem + .2vw)" padding="2px 0px" fontWeight="normal" color="black" whiteSpace="normal" bgColor={colorchange(item.stat)}>{item.stat}</Flex>
            <Flex></Flex>
        </Flexed>
          })
         }
</Scroll>
</div>

        </MainWrap>
      ); 
}

export default Calendar

{/* <main className="flex min-h-screen flex-col items-center space-y-4">
<h1 className="text-xl font-bold mt-4">List</h1>
{people.map((person, index) => (
  <div className="relative flex space-x-3 border rounded p-2 bg-gray-100"
    draggable
    onDragStart={() => (dragPerson.current = index)}
    onDragEnter={() => (draggedOverPerson.current = index)}
    onDragEnd={handleSort}
    onDragOver={(e) => e.preventDefault()}
  >
    <p>{person.name}</p>
  </div>
))}
</main> */}


//     console.log(MOCKEVENTS)
//     const [currentDate, setCurrentDate] = useState(new Date())
//     const [currentMonth, setCurrentMonth] = useState(new Date().getMonth())
//     const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
//     const [events, setEvents] = useState(MOCKEVENTS)

//     const draggedElDateRef = useRef()
//     const draggedElIdRef = useRef()

//     const getDaysInMonth = (year,month) => {
//         return new Date(year, month +1, 0).getDate();
//         }

        
// const onDragStart = (id) => {
//    draggedElIdRef.current = id
   
// }

// const onDragEnter = (e,date) => {
//     e.preventDefault()
//     draggedElDateRef.current = date
//  }

//  const onDragEnd = (e) => {
//     e.preventDefault()
//     const updatedEvents = events.map(ev => {
// if(ev.id === draggedElIdRef.current){
// ev.date= draggedElDateRef.current
// }
// return ev
//     })
//     onDragEvents(updatedEvents)
//  }

//  const onDragEvents = (updatedEvents) => {
// setEvents(updatedEvents)
//  }
//     const nextMonth = () => {
//         if(currentMonth < 11){
//     setCurrentMonth(prev => prev +1)
//         }else{
//             setCurrentMonth(0)
//             setCurrentYear(prev => prev +1)
//         }
//     }

//     const prevMonth = () => {
//         if(currentMonth > 0){
//     setCurrentMonth(prev => prev -1)
//         }else{
//             setCurrentMonth(11)
//             setCurrentYear(prev => prev -1)
//         }
//     }


//     const getsortedDays = (currentYear,currentMonth) => {
// const daysInMonth = range(getDaysInMonth(currentYear,currentMonth))
// const index = new Date(currentYear, currentMonth,1).getDay()
// return [...Array(index === 0? 6 : index -1), ...daysInMonth]
//     }
//     console.log(currentYear)

//   return (
//     <MainWrap>
//         <Flexed>
//         <Flex></Flex>
//         </Flexed>
// <CalWrap>
// <CalHead>
// <FaRegArrowAltCircleLeft onClick={prevMonth} style={{fontSize:"calc(1.3rem + .2vw)"}}/>
// {MONTHS[currentMonth]} {currentYear}
// <FaRegArrowAltCircleRight onClick={nextMonth} style={{fontSize:"calc(1.3rem + .2vw)"}}/>
// </CalHead>
// <DaysGrid>
// {
//     sortedDays(currentDate).map(Day => {
//        return <DaysHeader><p>{Day}</p></DaysHeader>
//     })
// }
// </DaysGrid>
// <CalBody>
//     {
//         getsortedDays(currentYear,currentMonth).map(day => {
//             console.log(day)
//             return <StyledDay onDragOver={e => e.preventDefault()} onDragEnter={e => onDragEnter(e, getDateObj(day, currentMonth, currentYear))} onDragEnd={onDragEnd} active={areDatesTheSame(new Date(), getDateObj(day, currentMonth, currentYear))} ><p>{day}</p>
//               {
//         events.map(event => {
//             console.log(event.date)
//            return areDatesTheSame(event.date,getDateObj(day,currentMonth, currentYear)) && <StyledEvent onDragStart={() => onDragStart(event.id)} draggable><p>{event.job}</p><p>{event.title}</p></StyledEvent>
//         })
//        }
//             </StyledDay>
//         })
//     }
// </CalBody>
// </CalWrap>
//     </MainWrap>