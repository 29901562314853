import { Flex, Flexed } from "../../utilities/Shared";

export const dayOfTheMonth = () => {
return new Date().getDay()
}


export const range = (end) => {
const {result} = Array.from({length : end}).reduce(({result, current}) =>({
result: [...result,current],
current: current +1
}), {result: [], current : 1})
return result
}
export const getDaysInMonth = (year,month) => {
    console.log(new Date(year, month, 0).getDate())
     return new Date(year, month, 0).getDate();
    }


export const ListItem = () => {
   return <Flexed padding="5px" fontSize="calc(1.1rem + .2vw)"  draggable>
        <Flex>1</Flex>
        <Flex>2</Flex>
        <Flex>3</Flex>
        <Flex>4</Flex>
        <Flex>5</Flex>
        <Flex>6</Flex>
        <Flex>7</Flex>
        <Flex>8</Flex>
        <Flex>9</Flex>
        <Flex>10</Flex>
        <Flex>11</Flex>
        <Flex>12</Flex>
        <Flex>13</Flex>
    </Flexed>
}