import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { Flex, Flexed, Input, MainWrap, Wrap, currency,PhoneNumber, Img } from '../../utilities/Shared';
import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from '../../modals/Modal';
import NewEmployee from './NewEmployee';

import DeleteConfirm from '../../utilities/DeleteConfirm';
import { useNavigate } from 'react-router-dom';
import GreenBar from '../../Assets/headerbargreen.png'
import greenflag from '../../Assets/flaggreen.png'
import EmployeePanel from './EmployeePanel';
import Spin from '../../components/Spinner/Spin'



const Employees = () => {

const [search, setSearch] = useState("")
const [emplist, setEmplist] = useState([])
const [nonactive, setNonactive] = useState([])
const [closedpolist, setClosedpolist] = useState([])
const [newEmployee, setNewemployee] = useState(false)
const [reset, setReset] = useState(false)
const [editVendor, setEditVendor] = useState(false)
const [deletevendor, setDeletevendor] = useState(false)
const [vendorinfo, setVendorinfo] = useState({})
const [company, setCompany] = useState("PLS")
const [spin, setSpin] = useState(false)
const nav = useNavigate()

useEffect(() => {
  setSpin(true)
  const getactive = async() => { 
 await axios.get('https://highgroundapi.herokuapp.com/employee/active')
      .then(res => {setEmplist(res.data)})
      .catch(err => {console.log(err);})
  }
  const getnonactive = async() => { 
    await axios.get('https://highgroundapi.herokuapp.com/employee/nonactive')
         .then(res => {setNonactive(res.data)})
         .catch(err => {console.log(err);})
     }

     getactive() 
     getnonactive()
  setSpin(false)
},[reset])





  return (
    <Wrap>


<Modal show={newEmployee} header={`Add New Employee`} onCancel={() => {setNewemployee(false)}} ><NewEmployee setNewemployee={setNewemployee} onCancel={() => {setNewemployee(false)}}  reset={reset} setReset={setReset}/></Modal>
    
   
<div>
{spin && <Spin asOverlay />} 
<Flexed><Flex style={{whiteSpace:"nowrap"}} fontSize="calc(1.2rem + .4vw)" textAlign="left" padding="20px 0px" fontWeight="bold" color="#7ABB43"
         >
         <Flexed style= {{position:"relative",top:"-10px"}}>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Employees</Flex>
          <Flex> <Img src={GreenBar} style={{width:"100%",height:"24px",}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",marginTop:"-10px",position:"relative"}}><Img src={greenflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>


 <Tabs defaultIndex={localStorage.getItem('default01')}>
    <TabList style={{marginBottom:"40px"}} >
    <Tab onClick={e => {setCompany("PLS");localStorage.setItem('default01',0);localStorage.setItem('company','PLS')}}>Current PLS - {emplist?.length || 0} Employees</Tab>
    <Tab onClick={e => {setCompany("CFP");localStorage.setItem('default01',1);localStorage.setItem('company','CFP')}}>Current CFP</Tab>
    <Tab onClick={e => {setCompany("MPS");localStorage.setItem('default01',2);localStorage.setItem('company','MPS')}} >Current MPS</Tab>
    <Tab onClick={e => {setCompany("MPS");localStorage.setItem('default01',2);localStorage.setItem('company','MPS')}} >Inactive - {nonactive?.length || 0} Employees</Tab>

  </TabList>

  <TabPanel >
  <EmployeePanel setlist={setEmplist} search={search} setSearch={setSearch} list={emplist} navi="employees" title="Active Employees" setNewemployee={setNewemployee}/>
  </TabPanel>
{/*/////////////////////////////////////////////////////CFP*/}
  <TabPanel>
  <EmployeePanel setSearch={setSearch} search={search}/>
  </TabPanel>
{/*/////////////////////////////////////////////////////MPS*/}
  <TabPanel>
  <EmployeePanel setSearch={setSearch} search={search}/>
  </TabPanel>
  <TabPanel >
  <EmployeePanel setlist={setNonactive} setSearch={setSearch} search={search} list={nonactive} navi="employees" title="Non Active Employees"/>
  </TabPanel>
  </Tabs>
  </div>
  </Wrap>
  )
}

export default Employees







   
