
import { createContext, useState, useEffect } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client'
import moment from "moment";
import { useParams } from "react-router-dom";



export const StitchingContext = createContext();

export const StitchingProvider = ({children}) => {
const [presslist, setPresslist] = useState([]);
const [presscurve, setPresscurve] = useState([]);
const [cutter, setCutter] = useState([]);
const [folder, setFolder] = useState([]);
const [stitchinginfo, setStitchinginfo] = useState([]);
const [stitch, setStitch] = useState([]);
const [pressStuff, setPressStuff] = useState([]);


const [reset, setReset] = useState(false);
const [info, setInfo] = useState();

const [operations, setOperations] = useState([]);
const[customerinfo, setCustomerinfo] = useState([])
const[pressforms, setPressForms] = useState([])
const [quoteinfo, setQuoteinfo] = useState();
const [pressEstTotal, setPressEstTotal] = useState(0);

const jobnum = useParams()

const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };

  
useEffect(() => {

const getjobinfo = async() => {
    let r = {'jobnum':jobnum.job}
    await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
     //await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
    .then(res => { 
        setCustomerinfo(res.data[0]);
        const getimpo = async() => {
            let q ={
                qoute:customerinfo?.quote
            }
      
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobimposition.php`,q,config)
            .then(res => {
           
                setQuoteinfo(res.data);
            
                getops()})
            .catch(err => console.log(err))
        }
        getimpo()
    })
    .catch(err=>console.log(err))
}
       getjobinfo()
       
},[jobnum,reset])





const getops = async() => {
       
    let stitchstuff = []
    let uu
    let stitchin = []
    let stitchout = []
    let pressinall = []
    let pressoutall = []

    let r = {
        job:jobnum.job
      }
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
   .then(res => {
  setOperations(res.data);
  setpressesttotals();
    res.data.map(item =>{
        if(item.name === "On Stitching"){stitchin.push(item);}
        if(item.name === "Off Stitching"){stitchout.push(item);}
        if(item.name === "Incomplete Stitching"){stitchout.push(item)}
    })
    stitchin.map((stitch,index) => {
         uu = {
in: stitch.changedon,
out: stitchout[index].changedon,
stitchind: getdiff(stitch.changedon,stitchout[index].changedon),
inEmp:stitch.changedby,
outEmp: stitchout[index].changedby
        }
        stitchstuff.push(uu)
    })
let newstitch = {
estTime:getstitchingtime(customerinfo?.quantity,customerinfo?.stitchup,customerinfo?.stitchpockets),
totalTime:getStitchTotals(stitchstuff),
times:stitchstuff
}
    setStitch(newstitch)

})
   .catch(err => console.log(err))
   }

  
const getforminfo = () => {
 
    
 


   
}


const setpress = () => {
let setter = []
    customerinfo.forms.map((form,index) => {
  
        let uu
        let ret = []
        let pressins = []
            let pressouts = []
            let pressstuff = []
        let forms = []
        operations.filter(filt => parseInt(filt.partof) === parseInt(form.item)).map(forminfo => {
            if(forminfo.name === "On Press"){pressins.push(forminfo);}
            if(forminfo.name === "Off Press"){pressouts.push(forminfo);}
            if(forminfo.name === "Incomplete Press"){pressouts.push(forminfo)}
        })
        pressins.map((pressin,index) => {
            uu = {
   in: pressin.changedon,
   out: pressouts[index].changedon,
   pressind: getdiff(pressin.changedon,pressouts[index].changedon),
   inEmp:pressin.changedby,
   outEmp: pressouts[index].changedby,


           }
          
           ret.push(uu) 
       })
      







       let  h ={
        formname:form.name,
        times:ret,
        totalTime:getPressTotals(ret),
        est:setpressest(form),
        
       }
       setter.push(h)

    }) 
    setPressForms(setter)
    //setforms(customerinfo) 
}

const setpressest = (form) => {
    let time = 0  
let t = quoteinfo.filter(filt => filt.component === form.name)
if(form.digital === true){
time = (parseFloat(t[0]?.digitaltime1) + parseFloat(t[0]?.digitaltime2)) 

}else{
  time = (parseFloat(t[0]?.presstime1) + parseFloat(t[0]?.presstime2)) / parseInt(form.numforms) 
}
return time
}

const setpressesttotals = () => {
    let u
   quoteinfo?.map(item => {
   u = parseFloat(item.presstime1) + parseFloat(item.presstime2) + parseFloat(item.digitaltime1) + parseFloat(item.digitaltime2)
   })
setPressEstTotal(u.toFixed(2))
}

  





useEffect(() => {

const getstitching = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getstitching.php`,config)
  .then(res => {
    setStitchinginfo(res.data); 
  })
  .catch(err => console.log(err))
}

getstitching()

 
},[reset])




const getstitchingtime = (quantity,numup,pockets) => {
    let u
     u = stitchinginfo.filter(filt => parseInt(filt.pockets) === parseInt(pockets))
    if(u[0]?.speed > 0){
        let   uio = parseInt(quantity) / parseInt(numup) /u[0]?.speed
         return uio.toFixed(2)
    }
}







const getStitchTotals = (item) => {
let uu = 0
item.map(ite => {
    uu += parseFloat(ite.stitchind)
})
return uu.toFixed(2)
}

const getPressTotals = (item) => {
    let uu = 0
    item.map(ite => {
        uu += parseFloat(ite.pressind)
    })
    return uu.toFixed(2)
    }



const getdiff = (inTime,outTime) => {
let a = 0
let b = 0
let c = []
  let time = 0

         b = moment(inTime)
         a = moment(outTime)
     
        time += a.diff(b, 'minutes') 
        time = time /100
        c = time.toFixed(2)
   

    return c
//    let lengtha = inTimes.length
//     let lengthb = outTimes.length
// let tot =[]
// let u
//     let newmap
// let other
// if(lengtha > lengthb){newmap = inTimes;other=outTimes}else{newmap=outTimes; other=inTimes}

//     newmap?.map((item,index) => {
//         let time
       
      
//           let intime = new Date(other[index])
//           let outtime = new Date(item)
//        time = Math.abs(outtime - intime)

//       console.log(lengtha)
//       console.log(lengthb)
//       console.log(item)
//       console.log(time)
//      // console.log(timeConvert(time/1000/60))
     
//     // let y =timeConvert(time/1000/60)
//     // if(isNaN(y.hours)){y.hours = 0}
//     // if(isNaN(y.minutes)){y.minutes = 0}
//     //  u = {hours:y.hours,minutes:y.minutes}

//     // tot.push(u)
 // })

 //return(tot)

}


const getstichingactual = (operations) => {

//     let stitchingins = []
//     let stitchingouts = []
//     let stitchingindis = []


// operations.map(item => {
//     if(item.name === "On Stitching"){stitchingins.push(item.changedon)}
//     if(item.name === "Off Stitching"){stitchingouts.push(item.changedon)} 
//     if(item.name === "Incomplete Stitching"){stitchingouts.push(item.changedon)}  
 }


// let y = {
//     ins:stitchingins,
//     outs:stitchingouts,
//     total:getdiff(stitchingins,stitchingouts)
// }

// let u = []
// u.push(y)
// setStitchingouttime(u)

// setStitchingtotal(getdiff(stitchingins,stitchingouts))
   






// const updateit = async(e,item,field,table) => {

// let r = {
//     table,
//     field,
//     value:e.target.value, 
//     id:item.id
// }

//     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatedynamic.php`,r,config)
//     .then(res => {
//       setReset(!reset)
//     })
//     .catch(err => console.log(err))
  
//   }



  return (
    <StitchingContext.Provider value={{operations,stitchinginfo,customerinfo,setCustomerinfo,stitch,pressforms,pressEstTotal,getstitchingtime }}>
          
        {children}
    </StitchingContext.Provider>
  )
}

export default StitchingContext