import React, { useContext } from 'react'
import { Flex, Flexed, Input, MainWrap, Scroll, Wrap } from '../../utilities/Shared'
import PressContext from '../../Context/PressContext'

const Folders = () => {
    const {folder,updateit} = useContext(PressContext)





    console.log(folder)
  return (
    <Wrap>
        <Flexed bgColor="#0E425A" padding="6px 0px " margin="0px 0px 10px 0px" color="white" position="sticky" top="0px" style={{zIndex:"100"}}>
            <Flex>Fold Type</Flex>
            <Flex>Hourly Rate</Flex>
            <Flex>Minimum</Flex>
            <Flex>Setup</Flex>
            <Flex>Run Speed</Flex>
            <Flex>Num Folds</Flex>
        </Flexed>
        <Scroll height="70vh">
        
        
            {
                folder.map(fold => {
                  return <Flexed> <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={fold.foldtype} onBlur={e => updateit(e,fold,'foldtype','folders')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={fold.runRate} onBlur={e => updateit(e,fold,'runRate','folders')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={fold.minimum} onBlur={e => updateit(e,fold,'minimum','folders')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={fold.setup} onBlur={e => updateit(e,fold,'setup','folders')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={fold.runspeed} onBlur={e => updateit(e,fold,'runspeed','folders')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={fold.numfolds} onBlur={e => updateit(e,fold,'numfolds','folders')} /></Flex>

                    </Flexed>
                })
            }
          
      
        </Scroll>
    </Wrap>
  )
}

export default Folders