import React from 'react'
import { Card } from './TaskCardStyles'



const Taskcard = ({item,setActivecard,index}) => {

  return (
  <Card 
        draggable
        onDragStart={e => setActivecard(index)}
        onDragEnd={e => setActivecard(null)}
        >
        <div>Id: {item.id} - {item.title} </div>
       
            
            </Card>
  

  )
}

export default Taskcard