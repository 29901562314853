import axios from 'axios'
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import { useEffect, useState } from 'react'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'
import PrintComponent from '../../Printing/Allocation'

const Allocated = () => {

const [list, setList] = useState([])
const [order, setOrder] = useState("ASC")

useEffect(() => {

const getallocates = async() => {
await axios.get('https://highgroundapi.herokuapp.com/inv/get/allocates')
.then(res => setList(res.data))
.catch(err => console.log(err))
}
getallocates()
},[])


const getavail = (item) => {
let t = 0
item.forjobs.map(item => {
    t += parseInt(item.quantity)
})

return item.currentInventory - t
}

console.log(list)  

  return (
    <MainWrap>

        <Flexed>
            <Flex><PrintComponent list={list}/></Flex>
        </Flexed>
        <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
  <Flex fontSize="var(--headerMedium)" onClick={e => sortnum('invid',order,list,setList,setOrder)}>InvId#</Flex>
  <Flex flex="2" fontSize="var(--headerMedium)" onClick={e => sortit('name',order,list,setList,setOrder)}>Item</Flex>
  <Flex   fontSize="var(--headerMedium)" onClick={e => sortit('',order,list,setList,setOrder)}>Size</Flex>
  <Flex >Job(s)</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('currentInventory',order,list,setList,setOrder)}>OnHand</Flex>
  <Flex >Alloted</Flex>
  <Flex  fontSize="var(--headerMedium)" >Available</Flex>


</Flexed>
<div style={{border:"1px solid black"}}>
{
 list.map(job => {
    return <><Flexed className="normalrow hand" fontSize="calc(.8rem + .4vw)">
      <Flex fontSize="calc(.8rem + .4vw)">{job.invid}</Flex>
      <Flex  fontSize="calc(.8rem + .4vw)"flex="2">{job.name}</Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{job.size}</Flex>
      <Flex ></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{job.currentInventory}</Flex>
      <Flex ></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{getavail(job)}</Flex>
   
      </Flexed>

      {job.forjobs.map(item => {return  <Flexed padding="10px 0px">
      <Flex fontSize="calc(.8rem + .4vw)" flex="4"></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{item.job}</Flex>
      <Flex ></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{item.quantity}</Flex>
      <Flex ></Flex>
      </Flexed>})}
     
      </>
  })
}
</div>
<br /><br /><br />

    </MainWrap>
  )
}

export default Allocated