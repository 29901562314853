import React from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox } from '../../utilities/Shared'

const Component = () => {
  return (
    <>
    <Flexed color="#418DA7" padding="10px 0px 0px 0px">
       
       
        <Flex><Label>Component Name</Label><br /><Input   style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        <Flex><Label>Run On</Label><br /><Input   style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        <Flex><Button>Use Project Size</Button></Flex>
    </Flexed>
    
<Flexed color="#418DA7" padding="10px 0px 0px 0px">
    <Flex flex="3"><Label>Paper</Label></Flex>
    <Flex><Label>Flat Size</Label></Flex>
    <Flex><Label>Finish Size</Label></Flex>
    
</Flexed>

     <Flexed  >
    
     <Flex flex="3"><Input   style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
    <Flex>
        <Flexed>
        <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">W</Flex>
        <Flex><Input   style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">L</Flex>
        <Flex><Input  style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        </Flexed>
    </Flex>

    <Flex>
    <Flexed>
    <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">W</Flex>
    <Flex><Input   style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
    <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">L</Flex>
    <Flex><Input  style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        </Flexed>
    </Flex>  
</Flexed>

<Flexed color="#418DA7" padding="10px 0px 0px 0px">
<Flex><Label>Score</Label></Flex>
    <Flex><Label>Perf</Label></Flex>
    <Flex><Label>Die Cut</Label></Flex>
    <Flex><Label>Folding</Label></Flex>
</Flexed>
<Flexed>
<Flex><Selectbox name="projectstyle" id=""  tabindex="11" required="">
<option value="No">No</option>
<option value="Yes">Yes</option>
					</Selectbox></Flex>
    <Flex><Selectbox name="projectstyle" id=""  tabindex="11" required="">
<option value="No">No</option>
<option value="Yes">Yes</option>
					</Selectbox></Flex>
    <Flex><Selectbox name="projectstyle" id=""  tabindex="11" required="">
<option value="No">No</option>
<option value="Yes">Yes</option>
					</Selectbox></Flex>
    <Flex>
    <Selectbox tabindex="40" name="" id="">
	
	<option value="" selected="">Estimator Choice</option>
	<option value="0">None</option>
		<option value="Roll Fold">Roll Fold</option>
		<option value="Letter Fold">Letter Fold</option>
		<option value="Z Fold">Z Fold</option>
		<option value="Accordian Fold">Accordian Fold</option>
		<option value="Double Parallel">Double Parallel</option>
		<option value="Gate Fold">Gate Fold</option>
		<option value="Double Gate Fold">Double Gate Fold</option>
		<option value="1/4 Fold">1/4 Fold</option>
		<option value="Bi Fold">Bi Fold</option>
		<option value="4">4</option>
		<option value="6">6</option>
		<option value="8">8</option>
		<option value="12">12</option>
		<option value="16">16</option>
		<option value="24">24</option>
		<option value="32">32</option>
</Selectbox>
    </Flex>
</Flexed>
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

    </>
  )
}

export default Component



