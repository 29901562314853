import axios from 'axios'
import React, { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';
  import { v4 as uuid } from "uuid";
import Select from '../../utilities/Select';

const Editcfpproduct = ({editinfo,onCancel,reset,setReset}) => {

   
const [info, setInfo] = useState(editinfo)

const unique_id = uuid();
const small_id = unique_id.slice(0,8).replace(/[^0-9]/g, '');;

//updateAllofproduct

console.log(info)
const submit = (e) => {
 e.preventDefault()

    const del = async() => {
            const newinfo = {
                name:info.name,
                vendor:info.vendor,
                priceunit:info.priceunit,
                unitprice:info.unitprice,
                itemid:info.itemid || small_id,
                size:info.size,
                notes:info.notes,
                id:info._id
               
             }   
       console.log(newinfo)
        await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/products/updateAllofproduct',newinfo)
        //await axios.post('http://localhost:5007/products/updateAllofproduct',newinfo)
        .then(res => {setReset(!reset);onCancel(false)})
        .catch(err => console.log(err))
    //     .then(res => {
    //         const config = {
    //             headers: {
    //               'Content-Type': 'application/json',
    //               'Access-Control-Allow-Origin':'*' 
    //             }
    //           };
    //           const tr = {
    //             item:info?.stockid,
    //             description:info?.name,
    //             size:info?.size,
    //             type:info?.type,
    //             averageprice:info?.price,
    //           }
    //           axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateProduct.php`,tr,config)
    //         .then(res =>  { })
    //         .catch(err => console.log(err))
    //     ;setReset(!reset);onCancel(false)})
    //     .catch(err => console.log(err))
     
   }
    del()
    
}




  return (
    <Wrap style={{color:"black",padding:"10px"}}>

        <ToastContainer position="bottom-center" theme="dark"/>
        <form onSubmit={submit}>
    <Flexed gap="15px">
<Flex textAlign="left"  flex="2">  <Label>CFP Product</Label><br /><Input type="text" placeholder="Product Name" value={info.name} onChange={e => setInfo({...info,name:e.target.value})} required/></Flex>
<Flex textAlign="left"  flex="1.5">  <Label>Stock Id</Label><br />{info.itemid || small_id}</Flex>

    </Flexed >



  <Flexed gap="15px" padding="20px 0px 0px 0px" >
 {/*}     <Flex textAlign="left"  flex="2">
<Label>Category</Label><br />
    <Selectbox value={info.category} onChange={e => setInfo({...info,category:e.target.value})}>
        <option value="Ink">Inks</option>
        <option value="Bindery">Bindery</option>
        <option value="Prepress">Prepress</option>
        <option value="Press">Press</option>
        <option value="Shipping">Shipping</option>
        <option value="Boxes">Boxes</option>
        <option value="Custodial">Custodial</option>
        <option value="Maintenance">Maintenance</option>
        </Selectbox>
</Flex>*/}
        <Flex>
        <Label>Size</Label><br />
    <Input value={info.size} onChange={e => setInfo({...info,size:e.target.value})} />
    </Flex>
    <Flex>
    <Label>Price</Label><br />
    <Input value={info.unitprice} onChange={e => setInfo({...info,unitprice:e.target.value})} required/>
    </Flex>
    <Flex>
    <Label>Unit</Label><br />
    <Selectbox  value={info?.priceunit} >
    <option  value=""  >Select Order Units</option>
    <option  value="Each">Each</option>
    <option  value="Foot">Foot</option>
    <option  value="Roll">Roll</option>
    <option  value="Case">Case</option>
    <option  value="Square Feet">Square Feet</option>
    <option  value="Yard">Yard</option>
    </Selectbox>
    </Flex>
   <Flex flex="2"></Flex>
    </Flexed>


<Flexed padding="20px 20px 0px 0px" justifyContent="space-bwtween" ><Flex textAlign="right"><Button style={{marginRight:"30px"}} type="button" onClick={onCancel} >Cancel</Button><Button type="submit">Edit Product</Button></Flex></Flexed>

    </form>
    </Wrap>
  )
}

export default Editcfpproduct
