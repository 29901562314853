import {  useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import { IoMdAddCircle } from 'react-icons/io'
import { Flex, Flexed, Img, Input, MainWrap, PhoneNumber, Wrap } from '../../utilities/Shared'
import Modal from '../../modals/Modal'
import NewCustomer from './NewCustomer'
import EditCustomer from './EditCustomer'
import DeleteConfirm from '../../utilities/DeleteConfirm'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import CustomerPanel from './CustomerPanel'
import Spin from '../../components/Spinner/Spin'
import GreenBar from '../../Assets/headerbargreen.png'
import greenflag from '../../Assets/flaggreen.png'
import OrangeBar from '../../Assets/headerbarorange.png'
import orangeflag from '../../Assets/flagorange.png'
import UserContext from '../../Context/UserContext'

const Customers = () => {

    const [search, setSearch] = useState("")
const [vendorlist, setVendorlist] = useState([])
const [proslist, setProslist] = useState([])
const [newVendor, setNewVendor] = useState(false)
const [editVendor, setEditVendor] = useState(false)
const [deletevendor, setDeletevendor] = useState(false)
const [reset, setReset] = useState(false)
const [vendorinfo, setVendorinfo] = useState({})

const [order, setOrder] = useState("ASC")
    
const {userInfo,spin,setSpin } = useContext(UserContext)


const nav = useNavigate()


useEffect(() => {

    const getvendorlist = async() => { 
        setSpin(true)
        await axios.get('https://highgroundapi.herokuapp.com/customers/all')
      //axios.get('http://localhost:5003/customers/all')
          .then(res => {console.log(res.data);

            setVendorlist(res.data.filter(item => item.pros === "1"))
            setProslist(res.data.filter(item => item.pros === "0"))})
          .catch(err => {console.log(err)}) 
          setSpin(false)
    }
    getvendorlist() 
    if(!localStorage.getItem('defaultCustomer')){
      localStorage.setItem('defaultCustomer','1')
    }
},[reset])

const DeleteVendor = async() => {

  //  let newinfo = {"id":vendorinfo._id}
 //await axios.post('https://highgroundapi.herokuapp.com/paper/delete/one',)
 // await axios.post('https://highgroundapi.herokuapp.com/vendor/remove',newinfo)
 // .then(res => {
 //   const config = {
  //    headers: {
   //     'Content-Type': 'application/json',
   //     'Access-Control-Allow-Origin':'*' 
   //   }
   // };
   // const tr = {"id":vendorinfo_id}
    
  //axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removepaper.php`,tr,config);
  //axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removevendor.php`,tr,config)
  //.then(res => {setReset(!reset);setDeletevendor(false)})
  //.catch(err => console.log(err))
 // console.log(tr)
 // })
 // .catch(err => console.log(err))
  
 console.log(proslist)
  }

  return (
    <MainWrap >
    <Wrap style={{padding:"30px 40px 0px 40px"}}>
       {spin && <Spin asOverlay />} 

       <Tabs >
    <TabList>
      <Tab style={{background:"#7ABB43",color:"white"}} onClick={e => localStorage.setItem('defaultCustomer',1)} >Customers</Tab>
      <Tab style={{background:"#EA8D1F",color:"white"}} onClick={e => localStorage.setItem('defaultCustomer',2)}>Prospects</Tab>
    </TabList>

    <TabPanel>
    <Flexed><Flex style={{whiteSpace:"nowrap"}} fontSize="calc(1.2rem + .4vw)" textAlign="left" padding="20px 0px" fontWeight="bold" color="#7ABB43"
         >
         <Flexed style= {{position:"relative",top:"-10px"}}>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Customers</Flex>
          <Flex> <Img src={GreenBar} style={{width:"100%",height:"24px"}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",marginTop:"-10px",position:"relative"}}><Img src={greenflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>
 <Modal show={newVendor} width="60%" onCancel={() => {setNewVendor(false)}} ><NewCustomer onCancel={() => {setNewVendor(false)}}  reset={reset} setReset={setReset}/></Modal>
    <Modal show={editVendor} header={`Edit Customer`} onCancel={() => {setEditVendor(false)}} ><EditCustomer onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>
    <Modal show={deletevendor} header={`Confirm you are deleting Customer -- ${vendorinfo?.name}`} onCancel={() => {setDeletevendor(false)}} ><DeleteConfirm editinfo={vendorinfo} reset={reset} setReset={setReset} handleChange={DeleteVendor} onCancel={() => {setDeletevendor(false)}} />
    </Modal>

    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle onClick={e => setNewVendor(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /> </Flex>
      </Flexed>
    <Tabs >
    <TabList >
      <Tab className="react-tabs__tab c">All</Tab>
      <Tab className="react-tabs__tab c" >A</Tab>
      <Tab className="react-tabs__tab c" >B</Tab>
      <Tab className="react-tabs__tab c" >C</Tab>
      <Tab className="react-tabs__tab c" >D</Tab>
      <Tab className="react-tabs__tab c" >E</Tab>
      <Tab className="react-tabs__tab c" >F</Tab>
      <Tab className="react-tabs__tab c" >G</Tab>
      <Tab className="react-tabs__tab c" >H</Tab>
      <Tab className="react-tabs__tab c" >I</Tab>
      <Tab className="react-tabs__tab c" >J</Tab>
      <Tab className="react-tabs__tab c" >K</Tab>
      <Tab className="react-tabs__tab c" >L</Tab>
      <Tab className="react-tabs__tab c" >M</Tab>
      <Tab className="react-tabs__tab c" >N</Tab>
      <Tab className="react-tabs__tab c" >O</Tab>
      <Tab className="react-tabs__tab c" >P</Tab>
      <Tab className="react-tabs__tab c" >Q</Tab>
      <Tab className="react-tabs__tab c" >R</Tab>
      <Tab className="react-tabs__tab c" >S</Tab>
      <Tab className="react-tabs__tab c" >T</Tab>
      <Tab className="react-tabs__tab c" >U</Tab>
      <Tab className="react-tabs__tab c" >V</Tab>
      <Tab className="react-tabs__tab c" >W</Tab>
      <Tab className="react-tabs__tab c" >X</Tab>
      <Tab className="react-tabs__tab c" >Y</Tab>
      <Tab className="react-tabs__tab c" >Z</Tab>
  
    </TabList>

    <TabPanel> <CustomerPanel rowclass="mainrow_c" vendorlist={vendorlist} search={search} setList={setVendorlist} setOrder={setOrder} setDeletevendor={setDeletevendor} order={order}/></TabPanel>
    <TabPanel> <CustomerPanel rowclass="mainrow_c" vendorlist={vendorlist.filter(option => option.name?.startsWith('A') || option.name?.startsWith('a'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('B') || option.name?.startsWith('b'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('C') || option.name?.startsWith('c'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('D') || option.name?.startsWith('d'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('E') || option.name?.startsWith('e'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('F') || option.name?.startsWith('f'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('G') || option.name?.startsWith('g'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('H') || option.name?.startsWith('h'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('I') || option.name?.startsWith('i'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('J') || option.name?.startsWith('j'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('K') || option.name?.startsWith('k'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('L') || option.name?.startsWith('l'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('M') || option.name?.startsWith('m'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('N') || option.name?.startsWith('n'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('O') || option.name?.startsWith('o'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('P') || option.name?.startsWith('p'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('Q') || option.name?.startsWith('q'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('R') || option.name?.startsWith('r'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('S') || option.name?.startsWith('s'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('T') || option.name?.startsWith('t'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('U') || option.name?.startsWith('u'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('V') || option.name?.startsWith('v'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('W') || option.name?.startsWith('w'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('X') || option.name?.startsWith('x'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('Y') || option.name?.startsWith('y'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_c"  vendorlist={vendorlist.filter(option => option.name?.startsWith('Z') || option.name?.startsWith('z'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>

</Tabs>

    </TabPanel>
    <TabPanel>
    <Flexed >
      <Flex style={{whiteSpace:"nowrap"}} fontSize="calc(1.2rem + .4vw)" textAlign="left" padding="20px 0px" fontWeight="bold" color="#EA8D1F"
         >
         <Flexed style= {{position:"relative",top:"-10px"}}>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Prospects</Flex>
          <Flex> <Img src={OrangeBar} style={{width:"100%",height:"24px"}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",marginTop:"-10px",position:"relative"}}><Img src={orangeflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>
 <Modal show={newVendor} width="60%" onCancel={() => {setNewVendor(false)}} ><NewCustomer onCancel={() => {setNewVendor(false)}}  reset={reset} setReset={setReset}/></Modal>
    <Modal show={editVendor} header={`Edit Customer`} onCancel={() => {setEditVendor(false)}} ><EditCustomer onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>
    <Modal show={deletevendor} header={`Confirm you are deleting Customer -- ${vendorinfo?.name}`} onCancel={() => {setDeletevendor(false)}} ><DeleteConfirm editinfo={vendorinfo} reset={reset} setReset={setReset} handleChange={DeleteVendor} onCancel={() => {setDeletevendor(false)}} />
    </Modal>

    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle onClick={e => setNewVendor(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"#EA8D1F"}} /> </Flex>
      </Flexed>
    <Tabs defaultIndex={localStorage.getItem("default1")}>
    <TabList >
      <Tab className="react-tabs__tab p">All</Tab>
      <Tab className="react-tabs__tab p">A</Tab>
      <Tab className="react-tabs__tab p">B</Tab>
      <Tab className="react-tabs__tab p">C</Tab>
      <Tab className="react-tabs__tab p">D</Tab>
      <Tab className="react-tabs__tab p">E</Tab>
      <Tab className="react-tabs__tab p">F</Tab>
      <Tab className="react-tabs__tab p">G</Tab>
      <Tab className="react-tabs__tab p">H</Tab>
      <Tab className="react-tabs__tab p">I</Tab>
      <Tab className="react-tabs__tab p">J</Tab>
      <Tab className="react-tabs__tab p">K</Tab>
      <Tab className="react-tabs__tab p">L</Tab>
      <Tab className="react-tabs__tab p">M</Tab>
      <Tab className="react-tabs__tab p">N</Tab>
      <Tab className="react-tabs__tab p">O</Tab>
      <Tab className="react-tabs__tab p">P</Tab>
      <Tab className="react-tabs__tab p">Q</Tab>
      <Tab className="react-tabs__tab p">R</Tab>
      <Tab className="react-tabs__tab p">S</Tab>
      <Tab className="react-tabs__tab p">T</Tab>
      <Tab className="react-tabs__tab p">U</Tab>
      <Tab className="react-tabs__tab p">V</Tab>
      <Tab className="react-tabs__tab p">W</Tab>
      <Tab className="react-tabs__tab p">X</Tab>
      <Tab className="react-tabs__tab p">Y</Tab>
      <Tab className="react-tabs__tab p">Z</Tab>

    </TabList>

    <TabPanel> <CustomerPanel rowclass="mainrow_p" vendorlist={proslist} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel> <CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('A') || option.name?.startsWith('a'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('B') || option.name?.startsWith('b'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('C') || option.name?.startsWith('c'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('D') || option.name?.startsWith('d'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('E') || option.name?.startsWith('e'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('F') || option.name?.startsWith('f'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('G') || option.name?.startsWith('g'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('H') || option.name?.startsWith('h'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('I') || option.name?.startsWith('i'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('J') || option.name?.startsWith('j'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('K') || option.name?.startsWith('k'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('L') || option.name?.startsWith('l'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('M') || option.name?.startsWith('m'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('N') || option.name?.startsWith('n'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('O') || option.name?.startsWith('o'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('P') || option.name?.startsWith('p'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('Q') || option.name?.startsWith('q'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('R') || option.name?.startsWith('r'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('S') || option.name?.startsWith('s'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('T') || option.name?.startsWith('t'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('U') || option.name?.startsWith('u'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('V') || option.name?.startsWith('v'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('W') || option.name?.startsWith('w'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('X') || option.name?.startsWith('x'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('Y') || option.name?.startsWith('y'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><CustomerPanel rowclass="mainrow_p" vendorlist={proslist.filter(option => option.name?.startsWith('Z') || option.name?.startsWith('z'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>

</Tabs>
    </TabPanel>
  </Tabs>
   
  

                
        
  </Wrap>
  </MainWrap>
  )
}

export default Customers