import React from 'react'
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import { useNavigate } from 'react-router-dom'

const Reports = () => {

const nav = useNavigate()

  return (
    <MainWrap>

        <Flexed fontSize="calc(1.2rem + .2vw)">
            <Flex onClick={() => nav('/daily')} className="hand" fontSize="inherit" >
                Select Weekly Estimates
            </Flex>
        </Flexed>
    </MainWrap>
  )
}

export default Reports