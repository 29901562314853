import React from 'react'
import PrepressTicket from './PrepressTicket'
import { MainWrap, Wrap } from '../../../utilities/Shared'


const Edittime = ({what,item}) => {

console.log(item)
  return (
    <Wrap >
        {
what === "Prepress" && <PrepressTicket item={item}/>
        }



    </Wrap>
  )
}

export default Edittime