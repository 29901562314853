import React, { useEffect, useState } from 'react'
import Dropdown from './Dropdown';
import axios from 'axios';
import { Flex, Flexed } from './Shared';

const Papers = ({setValue,value}) => {
 
  console.log(value)
    const [papers, setPapers] = useState([])

    useEffect(() => {
const getPapers = async() => {
await axios.get(`https://highgroundapi.herokuapp.com/paper/all`)
.then(res => {setPapers(res.data)})
.catch(err => console.log(err))
}
getPapers()
    },[])


  

  return (
   <Flexed>
    <Flex>
     
      <Dropdown  setValue={setValue} value={value} list={papers?.map(paper => ({label:paper?.name,value:paper?._id}))} />
    </Flex>
    </Flexed>
  )
}

export default Papers