import React, { useState } from 'react'
import { TableContainer } from './TableStyles'

const HeaderCell = ({column,sorting,sortTable}) => {
  console.log(sorting.column)
  console.log(column.accessor)
  console.log(sorting.order)
const isDescSorting = sorting?.column === column?.accessor && sorting?.order === "desc";
const isAscSorting = sorting?.column === column?.accessor && sorting?.order === "asc";
const futureSortingOrder = isDescSorting ? "asc" : "desc"
  return (<th key={column.id} colspan={column.colspan} onClick={() => sortTable({'column':column.accessor, 'order':futureSortingOrder})}>
    {column.label}
  {isDescSorting && <span>&nbsp;&nbsp;&#8595;</span>}
  {isAscSorting &&<span>&nbsp;&nbsp;&#8593;</span>}
  </th>)
}


const TableHeader = ({columns,sorting,sortTable}) => {
    return (
    <thead>
    <tr>
      {columns.map((column,index) => (
  <HeaderCell column={column}  sorting={sorting} key={index} sortTable={sortTable}/>
     ) )} 
      <th colspan="2"></th>
      
        </tr>
</thead>)
}


const TableBody = ({data,columns}) => {

 


  return <tbody>
 {
  data.map((item,index) => {
    return <tr key={index}>
{
  columns.map((column,index) => {
    return <td key={index} colspan={column.colspan}>
      {item[column.accessor]}
    </td>
   
  })
}
<td></td>
    <td></td>
    </tr>
  })
 }
</tbody>
}




const Table = ({columns,data}) => {
  console.log(data)
  const [sorting, setSorting] = useState({column:"stockid", order:"asc"})
  const sortTable = (newSorting) => {
    setSorting(newSorting)
  }

  return (
    <TableContainer>
       <TableHeader columns={columns} sorting={sorting} sortTable={sortTable} />
       <TableBody data={data} columns={columns} />
    </TableContainer>
  )
}

export default Table