import React from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { Wrapper,Header } from './ModalStyles'
import Backdrop from "./Backdrop";
import "./Modal.css";



const ModalOverlay = (props) => {

  const content = (
    <Wrapper width={`${props.width || "90%"}`} contentbgcolor={props.contentbgcolor}>
      <Header headertextAlign={props.headertextAlign} headerbgcolor={props.headerbgcolor} headercolor={props.headercolor}>
        <h2>{props.header}</h2>
      </Header>

      <div className={`modal__content`}>
        {props.children}
      </div>
      <footer className={`modal__footer ${props.footerClass}`}>
        {props.footer}
      </footer>
    </Wrapper>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal"));
};

const Modal = (props) => {
  return (
    <React.Fragment>
      {props.show && <Backdrop onClick={props.onCancel} />}
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Modal;