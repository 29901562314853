import React, { useContext } from 'react'
import { Flex, Flexed, Input, MainWrap, Scroll, Wrap } from '../../utilities/Shared'
import PressContext from '../../Context/PressContext'

const Stitching = () => {
    const {stitching,updateit} = useContext(PressContext)






  return (
    <Wrap>
        <Flexed bgColor="#0E425A" padding="6px 0px " margin="0px 0px 10px 0px" color="white" position="sticky" top="0px" style={{zIndex:"100"}}>
            <Flex>Pockets</Flex>
            <Flex>Hourly Rate</Flex>
            <Flex>Speed</Flex>
            <Flex>Setup 1 Pocket</Flex>
            <Flex>setup @ Pockets</Flex>
        
        </Flexed>
        <Scroll height="70vh">
        
        
            {
                stitching.map(stit => {
                  return <Flexed> <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={stit.pockets} onBlur={e => updateit(e,stit,'pockets','stitch')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={stit.cost} onBlur={e => updateit(e,stit,'cost','stitch')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={stit.speed} onBlur={e => updateit(e,stit,'speed','stitch')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={stit.set1} onBlur={e => updateit(e,stit,'set1','stitch')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={stit.set2} onBlur={e => updateit(e,stit,'set2','stitch')} /></Flex>
              

                    </Flexed>
                })
            }
          
      
        </Scroll>
    </Wrap>
  )
}

export default Stitching