import {  useContext, useEffect, useState } from 'react'
import axios from 'axios'
import { IoMdAddCircle } from 'react-icons/io'
import { Flex, Flexed, Img, Input, MainWrap,Wrap } from '../../../utilities/Shared'
import Modal from '../../../modals/Modal'
import NewVendor from './NewVendor'
import EditVendor from './EditVendor'
import DeleteConfirm from '../../../utilities/DeleteConfirm'
import { useNavigate } from 'react-router-dom'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import VendorPanel from './VendorPanel'
import Spin from '../../../components/Spinner/Spin'
import GreenBar from '../../../Assets/headerbargreen.png'
import greenflag from '../../../Assets/flaggreen.png'
import UserContext from '../../../Context/UserContext'

const Vendors = () => {

    const [search, setSearch] = useState("")
const [vendorlist, setVendorlist] = useState([])
const [newVendor, setNewVendor] = useState(false)
const [editVendor, setEditVendor] = useState(false)
const [deletevendor, setDeletevendor] = useState(false)
const [reset, setReset] = useState(false)
const [vendorinfo, setVendorinfo] = useState({})



const {spin,setSpin } = useContext(UserContext)

useEffect(() => {
  setSpin(true)
    const getvendorlist = async() => { 

      await axios.get('https://highgroundapi.herokuapp.com/vendor')
        .then(res => {console.log(res.data);setVendorlist(res.data)})
        .catch(err => console.log(err))
        setSpin(false)
    }
    getvendorlist() 
 
},[reset])

const DeleteVendor = async() => {

    let newinfo = {"id":vendorinfo._id}
 //await axios.post('https://highgroundapi.herokuapp.com/paper/delete/one',)
  await axios.post('https://highgroundapi.herokuapp.com/vendor/remove',newinfo)
  .then(res => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }   
    };
    const tr = {"id":vendorinfo.company.company_id}
    
  //axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removepaper.php`,tr,config);
  axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removevendor.php`,tr,config)
  .then(res => {setReset(!reset);setDeletevendor(false)})
  .catch(err => console.log(err))
  console.log(tr)
  })
  .catch(err => console.log(err))
  
 console.log(editVendor)
  }

  return (
    <MainWrap>
    <Wrap border="1px solid black">
    {spin && <Spin asOverlay />} 
    <Flexed><Flex style={{whiteSpace:"nowrap"}} fontSize="calc(1.2rem + .4vw)" textAlign="left" padding="20px 0px" fontWeight="bold" color="#7ABB43"
         >
         <Flexed style= {{position:"relative",top:"-10px"}}>
          <Flex flex="0" fontSize="calc(1.2rem + .4vw)">Vendors</Flex>
          <Flex> <img src={GreenBar} style={{width:"100%",height:"24px",zIndex:"10"}}/></Flex>
          </Flexed> 
          <Flexed><Flex fontSize="calc(1.2rem + .4vw)" style={{opacity:"1",background:"var(--plsBlue)",height:"24px",marginTop:"-10px",position:"relative"}}><Img src={greenflag} style={{width:"22px",zIndex:"20"}}/></Flex></Flexed>
          
         </Flex></Flexed>
 <Modal show={newVendor} header={`Add New Vendor`} onCancel={() => {setNewVendor(false)}} ><NewVendor onCancel={() => {setNewVendor(false)}}  reset={reset} setReset={setReset}/></Modal>
    <Modal show={editVendor} header={`Edit Vendor`} onCancel={() => {setEditVendor(false)}} ><EditVendor onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>
    <Modal show={deletevendor} header={`Confirm you are deleting vendor -- ${vendorinfo?.company?.name}`} onCancel={() => {setDeletevendor(false)}} ><DeleteConfirm editinfo={vendorinfo} reset={reset} setReset={setReset} handleChange={DeleteVendor} onCancel={() => {setDeletevendor(false)}} /></Modal>

    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle onClick={e => setNewVendor(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /> </Flex>
      </Flexed>
    <Tabs defaultIndex={0}>
    <TabList >
      <Tab>All</Tab>
      <Tab>A</Tab>
      <Tab>B</Tab>
      <Tab>C</Tab>
      <Tab>D</Tab>
      <Tab>E</Tab>
      <Tab>F</Tab>
      <Tab>G</Tab>
      <Tab>H</Tab>
      <Tab>I</Tab>
      <Tab>J</Tab>
      <Tab>K</Tab>
      <Tab>L</Tab>
      <Tab>M</Tab>
      <Tab>N</Tab>
      <Tab>O</Tab>
      <Tab>P</Tab>
      <Tab>Q</Tab>
      <Tab>R</Tab>
      <Tab>S</Tab>
      <Tab>T</Tab>
      <Tab>U</Tab>
      <Tab>V</Tab>
      <Tab>W</Tab>
      <Tab>X</Tab>
      <Tab>Y</Tab>
      <Tab>Z</Tab>
    </TabList>

    <TabPanel> <VendorPanel vendorlist={vendorlist} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel> <VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('A') || option.company.name?.startsWith('a'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('B') || option.company.name?.startsWith('b'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('C') || option.company.name?.startsWith('c'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('D') || option.company.name?.startsWith('d'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('E') || option.company.name?.startsWith('e'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('F') || option.company.name?.startsWith('f'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('G') || option.company.name?.startsWith('g'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('H') || option.company.name?.startsWith('h'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('I') || option.company.name?.startsWith('i'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('J') || option.company.name?.startsWith('j'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('K') || option.company.name?.startsWith('k'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('L') || option.company.name?.startsWith('l'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('M') || option.company.name?.startsWith('m'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('N') || option.company.name?.startsWith('n'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('O') || option.company.name?.startsWith('o'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('P') || option.company.name?.startsWith('p'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('Q') || option.company.name?.startsWith('q'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('R') || option.company.name?.startsWith('r'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('S') || option.company.name?.startsWith('s'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('T') || option.company.name?.startsWith('t'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('U') || option.company.name?.startsWith('u'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('V') || option.company.name?.startsWith('v'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('W') || option.company.name?.startsWith('w'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('X') || option.company.name?.startsWith('x'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('Y') || option.company.name?.startsWith('y'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
    <TabPanel><VendorPanel vendorlist={vendorlist.filter(option => option.company.name?.startsWith('Z') || option.company.name?.startsWith('z'))} search={search} setVendorinfo={setVendorinfo} setEditVendor={setEditVendor} setDeletevendor={setDeletevendor}/></TabPanel>
</Tabs>

  

                
        
  </Wrap>
  </MainWrap>
  )
}

export default Vendors