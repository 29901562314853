import { styled } from "styled-components";

export const Itemwrap = styled.div`
width:100%;
position:relative;
background-color:white;
height:65vh;

`;

export const ItemTop = styled.div`
width:100%;
position:relative;
background-color:#79BB43;
text-align:center;
font-weight:bold;
font-size:var(--headerMedium);
padding:7px 0px;
margin-top:50px;



`;

export const Item = styled.div`

width:100%;
position:relative;
background:${props => props.background || "#b2b2b2"};
text-align:left;
font-weight:bold;
font-size:var(--headerLarge);
padding:7px;
margin:20px auto;
display:block;
&:hover{
    background-color:#7ABB43;   
}

a{
    font-weight:bold;
    font-size:var(--headerLarge);
}
`;

export const Items = styled.div`
width:${props => props.width || "100%"};
position:relative;
min-height:200px;
background-color:white;
padding:0px 20px;



`;