
import { Tab, TabList, Tabs } from 'react-tabs'
import { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, MainWrap, Scroll, TBody, THead, Table, Td, Th, Tr, Wrap } from '../../utilities/Shared'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'
import { FaCopy } from "react-icons/fa";
import { colorchange, getemployeebynamefromempid } from '../../utilities/Convertions'
import { toast } from 'react-toastify';
import StitchingContext from '../../Context/StitchingContext'
const Bindery = ({activejobs,jobids,employeelist}) => {
 

const [jobs, setJobs] = useState()
const [imp, setImp] = useState([])
const [newer, setNewer] = useState([])
const [ops, setOps] = useState([])
const [stids, setStids] = useState([])
const [order, setOrder] = useState("ASC")
const [reset, setReset] = useState(false)

const {stitchinginfo,getstitchingtime}  = useContext(StitchingContext)

console.log(stitchinginfo)
const [employees, setEmployees] = useState([])


const config = {
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin':'*' 
  }
};


useEffect(() => {
  let stitchjobs = []
  let stitchids = []
activejobs.filter(filt => parseInt(filt.stitchup) > 0).map(item => {
  stitchjobs.push(item)
  stitchids.push(item.job)
}
  )
  setStids(stitchids)
  setJobs(stitchjobs)
},[])

useEffect(() => {
  getstitchops()
},[stids])




const getstitchops = async() => {
  console.log(stids)
  let r = {
    ids:stids
  }
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoperationsforactivejobs.php`,r,config)
            .then(res => {
              let ret
              let ter  = []
              jobs.map(item => {
              let uu =   res.data.filter(filt => parseInt(filt.job) === parseInt(item.job))
              uu?.some(cat => cat.name === "Off Stitching") && ter.push(item)
              uu[0]?.name === undefined ? ret = "On Incoming" : ret = uu[0]?.name

              item.stat=ret
              console.log(getstitchingtime(item.quantity,item.stitchup,item.stitchpockets))
              let hh = getstitchingtime(item.quantity,item.stitchup,item.stitchpockets)
            item.esttime= hh
              })
              console.log(ter)
             let uuu = jobs.filter(ar => !ter.find(rm => (rm.job === ar.job) ))
            
        
             setJobs(uuu)
              
            })
            .catch(error => { console.log(error)})
}

console.log(jobs)
// 
//     useEffect(() => {
//         const config = {
//             headers: {
//               'Content-Type': 'application/json',
//               'Access-Control-Allow-Origin':'*' 
//             }
//           };
//           let r = {
//             job:"123456"
//           }
        

     
//             const getemployees = async() => {
//                 //await axios.get(`https://highgroundapi.herokuapp.com/employee/active`)
//                 await axios.get(`https://highgroundapi.herokuapp.com/employee/active`)
//             .then(res => {setEmployees(res.data)})
//             .catch(error => { console.log(error)})
//             }
          
   
//             getemployees()
//           getactive()     
//     },[reset])


// useEffect(() => {
//   getimp(jobids)
// },[jobids])

//     const getimp = async(ids) => { 
//       let r ={ids:ids}
//       let rr = []
    
//       await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getM110.php`,r,config)
// .then(res => {
//   let io =[]
//   let newids = []
//   res.data.map(item => {
   
// if( parseInt(item.numforms) > 1){
// for(let i=1;i <= parseInt(item?.numforms);i++){ 

// io = {
// ...item,
// partofid:`${item.id}${i}`,
// partof:item.id,
// name:`${i}) ${item.component}`,

// } 
// rr.push(io)     

// }
// }else{

// io = {
// ...item,
// partofid:0,
// partof:item.id,
// name:item.component,

// } 
// rr.push(io)
// }
// newids.push(item.job)
//   })
  
 
//   getops(newids,rr); 
// }) 

// .catch(error => { console.log(error)})
//     }


//     const getops= async(ids,rr) => { 
    
//       let f ={ids:ids}
//       let op = []
//       await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoffpress.php`,f,config)
//   .then(res => {
   
    
// rr.map((item,index) => {

// let u = res.data.filter(filt => parseInt(filt.partof) === parseInt(item.partof) && parseInt(filt.partofid) === parseInt(item.partofid))

// let exists = u.find(el => el.name === "Off Press");
// if(exists){

// }else{


// let hh = {
// ...item,
// stat:u[0]?.name || "Incoming",
// impp:activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job)),
// newquantity:getquantity(item)
// }
// op.push(hh)
// }
// sortnum('job',order,op,setJobs,setOrder);
// })

//    })
//   .catch(error => { console.log(error)})
  
//     }
  
  
//   const getquantity = (item) => {
// let a = parseInt(item.needed) + parseInt(item.mroverride) + parseInt(item.additionalmr)
// let b = (a * parseInt(item.versions)) / parseInt(item.numforms)
// return b
//   }

  const CopyAllToClipboard = () => {
    var tb = document.getElementById('m110');
    tb.getElementsByTagName("thead")[0].style.display = "none";
    var range = document.createRange();
                 range.selectNode(document.getElementById('m110'));
                 window.getSelection().removeAllRanges();
                 window.getSelection().addRange(range);
                 document.execCommand("copy");
                 toast.success(`Copied Job To Clipboard`)
                 tb.getElementsByTagName("thead")[0].style.display = "block";
         }

const CopyToClipboard = (containerid) => {
    var tb = document.getElementById(containerid);
 tb.getElementsByTagName("td")[0].style.display = "none";
  var range = document.createRange();
               range.selectNode(document.getElementById(containerid));
               window.getSelection().removeAllRanges();
               window.getSelection().addRange(range);
              
               document.execCommand("copy");
               tb.getElementsByTagName("td")[0].style.display = "none";
               toast.success(`Copied Job To Clipboard`)
               tb.getElementsByTagName("td")[0].style.display = "block";
         //showTH(containerid);
         }	

console.log(jobs)
  
  return (
    <Wrap padding="0">

 <Flexed  margin="5px 0px 0px 0px" bgColor="#0E425A" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Stitcher Active Jobs - {jobs?.length}</Flex>
        <Flex textAlign="right"><Button bgcolor="orange" onClick={e => CopyAllToClipboard()}>Copy Table</Button></Flex>
        </Flexed>
        <Scroll height="70vh"> 
<Table id="m110">
<THead>
<Tr>
<Th flex=".6">&nbsp;</Th>
<Th flex=".8" onClick={e => sortnum('job',order,jobs,setJobs,setOrder)} className="hand">Job #</Th>
<Th flex="4" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)}>Customer</Th>
<Th flex="3" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)} >Description</Th>
<Th flex="1"  >Quantity</Th>
<Th flex="1"  >Est Time</Th>
<Th onClick={e => sortit('salesrep',order,jobs,setJobs,setOrder)}  className="hand">Sales Rep</Th>
<Th flex="1.5" onClick={e => sortdate('dateentered',order,jobs,setJobs,setOrder)} className="hand">Enter Date</Th>
<Th flex="1.5" onClick={e => sortdate('duedate',order,jobs,setJobs,setOrder)}  className="hand">Due Date</Th>
<Th flex="1.5" onClick={e => sortit('stat',order,jobs,setJobs,setOrder)}  className="hand">Status</Th>

</Tr></THead>
 <TBody hbgcolor="#0E425A">
 {jobs?.map((job,index) => {
 return <Tr id={`m110${index}`} className="hand" hcolor="white">
<Td flex=".6"><button onClick={e => CopyToClipboard(`m110${index}`)} class="" className="hand" >Copy</button></Td>
<Td flex=".8"><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Td>
<Td flex="4">{job.customer}</Td>
<Td flex="3">Stitch {job.stitchup} with {job.stitchpockets} pocket(s)</Td>
<Td flex="1"  >{job.quantity}</Td>
<Td flex="1"  >{job.esttime}</Td>
<Td flex="1">{getemployeebynamefromempid(job?.salesrep,employeelist)}</Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Td>
<Td flex="1.5"><Moment format="MM/DD/YYYY">{new Date(job.duedate)}</Moment></Td>
<Td flex="1.5" bgColor={colorchange(job.stat)} padding="3px">{job.stat}</Td>
</Tr>
 })
}
<Tr>
<Td flex=".6"></Td>
<Td flex=".8"></Td>
<Td flex="3"></Td>
<Td flex="1.5"></Td>
<Td ></Td>
<Td > </Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="3"></Td>
<Td ></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
<Td flex="1.5"></Td>
</Tr>
 </TBody>
 </Table>
</Scroll>






    {/*}
    <Flexed  margin="30px 0px 0px 0px" bgColor="#0E425A" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">M110 Active Jobs - {jobs?.length}</Flex></Flexed>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} gap="0px 10px" className="hand">
    <Flex flex=".1"></Flex>
      <Flex fontSize="var(--headerMedium)" onClick={e => sortnum('jobs',order,jobs,setJobs,setOrder)}>Job#</Flex>
      <Flex flex="2" fontSize="var(--headerMedium)" >Customer</Flex>
      <Flex  flex="1.5" fontSize="var(--headerMedium)" >Component</Flex>
      <Flex  flex="1.5" fontSize="var(--headerMedium)" >Sheet Size</Flex>
      <Flex  fontSize="var(--headerMedium)">Quantity</Flex>
      <Flex  fontSize="var(--headerMedium)">Run Type</Flex>
      <Flex  fontSize="var(--headerMedium)">Description</Flex>
      <Flex  fontSize="var(--headerMedium)">Sales Rep</Flex>
      <Flex  fontSize="var(--headerMedium)" >Entered</Flex>
      <Flex  fontSize="var(--headerMedium)" >Due</Flex>
      <Flex  fontSize="var(--headerMedium)">Status</Flex>
    
    </Flexed>
    {!jobs ? <>LOADING</> : <>
    <Scroll  height="78vh" border="1px solid #303030">
    {
     jobs?.map(job => {
        return <Flexed className="normalrows hand" padding="5px" gap="0px 10px" hoverbgcolor="#0E425A" hovercolor="white">
          <Flex  flex=".1"><FaCopy style={{fontSize:"calc(1rem + .2vw)"}} onClick={e => navigator.clipboard.writeText(`${job.job} ${job?.impp[0]?.customer} ${job.name} ${job.sheetl} X ${job.sheetw} ' ' ${job.newquantity} ${job.method} ${job?.impp[0]?.dateentered} ${job?.impp[0]?.duedate} ${job.stat}`)} /></Flex>
          <Flex ><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Flex>
          <Flex flex="2">{job?.impp[0]?.customer}</Flex>
          <Flex flex="1.5">{job.name}</Flex>
          <Flex flex="1.5">{job.sheetl} X {job.sheetw}</Flex>
          <Flex>{Math.ceil(job.newquantity)}</Flex>
          <Flex>{job.method}</Flex>
          <Flex>{job?.impp[0]?.jobname}</Flex>
                    <Flex>{getemployeebynamefromempid(job?.impp[0]?.salesrep,employees)}</Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.impp?.dateentered}</Moment></Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.impp?.duedate}</Moment></Flex>
          <Flex>{job.stat}</Flex>
        
          </Flexed>
      })
    }
    </Scroll>
</> }*/}


    </Wrap>
  )
}

export default Bindery



