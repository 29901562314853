import React, { useEffect, useState } from 'react'
import PrintComponent from './Electionspage'
import { Datepick, Flex, Flexed, Img, Input, MainWrap, Wrapper,currency } from '../utilities/Shared'
import LOGO from '../Assets/pls-logo.png'
import axios from 'axios'
import Moment from 'react-moment'
import { useNavigate }from 'react-router-dom'
import {AiOutlineSave} from 'react-icons/ai'

const elect = [
  {
  id:"1",
  name:"Hillsborough County Supervisor Of Elections",
  jobname:"Voter ID Cards",
  numup:2,
  mr:5,
  stock:"Voter ID Card Perfed Shells",
  prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
  description1:"8.5 X 11 Score and fold to 8.5 X 3.625",
  description2:"\*\* Pre=Perfed Shells (65# Opaque Cover)\n\n4/4 process (no bleed)\n\nMail Processing PLS to Tab (2) and Mail\nCustomer Indicia",
  description3:"Deliver to Tampa BMEU ASAP!",
  address:"601 E. Kennedy Blvd\n16th Floor\nTampa, FL 33602",
  foldtype:"Letter Fold",
 cost:.20,
 quote:34807,
 customerid:1934
  },
  {
    id:"11",
    name:"Hillsborough County Supervisor Of Elections",
    jobname:"Polling Place Change Cards",
    numup:2,
    mr:5,
    stock:"Voter ID Card Perfed Shells",
    prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
    description1:"8.5 X 11 Score and fold to 8.5 X 3.625",
    description2:"\*\* Pre=Perfed Shells (65# Opaque Cover)\n\n4/4 process (no bleed)\n\nMail Processing PLS to Tab (2) and Mail\nCustomer Indicia",
    description3:"Deliver to Tampa BMEU ASAP!",
    address:"601 E. Kennedy Blvd\n16th Floor\nTampa, FL 33602",
    foldtype:"Letter Fold",
   cost:.20,
   quote:34807,
   customerid:1934
    },
      {
        id:"5",
        name:"Hillsborough County Supervisor Of Elections",
        jobname:"ACFN (GOLD) Weekly Postcards",
        numup:4,
        mr:5,
        stock:"80# Opaque Cover",
        prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
        description1:"5.5 X 8.5 Score and fold to 5.5 X 4.25",
        description2:"Bifold SOE Postcard Shells (80# Opaque Cover)\n\n4/4 Process with full bleeds\n\nMail Processing Tab (1) and Mail\n Customer indicia",
        description3:"Deliver to Tampa BMEU ASAP!",
        address:"601 E. Kennedy Blvd\n16th Floor\nTampa, FL 33602",
        foldtype:"Bi Fold",
        cost:.25,
        quote:35222,
        customerid:1934
        },
        {
          id:"7",
          name:"Hillsborough County Supervisor Of Elections",
          jobname:"ACR (PINK) Weekly Postcards",
          numup:4,
          mr:5,
          stock:"80# Opaque Cover",
          prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
          description1:"5.5 X 8.5 Score and fold to 5.5 X 4.25",
          description2:"Bifold SOE Postcard Shells (80# Opaque Cover)\n\n4/4 Process with full bleeds\n\nMail Processing Tab (1) and Mail\n Customer indicia",
          description3:"Deliver to Tampa BMEU ASAP!",
          address:"601 E. Kennedy Blvd\n16th Floor\nTampa, FL 33602",
          foldtype:"Bi Fold",
          cost:.25,
          quote:35217,
          customerid:1934
          },
        {
          id:"9",
          name:"Hillsborough County Supervisor Of Elections",
          jobname:"ACN (BLUE) Weekly Postcards",
          numup:4,
          mr:5,
          stock:"80# Opaque Cover",
          prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
          description1:"5.5 X 8.5 Score and fold to 5.5 X 4.25",
          description2:"Bifold SOE Postcard Shells (80# Opaque Cover)\n\n4/4 Process with full bleeds\n\nMail Processing Tab (1) and Mail\n Customer indicia",
          description3:"Deliver to Tampa BMEU ASAP!",
          address:"601 E. Kennedy Blvd\n16th Floor\nTampa, FL 33602",
          foldtype:"Bi Fold",
          cost:.25,
          quote:35221,
          customerid:1934
          },
  {
    id:"2",
    name:"Pinellas County Supervisor Of Elections",
    jobname:"Voter ID Cards", 
    numup:2,
    mr:5,
    stock:"Voter ID Card Perfed Shells",
    prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
    description1:"8.5 X 11 Score and fold to 8.5 X 3.625",
  description2:"\*\* Pre=Perfed Shells (65# Opaque Cover)\n\n4/4 process (no bleed)\n\nMail Processing PLS to Tab (2) and Mail\nCustomer Indicia",
  description3:"Deliver to Tampa BMEU ASAP!",
  address:"13001 Starkey Road\nLargo, FL 33773",
  foldtype:"Letter Fold",
  cost:.20,
  quote:34839,
  customerid:1932
    },
    {
      id:"10",
      name:"Pinellas County Supervisor Of Elections",
      jobname:"Combo Cards", 
      numup:2,
      mr:5,
      stock:"Combo Cards",
      prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
      description1:"8.5 X 11 Score and fold to 8.5 X 3.625",
    description2:"\*\* Pre=Perfed Shells (65# Opaque Cover)\n\n4/4 process (no bleed)\n\nMail Processing PLS to Tab (2) and Mail\nCustomer Indicia",
    description3:"Deliver to Tampa BMEU ASAP!",
    address:"13001 Starkey Road\nLargo, FL 33773",
    foldtype:"Letter Fold",
    cost:.20,
    quote:34839,
    customerid:1932
      },
      {
        id:"12",
        name:"Pinellas County Supervisor Of Elections",
        jobname:"Polling Place Change Card", 
        numup:2,
        mr:5,
        stock:"Voter ID Card Perfed Shells",
        prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
        description1:"8.5 X 11 Score and fold to 8.5 X 3.625",
      description2:"\*\* Pre=Perfed Shells (65# Opaque Cover)\n\n4/4 process (no bleed)\n\nMail Processing PLS to Tab (2) and Mail\nCustomer Indicia",
      description3:"Deliver to Tampa BMEU ASAP!",
      address:"13001 Starkey Road\nLargo, FL 33773",
      foldtype:"Letter Fold",
      cost:.20,
      quote:34839,
      customerid:1932
        },/*
      {
        id:"4",
        name:"Pinellas County Supervisor Of Elections",
        jobname:"ACFN (Green) Weekly Postcards", 
        numup:4,
        mr:5,
        stock:"BiFold old SOE Postcard Shells",
        prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
        description1:"12 X 4.25 Score and fold to 6 X 4.24",
      description2:"Bifold SOE Postcard Shells (65# Opaque Cover)\n\n4/4 Process with full bleeds\n\nMail Processing Tab (1) and Mail\n Customer indicia",
      description3:"Deliver to Tampa BMEU ASAP!",
      address:"13001 Starkey Road\nLargo, FL 33773",
      foldtype:"Bi Fold",
      cost:.195,
      quote:34839,
      customerid:1932
        },*/
      {
        id:"6",
        name:"Pinellas County Supervisor Of Elections",
        jobname:"ACR (Beige) Weekly Postcards", 
        numup:4,
        mr:5,
        stock:"BiFold old SOE Postcard Shells",
        prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
        description1:"6x8.5\" folding to 6x4.25\"",
      description2:"Bifold SOE Postcard Shells (65# Opaque Cover)\n\n4/4 Process with full bleeds\n\nMail Processing Tab (1) and Mail\n Customer indicia",
      description3:"Deliver to Tampa BMEU ASAP!",
      address:"13001 Starkey Road\nLargo, FL 33773",
      foldtype:"Bi Fold",
      cost:.20,
      quote:34839,
      customerid:1932
        },
      {
        id:"8",
        name:"Pinellas County Supervisor Of Elections",
        jobname:"ACFN-M (BLUE) Weekly Postcards", 
        numup:4,
        mr:5,
        stock:"BiFold old SOE Postcard Shells",
        prepressnotes:"Files On Imcoming\nProofs: 1st Off To Pam",
        description1:"6x8.5\" folding to 6x4.25\"",
      description2:"Bifold SOE Postcard Shells (65# Opaque Cover)\n\n4/4 Process with full bleeds\n\nMail Processing Tab (1) and Mail\n Customer indicia",
      description3:"Deliver to Tampa BMEU ASAP!",
      address:"13001 Starkey Road\nLargo, FL 33773",
      foldtype:"Bi Fold",
      cost:.20,
      quote:34839,
      customerid:1932
        }
]



const Elections = () => {
const [which, setWhich] = useState({})
const [quantity, setQuantity] = useState(0)
const [jobnum, setJobnum] = useState("")
const [show, setShow] = useState(true)
const [duedate, setDuedate] = useState()
const [electlist, setElectlist] = useState([])
const [reset, setReset] = useState(false)
const [total, setTotal] = useState(0)
const [search, setSearch] = useState("")
const nav = useNavigate()
useEffect(() => {
axios.get(`https://supervoter-0eae7d1dca36.herokuapp.com/elect/getall`)
.then(res => setElectlist(res.data))
.catch(err => console.log(err))
},[reset])

useEffect(() => {
  let y= 0
  electlist?.map(item => {
     y += (item.quantity * item.cost)
setTotal(currency(y))
  })
},[electlist])

const setelector = (e)=> {
  console.log(e.target.value)
  const y = elect.filter(item => item.id === e.target.value)
  setWhich(y[0])
}
  useEffect(() => {
if(!which.id || quantity <= "" || jobnum <= "" || quantity <= 0){
  setShow(false)
}else{
  setShow(true)
}

  },[which,quantity,jobnum])

const submit = () => {
  alert()
}

const addelect = async() => {

  const addit = async() => {

    console.log(which)
    const y = {
    id:which.id,
        name:which.name,
        jobname:which.jobname,
        numup:which.numup,
        mr:which.mr,
        stock:which.stock,
        description1:which.description1,
        description2:which.description2,
        description3:which.description3,
        address:which.address,
        foldtype:which.foldtype,
        cost:which.cost,
        quantity,
        duedate,
        job:jobnum,
        prepressnotes:which.prepressnotes
    }
  

    if(which.id === "5" || which.id === "7" || which.id === "9"){
      if(parseInt(quantity) < 120){ y.cost = 30 / quantity;setWhich({...which,cost:30 / quantity})}
    }
if(which.id === "2" || which.id === "10" || which.id === "6" || which.id === "8"){
      if(parseInt(quantity) < 150){ y.cost = 30 / quantity;setWhich({...which,cost:30 / quantity})}
    }
console.log(y)
await axios.post(`https://supervoter-0eae7d1dca36.herokuapp.com/elect/addelect`,y)
            .then(res => {
              const addtwo = async() => {
  
                const t = {
                  job:jobnum,
                  quote:which.quote,
                  quantity:quantity,
                  duedate:new Date(duedate + ( 60 * 60 * 24 * 1000)),
                  name:which.name,
                  customerid:which.customerid,
                  jobname:which.jobname,
                  description1:which.description1,
                  description2:which.description2,
                  description3:which.description3,
                  prepressnotes:which.prepressnotes,
                  currentprice:(which.cost * quantity),
                  currentper:which.cost
                }
                console.log(t)
                if(which.id === "5" || which.id === "7" || which.id === "9"){
                  if(parseInt(quantity) < 120){ t.currentper = 30 / quantity;t.currentprice = 30.00}
                }
            if(which.id === "2" || which.id === "10" || which.id === "6" || which.id === "8"){
                  if(parseInt(quantity) < 150){ t.currentper = 30 / quantity;t.currentprice = 30.00}
                }
             const config = {
                  headers: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin':'*' 
                  }
                };
                await axios.post(`https://highgroundsoftware.com/dash/dashboard/elect/addElect.php`,t,config)
                .then(res => {setReset(!reset)})
                .catch(err => console.log(err))
              console.log(t)
              }
              addtwo()
             
            })
            .catch(err => console.log(err))

}


addit()

}




  return (
    <MainWrap >
     
     <form submit={submit}>
      <Flexed fontSize="calc(1.3rem + .2vw)" padding="20px 0px 10px 0px">
<Flex fontSize="calc(1.3rem + .2vw)"> Select Elector<br />
      <select onChange={e => setelector(e)} style={{padding:"6px",fontSize:"1rem",fontSizeAdjust:"0.6"}}>
        <option value="">Select Elector</option>
        {elect.map((item,index) => {
        return  <option style={{fontSize:"1rem"}} key={index} value={item.id}>{item.name} - {item.jobname}</option>
        })}
       
      </select></Flex>
<Flex fontSize="calc(1.3rem + .2vw)">Quantity<br /><Input style={{boxSizing:"border-box"}} type="text" value={quantity} onChange={e => setQuantity(e.target.value)} /></Flex>
<Flex fontSize="calc(1.3rem + .2vw)">Job<br /><Input style={{boxSizing:"border-box"}} type="text" value={jobnum} onChange={e => setJobnum(e.target.value)} /></Flex>
<Flex fontSize="calc(1.3rem + .2vw)" >Due Date<br /><Datepick selected={duedate} onChange={date => setDuedate(date)}/></Flex>
      </Flexed>
     
      </form>
      
      <Flexed  padding="10px 0px" textAlign="right" justifyContent="flex-end">
    <Flex>     
  <AiOutlineSave style={{fontSize:"calc(1.5rem + .4vw)",color:"green",marginRight:"25px"}} onClick={addelect}/>
  </Flex>   
        <Flex flex="0"><PrintComponent  cost={which.cost} elector={which} quantity={quantity} jobnum={jobnum} duedate={duedate} reset={reset} setReset={setReset}/></Flex>
        </Flexed>
       
 
<Flexed><Input type="text" placeholder="Search Jobs" value={search} onChange={e => setSearch(e.target.value)}/></Flexed>

    
    <Flexed bgcolor="rgb(0,0,0,.2)" margin="20px 0px" padding="6px 3px">
    <Flex flex=".5">Job</Flex>
      <Flex flex="2">Customer</Flex>
      <Flex flex="2">Job Name</Flex>
      <Flex >Entered</Flex>
      <Flex>Due</Flex>
      <Flex>Quantity</Flex>
      <Flex>Unit Cost</Flex>
      <Flex>Total</Flex>
    </Flexed>
    <div style={{maxHeight:"25px"}}>
    {electlist.filter(item => {return search.toLowerCase() === '' ? item : item.jobname.toLowerCase().includes(search) || item.name.toLowerCase().includes(search) || item.job.includes(search) || item.quantity.toString().includes(search)}).reverse().map((item,index) => {
 return <Flexed key={index} borderBottom="1px solid black" padding="3px 0px" style={{cursor:"pointer"}} onClick={e => nav(`/jobticket/${item.job}`)}>
 <Flex flex=".5"  padding="4px 0px">{item.job}</Flex>
 <Flex flex="2"  padding="4px 0px">{item.name}</Flex>
 <Flex flex="2">{item.jobname}</Flex>
 
 <Flex padding="4px 0px" ><Moment format="MM/DD/YYYY">{item.createdAt}</Moment></Flex>
 <Flex  padding="4px 0px"><Moment format="MM/DD/YYYY">{item.duedate}</Moment></Flex>
 <Flex padding="4px 0px">{item.quantity}</Flex>
 <Flex padding="4px 0px">{item.cost.toFixed(3)}</Flex>
 <Flex padding="4px 0px">{currency(item.quantity * item.cost)}</Flex>

</Flexed>

    })}
    
   <Flexed>
    <Flex flex="8.5"></Flex>
    <Flex  padding="5px 0px 0px 0px">${total}</Flex>
   </Flexed>
   </div>
    
    
    
    
    
    
   

    </MainWrap>
  )
}

export default Elections
