import React, { useEffect, useState } from 'react'
import { Flex, Flexed, PhoneNumber } from '../../utilities/Shared'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import { sortit, sortnum } from '../../utilities/Sort'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'

const VendorSearch = ({cust,csrreps,salesreps}) => {
    const [vendors, setVendors] = useState([])
    const [order, setOrder] = useState("ASC")

const params = useParams()
const nav = useNavigate()
useEffect(() => {
    const getcustomers =  async() => {
        const cust  = {"cust":params.term}
        await axios.post('https://highgroundapi.herokuapp.com/vendor/search/vendors',cust)
        .then(res => {console.log(res.data);setVendors(res.data)})
        .catch(err => console.log(err))
    }
    getcustomers()
},[])
   


  return (
    <>
    {vendors?.length > 0 && 
    <div style={{border:"1px solid #303030",margin:"30px 0px"}}>
    <Flexed ><Flex  bgColor="#0E425A" color="white" fontSize="calc(1.5rem + .4vw)">VENDOR RESULTS&nbsp;&nbsp;{vendors.length}</Flex></Flexed>
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" >
        <Flex className="hand" fontSize="var(--headerMedium)" flex=".5" onClick={() => sortit('name',order,vendors,setVendors,setOrder)}>ID#</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)" flex="2" onClick={() => sortnum('company_id',order,vendors,setVendors,setOrder)}>Vendor</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)" flex="3" onClick={() => sortit('address',order,vendors,setVendors,setOrder)}>Address</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)">Phone</Flex>
        <Flex></Flex>
        <Flex ></Flex>
    </Flexed>
    
    {vendors?.map((customer,index) => {
     return  <Flexed onClick={e => nav(`/vendors/vendor/${customer._id}`)} className="mainrow" key={index} margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey" >
     <Flex className="" flex=".5" >{customer.company?.company_id}</Flex>
     <Flex className="" flex="2" >{customer.company?.name} </Flex>
      <Flex className="" flex="3" >
         {customer.address?.address1}
         {customer.address?.address2 > "" && <>&nbsp;{customer.address?.address2}</>}
         {customer.address?.city > "" && <>&nbsp;&nbsp;{customer.address?.city},{customer.address?.state} {customer.address?.zip}</>}
       </Flex>
      <Flex className="" >{PhoneNumber(customer.numbers?.main)}</Flex>
      <Flex className=""></Flex>
      <Flex className=""></Flex>
     </Flexed>
    })
       
    }
    </div>}
    </>
  )
}

export default VendorSearch