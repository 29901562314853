import { useEffect, useState } from 'react'
import {Flex, Flexed, Input, Label, MainWrap, Selectbox} from '../../utilities/Shared'
import axios from 'axios'
import { CSRReps, CustomersList, SalesReps } from '../../utilities/Reps'
import Select from '../../utilities/Select'

const Newrfq = () => {

    const [customers, setCustomers]= useState([])
    const [currentcustomer, setCurrentcustomer]= useState({})
    const [customercontacts, setCustomercontacts]= useState([])
    const [nocustomer, setNocustomer]= useState(false)


useEffect(() => {
 const getcustomers = async() => {
        await axios.get('http://localhost:5003/customers/all')
        .then(res => {setCustomers(res.data)})
        .catch(err => console.log(err))
    }
   
    getcustomers()        
},[])

const changeCustomer = (customerName) => {
    console.log(customerName)
    let customerCurrent = customers.filter(filt => filt.name.toLowerCase() === customerName.toLowerCase()) 
    if(customerCurrent.length > 0 ){
      //  setCurrentcustomer(customerCurrent[0])
    }else{
      //  setCurrentcustomer({})
    }
   
}

console.log(customers)

  return (
    <MainWrap>
<Flexed>
    <Flex><Label>Customerr</Label><br />
    <Select list={customers?.map(item => {return {"id":item._id,name:item.company?.name};})} listid="vendor" setValue={setCurrentcustomer} value={currentcustomer?.name} required/>
   </Flex>
   <Flex><Label>Sales Rep</Label><br />
   <SalesReps value={currentcustomer.salesrep?._id}/>
   </Flex>
   <Flex><Label>CSR</Label><br />
   <CSRReps value={currentcustomer.custrep?._id}/>
   </Flex>
   <Flex><Label>Contact</Label><br />
    <Input type="text" border={!currentcustomer._id ? "2px solid red" : ""}  value={currentcustomer.name}  onChange={e => changeCustomer(e.target.value)} placeholder="Select Customer"  list="customerselect" />
   <datalist id="customerselect">
   <option data-item="" value="Select Customer"/> 
{
    customercontacts?.map(contact => {
    return <option data-item="" value={contact.name} /> 
    },[])
}
   </datalist>
   </Flex>
</Flexed>
    </MainWrap>
  )
}

export default Newrfq






