import React from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox } from '../../utilities/Shared'
import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import States from '../../utilities/States';
import { toast } from 'react-toastify'

const NewLocation = ({onCancel,customerid,setReset,reset}) => {

    const[ newinfo, setNewinfo] = useState({itemid:uuidv4(),state:"FL"})

    const slip = async(info,id) => {

 
      const config = {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*' 
          }
        };   
        
        const tr = {
          company:id || "",
          name:newinfo.name || "",
          address1:newinfo.address1 || "",
          address2:newinfo.address2 || "",
          city:newinfo.city || "", 
          state:newinfo.state || "",
          zip:newinfo.zip || "",
          attn:newinfo.attn || "",
          phone:newinfo?.phone ? String(newinfo?.phone).replace(/\D/g,'') : "",
          locationid:newinfo.itemid
    } 
console.log(tr)
  axios.post(`https://highgroundsoftware.com/dash/dashboard/php/newlocation.php`,tr,config)
    .then(res => {console.log(res.data);toast.success('Saved');setReset(!reset);onCancel()})
   .catch(err => toast.error(err))
  }


    const addNewLocation = async(e) => {
e.preventDefault()
const y = {
    customerid:customerid,
    info:newinfo
}
console.log(y)
       await axios.post(` https://highgroundapi.herokuapp.com/customers/addlocation`,y)
      .then(res => {console.log(res.data);slip(newinfo,res.data.company_id)})
      .catch(err  => console.log(err))
    }
  return (

    <form onSubmit={e => addNewLocation(e)}>
    <Flexed gap="20px">
      
        <Flex><Label>Address 1</Label><Input type="text" value={newinfo?.address1} onChange={e => setNewinfo({...newinfo,address1:e.target.value})}/></Flex>
        <Flex><Label>Address2</Label><Input type="text" value={newinfo?.address2} onChange={e => setNewinfo({...newinfo,address2:e.target.value})}/></Flex>
        <Flex><Label>Location Name</Label><Input type="text" value={newinfo?.name} onChange={e => setNewinfo({...newinfo,name:e.target.value})} required/></Flex>
        <Flex flex=".8"><Label>Phone</Label><Input type="text" value={PhoneNumber(newinfo?.phone)} onChange={e => setNewinfo({...newinfo,phone:e.target.value})}/></Flex>
       
    </Flexed>
        <Flexed gap="20px" alignItems="flex-end">
        <Flex flex="1.5"><Label>City</Label><Input type="text" value={newinfo?.city} onChange={e => setNewinfo({...newinfo,city:e.target.value})}/></Flex>
        <Flex flex=""><Label>State</Label><States value={newinfo?.state} onChange={e => setNewinfo({...newinfo,state:e.target.value})} /></Flex>
        <Flex><Label>Zip</Label><Input type="text" value={newinfo?.zip} onChange={e => setNewinfo({...newinfo,zip:e.target.value})}/></Flex>
        <Flex><Label>Attn</Label><Input type="text" value={newinfo?.attn} onChange={e => setNewinfo({...newinfo,attn:e.target.value})}/></Flex>
        <Flex display="flex" justifyContent="flex-end" ><Button type="button" height="30px" onClick={onCancel}>Close</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" ><Button height="30px">Add Location</Button></Flex>
    </Flexed>
    </form>
    
  
  )
}

export default NewLocation

