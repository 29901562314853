import React from 'react'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import PressItem from './PressItem';
import { Scroll } from '../../utilities/Shared';






const PressPanel = ({forms,updateformitem,quote}) => {
  return (
    <div>
      
<Tabs >
    <TabList >
        {
            forms?.map(item => {
              return  <Tab style={{fontSize:"1.1rem"}}>{item.componentName}</Tab>
            })
        }
     
    </TabList>
    {
            forms?.map(item => {
              return  <TabPanel><PressItem item={item} updateformitem={updateformitem} /></TabPanel>
            })
        }
  
  </Tabs>



    </div>
  )
}

export default PressPanel