import {  useEffect, useState } from 'react'
import axios from 'axios'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import { IoMdAddCircle } from 'react-icons/io'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, Wrap } from '../../utilities/Shared'
import Modal from '../../modals/Modal'
//import NewVendor from './NewVendor'
//import EditVendor from './EditVendor'
import DeleteConfirm from '../../utilities/DeleteConfirm'
import { useNavigate } from 'react-router-dom'
import JobsPanel from './JobsPanel'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'

const Jobs = () => {

    const [search, setSearch] = useState("")
const [list, setList] = useState([])
const [csr, setCsr] = useState([])
const [newVendor, setNewVendor] = useState(false)
const [editVendor, setEditVendor] = useState(false)
const [deletevendor, setDeletevendor] = useState(false)
const [reset, setReset] = useState(false)
const [vendorinfo, setVendorinfo] = useState({})
const [order, setOrder] = useState("ASC")
const nav = useNavigate()


useEffect(() => {

    const getvendorlist = async() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
    await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getactivejobs.php',config)
    .then(res => {setList(res.data);setCsr(res.data.filter(item => item.salesrep === "1720"))})
    .catch(error => { console.log({...error});})
    //console.log(error.response.status);
    //console.log(error.response.headers);})
}
getvendorlist() 

   /* const getvendorlist = async() => { 
      await axios.get('https://highgroundapi.herokuapp.com/vendor')
        .then(res => {console.log(res.data);setVendorlist(res.data)})
        .catch(err => console.log(err))
    }
    getvendorlist() */
},[reset])

const DeleteVendor = async() => {

    let newinfo = {"id":vendorinfo._id}
 //await axios.post('https://highgroundapi.herokuapp.com/paper/delete/one',)
  await axios.post('https://highgroundapi.herokuapp.com/vendor/remove',newinfo)
  .then(res => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    };
    const tr = {"id":vendorinfo.company.company_id}
    
  //axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removepaper.php`,tr,config);
  axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removevendor.php`,tr,config)
  .then(res => {setReset(!reset);setDeletevendor(false)})
  .catch(err => console.log(err))
  console.log(tr)
  })
  .catch(err => console.log(err))
  
 console.log(csr)
  }

  return (
    <MainWrap >
    <Wrap >
    <Flexed><Flex fontSize="calc(1.2rem + .4vw)" textAlign="center" padding="20px" fontWeight="bold"
         >Active Jobs</Flex></Flexed>
    <Tabs defaultIndex={localStorage.getItem('default01')}>
    <TabList style={{marginBottom:"40px"}} >
    <Tab>Kelly's Open Jobs</Tab>
    <Tab >All Open Jobs </Tab>
  </TabList>

  <TabPanel >
    {/*<Modal show={newVendor} header={`Add New Vendor`} onCancel={() => {setNewVendor(false)}} ><NewVendor onCancel={() => {setNewVendor(false)}}  reset={reset} setReset={setReset}/></Modal>
    <Modal show={editVendor} header={`Edit Vendor`} onCancel={() => {setEditVendor(false)}} ><EditVendor onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>
    <Modal show={deletevendor} header={`Confirm you are deleting vendor -- ${vendorinfo?.company?.name}`} onCancel={() => {setDeletevendor(false)}} ><DeleteConfirm editinfo={vendorinfo} reset={reset} setReset={setReset} handleChange={DeleteVendor} onCancel={() => {setDeletevendor(false)}} /></Modal>
  */}
    <Flexed margin="0px 0px 40px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle onClick={e => setNewVendor(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /> </Flex>
      </Flexed>
      <JobsPanel rowclass="mainrow_c" list={csr} search={search} setList={setCsr} order={order} setOrder={setOrder}/>

  </TabPanel>
{/*/////////////////////////////////////////////////////CFP*/}
  <TabPanel>
    {/*<Modal show={newVendor} header={`Add New Vendor`} onCancel={() => {setNewVendor(false)}} ><NewVendor onCancel={() => {setNewVendor(false)}}  reset={reset} setReset={setReset}/></Modal>
    <Modal show={editVendor} header={`Edit Vendor`} onCancel={() => {setEditVendor(false)}} ><EditVendor onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>
    <Modal show={deletevendor} header={`Confirm you are deleting vendor -- ${vendorinfo?.company?.name}`} onCancel={() => {setDeletevendor(false)}} ><DeleteConfirm editinfo={vendorinfo} reset={reset} setReset={setReset} handleChange={DeleteVendor} onCancel={() => {setDeletevendor(false)}} /></Modal>
  */}
    <Flexed margin="0px 0px 40px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle onClick={e => setNewVendor(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /> </Flex>
      </Flexed>
      <JobsPanel rowclass="mainrow_c" list={list} search={search} setList={setList} order={order} setOrder={setOrder}/>
  </TabPanel>
{/*/////////////////////////////////////////////////////MPS*/}

  </Tabs>

    
                
        
  </Wrap>
  </MainWrap>
  )
}

export default Jobs