import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { Flex, Flexed, currency } from '../../utilities/Shared'

const Cfpsales = () => {


    const [sales, setSales] = useState([])

    useEffect(() => {
        getAllSales()
    },[])

    const getAllSales = async() => { 
        await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/all/sales`)
        .then(res => {console.log(res.data);setSales(res.data) })
        .catch(err => console.log(err))
     }

console.log(sales)
  return (
    <>
    <Flexed>CFP Sales</Flexed>
   <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="25px 0px 0px 0px">
    <Flex textAlign="center">Today</Flex>
    <Flex textAlign="center">This Week</Flex>
    <Flex textAlign="center">Last Week</Flex>
    <Flex textAlign="center">This Month</Flex>
    <Flex textAlign="center">QTD</Flex>
    <Flex textAlign="center">YTD</Flex>
</Flexed>
<Flexed padding="3px 0px" fontWeight="bold"  color="rgb(0,0,0,.7)" bgColor="white">
    <Flex textAlign="center">${currency(sales?.today)}</Flex>
    <Flex textAlign="center">${currency(sales?.week)}</Flex>
    <Flex textAlign="center">${currency(sales?.lastweek)}</Flex>
    <Flex textAlign="center">${currency(sales?.month)}</Flex>
    <Flex textAlign="center">${currency(sales?.quarter)}</Flex>
    <Flex textAlign="center">${currency(sales?.year)}</Flex>
</Flexed>
</>
  )
}

export default Cfpsales