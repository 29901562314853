import React, { useEffect, useRef } from 'react'
import { v4 as uuidv4 } from 'uuid';
import { Scroll } from '../utilities/Shared';


const Conversations = ({currentchat}) => {
    const scrollRef = useRef();

    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth" });
      }, [currentchat]);


  return (
    <Scroll height="530px">
    <div className="chat-messages">
                <div  key={uuidv4()}>
                    {
                    currentchat?.map(item => {
                  return <div
                      className={`message ${item.messagefrom === sessionStorage.getItem('trackuser') ? "right" : "left"}`}>
                      <div className={`content ${item.messagefrom === sessionStorage.getItem('trackuser') ? "sended" : "recieved"}`}  ref={scrollRef}>
                        <p>{item.message}</p>
                      </div>
                    </div>
                    })    
                }
                </div>  
          </div>
          </Scroll>
  )
}

export default Conversations




//${item.messagefrom === sessionStorage.getItem('trackuser') ? "sended" : "recieved"}`}