import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Scroll, Selectbox, Textauto, Wrapper } from '../../../utilities/Shared'
import axios from 'axios';
import Collapse from '../../../utilities/Collapse';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { RiDeleteBack2Fill } from "react-icons/ri";
import JobticketContext from '../../../Context/JobticketContext';
import { colorchange } from '../../../utilities/Convertions';

const moment = require("moment");
const PressTicket = ({jobinfo,forms,reset,setReset,job}) => {

  const[operations, setOperations] = useState([])
  const[newforms, setnewForms] = useState([])
  const[imposition, setImposition] = useState([])
  const[proofstotal, setProofstotal] = useState({hours:0,minutes:0})
 




  const[operationitems, setOperationitems] = useState([])
  const[prepress, setPrespress] = useState([])
  const[onprepresstimes, setOnprepresstimes] = useState({})
  const[offprepresstimes, setOffprepresstimes] = useState({})

  const[onplatingtimes, setOnplatingtimes] = useState({})
  const[offplatingtimes, setOffplatingtimes] = useState({})
  const[proofin, setProofin] = useState(false)
  const[proofout, setProofout] = useState(false)
  
  const [value, onChange] = useState(new Date());
  const [onTimes, setOnTimes] = useState(null);
  const [offTimes, setOffTimes] = useState(null);




  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };


  useEffect(() => {

    let r = {
        job:job,
    }
    const getops = async() => {
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
    .then(res => {setOperations(res.data);})
    .catch(err => console.log(err))
    }
    const getquote = async() => {
      let r = {
        quote:jobinfo?.quote,
      }
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getimposition.php`,r,config)
     .then(res => {setImposition(res.data);})
     .catch(err => console.log(err))
     }
    getops()
    getquote()
  },[reset])

  useEffect(() => {
    setPrepressValues()
  },[operations])


  
const setPrepressValues = () => {
  let imp
  let ops = []
let proofing = []
console.log(forms)
  forms.map((item,index) => {
    let pressins = []
    let pressouts = []
    let pressindis = []
   
    console.log(operations)
     imp = imposition?.filter(filter => filter.component === item.originalform)
     console.log(imp)
let n = 1
operations?.filter(filter => filter.comp === item.name).map((op,index) => {
  let diff = 0
    if(op.name === "On Press"){pressins.push({id:op.id,time:op.changedon,status:"On Press"})}
    if(op.name === "On Digital"){pressins.push({id:op.id,time:op.changedon,status:"On Digital"})}
    if(op.name === "On Colormax"){pressins.push({id:op.id,time:op.changedon,status:"On Colormax"})}
    if(op.name === "Off Press"){pressouts.push({id:op.id,time:op.changedon,status:"Off Press"})} 
    if(op.name === "Off Digital"){pressouts.push({id:op.id,time:op.changedon,status:"Off Digital"})}
    if(op.name === "Off Colormax"){pressouts.push({id:op.id,time:op.changedon,status:"Off Colormax"})} 
    if(op.name === "Incomplete Press"){pressouts.push({id:op.id,time:op.changedon,status:"Incomplete Press"})}
    if(op.name === "Incomplete Digital"){pressouts.push({id:op.id,time:op.changedon,status:"Incomplete Digital"})}
    if(op.name === "Incomplete Colormax"){pressouts.push({id:op.id,time:op.changedon,status:"Incomplete Colormax"})} 
    if(op.name === "AA Changes Press"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Press"})} 
    if(op.name === "PE Changes Press"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Press"})}
    if(op.name === "AA Changes Digital"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Digital"})} 
    if(op.name === "PE Changes Digital"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Digital"})}
    if(op.name === "AA Changes Colormax"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Colormax"})} 
    if(op.name === "PE Changes Colormax"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Colormax"})}


    })

   
   pressindis=(getdiff(pressins,pressouts))
  

    const y = {
      id:item.id,
      item:item.item,
        name:item.name,
        formid:item.formid,
        partofid:item.partofid,
        partof:item.item,
        processtext:item.processtext,
        press:
          {'ins':pressins,
            'out':pressouts,
            'indiv':pressindis,
            'estimatedtime':setesttime(imp,'press'),
          
    }
    }
    proofing.push(y)
})
setnewForms(proofing)
}



const getdiff = (inTimes,outTimes) => {

let tot =[]
let u


inTimes?.map((item,index) => {

      let time
        let intime = new Date(item?.time)
        let outtime = new Date(outTimes[index]?.time)
     time = Math.abs(outtime - intime)



   
  let y =timeConvert(time/1000/60)
  if(isNaN(y.hours)){y.hours = 0}
  if(isNaN(y.minutes)){y.minutes = 0}
   u = {hours:y.hours,minutes:y.minutes}
  tot.push(u)
})
return(tot)
}

const setesttime = (imp,item) => {
  let newitem

switch(item){
case "press":
  if(parseInt(imp[0]?.numforms) > 1){
      let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
  
      newitem = (r / parseInt(imp[0]?.numforms))
  } 
break;
}

let y = converttime(newitem)

return y
}



function timeConvert(n) { 

  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

 // if(isNaN(rhours)){rhours = 0}
  //if(isNaN(rminutes)){rminutes = 0}
  let t = {hours:proofstotal.hours + rhours,minutes:proofstotal.minutes + rminutes}

  return{hours:rhours,minutes:rminutes}
  } 

  const converttime = (item) => {

    let hours = 0
        let minutes = 0
        let myArray = []
    if(item !== undefined){
         myArray = item.toString()?.split(".");
       hours = parseInt(myArray[0])
       minutes = parseInt(myArray[1])

        let check = minutes/100
        if(check > 1){
         minutes = minutes % 100
       
        }
    }
   return({hours,minutes})
}


const getDiff = (intime,outtime,item) => {
   intime = new Date(intime)
   outtime = new Date(outtime)
     let  time = Math.abs(outtime - intime)

     let y = (timeConvert(time/1000/60))


     return `${y.hours}:${y.minutes}`
}








const setchangevalues = (item) => {
   
  let d
  let s
  let p
  let push
switch(item){
  case "On Press":
d = 3
s = 1
p = 1
push=0
break;
case "Off Press":
d = 3
s = 0
p = 1
push=43
break;
case "Incomplete Press":
d = 3
s = 0
p = 1
push=0
break;
case "AA Changes Press ":
d = 3
s = 0
p = 1
push=0
break;
case "PE Changes ":
d = 3
s = 0
p = 1
push=0
break;
case "Ready Press":
  d = 3
  s = 1
  p = 1
  push=0
  break;
  case "On Digital":
 case "On Colormax":
    d = 2
    s = 1
    p = 1
    push=0
    break;
    case "Off Digital":
    case "Off Colormax":
    d = 2
    s = 0
    p = 1
    push=42
    break;
    case "Incomplete Digital":
    case "Incomplete Colormax":
    d = 2
    s = 0
    p = 1
    push=0
    break;
    case "AA Changes Digital":
    case "AA Changes Colormax":
    d = 2
    s = 0
    p = 1
    push=0
    break;
    case "PE Changes Digital":
    case "PE Changes Colormax":
    d = 2
    s = 0
    p = 1
    push=0
    break;
    case "Ready Digital":
    case "Ready Colormax":
      d = 2
      s = 1
      p = 1
      push=0
      break;

}
return {d,s,p,push}
}



const setpushes = (e,form) => {
  console.log(form)
let set = {}
  if(e.target.value === "Off Press"){
set.dept = 4           
set.push = 0
set.name = "Ready Bindery"
set.process=0
  }
  const z = {
    job:job,
    item:form.item,
    partof:form.partof,
    partofid:form.partofid,
    process:set.process,
    comp:form.name,
    changedby:sessionStorage.getItem('eid'),
  name:set.name,
  dept:set.dept,
  status:0,
  push:set.push
  }
  console.log(z)
  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )

 
  }
  savit()
}





const changestatus = (e,form,index) => {

let values = setchangevalues(e.target.value)

const z = {
  job:job,
  item:form.item,
  partof:form.item,
  partofid:form.partofid,
  process:values.p,
	comp:form.name,
name:e.target.value,
dept:values.d,
status:values.s,
changedby:sessionStorage.getItem('eid'),
push:values.push
}
console.log(z)
const savit = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
  .then(res => {setpushes(e,form)} )
  .catch(err => toast.error('Something went wrong and process was not saved') )
}
savit()
}







const changetime = (e,form) => {

  const z = {
    changedon:e.target.value,
id:form.id
  }

  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateoperation.php`,z,config)
    .then(res => {setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )
  }
 savit()
}


console.log(newforms)

  return (
    <Wrapper style={{height:"70vh",position:"relative",color:"black"}}>
    
  
    <Scroll height="100%">

    <div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" fontWeight="bold" style={{textDecoration:"underline"}}> Press</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.press.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.press.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.press.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.press.out[index].status)}} type="datetime-local" value={form.press.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.press.indiv[index].hours}:{form.press.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.press.estimatedtime.hours}:{form.press.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value={`Ready ${form.processtext}`}>{`Ready ${form.processtext}`}</option>
 <option value={`On ${form.processtext}`}>{`On ${form.processtext}`}</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value={`Off ${form.processtext}`}>{`Off ${form.processtext}`}</option>
 </optgroup>
 <optgroup label="Changes">
 <option value={`Incomplete ${form.processtext}`}>{`Incomplete ${form.processtext}`}</option>
 <option value={`AA Changes ${form.processtext}`}>{`AA Changes ${form.processtext}`}</option>
 <option value={`PE Changes ${form.processtext}`}>{`PE Changes ${form.processtext}`}</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             <Flexed borderBottom="1px solid grey" ><Flex>{form.digital ? 'Toner' : 'Inks'} Used: <Input /> </Flex><Flex>Stock Used: <Input /> </Flex><Flex flex="5">Notes:<br /><Textauto minHeight="25px"/></Flex></Flexed><br />
             </div> </>
    })
  }

 
</div>


<br /> <br /> <br /> <br />
    </Scroll>
    

    
    </Wrapper>
  )
}

export default PressTicket




