// Filename - App.js

import React, { useEffect, useState } from "react";
import Papa from "papaparse";
import { Flex, Flexed, MainWrap } from "../../utilities/Shared";
import { v4 as uuidv4 } from 'uuid';
import axios from "axios";

// Allowed extensions for input file
const allowedExtensions = ["csv"];

const Csv = () => {

	// This state will store the parsed data
	const [data, setData] = useState([]);
	const [locations, setLocations] = useState([]);
const [count , setCount] = useState(0)
const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  }; 

useEffect(() => {
	let rr ={
		id:327
	  }

const getloca = async() => {
	await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getoldlocation.php',rr,config)
    .then(res => {setLocations(res.data)})
    .catch(err => console.log(err))
}
getloca()
},[])



const job = 104165
	// It state will contain the error when
	// correct file extension is not used
	const [error, setError] = useState("");

	// It will store the file uploaded by the user
	const [file, setFile] = useState("");

	// This function will be called when
	// the file input changes
	const handleFileChange = (e) => {
		setError("");

		// Check if user has entered the file
		if (e.target.files.length) {
			const inputFile = e.target.files[0];

			// Check the file extensions, if it not
			// included in the allowed extensions
			// we show the error
			const fileExtension =
				inputFile?.type.split("/")[1];
			if (
				!allowedExtensions.includes(fileExtension)
			) {
				setError("Please input a csv file");
				return;
			}

			// If input type is correct set the state
			setFile(inputFile);
		}
	};
	const handleParse = () => {
	
		// If user clicks the parse button without
		// a file we show a error
		if (!file) return alert("Enter a valid file");

		// Initialize a reader which allows user
		// to read any file or blob.
		const reader = new FileReader();

		// Event listener on reader when the file
		// loads, we parse it and set the data.
		reader.onload = async ({ target }) => {
            console.log(target)
			const csv = Papa.parse(target.result, {
				header: true,
			});
          
			const parsedData = csv?.data;
            console.log(parsedData)
			let uu = []

            parsedData.map(item => {
                let y ={...item,job}
                uu.push(y)
            })

			setData(uu);


		};
		reader.readAsText(file);
	};

	console.log(locations)
const go = () => {

//     const saveshipping = async(items,field) => {
  let newlist = []
// let sponsors = []


console.log(data)

data?.map(item => {

let gg =locations.filter(filt => filt.address1.includes(item['ADDRESS']?.substr(6)))

console.log(gg)




// oldid:,
// locationname:,
// address1:,
// address2:,
// city:,
// state:,
// zip:,
// locationid:,
// addedid:,
// items:,




    let Y ={
		shipmethod:"UPS",
itemid:uuidv4(),
name:'AAA Insurance Posters - 1 Each of 4 Versions',
requested:"4",
shipdate:"2024-07-22T04:00:00.000Z",
addedid:uuidv4(),
      } 
      newlist.push(Y)
  
 })
console.log(newlist)
//        let h = {
//             id:'6669d3410371cc85cd98282f',
//             field:"billable",
//             value:newlist  
//        }
//        console.log(newlist)
//         await axios.post('http://localhost:5003/jobs/update',h)
//        // await axios.post('https://highgroundapi.herokuapp.com/jobs/update',h)
//         .then(res => {locationadd()})
//         .catch(err => console.log(err))
     
//     }
    
//     const locationadd = async() => {

// let yyy = []
// let ppp = []
//    data.map(item => {
//     let h = {
//         shipmethod:"PLS Delivery",
// itemid:uuidv4(),
// name:item['School'],
// requested:item['Quantity'],
// shipdate:"2024-06-25T04:00:00.000Z",
// addedid:uuidv4()
//     }
// yyy.push(h)
//    })


// let uu = {
//         locationid:'034499cb-4751-4826-8b8e-ec66629acc06',
//         items:yyy,
//         oldid:309,
//         locationname:'American Fundraising',
//         address1:'5009 Tampa W Blvd',
//         address2:'',
//         city:'Tampa',
//         state:'FL',
//         zip:'33634'
// }
// ppp.push(uu)


// let jj = {
   
//         id:'6669d3410371cc85cd98282f',
//         field:'shippingitems',
//         value:ppp
    
// }
// //await axios.post('https://highgroundapi.herokuapp.com/jobs/update',jj)
//     await axios.post('http://localhost:5003/jobs/update',jj)
//     .then(res => {})
//     .catch(err => console.log(err))



//     }
//  //   saveshipping()
}




	return (
		<MainWrap >
		
			<div className="container">
				<label
					htmlFor="csvInput"
					style={{ display: "block" }}
				>
				
				</label>
				<input
					onChange={handleFileChange}
					id="csvInput"
					name="file"
					type="File"
				/>
				<div>
					<button onClick={handleParse}>
						Parse
					</button>
                    <button onClick={go}>
						upload
					</button>
				</div>
                {/* <Flexed>
                                <Flex>Versions</Flex>
                                <Flex>School</Flex>
                                <Flex>Total Car Tags</Flex>
                                <Flex>#Orange</Flex>
                                <Flex>Versions Total Count</Flex>
                                <Flex>Form#</Flex>
                                <Flex>Sponsor</Flex>
                            </Flexed>
				<div style={{ marginTop: "3rem" }}>
					{
                        data.map(item => {
                             return <Flexed className="normalrow" padding="8px 0px">
                                <Flex>{item["Version"]}</Flex>
                                <Flex>{item["School"]}</Flex>
                                <Flex>{item["Total #↵Car Tags"]}</Flex>
                                <Flex>{item["#Orange"]}</Flex>
                                <Flex>{item["Version Total Count"]}</Flex>
                                <Flex>{item["FORM #"]}</Flex>
                                <Flex>{item["Car Tag Sponsor"]}</Flex>
                            </Flexed>
                            
                        })
                    }
				</div> */}
			</div>
		</MainWrap>
	);
};

export default Csv;
