import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom'
import { UserProvider } from './Context/UserContext';
import ScrollToTop from './utilities/ScrollToTop'


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
     <BrowserRouter>
     <UserProvider>
    <App />
    <ScrollToTop />
   
    </UserProvider>
    </BrowserRouter>
  </React.StrictMode>
);

