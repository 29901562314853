import { styled } from "styled-components";

export const HeaderWrap = styled.div`
width:100%;
height:var(--Header-height);
background-color:white;
position:fixed;
top:0;
z-index:1000;
`;

export const LOGO = styled.img`
height:calc(var(--Header-height) - 5px);
`;

export const Logout = styled.div`
height:70%;
background-color:var(--plsBlue);
width:200px;
color:white;
position:relative; 
right:-3px;
font-size:calc(3rem + .4vw);
padding:5px 0px;
`;

