import React, { useEffect, useState } from 'react'
import { Button, Datepick, Flex, Flexed, Input, Selectbox, Wrap } from '../../utilities/Shared'
import axios from 'axios';
import Moment from 'react-moment';


const Newjob = () => {

    const [quoteinfo, setQuoteinfo] = useState()
    const [customerinfo, setCustomerinfo] = useState()
    const [quotetype, setQuotetype] = useState("")
    const [jobtype, setJobtype] = useState("")
    const [jobnum, setJobnum] = useState("")
    const [duedate, setDuedate] = useState(new Date())
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };


const getquote = async(e) => {
let r = {
    quote:e.target.value
}
    let quote = await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getrfqquote.php`,r,config)
    // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
    .then(res => {if(res.data.length > 0){
        setQuoteinfo(res.data[0])
    }})
    .catch(err => console.log(err))

}

useEffect(() => {
const getcustomer = async() => {
  let quote = await axios.get(`http://localhost:5003/customers/hg/name/${quoteinfo?.customer}`)
  // await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,r)
  .then(res => {console.log(res.data[0]);setCustomerinfo(res.data[0])})
  .catch(err => console.log(err))
}
 
getcustomer()
  
},[quoteinfo])


const processform = (e) => {
e.preventDefault()


}

console.log(quoteinfo)
console.log(customerinfo)
//36723


  return (
    <Wrap padding="20px 10px" width="80%" margin="50px auto" >
      <form onSubmit={processform}>
        <Flexed gap="20px" >
            <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)">Quote Type<br />
            <Selectbox  value={quotetype} style={{fontSize:"calc(1rem + .2vw)"}} onChange={e => setQuotetype(e.target.value)} required>
 <option value="" selected disabled>Type of Job</option>
 <option value="Allocated">New From Quote</option>
 <option value="Allocated">Existing Quote</option>
 <option value="Allocated">New Job No Quote</option>
 </Selectbox>
 </Flex>
            <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)} required>Estimate#<br /><Input /></Flex>
            <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)" >Job#<br /><Input maxLength="6" disabled={!quoteinfo ? true : false} value={jobnum} onChange={e => setJobnum(e.target.value)} required/></Flex>
            <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)">Due Date<br /><Datepick  selected={duedate}  onChange={(date) => setDuedate(date)} required/></Flex>
        </Flexed>

        <Flexed gap="20px" margin="25px 0px 0px 0px">
        <Flex flex="3" color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)}>Customer<br /><Input value={customerinfo?.name} required/></Flex>
        <Flex flex=".5" color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)}> ID#<br /><Input value={customerinfo?.company_id} disabled/></Flex>
        <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)} >Contact<br />
         <Selectbox  value={jobtype} style={{fontSize:"calc(1rem + .2vw)"}}>
         <option value="" selected disabled>Select Contact</option>
          {customerinfo?.contacts.map(cust => {
           return <option value={cust.contactid}>{cust.name}</option>

          })}

 
 </Selectbox>
 </Flex>
   
        
        </Flexed>
        <Flexed gap="20px" margin="25px 0px 0px 0px">
        <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)}>Job Name<br /><Input value={quoteinfo?.pname} required/></Flex>
        <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)} required>Type of Job<br />
        <Selectbox  value={jobtype} style={{fontSize:"calc(1rem + .2vw)"}} onChange={e => setJobtype(e.target.value)}>
 <option value="New">New</option>
 <option value="Reprint">Reprint</option>
 <option value="Reprint w/ Changes">Reprint w/ Changes</option>
 <option value="Replenish Inventory">Replenish Inventory</option>
 <option value="Pull From Inventory">Pull From Inventory</option>
 </Selectbox>
 </Flex>
        <Flex color="#0E425A" fontSize="calc(1.3rem + .2vw)" onChange={e => getquote(e)}>Quantity<br /><Input required /></Flex>
        </Flexed>

        <Flexed padding="25px 0px" >
          <Flex textAlign="right"><Button type="submit" fontSize="calc(1.3rem + .2vw)" color="#0E425A">Start Job</Button></Flex>
        </Flexed>
        </form>
    </Wrap>
  )
}

export default Newjob