import React, { useRef, useState } from 'react'
import { flushSync } from 'react-dom';
import { Button, Datepick, Flex, Flexed, Input, Label, MainWrap, Wrap } from '../../utilities/Shared'
import { v4 as uuidv4 } from 'uuid';
import PrintComponent from '../../Printing/EDDM';

const Eddm = () => {

const [date, setDate] = useState(null)
const [item, setItem] = useState()
const [items, setItems] = useState([])
const [setup, setSetup] = useState([])
const [list, setList] = useState([])
const [count, setCount] = useState(0)
const [job, setJob] = useState({date:new Date(),permit:1829,type:"Permit Imprint",perbundle:100,holder:"PLS",deltype:"Residential"})

const inputRef = useRef(null);

const additem = (e) => {
    e.preventDefault()
    let id = uuidv4()

let i
let et =""
let tre = []
let counter = 1
    let a = (parseInt(item.quantity) % parseInt(job.perbundle))
    let b = Math.floor(item.quantity / job.perbundle)
  console.log(b)

 for(i=1;i<=b;i++){

et = {
    route:item.route,
pages:a > 0 ? b + 1 : b,
perbundle:job.perbundle,
date:job.date,
zip:item.zip,
tre:counter,
id
}
list.push(et)
counter = counter + 1
 }
if(a > 0){
    let y = {
        route:item.route,
        pages:b + 1,
        perbundle:a,
        date:job.date,
        zip:item.zip,
        tre:counter,   
        id
    }
    list.push(y)
}


 let r = {
    zip:item.zip,
    route:item.route,
    quantity:item.quantity,
    id 
 }


    setItems(items =>[...items,r])
    setItem({...item,quantity:"",zip:item.zip,route:""})
 


    
    inputRef.current.focus()
}

const deleteitem = (id) => {
 const newItems = items.filter(item => item.id !== id);
 const newList = list.filter(item => item.id !== id);   
   setItems(newItems);
   setList(newList)
}



const updatezip = (e,itemselected) => {
const updateinfo = () => {
    list.map(it => {
        if(it.id = itemselected.id){
    return {...it,zip:e.target.value }
        }
        return it
    })
}
updateinfo()

}
console.log(list)
console.log(items)
let r = 0

  return (
<MainWrap > 
    <Wrap >
<Flexed><Flex textAlign="center"  padding="20px" fontSize="calc(1.1rem + .4vw)">PLS EDDM</Flex></Flexed>

<Flexed padding="20px 0px" margin="0px 0px 15px 0px">
    <Flex flex=".5" style={{border:!job.date ? "2px solid rgb(255,0,0,.6)" : ""}}><Label>Date</Label><br /><Datepick  selected={job.date}  onChange={(date) => setJob({...job,date:date})}/></Flex>
    <Flex><Label>Per Bundle</Label><br /><Input type="text" value={job.perbundle} onChange={e => setJob({...job,perbundle:e.target.value})} /></Flex>
    <Flex flex="2"><Label>Mailng Name</Label><br /><Input type="text" value={job.mailingname} onChange={e => setJob({...job,mailingname:e.target.value})} /></Flex>
   
   
</Flexed>
<Flexed margin="-20px 0px 20px 0px">
<Flex flex="2"><Label>Permit Holder Name</Label><Input type="text" value={job.holder} onChange={e => setJob({...job,holder:e.target.value})} /></Flex>
<Flex><Label>Permit Number</Label><Input type="text" value={job.permit} onChange={e => setJob({...job,permit:e.target.value})} /></Flex>

    <Flex><Label>Permit Type</Label><Input type="text" value={job.type} onChange={e => setJob({...job,type:e.target.value})} /></Flex>
    <Flex><Label>Delivery Type</Label><Input type="text" value={job.deltype} onChange={e => setJob({...job,deltype:e.target.value})} /></Flex>
</Flexed>
<hr />
<form onSubmit={additem} autoComplete='on'>
<Flexed padding="20px 0px" alignItems="center" bgColor="rgb(0,0,0,.1)"  margin="20px 0px 0px">
    <Flex><Label>Zip Code</Label><br /><Input ref={inputRef} type="text" value={item?.zip} onChange={e => setItem({...item,zip:e.target.value})} required /></Flex>

    <Flex><Label>Route</Label><br /><Input type="text" value={item?.route} onChange={e => setItem({...item,route:e.target.value})} required /></Flex>
    <Flex><Label>Quantity</Label><br /><Input type="text" value={item?.quantity} onChange={e => setItem({...item,quantity:e.target.value})} required /></Flex>
    <Flex >{
        item?.id?
        <Button position="absolute" top="-3px" left="50px" >Update</Button>:
        <Button position="absolute" top="-3px" left="50px" >Add to Batch</Button>
    }</Flex>
</Flexed>
</form>
<hr />

<Flexed padding="20px 20px 0px 20px">
    <Flex fontSize="calc(1.1rem + .4vw)">
<u>Current Items in EDDM</u><PrintComponent  job={job} items={list} disabled/>
    </Flex>
    </Flexed>

    <Flexed padding="4px 0px" bgColor="#0E425A" color="white">
    <Flex>Zip Code</Flex>
        <Flex>Route</Flex>
        <Flex >Quantity</Flex>
   <Flex flex=".2"></Flex>
 
    </Flexed>
{

    items?.map(item => {
       
        r += parseInt(item.quantity);
     return   <Flexed borderBottom="1px solid grey" >
        <Flex ><Input type="text" value={item.zip} onChange={e => updatezip(e,item)}/></Flex>
        <Flex ><Input type="text" value={item.route}/></Flex>
        <Flex ><Input type="text" value={item.quantity}/></Flex>
   <Flex flex=".2" ><Button onClick={e => {deleteitem(item.id)}} >Delete</Button></Flex>
    </Flexed>
    })


}
<Flexed>
        <Flex></Flex>
        <Flex></Flex>
        <Flex >{r}</Flex>
   <Flex flex=".2"></Flex>
    </Flexed>





    </Wrap>

</MainWrap>
  )
}

export default Eddm