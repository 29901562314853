import React from 'react'
import { Button, Flex, Flexed } from './Shared'

const DeleteConfirm = ({editinfo,onCancel,reset,setReset,handleChange}) => {
    console.log(editinfo)
  return (
 <>
    <Flexed><Flex>Please Confirm That The Above Item Is Infact What You Are Trying To DELETE!</Flex></Flexed>
    <Flexed padding="25px 0px" justifyContent="flex-end"> <Flex flex="0" wordBreak="normal"><Button  style={{marginRight:"30px"}} onClick={onCancel}>Cancel</Button></Flex><Flex wordBreak="normal" flex="0"><Button style={{background:"rgb(255,0,0,.7)",color:"white"}} onClick={handleChange}>DELETE</Button></Flex></Flexed>
 </>
  )

}

export default DeleteConfirm