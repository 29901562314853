import { IoMdAdd, IoMdAddCircle } from 'react-icons/io'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, Scroll, Wrap, currency } from '../../utilities/Shared'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import Moment from 'react-moment'
import Collapse from '../../utilities/Collapse'
import axios from 'axios'
import { sortit, sortnum} from '../../utilities/Sort'
import { useState } from 'react'

const EmployeePanel = ({list,search,setSearch,setlist,setNewemployee,setEditVendor,setDeletevendor,rowclass,navi,title}) => {


    const nav = useNavigate()
    const [order, setOrder] = useState("ASC")



  return (
 <Wrap>
      <Scroll height="65vh">
    <Flexed margin="0px 0px 20px 0px"><Flex><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} onClick={e => setNewemployee(true)}/> </Flex>
      </Flexed>
<p style={{fontSize:"calc(1rem + .4vw)",padding:"0px 0px 10px 0px"}}>{title}</p>
      <Flexed margin="0" style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}}>
      <Flex className="hand" flex=".5" onClick={e => sortnum('empId',order,list,setlist,setOrder)}>Emp Id</Flex>
      <Flex  className="hand" onClick={e => sortit('firstName',order,list,setlist,setOrder)}>First</Flex>
      <Flex  className="hand" onClick={e => sortit('lastName',order,list,setlist,setOrder)}>Last</Flex>
<Flex flex="2" >Deartments</Flex>
<Flex flex="2" >Email</Flex>

<Flex flex=".5">Ext</Flex>
<Flex>Hire Date</Flex>

      </Flexed>
   
      {list?.filter(ite => {return search?.toLowerCase() === '' ? ite : ite.firstName.toLowerCase().includes(search.toLowerCase()) || ite.lastName.toLowerCase().includes(search.toLowerCase()) || ite.preferredName?.toLowerCase().includes(search.toLowerCase()) || ite.empId.includes(search)}).map((emp,index) => {
        return <Flexed key={emp.empId} className="normalrow" onClick={() => nav(`/${navi}/${emp?._id}`)}>
            <Flex flex=".5">{emp.empId}</Flex>
      <Flex>{emp.preferredName > "" ? emp.preferredName : emp.firstName}</Flex>
      <Flex>{emp.lastName}</Flex>
<Flex flex="2">{emp.departments.map((dept,index) => { return <span key={index}>{dept.label}&nbsp;&nbsp;</span>})}</Flex>
<Flex flex="2">{emp.workEmail || "" }</Flex>

<Flex flex=".5">{emp.ext || "" }</Flex>
<Flex><Moment format="MM/DD/YYYY">{emp.hireDate}</Moment></Flex>
        </Flexed>
      })}


</Scroll>

 </Wrap>
  )
}

export default EmployeePanel