import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/CFPLOGO.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "./../utilities/Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;
font-weight:bold;
text-transform:capitalize;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};


h2{
    margin:0;
    padding:0:
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({savedpo}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)

useEffect(() => {
    const addit = () => {
        let u = 0
        savedpo?.items?.map(item => {
       u += (parseInt(item.quantity) * item.unitprice)
            })
            setTotal(u)
    
    }
    addit()
},[savedpo])



  useEffect(() => {
const getpoinfo = async() => {

}




getpoinfo()
  },[])


  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      

 console.log(savedpo)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint poinfo={savedpo} total={total} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
     
  
  }

  render() {
    return (
      
     <Wrapper>

<Flexed style={{fontSize:"1.6rem"}}>
    <Flex style={{fontSize:"1.6rem"}}><Img src={LOGO} alt="Logo" style={{height:"120px",left:"-30px",top:"-20px"}}/></Flex>
    <Flex style={{fontSize:"1.6rem"}}>4250 118th Ave N<br />Clearwater, Fl 33762<br />Phone: 727-573-1763<br />Fax: 727-573-2662<br />www.plsprint.com</Flex>
    <Flex flex="1.6" style={{fontSize:"2.4rem",textAlign:"right",top:"10px"}}>Purchase Order</Flex>
</Flexed>
<Flexed padding="30px 0px 0px 0px">
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}><span style={{fontWeight:"bold",fontSize:"1.6rem"}}>Vendor</span><br />{this.props.poinfo?.vendor?.name}<br />{this.props.poinfo?.vendor?.address[0]?.address1}<br />{this.props.poinfo?.vendor?.address[0]?.address2 ? <>{`${this.props.poinfo?.vendor?.address[0]?.address2}<br />`}</> : null} {this.props.poinfo?.vendor?.address[0]?.city}, {this.props.poinfo?.vendor?.address[0]?.state} {this.props.poinfo?.vendor?.address[0]?.zip}</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}><span style={{fontWeight:"bold",fontSize:"1.6rem"}}>Ship To</span><br />{this.props.poinfo?.shipto?.name}<br />{this.props.poinfo?.shipto?.address[0]?.address1}<br />{this.props.poinfo?.shipto?.address[0]?.address2 ? <>{`${this.props.poinfo?.shipto?.address[0]?.address2}<br />`}</> : null} {this.props.poinfo?.shipto?.address[0]?.city}, {this.props.poinfo?.shipto?.address[0]?.state} {this.props.poinfo?.shipto?.address[0]?.zip}</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}><span style={{fontWeight:"bold",fontSize:"1.6rem"}}>Order Date</span><br /><Moment style={{fontWeight:"normal",fontSize:"1.5rem"}} format="MM/DD/YYYY">{this.props.poinfo?.createdAt}</Moment><br /><br /><span style={{fontWeight:"bold",fontSize:"1.5rem"}}>Due Date</span><br /><Moment style={{fontWeight:"normal",fontSize:"1.6rem"}} format="MM/DD/YYYY">{this.props.poinfo?.duedate}</Moment></Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}><span style={{fontWeight:"bold",fontSize:"1.6rem"}}>PO No.</span><br />{this.props.poinfo?.poid}<br /><br /><span style={{fontWeight:"bold",fontSize:"1.5rem"}}>Vendor Invoice</span><br />{this.props.poinfo?.invoice}</Flex>
</Flexed>
<Flexed padding="5px 0px" style={{background:"rgb(0,0,0,.1)",border:"1px solid black",borderBottom:"none",fontWeight:"bold",fontWeight:"normal",fontSize:"1.5rem",marginTop:"25px"}} >
    <Flex style={{fontWeight:"bold",fontSize:"1.6rem"}}   flex="2">Item</Flex>
    <Flex style={{fontWeight:"bold",fontSize:"1.6rem"}}  > Ordered</Flex>
    <Flex style={{fontWeight:"bold",fontSize:"1.6rem"}}  flex="3" textAlign="left">Description</Flex>
    <Flex style={{fontWeight:"bold",fontSize:"1.6rem"}}  >Unit $ </Flex>
    <Flex style={{fontWeight:"bold",fontSize:"1.6rem"}}  >Per</Flex>
    <Flex style={{fontWeight:"bold",fontSize:"1.6rem"}}  >Price</Flex>
</Flexed>
<div style={{height:"60vh",border:"1px solid black",position:"relative",width:"100%"}}>
{this.props.poinfo?.items?.map(item => {
return <><Flexed padding="5px 0px" style={{borderBottom:"1px solid lightgrey",marginTop:"8px"}} >
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}   flex="2">{item.name}</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  > {item.quantity} {item.orderby}</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  whiteSpace="normal" flex="3" textAlign="left">{item.description}</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  >{currency(item.unitprice)} </Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  >{item.priceby}</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  >{currency(item.unitprice * item.quantity)}</Flex>
</Flexed>

    </>
 })}
</div>
<Flexed>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}>Job Comments:</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}} flex="5"></Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  textAlign="center">Sub Total<br />Taxes<br />Total</Flex>
    <Flex style={{fontWeight:"normal",fontSize:"1.5rem"}}  textAlign="center">${currency(this.props.total)}<br />0<br/>${currency(this.props.total)}</Flex>
</Flexed>
   
  





    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
