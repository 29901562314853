import { Button, Html } from "@react-email/components";
import * as React from "react";
import { MainWrap } from "../utilities/Shared";
import { sendEmail } from "./sendEmail";


const {REACT_APP_EMAIL} = process.env

export default function Email() {



  
console.log(REACT_APP_EMAIL)
  return (
    <MainWrap>
    <Html>
      <Button
        style={{ background: "#000", color: "#fff", padding: "12px 20px" }}
        onClick={e => sendEmail()}
      >
        Click me
      </Button>

    </Html>
    </MainWrap>
  );
}
