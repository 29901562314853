export const POTypes = [
    {"id":"Sheet Stock","name":"Stock"},
    {"id":"Roll Stock","name":"Roll Stock"},
    {"id":"Envelope","name":"Envelopes"},
    {"id":"Outside Services","name":"Outside Services"},
    {"id":"Bindery","name":"Bindery"},
    {"id":"Custodial","name":"Custodial"},
    {"id":"Inks","name":"Inks"},
    {"id":"Prepress","name":"Prepress"},
    {"id":"Press","name":"Press"},
    {"id":"Shipping","name":"Shipping"},
    {"id":"Maintenance","name":"Maintenance"},
    {"id":"Boxes","name":"Boxes"},
    {"id":"MPS","name":"MPS"},
    
];



export const PLSOrderbylist = [
    {"id":3,"name":"Each"},
    {"id":4,"name":"Sheets"},
    {"id":5,"name":"LBS"},
    {"id":6,"name":"Case"},
    {"id":8,"name":"Box"},
];

export const PLStypelist = [
    {"id":1,"name":"CWT"},
    {"id":2,"name":"Per M"},
    {"id":3,"name":"Each"},
    {"id":4,"name":"Roll LBS"},
    {"id":5,"name":"LBS"},
    {"id":6,"name":"Case"},
    {"id":8,"name":"Box"},
];

export const CFPtypelist = [
    {"id":1,"name":"Each"},
    {"id":2,"name":"Foot"},
    {"id":3,"name":"Roll"},
    {"id":4,"name":"Case"},
    {"id":5,"name":"Square Foot"},
    {"id":6,"name":"Yard"},
];

export const addinventorylist = [
    {"id":0,"name":""},
    {"id":1,"name":"Add from PO"},
    {"id":2,"name":"Add left over from job"},
    {"id":3,"name":"Add from customer stock"}, 
    {"id":4,"name":"Physical Count Adjustment"}, 
];
export const removeinventorylist = [
    {"id":2,"name":"Remove for job"},
    {"id":3,"name":"Remove for customer"}, 
    {"id":3,"name":"Remove as bad stock"}, 
    {"id":4,"name":"Physical Count Adjustment"}, 
];
export const adjustinventorylist = [
    {"id":2,"name":"Adjust wrong Quantity"},
    {"id":3,"name":"Adjust End of Month Totals"}, 
    {"id":3,"name":"Other"}, 

]

export const closeitem = [
    {"id":0,"name":"Open"},
    {"id":1,"name":"Closed"}, 

]


export const locationareas = [
    {"id":10,"name":"Select Area"},
    {"id":0,"name":"Paper Areas"},
    {"id":0,"name":"Digital"},
    {"id":0,"name":"Colormax"},
    {"id":0,"name":"Web Rolls"},
    {"id":0,"name":"Floor"},
    {"id":0,"name":"Fidelity"},
]

export const presses = [
    {"id":0,"name":"",itemid:0},
    {"id":9,"name":"Colormax",itemid:0},
    {"id":4,"name":"GL440",itemid:0},
    {"id":3,"name":"GL540",itemid:0},
    {"id":5,"name":"M110 - Folding",itemid:0},
    {"id":6,"name":"M110 - Sheeter",itemid:0},
    {"id":7,"name":"Ricoh B&W",itemid:0},
    {"id":8,"name":"Ricoh Color",itemid:0},
    {"id":10,"name":"Ricoh Oversized",itemid:0},
]

export const methods = [
    {"id":1,"name":"Sheetwise",itemid:0},
    {"id":2,"name":"Work & Turn",itemid:0},
    {"id":3,"name":"One-Sided",itemid:0},
    {"id":4,"name":"Perfect",itemid:0},
    {"id":5,"name":"Work & Tumble",itemid:0},
  
]

export const pullcutlist = [
    {"id":0,"name":"",itemid:0},
    {"id":1,"name":"Robert H",itemid:0},
    {"id":2,"name":"Pam B",itemid:0},
    {"id":5,"name":"Duane H",itemid:0},
    {"id":3,"name":"Kevin C",itemid:0},
    {"id":4,"name":"Laura N",itemid:0},
   
  
]

export const foldinglist = [
    {"id":0,"name":"",itemid:0},
    {"id":1,"name":"Robert H",itemid:0},
    {"id":2,"name":"Pam B",itemid:0},
    {"id":5,"name":"Duane H",itemid:0},
    {"id":3,"name":"Kevin C",itemid:0},
    {"id":4,"name":"Laura N",itemid:0},
   
  
]


