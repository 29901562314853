import {useState,useEffect, useContext} from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Scroll, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import States from '../../utilities/States'
import {SalesReps} from '../../utilities/Reps'
import {CSRReps} from '../../utilities/Reps'
import BillingTerms from '../../utilities/BillingTerms'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
const NewCustomer = ({onCancel,setReset,reset}) => {

    const userInfo = useContext(UserContext)
console.log(userInfo)
const stat = localStorage.getItem('defaultCustomer')
const [info, setInfo] = useState({state:"FL",pros:stat === "2" ? 0 : 1,terms:"Net 30",taxexempt:"No",sales:userInfo.userInfo._id})
const [disalesave, setDisalesave] = useState(false)
const [addbuttongoaway, setAddbuttongoaway] = useState(false)


console.log(info)
const addCustomer = async(e) => {
e.preventDefault()
setDisalesave(true)
   let itemid
    let contactid 
    let address
    let contacts
console.log(info)
    if(info.address1 > ""){
        address = {
            address1:info.address1,
            address2:info.address2,
            city:info.city,
            state:info.state,
            zip:info.zip,
            address1:info.address1,
            itemid: uuidv4(),
            name:info.name.trim()
        }
    }else{address = []}

    if(info.contact > ""){
contacts = {
    name:info.contact,
    contactid: uuidv4(),
    email:info.contactemail,
    officephone:info.contactphone
}
    }else{contacts = []}






   let r = {
    name:info.name.trim(),
    dba:info.dba,
    mainphone:info.mainphone,
    fax:info.fax,
address,
website:info.website,
notes:info.notes,
custrep:info.csr,
salesrep:info.sales,
taxexempt:info.taxexempt,
billingterms:info.terms,
contacts,
mainlocation:address.itemid,
maincontact:contacts.contactid,
pros:info.pros
   }
   console.log(r)
await axios.post('https://highgroundapi.herokuapp.com/customers/add',r)
//await axios.post('http://localhost:5003/customers/add',r)
.then(res => {slip(res.data,address,contacts)})
.catch(err => toast.error(err))
}




const slip = async(info,address,contacts) => {

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };   
      
   
      const tr = {
        companyid:info.company_id,
        name:info.name,
        phone:info.mainphone,
         notes:info.notes,
         website:info.website,
         custrep:info.custrep,
         salesrep:info.salesrep,
         taxexempt:info.taxexempt,
         billingterm:info.billingterms,
         pros:info.pros
  }

  if(address.address1 > ""){tr.address1 = address.address1}
  if(address.address2 > ""){tr.address2 = address.address2}
  if(address.city > ""){tr.city = address.city}
  if(address.state > ""){tr.state = address.state}
  if(address.zip > ""){tr.zip = address.zip}
  if(address.itemid > ""){tr.locationid = address.itemid}
  if(contacts.name > ""){tr.contact = contacts.name}
  if(contacts.name > ""){tr.contactid = contacts.contactid}
  
  
  console.log(tr)
 axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addcustomer.php`,tr,config)
 .then(res => {console.log(res.data);toast.success('Saved');setReset(!reset);onCancel()})
 .catch(err => toast.error(err))
}




  return (
    <Wrap bgcolor="#0E425A">
        <Flexed>
            <Flex textAlign="center" fontSize="1.7rem" fontWeight="bold" padding="0px 0px 20px 0px">Add {stat === "2" ? "Prospect" : "Customer"}</Flex>
        </Flexed>
        <form onSubmit={addCustomer}>
<Scroll height="350px">
<Flexed fontWeight="bold" letterSpacing="1px"gap="20px" padding="0px 20px 20px 20px">
    <Flex>
       <Flexed flexDirection="column">
        <Flex><Label>Company Name*</Label><Input value={info?.name} onChange={e => setInfo({...info,name:e.target.value})} required/></Flex>
    <Flex><Label>Company DBA</Label><Input value={info?.dba} onChange={e => setInfo({...info,dba:e.target.value})}/></Flex>
    <br />
    <Flex><Label>Contact Person*</Label><Input value={info?.contact} onChange={e => setInfo({...info,contact:e.target.value})} required/></Flex>
    <Flex><Label>Email</Label><Input type="email" value={info?.contactemail} onChange={e => setInfo({...info,contactemail:e.target.value})}/></Flex>
    <Flex><Label>Phone</Label><Input value={PhoneNumber(info?.contactphone)} onChange={e => setInfo({...info,contactphone:e.target.value})}/></Flex>
    <br />
    <Flex flex="2"><Label>Address 1</Label><Input value={info?.address1} onChange={e => setInfo({...info,address1:e.target.value})}/></Flex>
    <Flex  flex="2"><Label>Address 2</Label><Input  value={info?.address2} onChange={e => setInfo({...info,address2:e.target.value})}/></Flex>
    <Flex flex="1.5"><Label>City</Label><Input value={info?.city} onChange={e => setInfo({...info,city:e.target.value})}/></Flex>
<Flexed>
<Flex ><Label>State</Label><States value={info?.state} onChange={e => setInfo({...info,state:e.target.value})}/></Flex>
    <Flex ><Label>Zip</Label><Input value={info?.zip} onChange={e => setInfo({...info,zip:e.target.value})}/></Flex>
    
</Flexed>
    </Flexed> 

    </Flex>


    <Flex>
        <Flexed flexDirection="column">
    <Flex><Label>Sales Rep</Label><SalesReps value={info?.sales} onChange={e => setInfo({...info,sales:e.target.value})} /></Flex>
    <Flex><Label>CSR</Label><CSRReps value={info?.csr} onChange={e => setInfo({...info,csr:e.target.value})} /></Flex>
<br />
<Flexed>
<Flex ><Label>Tax Exempt</Label><Selectbox value={info?.taxexempt} onChange={e => setInfo({...info,taxexempt:e.target.value})}>
    <option value="No" >No</option>
    <option value="Yes" >Yes</option>
    </Selectbox></Flex>
    <Flex><Label> Billing Terms</Label><BillingTerms value={info?.terms} onChange={e => setInfo({...info,terms:e.target.value})}/> </Flex>

</Flexed>
<Flexed>
<Flex><Label>Status</Label><Selectbox value={info?.pros} onChange={e => setInfo({...info,pros:e.target.value})} ><option disabled selected required>Select Status</option><option value="0" >Prospect</option><option value="1" >Customer</option></Selectbox></Flex>
   
    <Flex></Flex>
    </Flexed> 
    </Flexed>
  
    </Flex>
   <Button  position="absolute" bottom="20px" fontWeight="bold" bgcolor="#F1F1F2" color="#303030" right="15%" padding="8px 40px" disabled={disalesave} disaled={addbuttongoaway}>Submit</Button>
</Flexed>

   


</Scroll>
</form>
   </Wrap>

  )
}

export default NewCustomer