import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Input, Label, Selectbox, Textarea, Textauto } from '../../utilities/Shared'
import EstimateContext from '../../Context/EstimateContext'
import GetPapers from './GetPapers'
import Papers from '../../utilities/Papers'
import Select from '../../utilities/Select'
import { methods, presses } from '../../utilities/Lists'
import SheetLayout from './SheetLayout'
import Costpanel from './Costpanel'


const PressItem = ({item,updateformitem}) => {

  const [currentpaper, setCurrentpaper] = useState(item.paper)

const {quote,updatequantity,papers,forms} = useContext(EstimateContext)









const updatepaper  = (e,node) => {

    var inputValue = e.target.value;
    console.log(inputValue)
    // setSelectedItem({...selectedItem, itemname: e.target.value})
     let item = 0;
     let dd 
  
         var options = document.getElementById(node).childNodes;
  
         for (var i = 0; i < options.length; i++) {

           if (options[i].value === inputValue) {
            let addressdata = options[i].dataset
    item = {
      addid:addressdata.id
    }
             dd ={
              label:addressdata?.name,
              value:inputValue,
              stockid:addressdata?.stockid,
              id:addressdata?.itemid
             
            }
            
           }
    } 
    console.log(dd)
if(dd === undefined){dd = {
  label:inputValue,
  value:inputValue,
  stockid:0,
  id:0
 
}}
console.log(dd)
   // updateformitem(dd,item,'paper')
   
}
console.log(currentpaper)

const change = (e,node) => {
  var inputValue = e.target.value;
  var options = document.getElementById(node).childNodes;

  let dd 

  for (var i = 0; i < options.length; i++) {

    if (options[i].value === inputValue) {
     let addressdata = options[i].dataset
item = {
addid:addressdata.id
}
      dd ={
       label:addressdata?.name,
       value:inputValue,
       stockid:addressdata?.stockid,
       id:addressdata?.itemid
      
     }
     
    }
} 
console.log(dd)
if(dd !== undefined){setCurrentpaper({label:dd.label,value:dd.value})}else{setCurrentpaper({label:inputValue,value:dd?.stockid})}

}
console.log(papers)

  return (
    <div style={{background:"#CED9DE"}}>
      <Flexed>
      <Flex></Flex>
    </Flexed>
    <Flexed padding="10px 0px">
        <Flex flex="1.5"><Label>Component Name</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
         <Flex flex=".5"></Flex>
        <Flex flex=".5"><Label>Quantity 1</Label><br /><Input type="text" value={item.quantity[0]} onChange={e => updatequantity(e,0)}/></Flex>
        <Flex flex=".5"><Label>Add'l Qty's</Label><br /><Input type="text" defaultValue={item.quantity[item.quantity.length - 1] - item.quantity[0] } onChange={e => updatequantity(e,(item.quantity.length - 1))}/></Flex>
        <Flex flex=".5"></Flex>
        <Flex></Flex>
    <Flex></Flex>
    </Flexed>


<Flexed>
  <Flex><SheetLayout /></Flex>
  <Flex><SheetLayout /></Flex>
  <Flex><Costpanel /></Flex>
</Flexed>



<Flexed gap="20px" padding="8px 0px">


<Flex flex="2.5" ><Label>Paper</Label><Input   type="text"  list='papers' value={`${currentpaper?.stockid}`}   onChange={e => change(e,'papers')} />
   <datalist id='papers'>
{
    papers?.map((paper,index) => {
     return   <option key={index} data-itemid={paper?._id} data-stockid={paper?.stockid} data-id={item?.addid} data-name={paper?.name} value={`${paper?.name} - ${paper?.stockid}`}  /> 
    })
}
   </datalist></Flex>
    <Flex flex=".5"><Label>Paper Id</Label><br /><Input type="text" value={item.paper?.stockid}/></Flex>
    <Flex> {/* <Label>Press</Label><br />
        <Selectbox value="" >
        <option value="" disabled>Select Press</option>
          {presses.map(press => {
          return  <option value={press.id}>{press.name}</option>
          })}
        </Selectbox> */}
      </Flex>
      <Flex>{/* <Label>Press Method</Label><br />
        <Selectbox value="" >
        <option value="" disabled>Select Method</option>
          {methods.map(method => {
          return  <option value={method.id}>{method.name}</option>
          })}
        </Selectbox> */}
      </Flex>

</Flexed>

<Flexed padding="8px 0px" gap="30px">
  <Flex><Label>Parent Size: </Label>   <br />
  <Flexed>
  <Flex><Label>W:</Label> <Input /></Flex>
  <Flex><Label>L:</Label> <Input /></Flex>
  <Flex><Label>Up:</Label> <Input /></Flex>
  <Flex><Label>Needed:</Label> <Input /></Flex>
  <Flex><Label>Over-ride:</Label> <Input /></Flex>
  </Flexed>
  <Label>Press Size:</Label><br />
  <Flexed>
  <Flex ><Label>W:</Label> <Input /></Flex>
  <Flex><Label>L:</Label> <Input /></Flex>
  <Flex><Label>Up:</Label> <Input /></Flex>
  <Flex><Label>Needed:</Label> <Input /></Flex>
  <Flex><Label>Over-ride:</Label> <Input /></Flex>
  </Flexed>
  <Label>Piece Size:</Label><br />
  <Flexed>
  <Flex><Label>W:</Label> <Input /></Flex>
  <Flex><Label>L:</Label> <Input /></Flex>
  <Flex><Label>Up:</Label> <Input /></Flex>
  <Flex><Label>Needed:</Label> <Input /></Flex>
  <Flex><Label>Over-ride:</Label> <Input /></Flex>
  </Flexed>
  </Flex>
<Flex>2</Flex>



</Flexed>



{/*   
 
<Flexed gap="20px" padding="10px 0px">
  <Flex ><Label>Front Inks</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Front %</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Front Coating</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Passes</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Total Plates</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
</Flexed>
<Flexed gap="20px" padding="10px 0px">
  <Flex ><Label>Back Inks</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Back %</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Back Coating</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Passes</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Total Plates</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
</Flexed>
      
       
<Flexed gap="20px" padding="10px 0px">
<Flex flex="2"><Label>Paper</Label><br />  <Papers  setValue={getnewpaper} value={paperitem} /></Flex>
<Flex flex=".5"><Label>Paper ID</Label><br /><Input type="text" value={paperitem.value} onChange={e => updatequantity(e,0)}/></Flex>
  <Flex><Label>Versions</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label># Pages</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>/Form</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
</Flexed>
Parent Sheet
<Flexed gap="20px" padding="10px 0px">
  <Flex ><Label>Width</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Length</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label># Out</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>{item.quantity[0]}</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>{item.quantity[item.quantity.length - 1] - item.quantity[0]}</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
</Flexed>
Press Sheet
<Flexed gap="20px" padding="10px 0px">
  <Flex ><Label>Width</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label>Length</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex><Label># Out</Label><br /><Input type="text" defaultValue={item.componentName} onChange={e => updateformitem(e.target.value,item,'componentName')}/></Flex>
  <Flex></Flex>
  <Flex></Flex>
</Flexed> */}
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

    </div>
  )
}

export default PressItem

