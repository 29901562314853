import axios from "axios"
import { useEffect, useState } from "react"
import { getemployeebynamefromempid } from "../../utilities/Convertions"
import Employees from "../Employees/Employees"
import { sortnum } from "../../utilities/Sort"



export default function useActiveJobs(){


    const [activejobs, setActivejobs] = useState()
    const [jobids, setJobids] = useState([])
    const [jobs, setJobs] = useState([])
    const [est, setEst] = useState([])
const [order, setOrder] = useState("ASC")

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };
    useEffect(() => {
     
        const getactive = async() => { 
          let r = {
            job:""
          }
          await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getactivejobs.php`,r,config)
    .then(res => {
      console.log(res.data)
      let u = []
      res.data.map(item => {
u.push(item.job)
      })
      let ids = u
      setActivejobs(res.data)
      setJobids(u)
    })
    .catch(error => { console.log(error)})
        }
const getest = async() => {
let r = {
  quote:""
}
await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getallimpo.php`,r,config)
.then(res => {setEst(res.data)})
.catch(err => console.log(err))
}
getest()

        getactive()     
  },[])
  console.log(activejobs)
  console.log(jobids)

useEffect(() => {
getimp(jobids)
},[jobids])

  const getimp = async(ids) => { 
    let r ={ids:ids}
    let rr = []
  
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getgl40.php`,r,config)
.then(res => { ;
let io =[]
let newids = []
res.data.map(item => {
 
if( parseInt(item.numforms) > 1){
for(let i=1;i <= parseInt(item?.numforms);i++){ 

io = {
...item,
partofid:`${item.id}${i}`,
partof:item.id,
name:`${i}) ${item.component}`,


} 
rr.push(io)     

}
}else{

io = {
...item,
partofid:0,
partof:item.id,
name:item.component,

} 
rr.push(io)
}
newids.push(item.job)
})


getops(newids,rr); 
}) 

.catch(error => { console.log(error)})
  }


  const getops= async(ids,rr) => { 

    let f ={ids:ids}
    let op = []
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoffpress.php`,f,config)
.then(res => {
   
  
rr.map((item,index) => {

let u = res.data.filter(filt => parseInt(filt.partof) === parseInt(item.partof) && parseInt(filt.partofid) === parseInt(item.partofid))

let exists = u.find(el => el.name === "Off Press");
if(exists){

}else{

let uu = activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job))
console.log(uu)
let hh = {
...item,
stat:u[0]?.name || "Incoming",
impp:activejobs?.filter(filtt => parseInt(filtt.job) === parseInt(item.job)),
newquantity:getquantity(item),
customer:uu[0].customer,
//salesrep:getemployeebynamefromempid(uu[0]?.salesrep,Employees),
dateentered:uu[0].dateentered,
datedue:uu[0].duedate
}
op.push(hh)
}

let ur = sortnum('job',order,op,setJobs,setOrder);

setJobs(op)
})

 })
.catch(error => { console.log(error)})

  }

  console.log(jobs)
  const getquantity = (item) => {
    let a = parseInt(item.needed) + parseInt(item.mroverride)  + parseInt(item.additionalmr)
    if(item.mroverride == 0){
    a +=  parseInt(item.mr)
    }
    let b = a   / parseInt(item.numforms)
    return b
      }
      console.log(jobs)
return jobs
}