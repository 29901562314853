import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "../utilities/Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;
font-weight:bold;
text-transform:capitalize;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || ""};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};


h2{
    margin:0;
    padding:0:
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({poinfo,title}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)

useEffect(() => {
    const addit = () => {
        let u = 0
        poinfo?.items?.map(item => {
       u += (item.extprice)
            })
            setTotal(u)
    
    }
    addit()
},[poinfo])



  useEffect(() => {
const getpoinfo = async() => {

}




getpoinfo()
  },[])


  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      

 console.log(poinfo)


  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint poinfo={poinfo} title={title} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
     
  
  }

  render() {
    return (
      
     <Wrapper style={{fontSize:".9rem",display: 'block',padding:"10px 0px",pageBreakAfter: "auto"}}>
   
<Flexed style={{border:"1px solid black",padding:"6px",overflowWrap:"break-word"}}>

    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}}>Bin</Flex>
    <Flex  style={{borderRight:"1px solid black",padding:"6px"}}>Inv ID</Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"6px"}}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}}>Current</Flex>
   <Flex style={{padding:"6px"}}>Actual</Flex>

   </Flexed>
<Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed><Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
   <Flexed style={{border:"1px solid black",padding:"6px"}} >
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px",overflowWrap:"break-word",minWidth:"0",wordBreak:"break-word"}} flex="4">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"6px"}} whiteSpace="normal">&nbsp;</Flex>
   <Flex style={{padding:"3px"}} whiteSpace="normal">&nbsp;</Flex>
   </Flexed>
    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
