import React, { useEffect, useState } from 'react'
import { Flex, Flexed } from '../../utilities/Shared'
import { useNavigate, useParams  } from 'react-router-dom'
import axios from 'axios'
import { sortit, sortnum } from '../../utilities/Sort'

const CustomerSearch = ({term,csrreps,salesreps}) => {
    const [customers, setCustomers] = useState([])
    const [order, setOrder] = useState("ASC")


const params = useParams()



const nav = useNavigate()
useEffect(() => {
 

    const getcustomers =  async() => {
        
       await axios.post('https://highgroundapi.herokuapp.com/customers/search/customers',{"cust":term})
       // await axios.post('http://localhost:5003/customers/search/customers',cust)
        .then(res => {console.log(res.data);setCustomers(res.data)})
        .catch(err => console.log(err))
    }
    getcustomers()
},[])
   


  return (
    <>
    {customers.length > 0 && 
    <div style={{border:"1px solid #303030",margin:"30px 0px"}}>
    <Flexed ><Flex  bgColor="#0E425A" color="white" fontSize="calc(1.5rem + .4vw)">CUSTOMER RESULTS&nbsp;&nbsp;{customers.length}</Flex></Flexed>
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" >
        <Flex className="hand" fontSize="var(--headerMedium)" flex="2.5" onClick={() => sortit('name',order,customers,setCustomers,setOrder)}>Customer</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)" flex=".5" onClick={() => sortnum('company_id',order,customers,setCustomers,setOrder)}>ID#</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)" flex="2.5" onClick={() => sortit('address',order,customers,setCustomers,setOrder)}>Address</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)">Phone</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)" onClick={() => sortit('salesrep',order,customers,setCustomers,setOrder)}>Sales Rep</Flex>
        <Flex className="hand" fontSize="var(--headerMedium)" onClick={() => sortit('custrep',order,customers,setCustomers,setOrder)}>CSR</Flex>
    </Flexed>
    
    {customers?.map(customer => {
     return <Flexed className="normalrow" onClick={() => nav(`/customers/customer/${customer._id}`)} >
     <Flex  flex="2.5" >{customer.name}</Flex>
     <Flex  flex=".5" >{customer.company_id}</Flex>
     <Flex  flex="2.5">{customer.address.filter(add => add.itemid === customer.mainlocation).map(res => {return <>{res.address1} {res.address2} {res.city}, {res.state} {res.zip}</>})}</Flex>
     <Flex >{customer.mainphone}</Flex>
     <Flex >{salesreps?.filter(rep => rep._id === customer.salesrep).map(rep =>{return rep.firstName+" "+rep.lastName.substr(0,1)})}</Flex>
     <Flex >{csrreps?.filter(rep => rep._id === customer.custrep).map(rep =>{return rep.firstName+" "+rep.lastName.substr(0,1)})}</Flex>
    </Flexed>
    })
       
    }
    </div>}
    </>
  )
}

export default CustomerSearch