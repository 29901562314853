import React, { useEffect, useState } from 'react'
import { Flex, Flexed, Input, Label, Selectbox, Textauto } from '../../utilities/Shared'
import Moment from 'react-moment'
import { RiDeleteBack2Fill } from "react-icons/ri";
import axios from 'axios';

const Shipitem = ({item,addtopack,setCurrentlocation,jobinfo,placeindex,setReset,reset,customerinfo,currentlocation}) => {

    const[newitem, setNewitem] = useState(item)
    const[newshipto, setNewshipto] = useState({})
    const[sentamount, setSentamount] = useState(0)
    const[locations, setLocations] = useState([])

const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };    


useEffect(() => {
    axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoldlocation.php`,config)
    .then(res => {setLocations(res.data);setNewitem({...newitem,type:"",sending:"",per:"",totalitem:""})})
   .catch(err => {console.log(err)}) 

},[])



const deletepack = (item,index,shipid) => {

    let rr 
    let h
      jobinfo.shippingitems.map(item =>{
        let uy = item.shiplist?.filter(filt => filt.shipid === shipid)
        item.sent === null ? item.sent = 0 :item.sent = item.sent
   if(uy?.length > 0){
   
    item.sent = parseInt(item.sent) - parseInt(uy[0]?.sending)
   }
       
       h = item.shiplist?.filter(filt => filt.shipid !== shipid)
       item.shiplist = h
        
    })
    
   

let y = 
{
    id:jobinfo._id ,
    field:'shippingitems',
    value:jobinfo.shippingitems 
}

const  loadshipping = async() => {
    await axios.post(`https://highgroundapi.herokuapp.com/jobs/update`,y)
    .then(resp => {window.location.reload()})
    .catch(err => console.log(err))
}
loadshipping()

}

const getsentamount = () => {
    let y = 0
    newitem?.shiplist?.map(item => {
y += parseInt(item.sending)
    })
   return y
}



const pack = (e,item) => {

    console.log(e.target.checked)

    
let u = {
    ...item,
    job:item.job,
    shiptoid:newitem.shiptoid,
    shipto:item.shipto,
    method:item.method,
    itemid:item.id
   
}
if(e.target.checked){
    setCurrentlocation(newitem.shiptoid)
}else{
    setCurrentlocation(null)
}
console.log(u)
    
setNewitem({...newitem,check:e.target.checked,sent:0})
  addtopack(e.target.checked,item)
    
}


const changeit = (e,item,field) => {
    newitem[field] = e.target.value
    newitem.check = false
    addtopack(false,item)
    let a
    let tot

    let b = isNaN(parseInt(newitem.sending)) ? 0 : parseInt(newitem.sending)
    let c = isNaN(parseInt(newitem.per)) ? 0 : parseInt(newitem.per) 
    a = b / c

    let d = b % c
  
    if(c > 0 && b > 0){
         tot = `${Math.floor(a)} ${newitem.type} @ ${newitem.per} Pieces Each \n`
        if(d !== 0){
tot += `${1} ${newitem.type} @ ${d} Pieces`
        }
    }

        setNewitem({...newitem,totalitem:tot,itemcount:Math.ceil(a)})
}
   



          const changeaddress = (e,item) => {
            const objIndex = jobinfo.shippingitems.findIndex((obj => obj.id === item.id));
            item.shipto = e.target.options[e.target.selectedIndex].text
            item.shiptoid = e.target.options[e.target.selectedIndex].value
            jobinfo.shippingitems[objIndex] = item
            let z = jobinfo.shippingitems
            let y = 
            {
                id:jobinfo._id,
                field:'shippingitems',
                value:z
            }

            const  loadshipping = async() => {
                await axios.post(`http://localhost:5003/jobs/update`,y)
               // await axios.post(`http://localhost:5003/jobs/update`,y)
                .then(resp => setReset(!reset))
                .catch(err => console.log(err))
            }
            loadshipping()
          }


          const changemethod = (e,item) => {
            const objIndex = jobinfo.shippingitems.findIndex((obj => obj.id === item.id));
            item.method = e.target.value
            jobinfo.shippingitems[objIndex] = item
            let z = jobinfo.shippingitems
            let y = 
            {
                id:jobinfo._id,
                field:'shippingitems',
                value:z
            }
    
            const  loadshipping = async() => {
                await axios.post(`http://localhost:5003/jobs/update`,y)
               // await axios.post(`http://localhost:5003/jobs/update`,y)
                .then(resp => setReset(!reset))
                .catch(err => console.log(err))
            }
            loadshipping()
          }
          
          console.log(item)

  return (
 <div className="normalrow" style={{border:"1px solid #0E425A",marginBottom:"10px"}}>

<Flexed>
<Flex>
    <Flexed>
        <Flex flex="5"><Label>Item:</Label><br /><Input type="text"  value={item.description}/></Flex>
        <Flex><Label>Ordered:</Label><br /><Input type="text" value={item.quantity} disabled/></Flex>
        <Flex><Label>Sent:</Label><br /><Input type="text" value={getsentamount(item)} style={{background:sentamount < newitem.quantity ? "#FAF884" : ""}} disabled/></Flex>
        <Flex><Label>Type:</Label><br /><Input type="text" /></Flex>
        <Flex><Label>Shipping:</Label><br /><Input type="text" /></Flex>
        <Flex><Label>Per:</Label><br /><Input type="text" /></Flex>
    </Flexed>
    <Flexed style={{marginTop:"-10px"}}>
    <Flex><Label>Ship Type:</Label><br /><Input type="text" /></Flex>
    <Flex><Label>Ship To:</Label><br /><Input type="text" /></Flex>
      
    </Flexed>
</Flex>

<Flex flex=".3"><Label>Total Items:</Label><br /><Textauto /> </Flex>

<Flex flex=".1" textAlign="center"><Label>Include:</Label><br /><Input type="checkbox" checked={newitem.check} width="20px" onClick={e => pack(e,item)}  /> </Flex>
</Flexed>






   <Flexed padding="5px 3px" >
    <Flex fontSize="calc(1.2rem + .2vw)" flex="3">
   
       </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".9"><Input type="text" value={item.quantity} disabled/></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".9"><Input type="text" value={getsentamount(item)} style={{background:sentamount < newitem.quantity ? "#FAF884" : ""}} disabled/></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".9">
<Selectbox value={newitem?.type}   fontSize="calc(.9rem + .2vw)" onChange={e => setNewitem({...newitem,type:e.target.value})} disabled={newitem.check ? true : false}>
<option value=""></option>
    <option value="Box(s)">Box(s)</option>
    <option value="Skid(s)">Skids</option>
    <option value="Item(s)">Items</option>
</Selectbox>
</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" defaultValue={newitem?.sending}  onChange={e => changeit(e,item,'sending')}  disabled={newitem.check ? true : false}/></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".5"><Input type="text" defaultValue={newitem?.per}  onChange={e => changeit(e,item,'per')} disabled={newitem.check ? true : false}/></Flex>

    <Flex fontSize="calc(1.2rem + .2vw)"> <Textauto fontSize="calc(1rem + .2vw)" minHeight="20px" defaultValue={newitem.totalitem} minRows="1" onChange={e => changeit(e,item,'totalitem')} disabled={newitem.check ? true : false}/></Flex>

    <Flex fontSize="calc(1.2rem + .2vw)" textAlign="center" flex=".5">
       
      
        </Flex>
</Flexed>
<Flexed style={{marginTop:"-10px"}}>
<Flex fontSize="calc(1.2rem + .2vw)" flex="2">Ship To:<br />
    <Selectbox  value={newitem.shiptoid} onChange={e => changeaddress(e,newitem)} disabled={newitem.check ? true : false} required>
       
    <option value="" >Select Address</option>
    <option value="2850">Tampa BMEU</option>
        {
          customerinfo.address?.map((addy) => {
            return <option value={addy.old} >{addy.address1} {addy.address2} {addy.city} {addy.state} {addy.zip}</option>
          })  
        }
</Selectbox>
    </Flex>
    <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">Ship Type:<br />
    <Selectbox defaultValue={item.method} onChange={e => changemethod(e,newitem)} disabled={newitem.check ? true : false} required>
    
    <option value="" >Select Method</option>
      <option value="PLS Delivery" >PLS Delivery</option>
      <option value="Pick Up" >Pick Up</option>
      <option value="UPS" >UPS</option>
      <option value="FED EX" >FED EX</option>
      <option value="NPL Pickup" >NPL Pickup</option>
      <option value="Outside Truck" >Outside Truck</option>
  
</Selectbox>
    </Flex>
</Flexed>
{/*
{newitem.shiplist?.map((item,index) => {
    console.log(item)
    
   return <> <Flexed padding="10px 3px" >
    <Flex><Moment format="MM/DD/YYYY">{item.date ? item.date : null}</Moment></Flex>
    <Flex fontSize="calc(1rem + .2vw)" >{item.shipid}</Flex>
    <Flex flex="2.2">&nbsp;</Flex>
    <Flex fontSize="calc(1rem + .2vw)">{item?.sending}</Flex>
    <Flex fontSize="calc(1rem + .2vw)" flex="3.8">{item.totalitem} </Flex>

    <Flex fontSize="calc(1rem + .2vw)" flex=".5"><RiDeleteBack2Fill style={{fontSize:"calc(1.6rem + .2vw)",color:"rgb(255,0,0,.6)"}} onClick={e => deletepack(item,index,item.shipid)} /> </Flex>
  
</Flexed>
<Flexed margin="-5px 0px 10px 0px">

</Flexed>
</>
})
   
}
*/}
</div>

  )
}

export default Shipitem