import React, { useContext, useEffect,useState } from 'react'
import { Button, Datepick, Flex, Flexed, Input, MainWrap, Textarea, Wrap, currency } from '../../utilities/Shared'
import { useNavigate } from 'react-router-dom'
import "react-datepicker/dist/react-datepicker.css";
import {POTypes,PLSOrderbylist,PLStypelist} from '../../utilities/Lists'
//import Dropdown from '../../utilities/Dropdown'
import axios from 'axios'
import Select from '../../utilities/Select'
import { v4 as uuidv4 } from 'uuid';
import InventoryBox from '../../components/InventoryBox';
import UserContext from '../../Context/UserContext';


const CFPNewpo = () => {

    const nav = useNavigate()

    const { userInfo } = useContext(UserContext)

    const [duedate, setDuedate] = useState(new Date())
    const [selectedvendor, setSelectedvendor] = useState({})
    const [selectedshipto, setSelectedshipto] = useState({id:"665779b86560608efb471577",name:"CFP"})
    const [forjobsshow, setForjobsshow] = useState(false)
    const [reset, setReset] = useState(false)
   const [type, setType] = useState()
   const [itemslist, setItemslist] = useState([])
   const [selected ,setSelected] = useState({})
   const [selectedItem ,setSelectedItem] = useState({})
   const [paperslist, setPaperslist] = useState({})
   const [supplieslist, setSupplieslist] = useState({})
   const [vendorList, setVendorList] = useState([])
   const [sameitems, setSameitems] = useState([])
   const [orderunit, setOrderunit] = useState({})
   const [priceby, setPriceby] = useState({})
   const [orderby, setOrderby] = useState({})
   const [items, setItems] = useState([])
   const [forjoblist, setForjoblist] = useState([])
   const [q, setQ] = useState()
   const [j, setJ] = useState()
   const [invoice, setInvoice] = useState()

   const preset = {
    jobnum:"",
    name:null,
    stockid:null,
    type:null,
    quantity:null,
    orderby:"",
    width:null,
    length:null,
    unitprice:null,
    weight:null,
    pounds:null,
    divide:null,
    mwt:null,
    cwt:null,
    priceby:null,
    extprice:0.00,
    notes:"",
    forjobs:[],
    itemid:null,
    description:""
    }


useEffect(() => {
    setSelected(preset)
    setSelectedItem([])
    setSameitems([])
    setItems([])
},[])

useEffect(() => {

    const getitems = async() =>{
        let y
      await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/products/getallproducts')
      .then(res => {console.log(res.data);setItemslist(res.data)})              
      .catch(err => console.log())
      }

      const getvendors = async() =>{
        let y
      await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/vendors/getall')
      .then(res => {console.log(res.data);setVendorList(res.data)})              
      .catch(err => console.log())
      }
      


      getvendors()
      getitems()

    // const vendorlist = async() => {
    //     //await axios.get('https://highgroundapi.herokuapp.com/vendor')
    //     await axios.get('https://highgroundapi.herokuapp.com/vendor')
    //     .then(res => {setVendorList(res.data)})
    //     .catch(err => console.log(err))
    //     }
    //     const p = async() => {
    //         //await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    //         await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    //         .then(res => {setPaperslist(res.data)})
    //         .catch(err => console.log(err)) 
    //     }
    //     const pa = async() => {
    //         //await axios.get('https://highgroundapi.herokuapp.com/supplies')
    //         await axios.get('https://highgroundapi.herokuapp.com/supplies')
    //         .then(res => {setSupplieslist(res.data)})
    //         .catch(err => console.log(err)) 
    //     }
    //     pa()
    //     p()
    //     vendorlist()    
},[])



  
  
    useEffect(() => {
    console.log(selectedItem)
    console.log(itemslist)
    
        const newlyselected = itemslist?.filter(item => item._id === selectedItem?.id)
            if(type?.name !== "" || type?.name !== null){
              
              if(newlyselected.length === 0 ){
   setSelected({...selected,name:selectedItem.name,priceunit:"",unitprice:0,size:"",itemid:null})
              
          }else{
            setSelected({...selected,name:newlyselected[0]?.name,itemid:newlyselected[0]?.itemid,priceunit:newlyselected[0]?.priceunit,unitprice:newlyselected[0]?.unitprice,size:newlyselected[0]?.size})
          }
        }
          
          },[selectedItem])
  
          const getmweight = () => {
            setPriceby({id:0,name:""})
            let a = Math.ceil((parseFloat(selected.width) * parseFloat(selected.length) * 2 * parseInt(selected.weight)) / parseInt(selected.divide))
            let b = (a * selected.quantity) /1000
           const newweight =  {...selected,mwt:a,pounds:b}
           setSelected(newweight)
          }
          
          
       
          
          
          useEffect(() => {
          
            const getCost = () =>  {
          
              let a
            
            switch(priceby.name){
              case "CWT":
                case "Roll LBS":
              a = (selected.cwt * selected.quantity) / 100
            break;
            case "Per M":
             a = (selected.quantity / 1000) * selected.unitprice
            break;
            case "Each":
            case "LBS":
            case "Case":
            case "Lot":
            case "Box":
              a = selected.quantity  * selected.unitprice
              break;
            default:
             a = null
              break;
            }
        
            setSelected({...selected,extprice:a,priceby:priceby.name,orderby:priceby.name})
            }
            getCost()
          },[priceby])
          
          
          
          const addtolist = (e) => {
           
          e.preventDefault()
         console.log(selectedItem)
        const t = {...selected,itemaddid:uuidv4(),name:selectedItem.name}
          
         console.log(t)


          
          if(selected.itemaddid){
            const objIndex = items.findIndex((obj => obj.itemaddid === selected.itemaddid));
            items[objIndex] = t
            setItems(items)
            setSelectedItem({id:null,name:""})
            setSelected(preset)
            setType({id:null,name:""})
            setOrderby({id:null,name:""})
            setPriceby({id:null,name:""})
            setSameitems({})
            }else{
              setItems([...items,t])
              setSelectedItem({id:null,name:""})
                    setSelected(preset)
                    setType({id:null,name:""})
                    setOrderby({id:null,name:""})
                    setPriceby({id:null,name:""})
                    
          }
          
          
          }
          
          
          const setupdateitem = (item) => {
          
          
          type.name = item.type
          const newselectedItem = {id:item.id,name:item.name}
          setSelectedItem(newselectedItem)
          setSelected(item)
          const neworder = {id:0,name:item.orderby}
          const newpriceby = {id:0,name:""}
          setOrderby(neworder)
          setPriceby(newpriceby)
          
          }


const convert = ($value) => {
    switch($value){
        case "6384b93c8841c84328f6e32b":
        return "1";
        break;
    
        case "6384b93c8841c84328f6e32c":
        return "2";
        break;
    
        case "6384b93c8841c84328f6e32d":
        return "3";
        break;
    
        case "6384b93c8841c84328f6e32e":
        return "5";
        break;
    
        case "6384b93c8841c84328f6e32f":
        return "6";
        break;
    
        case "6384b93c8841c84328f6e330":
        return "7";
        break;
    
        case "6384b93c8841c84328f6e331":
        return "8";
        break;
    
        case "6384b93c8841c84328f6e332":
        return "9";
        break;
    
        case "6384b93c8841c84328f6e333":
        return "10";
        break;
    
        case "6384b93c8841c84328f6e334":
        return "11";
        break;
    
        case "6384b93c8841c84328f6e335":
        return "12";
        break;
    
        case "6384b93c8841c84328f6e336":
        return "13";
        break;
    
        case "6384b93c8841c84328f6e337":
        return "14";
        break;
    
        case "6384b93c8841c84328f6e338":
        return "15";
        break;
    
        case "6384b93c8841c84328f6e339":
        return "16";
        break;
    
        case "6384b93c8841c84328f6e33a":
        return "17";
        break;
    
        case "6384b93c8841c84328f6e33b":
        return "18";
        break;
    
        case "6384b93c8841c84328f6e33c":
        return "19";
        break;
    
        case "6384b93c8841c84328f6e33d":
        return "20";
        break;
    
        case "6384b93c8841c84328f6e33e":
        return "21";
        break;
    
        case "6384b93c8841c84328f6e33f":
        return "22";
        break;
    
        case "6384b93c8841c84328f6e340":
        return "23";
        break;
    
        case "6384b93c8841c84328f6e341":
        return "24";
        break;
    
        case "6384b93c8841c84328f6e342":
        return "26";
        break;
    
        case "6384b93c8841c84328f6e343":
        return "27";
        break;
    
        case "6384b93c8841c84328f6e344":
        return "28";
        break;
    
        case "6384b93c8841c84328f6e345":
        return "29";
        break;
    
        case "6384b93c8841c84328f6e346":
        return "30";
        break;
    
        case "6384b93c8841c84328f6e347":
        return "31";
        break;
    
        case "6384b93c8841c84328f6e348":
        return "32";
        break;
    
        case "6384b93c8841c84328f6e349":
        return "33";
        break;
    
        case "6384b93c8841c84328f6e34a":
        return "34";
        break;
    
        case "6384b93c8841c84328f6e34b":
        return "35";
        break;
    
        case "6384b93c8841c84328f6e34c":
        return "36";
        break;
    
        case "6384b93c8841c84328f6e34d":
        return "37";
        break;
    
        case "6384b93c8841c84328f6e34e":
        return "38";
        break;
    
        case "6384b93c8841c84328f6e34f":
        return "40";
        break;
    
        case "6384b93c8841c84328f6e350":
        return "41";
        break;
    
        case "6384b93c8841c84328f6e351":
        return "42";
        break;
    
        case "6384b93c8841c84328f6e352":
        return "4";
        break;
    
        case "6384b93c8841c84328f6e353":
        return "43";
        break;
    
        case "6384b93c8841c84328f6e354":
        return "25";
        break;
    
        case "6384b93c8841c84328f6e355":
        return "46";
        break;
    
        case "6384b93c8841c84328f6e356":
        return "49";
        break;
    
        case "6384b93c8841c84328f6e357":
        return "53";
        break;
    
        case "6384b93c8841c84328f6e358":
        return "56";
        break;
    
        case "6384b93c8841c84328f6e359":
        return "58";
        break;
    
        case "6384b93c8841c84328f6e35a":
        return "59";
        break;
    
        case "6384b93c8841c84328f6e35b":
        return "45";
        break;
    
        case "6384b93c8841c84328f6e35c":
        return "50";
        break;
    
        case "6384b93c8841c84328f6e35d":
        return "51";
        break;
    
        case "6384b93c8841c84328f6e35e":
        return "52";
        break;
    
        case "6384b93c8841c84328f6e35f":
        return "60";
        break;
    
        case "6384b93c8841c84328f6e360":
        return "61";
        break;
    
        case "6384b93c8841c84328f6e361":
        return "47";
        break;
    
        case "6384b93c8841c84328f6e362":
        return "48";
        break;
    
        case "6384b93c8841c84328f6e363":
        return "54";
        break;
    
        case "6384b93c8841c84328f6e364":
        return "57";
        break;
    
        case "6384b93c8841c84328f6e365":
        return "62";
        break;
    
        case "6384b93c8841c84328f6e366":
        return "64";
        break;
    
        case "6384b93c8841c84328f6e367":
        return "66";
        break;
    
        case "6384b93c8841c84328f6e368":
        return "55";
        break;
    
        case "6384b93c8841c84328f6e369":
        return "65";
        break;
    
        case "6384b93c8841c84328f6e36a":
        return "67";
        break;
    
        case "6384b93c8841c84328f6e36b":
        return "75";
        break;
    
        case "6384b93c8841c84328f6e36c":
        return "76";
        break;
    
        case "6384b93c8841c84328f6e36d":
        return "77";
        break;
    
        case "6384b93c8841c84328f6e36e":
        return "78";
        break;
    
        case "6384b93c8841c84328f6e36f":
        return "63";
        break;
    
        case "6384b93c8841c84328f6e370":
        return "79";
        break;
    
        case "6384b93c8841c84328f6e371":
        return "80";
        break;
    
        case "6384b93c8841c84328f6e372":
        return "83";
        break;
    
        case "6384b93c8841c84328f6e373":
        return "87";
        break;
    
        case "6384b93c8841c84328f6e374":
        return "88";
        break;
    
        case "6384b93c8841c84328f6e375":
        return "81";
        break;
    
        case "6384b93c8841c84328f6e376":
        return "82";
        break;
    
        case "6384b93c8841c84328f6e377":
        return "84";
        break;
    
        case "6384b93c8841c84328f6e378":
        return "89";
        break;
    
        case "6384b93c8841c84328f6e379":
        return "91";
        break;
    
        case "6384b93c8841c84328f6e37a":
        return "94";
        break;
    
        case "6384b93c8841c84328f6e37b":
        return "85";
        break;
    
        case "6384b93c8841c84328f6e37c":
        return "86";
        break;
    
        case "6384b93c8841c84328f6e37d":
        return "90";
        break;
    
        case "6384b93c8841c84328f6e37e":
        return "92";
        break;
    
        case "6384b93c8841c84328f6e37f":
        return "96";
        break;
    
        case "6384b93c8841c84328f6e380":
        return "98";
        break;
    
        case "6384b93c8841c84328f6e381":
        return "99";
        break;
    
        case "6384b93c8841c84328f6e382":
        return "93";
        break;
    
        case "6384b93c8841c84328f6e383":
        return "97";
        break;
    
        case "6384b93c8841c84328f6e384":
        return "100";
        break;
    
        case "6384b93c8841c84328f6e385":
        return "101";
        break;
    
        case "6384b93c8841c84328f6e386":
        return "102";
        break;
    
        case "6384b93c8841c84328f6e387":
        return "103";
        break;
    
        case "6384b93c8841c84328f6e388":
        return "104";
        break;
    
        case "6384b93c8841c84328f6e389":
        return "105";
        break;
    
        case "6384b93c8841c84328f6e38a":
        return "106";
        break;
    
        case "6384b93c8841c84328f6e38b":
        return "108";
        break;
    
        case "6384b93c8841c84328f6e38c":
        return "109";
        break;
    
        case "6384b93c8841c84328f6e38d":
        return "110";
        break;
    
        case "6384b93c8841c84328f6e38e":
        return "113";
        break;
    
        case "6384b93c8841c84328f6e38f":
        return "117";
        break;
    
        case "6384b93c8841c84328f6e390":
        return "118";
        break;
    
        case "6384b93c8841c84328f6e391":
        return "119";
        break;
    
        case "6384b93c8841c84328f6e392":
        return "121";
        break;
    
        case "6384b93c8841c84328f6e393":
        return "68";
        break;
    
        case "6384b93c8841c84328f6e394":
        return "70";
        break;
    
        case "6384b93c8841c84328f6e395":
        return "71";
        break;
    
        case "6384b93c8841c84328f6e396":
        return "72";
        break;
    
        case "6384b93c8841c84328f6e397":
        return "73";
        break;
    
        case "6384b93c8841c84328f6e398":
        return "74";
        break;
    
        case "6384b93c8841c84328f6e399":
        return "115";
        break;
    
        case "6384b93c8841c84328f6e39a":
        return "127";
        break;
    
        case "6384b93c8841c84328f6e39b":
        return "128";
        break;
    
        case "6384b93c8841c84328f6e39c":
        return "130";
        break;
    
        case "6384b93c8841c84328f6e39d":
        return "129";
        break;
    
        case "6384b93c8841c84328f6e39e":
        return "133";
        break;
    
        case "6384b93c8841c84328f6e39f":
        return "135";
        break;
    
        case "6384b93c8841c84328f6e3a0":
        return "136";
        break;
    
        case "6384b93c8841c84328f6e3a1":
        return "116";
        break;
    
        case "6384b93c8841c84328f6e3a2":
        return "120";
        break;
    
        case "6384b93c8841c84328f6e3a3":
        return "122";
        break;
    
        case "6384b93c8841c84328f6e3a4":
        return "123";
        break;
    
        case "6384b93c8841c84328f6e3a5":
        return "124";
        break;
    
        case "6384b93c8841c84328f6e3a6":
        return "125";
        break;
    
        case "6384b93c8841c84328f6e3a7":
        return "137";
        break;
    
        case "6384b93c8841c84328f6e3a8":
        return "138";
        break;
    
        case "6384b93c8841c84328f6e3a9":
        return "139";
        break;
    
        case "6384b93c8841c84328f6e3aa":
        return "140";
        break;
    
        case "6384b93c8841c84328f6e3ab":
        return "141";
        break;
    
        case "6384b93c8841c84328f6e3ac":
        return "142";
        break;
    
        case "6384b93c8841c84328f6e3ad":
        return "143";
        break;
    
        case "6384b93c8841c84328f6e3ae":
        return "156";
        break;
    
        case "6384b93c8841c84328f6e3af":
        return "157";
        break;
    
        case "6384b93c8841c84328f6e3b0":
        return "160";
        break;
    
        case "6384b93c8841c84328f6e3b1":
        return "173";
        break;
    
        case "6384b93c8841c84328f6e3b2":
        return "202";
        break;
    
        case "6384b93c8841c84328f6e3b3":
        return "208";
        break;
    
        case "6384b93c8841c84328f6e3b4":
        return "210";
        break;
    
        case "6384b93c8841c84328f6e3b5":
        return "211";
        break;
    
        case "6384b93c8841c84328f6e3b6":
        return "204";
        break;
    
        case "6384b93c8841c84328f6e3b7":
        return "205";
        break;
    
        case "6384b93c8841c84328f6e3b8":
        return "134";
        break;
    
        case "6384b93c8841c84328f6e3b9":
        return "241";
        break;
    
        case "6384b93c8841c84328f6e3ba":
        return "242";
        break;
    
        case "6384b93c8841c84328f6e3bb":
        return "245";
        break;
    
        case "6384b93c8841c84328f6e3bc":
        return "253";
        break;
    
        case "6384b93c8841c84328f6e3bd":
        return "254";
        break;
    
        case "6384b93c8841c84328f6e3be":
        return "258";
        break;
    
        case "6384b93c8841c84328f6e3bf":
        return "259";
        break;
    
        case "6384b93c8841c84328f6e3c0":
        return "256";
        break;
    
        case "6384b93c8841c84328f6e3c1":
        return "262";
        break;
    
        case "6384b93c8841c84328f6e3c2":
        return "263";
        break;
    
        case "6384b93c8841c84328f6e3c3":
        return "265";
        break;
    
        case "6384b93c8841c84328f6e3c4":
        return "266";
        break;
    
        case "6384b93c8841c84328f6e3c5":
        return "269";
        break;
    
        case "6384b93c8841c84328f6e3c6":
        return "271";
        break;
    
        case "6384b93c8841c84328f6e3c7":
        return "163";
        break;
    
        case "6384b93c8841c84328f6e3c8":
        return "170";
        break;
    
        case "6384b93c8841c84328f6e3c9":
        return "171";
        break;
    
        case "6384b93c8841c84328f6e3ca":
        return "178";
        break;
    
        case "6384b93c8841c84328f6e3cb":
        return "184";
        break;
    
        case "6384b93c8841c84328f6e3cc":
        return "189";
        break;
    
        case "6384b93c8841c84328f6e3cd":
        return "194";
        break;
    
        case "6384b93c8841c84328f6e3ce":
        return "172";
        break;
    
        case "6384b93c8841c84328f6e3cf":
        return "195";
        break;
    
        case "6384b93c8841c84328f6e3d0":
        return "196";
        break;
    
        case "6384b93c8841c84328f6e3d1":
        return "290";
        break;
    
        case "6384b93c8841c84328f6e3d2":
        return "291";
        break;
    
        case "6384b93c8841c84328f6e3d3":
        return "292";
        break;
    
        case "6384b93c8841c84328f6e3d4":
        return "294";
        break;
    
        case "6384b93c8841c84328f6e3d5":
        return "296";
        break;
    
        case "6384b93c8841c84328f6e3d6":
        return "298";
        break;
    
        case "6384b93c8841c84328f6e3d7":
        return "295";
        break;
    
        case "6384b93c8841c84328f6e3d8":
        return "297";
        break;
    
        case "6384b93c8841c84328f6e3d9":
        return "300";
        break;
    
        case "6384b93c8841c84328f6e3da":
        return "301";
        break;
    
        case "6384b93c8841c84328f6e3db":
        return "304";
        break;
    
        case "6384b93c8841c84328f6e3dc":
        return "305";
        break;
    
        case "6384b93c8841c84328f6e3dd":
        return "308";
        break;
    
        case "6384b93c8841c84328f6e3de":
        return "312";
        break;
    
        case "6384b93c8841c84328f6e3df":
        return "313";
        break;
    
        case "6384b93c8841c84328f6e3e0":
        return "314";
        break;
    
        case "6384b93c8841c84328f6e3e1":
        return "318";
        break;
    
        case "6384b93c8841c84328f6e3e2":
        return "307";
        break;
    
        case "6384b93c8841c84328f6e3e4":
        return "315";
        break;
    
        case "6384b93c8841c84328f6e3e5":
        return "320";
        break;
    
        case "6384b93c8841c84328f6e3e6":
        return "321";
        break;
    
        case "6384b93c8841c84328f6e3e7":
        return "347";
        break;
    
        case "6384b93c8841c84328f6e3e8":
        return "348";
        break;
    
        case "6384b93c8841c84328f6e3e9":
        return "350";
        break;
    
        case "6384b93c8841c84328f6e3ea":
        return "353";
        break;
    
        case "6384b93c8841c84328f6e3eb":
        return "360";
        break;
    
        case "6384b93c8841c84328f6e3ec":
        return "316";
        break;
    
        case "6384b93c8841c84328f6e3ed":
        return "319";
        break;
    
        case "6384b93c8841c84328f6e3ee":
        return "324";
        break;
    
        case "6384b93c8841c84328f6e3ef":
        return "355";
        break;
    
        case "6384b93c8841c84328f6e3f0":
        return "358";
        break;
    
        case "6384b93c8841c84328f6e3f1":
        return "359";
        break;
    
        case "6384b93c8841c84328f6e3f2":
        return "378";
        break;
    
        case "6384b93c8841c84328f6e3f3":
        return "380";
        break;
    
        case "6384b93c8841c84328f6e3f4":
        return "383";
        break;
    
        case "6384b93c8841c84328f6e3f5":
        return "386";
        break;
    
        case "6384b93c8841c84328f6e3f6":
        return "382";
        break;
    
        case "6384b93c8841c84328f6e3f7":
        return "387";
        break;
    
        case "6384b93c8841c84328f6e3f8":
        return "215";
        break;
    
        case "6384b93c8841c84328f6e3f9":
        return "226";
        break;
    
        case "6384b93c8841c84328f6e3fa":
        return "228";
        break;
    
        case "6384b93c8841c84328f6e3fb":
        return "229";
        break;
    
        case "6384b93c8841c84328f6e3fc":
        return "326";
        break;
    
        case "6384b93c8841c84328f6e3fd":
        return "329";
        break;
    
        case "6384b93c8841c84328f6e3fe":
        return "332";
        break;
    
        case "6384b93c8841c84328f6e3ff":
        return "335";
        break;
    
        case "6384b93c8841c84328f6e400":
        return "337";
        break;
    
        case "6384b93c8841c84328f6e401":
        return "222";
        break;
    
        case "6384b93c8841c84328f6e402":
        return "227";
        break;
    
        case "6384b93c8841c84328f6e403":
        return "232";
        break;
    
        case "6384b93c8841c84328f6e404":
        return "331";
        break;
    
        case "6384b93c8841c84328f6e405":
        return "338";
        break;
    
        case "6384b93c8841c84328f6e406":
        return "339";
        break;
    
        case "6384b93c8841c84328f6e407":
        return "388";
        break;
    
        case "6384b93c8841c84328f6e408":
        return "389";
        break;
    
        case "6384b93c8841c84328f6e409":
        return "390";
        break;
    
        case "6384b93c8841c84328f6e40a":
        return "392";
        break;
    
        case "6384b93c8841c84328f6e40b":
        return "395";
        break;
    
        case "6384b93c8841c84328f6e40c":
        return "397";
        break;
    
        case "6384b93c8841c84328f6e40d":
        return "400";
        break;
    
        case "6384b93c8841c84328f6e40e":
        return "409";
        break;
    
        case "6384b93c8841c84328f6e40f":
        return "411";
        break;
    
        case "6384b93c8841c84328f6e410":
        return "414";
        break;
    
        case "6384b93c8841c84328f6e411":
        return "415";
        break;
    
        case "6384b93c8841c84328f6e412":
        return "416";
        break;
    
        case "6384b93c8841c84328f6e413":
        return "417";
        break;
    
        case "6384b93c8841c84328f6e414":
        return "406";
        break;
    
        case "6384b93c8841c84328f6e415":
        return "410";
        break;
    
        case "6384b93c8841c84328f6e416":
        return "418";
        break;
    
        case "6384b93c8841c84328f6e417":
        return "419";
        break;
    
        case "6384b93c8841c84328f6e418":
        return "422";
        break;
    
        case "6384b93c8841c84328f6e419":
        return "421";
        break;
    
        case "6384b93c8841c84328f6e41a":
        return "427";
        break;
    
        case "6384b93c8841c84328f6e41b":
        return "428";
        break;
    
        case "6384b93c8841c84328f6e41c":
        return "429";
        break;
    
        case "6384b93c8841c84328f6e41d":
        return "435";
        break;
    
        case "6384b93c8841c84328f6e41e":
        return "423";
        break;
    
        case "6384b93c8841c84328f6e41f":
        return "430";
        break;
    
        case "6384b93c8841c84328f6e420":
        return "441";
        break;
    
        case "6384b93c8841c84328f6e421":
        return "442";
        break;
    
        case "6384b93c8841c84328f6e422":
        return "248";
        break;
    
        case "6384b93c8841c84328f6e423":
        return "249";
        break;
    
        case "6384b93c8841c84328f6e424":
        return "250";
        break;
    
        case "6384b93c8841c84328f6e425":
        return "252";
        break;
    
        case "6384b93c8841c84328f6e426":
        return "260";
        break;
    
        case "6384b93c8841c84328f6e427":
        return "436";
        break;
    
        case "6384b93c8841c84328f6e428":
        return "438";
        break;
    
        case "6384b93c8841c84328f6e429":
        return "444";
        break;
    
        case "6384b93c8841c84328f6e42a":
        return "361";
        break;
    
        case "6384b93c8841c84328f6e42b":
        return "366";
        break;
    
        case "6384b93c8841c84328f6e42c":
        return "367";
        break;
    
        case "6384b93c8841c84328f6e42d":
        return "372";
        break;
    
        case "6384b93c8841c84328f6e42e":
        return "373";
        break;
    
        case "6384b93c8841c84328f6e42f":
        return "374";
        break;
    
        case "6384b93c8841c84328f6e430":
        return "362";
        break;
    
        case "6384b93c8841c84328f6e431":
        return "363";
        break;
    
        case "6384b93c8841c84328f6e432":
        return "364";
        break;
    
        case "6384b93c8841c84328f6e433":
        return "370";
        break;
    
        case "6384b93c8841c84328f6e434":
        return "377";
        break;
    
        case "6384b93c8841c84328f6e435":
        return "379";
        break;
    
        case "6384b93c8841c84328f6e436":
        return "476";
        break;
    
        case "6384b93c8841c84328f6e437":
        return "478";
        break;
    
        case "6384b93c8841c84328f6e438":
        return "479";
        break;
    
        case "6384b93c8841c84328f6e439":
        return "483";
        break;
    
        case "6384b93c8841c84328f6e43a":
        return "486";
        break;
    
        case "6384b93c8841c84328f6e43b":
        return "488";
        break;
    
        case "6384b93c8841c84328f6e43c":
        return "491";
        break;
    
        case "6384b93c8841c84328f6e43d":
        return "481";
        break;
    
        case "6384b93c8841c84328f6e43e":
        return "490";
        break;
    
        case "6384b93c8841c84328f6e43f":
        return "494";
        break;
    
        case "6384b93c8841c84328f6e440":
        return "495";
        break;
    
        case "6384b93c8841c84328f6e441":
        return "500";
        break;
    
        case "6384b93c8841c84328f6e442":
        return "496";
        break;
    
        case "6384b93c8841c84328f6e443":
        return "501";
        break;
    
        case "6384b93c8841c84328f6e444":
        return "502";
        break;
    
        case "6384b93c8841c84328f6e445":
        return "503";
        break;
    
        case "6384b93c8841c84328f6e446":
        return "506";
        break;
    
        case "6384b93c8841c84328f6e447":
        return "508";
        break;
    
        case "6384b93c8841c84328f6e448":
        return "505";
        break;
    
        case "6384b93c8841c84328f6e449":
        return "509";
        break;
    
        case "6384b93c8841c84328f6e44a":
        return "510";
        break;
    
        case "6384b93c8841c84328f6e44b":
        return "513";
        break;
    
        case "6384b93c8841c84328f6e44c":
        return "514";
        break;
    
        case "6384b93c8841c84328f6e44d":
        return "515";
        break;
    
        case "6384b93c8841c84328f6e44e":
        return "512";
        break;
    
        case "6384b93c8841c84328f6e44f":
        return "516";
        break;
    
        case "6384b93c8841c84328f6e450":
        return "518";
        break;
    
        case "6384b93c8841c84328f6e451":
        return "519";
        break;
    
        case "6384b93c8841c84328f6e452":
        return "522";
        break;
    
        case "6384b93c8841c84328f6e453":
        return "273";
        break;
    
        case "6384b93c8841c84328f6e454":
        return "277";
        break;
    
        case "6384b93c8841c84328f6e455":
        return "278";
        break;
    
        case "6384b93c8841c84328f6e456":
        return "283";
        break;
    
        case "6384b93c8841c84328f6e457":
        return "276";
        break;
    
        case "6384b93c8841c84328f6e458":
        return "280";
        break;
    
        case "6384b93c8841c84328f6e459":
        return "282";
        break;
    
        case "6384b93c8841c84328f6e45a":
        return "328";
        break;
    
        case "6384b93c8841c84328f6e45b":
        return "333";
        break;
    
        case "6384b93c8841c84328f6e45c":
        return "286";
        break;
    
        case "6384b93c8841c84328f6e45d":
        return "288";
        break;
    
        case "6384b93c8841c84328f6e45e":
        return "340";
        break;
    
        case "6384b93c8841c84328f6e45f":
        return "345";
        break;
    
        case "6384b93c8841c84328f6e460":
        return "528";
        break;
    
        case "6384b93c8841c84328f6e461":
        return "530";
        break;
    
        case "6384b93c8841c84328f6e462":
        return "535";
        break;
    
        case "6384b93c8841c84328f6e463":
        return "538";
        break;
    
        case "6384b93c8841c84328f6e464":
        return "396";
        break;
    
        case "6384b93c8841c84328f6e465":
        return "401";
        break;
    
        case "6384b93c8841c84328f6e466":
        return "404";
        break;
    
        case "6384b93c8841c84328f6e467":
        return "408";
        break;
    
        case "6384b93c8841c84328f6e468":
        return "445";
        break;
    
        case "6384b93c8841c84328f6e469":
        return "446";
        break;
    
        case "6384b93c8841c84328f6e46a":
        return "447";
        break;
    
        case "6384b93c8841c84328f6e46b":
        return "448";
        break;
    
        case "6384b93c8841c84328f6e46c":
        return "449";
        break;
    
        case "6384b93c8841c84328f6e46d":
        return "453";
        break;
    
        case "6384b93c8841c84328f6e46e":
        return "454";
        break;
    
        case "6384b93c8841c84328f6e46f":
        return "450";
        break;
    
        case "6384b93c8841c84328f6e470":
        return "461";
        break;
    
        case "6384b93c8841c84328f6e471":
        return "464";
        break;
    
        case "6384b93c8841c84328f6e472":
        return "465";
        break;
    
        case "6384b93c8841c84328f6e473":
        return "466";
        break;
    
        case "6384b93c8841c84328f6e474":
        return "471";
        break;
    
        case "6384b93c8841c84328f6e475":
        return "487";
        break;
    
        case "6384b93c8841c84328f6e476":
        return "523";
        break;
    
        case "6384b93c8841c84328f6e477":
        return "527";
        break;
    
        case "6384b93c8841c84328f6e478":
        return "529";
        break;
    
        case "6384b93c8841c84328f6e479":
        return "531";
        break;
    
        case "6384b93c8841c84328f6e47a":
        return "536";
        break;
    
        case "6384b93c8841c84328f6e47b":
        return "537";
        break;
    
        case "6384b93c8841c84328f6e47c":
        return "0";
        break;
    
        case "6384b93c8841c84328f6e47d":
        return "540";
        break;
    
        case "6384b93c8841c84328f6e47e":
        return "541";
        break;
    
        case "6384b93c8841c84328f6e47f":
        return "542";
        break;
    
        case "6384b93c8841c84328f6e480":
        return "543";
        break;
    
        case "6384b93c8841c84328f6e481":
        return "544";
        break;
    
        case "6384b93c8841c84328f6e482":
        return "545";
        break;
    
        case "6384b93c8841c84328f6e483":
        return "546";
        break;
    
        case "6384b93c8841c84328f6e484":
        return "547";
        break;
    
        case "6384b93c8841c84328f6e485":
        return "548";
        break;
    
        case "6384b93c8841c84328f6e486":
        return "549";
        break;
    
        case "6384b93c8841c84328f6e487":
        return "550";
        break;
    
        case "6384b93c8841c84328f6e488":
        return "551";
        break;
    
        case "6384b93c8841c84328f6e489":
        return "552";
        break;
    
        case "6384b93c8841c84328f6e48a":
        return "553";
        break;
    
        case "6384b93c8841c84328f6e48b":
        return "554";
        break;
    
        case "6384b93c8841c84328f6e48c":
        return "555";
        break;
    
        case "6384b93c8841c84328f6e48d":
        return "556";
        break;
    
        case "6384b93c8841c84328f6e48e":
        return "557";
        break;
    
        case "6384b93c8841c84328f6e48f":
        return "558";
        break;
    
        case "6384b93c8841c84328f6e490":
        return "559";
        break;
    
        case "6384b93c8841c84328f6e491":
        return "560";
        break;
    
        case "6384b93c8841c84328f6e492":
        return "561";
        break;
    
        case "6384b93c8841c84328f6e493":
        return "562";
        break;
    
        case "6384b93c8841c84328f6e494":
        return "563";
        break;
    
        case "6384b93c8841c84328f6e495":
        return "564";
        break;
    
        case "6384b93c8841c84328f6e496":
        return "565";
        break;
    
        case "6384b93c8841c84328f6e497":
        return "566";
        break;
    
        case "6384b93c8841c84328f6e498":
        return "567";
        break;
    
        case "6384b93c8841c84328f6e499":
        return "568";
        break;
    
        case "6384b93c8841c84328f6e49a":
        return "569";
        break;
    
        case "6384b93c8841c84328f6e49b":
        return "570";
        break;
    
        case "6384b93c8841c84328f6e49c":
        return "571";
        break;
    
        case "6384b93c8841c84328f6e49d":
        return "572";
        break;
    
        case "6384b93c8841c84328f6e49e":
        return "573";
        break;
    
        case "6384b93c8841c84328f6e49f":
        return "574";
        break;
    
        case "6384b93c8841c84328f6e4a0":
        return "575";
        break;
    
        case "6384b93c8841c84328f6e4a1":
        return "576";
        break;
    
        case "6384b93c8841c84328f6e4a2":
        return "577";
        break;
    
        case "6384b93c8841c84328f6e4a3":
        return "581";
        break;
    
        case "6384b93c8841c84328f6e4a4":
        return "580";
        break;
    
        case "6384b93c8841c84328f6e4a5":
        return "582";
        break;
    
        case "6384b93c8841c84328f6e4a6":
        return "583";
        break;
    
        case "6384b93c8841c84328f6e4a7":
        return "584";
        break;
    
        case "6384b93c8841c84328f6e4a8":
        return "585";
        break;
    
        case "6384b93c8841c84328f6e4a9":
        return "586";
        break;
    
        case "6384b93c8841c84328f6e4aa":
        return "587";
        break;
    
        case "6384b93c8841c84328f6e4ab":
        return "588";
        break;
    
        case "6384b93c8841c84328f6e4ac":
        return "589";
        break;
    
        case "6384b93c8841c84328f6e4ad":
        return "590";
        break;
    
        case "6384b93c8841c84328f6e4ae":
        return "591";
        break;
    
        case "6384b93c8841c84328f6e4af":
        return "592";
        break;
    
        case "6384b93c8841c84328f6e4b0":
        return "593";
        break;
    
        case "6384b93c8841c84328f6e4b1":
        return "594";
        break;
    
        case "6384b93c8841c84328f6e4b2":
        return "595";
        break;
    
        case "6384b93c8841c84328f6e4b3":
        return "596";
        break;
    
        case "6384b93c8841c84328f6e4b4":
        return "597";
        break;
    
        case "6384b93c8841c84328f6e4b5":
        return "598";
        break;
    
        case "6384b93c8841c84328f6e4b6":
        return "599";
        break;
    
        case "6384b93c8841c84328f6e4b7":
        return "600";
        break;
    
        case "6384b93c8841c84328f6e4b8":
        return "601";
        break;
    
        case "6384b93c8841c84328f6e4b9":
        return "602";
        break;
    
        case "6384b93c8841c84328f6e4ba":
        return "603";
        break;
    
        case "6384b93c8841c84328f6e4bb":
        return "604";
        break;
    
        case "6384b93c8841c84328f6e4bc":
        return "605";
        break;
    
        case "6384b93c8841c84328f6e4bd":
        return "606";
        break;
    
        case "6384b93c8841c84328f6e4be":
        return "607";
        break;
    
        case "6384b93c8841c84328f6e4bf":
        return "608";
        break;
    
        case "6384b93c8841c84328f6e4c0":
        return "609";
        break;
    
        case "6384b93c8841c84328f6e4c1":
        return "610";
        break;
    
        case "6384b93c8841c84328f6e4c2":
        return "611";
        break;
    
        case "6384b93c8841c84328f6e4c3":
        return "612";
        break;
    
        case "6384b93c8841c84328f6e4c4":
        return "613";
        break;
    
        case "6384b93c8841c84328f6e4c5":
        return "614";
        break;
    
        case "6384b93c8841c84328f6e4c6":
        return "615";
        break;
    
        case "6384b93c8841c84328f6e4c7":
        return "616";
        break;
    
        case "6384b93c8841c84328f6e4c8":
        return "617";
        break;
    
        case "6384b93c8841c84328f6e4c9":
        return "618";
        break;
    
        case "6384b93c8841c84328f6e4ca":
        return "619";
        break;
    
        case "6384b93c8841c84328f6e4cb":
        return "620";
        break;
    
        case "6384b93c8841c84328f6e4cc":
        return "621";
        break;
    
        case "6384b93c8841c84328f6e4cd":
        return "622";
        break;
    
        case "63d93a41eee46e3d28c8f5b0":
        return "623";
        break;
    
        case "63d93a57eee46e3d28c8f5b9":
        return "624";
        break;
    
        case "64dcd3fbef35b2ca07830a7f":
        return "625";
        break;
    
        case "63f76953283bc7bc9ed2382c":
        return "627";
        break;
    
        case "64c414f2c92adb1c03115b05":
        return "628";
        break;
    
        case "64c4172ec92adb1c03115b09":
        return "629";
        break;
    
        case "64c41786c92adb1c03115b0d":
        return "630";
        break;
    
        case "64c419c5c92adb1c03115b15":
        return "632";
        break;
    
        case "64c41a46c92adb1c03115b19":
        return "633";
        break;
    
        case "64c41a7dc92adb1c03115b1d":
        return "634";
        break;
    
        case "64c41adbc92adb1c03115b21":
        return "635";
        break;
    
        case "64dcd1c4ef35b2ca07830a5c":
        return "658";
        break;
    
        case "64dcd23cef35b2ca07830a61":
        return "630";
        break;
    
        case "64dcd28bef35b2ca07830a66":
        return "629";
        break;
    
        case "64dcd2d9ef35b2ca07830a6b":
        return "628";
        break;
    
        case "64dcd359ef35b2ca07830a75":
        return "627";
        break;
    
        case "64dcd3aeef35b2ca07830a7a":
        return "626";
        break;
    
        case "64dcd3fbef35b2ca07830a7f":
        return "625";
        break;
    
        case "64dcd75eef35b2ca07830aa9":
        return "624";
        break;
    
        case "64dcd794ef35b2ca07830aac":
        return "623";
        break;
    
        case "64fb218a9d6aaee8af1189cd":
        return "673";
        break; 
        default:
        case "6384b93c8841c84328f6e47c":
       
    }
    }

    console.log(selected) 

const savepo = async(e) => {
    e.preventDefault()
 const ship = vendorList.filter(filt => filt._id === selectedshipto.id)
 const vend = vendorList.filter(filt => filt._id === selectedvendor.id)

  const y = {
    createdBy:userInfo?._id,
        vendor:selectedvendor.id,
        shipto:selectedshipto.id,
        items,
        duedate:duedate,
     status:0,
     invoice:invoice
    }

 console.log(y)  
 await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/pos/addpo',y)
  //  await axios.post(' https://cfpapi-6ab062006fa0.herokuapp.com/pos/addpo',y)
   .then(res => {
    console.log(res.data);
    nav(`/pos/cfppo/${res.data._id}`)
  })
   .catch(err => console.log(err))



// console.log(y)
//  axios.post('https://highgroundapi.herokuapp.com/po/add',y)
//    //axios.post('http://localhost:5003/po/add',y)
//     .then(res => {console.log(res.data);toast.success('Po Added.');slip(res.data);nav(`/pos/ponew/${res.data._id}`, { replace: true });
// })
//     .catch(err => console.log(err))

}

console.log(items)



const removewholeitem = (selected) => {

  let h = items?.filter(item => item.itemaddid !== selected.itemaddid)
   console.log(h)
  setItems(h)
  setSelected(preset);
  setSelectedItem({id:null,name:""});
  setType({id:null,name:""});
  setSameitems([]);
  setOrderby({id:null,name:""})
  }


console.log(selectedItem)



  return (


    <MainWrap >
         
        <Wrap>
        {/* <Modal show={forjobsshow} header={`Add jobs for this item`} onCancel={() => {setForjobsshow(false)}} ><ForJobs onCancel={() => {setForjobsshow(false)}}  reset={reset} setReset={setReset} forjoblist={forjoblist} setForjoblist={setForjoblist} setSelected={setSelected} selected={selected}/></Modal> */}
<Flexed>
    <Flex padding="25px" textAlign="center" fontSize="calc(1.3rem + .4vw)" fontWeight="bold" color="#0E425A">New Purchase Order For CFP </Flex>
</Flexed>
<form onSubmit={savepo}>
<Flexed>
    <Flex>Vendor:<br /><Select list={vendorList?.map(item => {return {"id":item._id,name:item?.name};})} listid="vendor" setValue={setSelectedvendor} value={selectedvendor?.name} required/></Flex>
    <Flex>Shipto:<br /><Select list={vendorList?.map(item => {return {"id":item._id,name:item?.name};})} listid="shipto" setValue={setSelectedshipto} value={selectedshipto.name} required/></Flex>
    <Flex flex=".5">PO Date:<br /><Datepick selected={new Date()} disabled/> </Flex>
    <Flex flex=".5">Due Date:<br /><Datepick selected={duedate}  onChange={(date) => setDuedate(date)} /> </Flex>
</Flexed>
<Flexed style={{marginTop:"10px"}} padding="10px 10px 10px 0px" alignItems="flex-end"><Flex>Vendor Invoice: <Input  value={invoice} onChange={e => setInvoice(e.target.value)}/></Flex><Flex flex="2"></Flex><Flex textAlign="right" >
  <Button  >Save P.O.</Button></Flex></Flexed>
</form>
<InventoryBox items={items} setupdateitem={setupdateitem}/>



<hr />


<form onSubmit={addtolist}>

   <Flexed>
   <Flex flex="4">Item<br /><Select list={itemslist?.map(rt => {return {"id":rt?._id,'name':rt?.name,'stockid':rt.itemid};})} listid="itemslist" setValue={setSelectedItem}  value={selectedItem?.name}/></Flex>
   <Flex flex=".5"><Flex flex=".5">Product ID<br /><Input value={selected?.itemid !== null ? selected?.itemid : ""} type="text" disabled/></Flex>
    {/*Job #<br /><Input value={selected?.jobnum === undefined ? "" : selected?.jobnum} type="text" onChange={e => setSelected({...selected,jobnum:e.target.value})} />*/}</Flex>
   </Flexed>
 

  
   <Flexed  margin="15px 0px 0px 0px">
  
   <Flex>Quantity<br /><Input value={selected?.quantity !== null ? selected?.quantity : ""} type="text" onChange={e => setSelected({...selected,quantity:e.target.value})} required/></Flex>
   <Flex >Order By<br />   <select value={orderby.name}  onChange={e => {setOrderby({id:e.target.value,name:e.target.options[e.target.selectedIndex].text})}}>
    <option ></option>
   {PLSOrderbylist.map(rt => {return <option key={rt.name} value={rt.name}>{rt.name}</option>})} 

</select></Flex>
   <Flex >Width<br /><Input value={selected?.width !== null ? selected?.width : ""} type="text" onChange={e => {setSelected({...selected,width:e.target.value})}} /></Flex>
       <Flex >Length<br /><Input value={selected?.length !== null ? selected?.length : ""} type="text" onChange={e => setSelected({...selected,length:e.target.value})}  /></Flex>
       <Flex>Unit Price<br /><Input type="text" value={selected?.unitprice !== null ? selected?.unitprice : ""} onChange={e => setSelected({...selected,unitprice:e.target.value})} />{
   }</Flex>
       <Flex > <br />    <select border={orderby.name <= "" || orderby.name ===  undefined ? "1.5px solid red" : ""} value={priceby.name}  onChange={e => {console.log();setPriceby({id:e.target.value,name:e.target.options[e.target.selectedIndex].text})}}>
    <option ></option>
    {PLStypelist.map(rt => {return <option value={rt.name}>{rt.name}</option>})} 
    </select></Flex>
    
   </Flexed>
   <Flexed  margin="15px 0px 0px 0px">
   <Flex  flex="2">Description:<br /><Textarea rows="5" value={selected?.description !== null ? selected?.description : ""} onChange={e => setSelected({...selected,description:e.target.value})}></Textarea></Flex>
   <Flex flex=".5" style={{width:"100%"}} textAlign="right">
    <Flexed flexDirection="column">
      <Flex margin="10px 0px 0px 0px">Ext Price<br />${isNaN(selected?.extprice) ? 0 : `${currency(selected?.extprice)}`}</Flex>
      <Flex padding="10px 0px 0px 0px">  {selectedItem.id <= "" ? <Button>Add To Database</Button>: selectedItem.name > "" && <Button>Update Database</Button>}</Flex>
    </Flexed>
    
    
   </Flex> 
   </Flexed>

  
<Flexed>
   
<Flex padding="20px" textAlign="left">
{selected.itemaddid > "" && <Button bgcolor="rgb(255,0,0,.6)" color="white" onClick={e => removewholeitem(selected)} >Remove Item</Button>}
</Flex>
  
  <Flex textAlign="right"><br />{selected.itemaddid ? <Button bgcolor="rgb(255,0,0,.4)" color="red" style={{marginRight:"50%",position:"relative",top:"0"}} type="button">Remove Item</Button>: ""}<Button style={{marginRight:"30px",position:"relative",top:"0"}} type="button" onClick={e => {setSelected(preset);setSelectedItem({id:null,name:""});setType({id:null,name:""});setSameitems([]);setOrderby({id:null,name:""})}}>Clear Item</Button><Button type="submit">{selected.itemaddid > "" ? "Update PO Item" : "Add To PO"}</Button></Flex>
</Flexed>
    

   </form>











<br />
<br />

<br />
        </Wrap>
    </MainWrap>

  )
}

export default CFPNewpo