import {useState} from 'react'
import Select from 'react-select';



/*position:relative;
border-radius:5px;
width: ${props => props.height || "100%"};
border:1px solid rgb(0,0,0,.3);
outline: none;
font-size:calc(.5rem + .5vw);
padding:6px 5px;

&:focus{
    border:2px solid var(--plsGreen);
  }
*/

const Dropdown = ({list,setValue,required,value}) => {


    const customStyles = {
      option: (defaultStyles, state) => ({
        ...defaultStyles,
        padding:"3px",
        backgroundColor:"white",
        color:"var(--plsBlue)",
        borderBottom:"1px solid lightgrey",
     
      }),
      control:  (base, state) => ({
        ...base,
        height: "30px",
        minHeight: "30px",
        outline:value?.value === '' ?  "2px solid red" : "1px solid grey",
        border: state.isFocused ? "1px solid green" : "0",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        marginLeft: 0,
        position: "absolute",
        left: 0,
        marginTop:"-5px",
        fontSize:"calc(.7rem + .4vw)",
        
    }),
    placeholder: (provided, state) => ({
        ...provided,
        marginLeft: 0,
        position: "absolute",
        left: 0,
        marginTop:"-5px",
        fontSize:"calc(.5rem + .4vw)"
    }),
    input: (provided, state) => ({
        ...provided,
        marginLeft: 0,
        position: "relative",
        left: -8,
        marginTop:"-5px",
        fontSize:"calc(.5rem + .4vw)",
        wordBreak:"normal",
      
    }),

    };

    const handleChange = (selectedOption) => {
      setValue(selectedOption);
    };

  return (
     <Select
    
    value={value}
        onChange={handleChange}
        options={list}
       styles={customStyles}
      required={required}/>
  )
}

export default Dropdown