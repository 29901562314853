import React from 'react'
import { Selectbox } from './Shared'

const BillingTerms = ({onChange,value}) => {
   
      return (
        <Selectbox onChange={onChange} value={value}>
              <option value="Net 30">Net 30</option>
              <option value="1/10 Net 30">1/10 Net 30</option>
              <option value="2/10 Net 30">2/10 Net 30</option>
              <option value="1/2% 30 Days">1/2% 30 Days</option>
              <option value="Net 15">Net 15</option>
              <option value="Net 45">Net 45</option>
              <option value="C.O.D">C.O.D</option>
    </Selectbox>
      )
}

export default BillingTerms