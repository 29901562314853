import React, { useEffect, useState } from 'react'




export default function useDebounce(value){

    const delay = 250
const [debouncevalue, setDebouncevalue] = useState(value)
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncevalue(value)
        },delay)
    
        return () => {
            clearTimeout(handler)
        }
    },[value])
        return debouncevalue;
}






