import axios from 'axios'
import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MainWrap } from '../../utilities/Shared'

const Ticketcheck = () => {

    const nav = useNavigate()
const param = useParams()

useEffect( () => {

    console.log(param.job)
    const getcurrent = async() => {
       
        let r = {'jobnum':param.job}
        await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
       .then(res => {
        if(res.data.length > 0){
         
          nav(`/jobticket/good/${param.job}`)
        }else{
         
          nav(`/jobticket/save/${param.job}`)
        }
      })
       .catch(err => console.log(err))
       }
       getcurrent()   
},[])
   


  return (
   <MainWrap>

<span style={{fontSize:"calc(2.4rem + .2vw)",color:"green"}}>Configuring Job. Please wait one moment</span>


   </MainWrap>
  )
}

export default Ticketcheck