import axios from "axios"
import { useEffect, useState } from "react"








export const changeidtopress = (id) => {

    switch(id){
case '1' :
    case '2': 
    return "Anicolor"
   
case '7' :
     return "Ricoh B & W"
     
    }
}


export const colorchange = (item) => {

let a
switch(item){
    case "On Prepress" :
    case "On Plating" :
    case "On Digital" :
    case "On Colormax" :
    case "On Cutting" :
    case "On Folding" :
    case "On Die Cutting" :
    case "On Gluer" :
    case "On Handwork" :
    case "On Hand Stitching" :
    case "On Stitching" :
    case "On Drilling" :
    case "On Tabbing" :
    case "On Inkjetting" :
    case "On Inserting" :
    case "On Mail Handwork" :
    case "On Coil Binding" :
    case "On Shrink Wrap" :
    case "On Banding" :
    case "On Tabbing / Inkjetting" :
    
                a = 'var(--on)'
        break;    
    case "Off Press" :
    case "Off Prepress" :
    case "Off Proofing" :
    case "Off Plating" :
    case "Off Digital" :
    case "Off Colormax" :
    case "Off Cutting" :
    case "Off Folding" :
    case "Off Die Cutting" :
    case "Off Gluer" :
    case "Off Handwork" :
    case "Off Hand Stitching" :
    case "Off Stitching" :
    case "Off Drilling" :
    case "Off Tabbing" :
    case "Off Inkjetting" :
    case "Off Inserting" :
    case "Off Mail Handwork" :
    case "Off Coil Binding" :
    case "Off Shrink Wrap" :
    case "Off Banding" :
    case "Off Tabbing / Inkjetting" :
        a = 'var(--off)'
        break;
    case "Incomplete Press" :
    case "Incomplete Prepress" :
    case "Incomplete Proofing" :
    case "Incomplete Plating" :
    case "Incomplete Digital" :
    case "Incomplete Colormax" :
    case "Incomplete Cutting" :
    case "Incomplete Folding" :
    case "Incomplete Die Cutting" :
    case "Incomplete Gluer" :
    case "Incomplete Handwork" :
    case "Incomplete Hand Stitching" :
    case "Incomplete Stitching" :
    case "Incomplete Drilling" :
    case "Incomplete Tabbing" :
    case "Incomplete Inserting" :
    case "Incomplete Inkjetting" :
    case "Incomplete Mail Handwork" :
    case "Incomplete Coil Binding" :
    case "Incomplete Shrink Wrap" :
    case "Incomplete Banding" :
     case "Incomplete Tabbing / Inkjetting" :
            a = 'var(--incomplete)'
        break;
    case "AA Changes Press" :
    case "AA Changes Prepress" :
    case "AA Changes Proofing" :
    case "AA Changes Plating" :
    case "AA Changes Digital" :
    case "AA Changes Colormax" :
            a = 'var(--aa)'
         break;
        case "PE Changes Press" :
        case "PE Changes Prepress" :
        case "PE Changes Proofing" :
        case "PE Changes Plating" :
        case "PE Changes Digital" :
        case "PE Changes Colormax" :
                a = 'var(--pe)'
         break;
    case "Ready Plating" :
    case "Plated" :
    case "Ready Digital":
    case "Ready Colormax":
    case "Ready Bindery":
         a = 'var(--ready)'
         break;
     case "On Proofing" :     
        a = '#FFB6C1'  
        break;
        case "On Press" :     
        a = '#FFBF00'  
        break; 
        case "Ready Press":
            a="#79BB43"
            break;   
            case "Prepress On Hold":
            case "Proofing On Hold":
            case "Plating On Hold":
                a = 'rgb(255,0,0,.7)'
                break;
    }
   


    return a
}

export const getemployeebynamefromempid = (id,list) => {

let h = list?.filter(filt => parseInt(filt.empId) === parseInt(id))
  
    return h[0]?.firstName+' '+h[0]?.lastName.substr(0,1) || "Unkown"
}


export const convertprintstyle = (style) => {
    let a = 0
    let b = 0
    switch(style){
        case "Work & Turn":
            a = 2
            b = .25
        break;
        case "Sheetwise":
            a = 2
            b = .50
        break;
        case "Work & Tumble":
            a = 1
            b = .25
        break;
        case "One-Sided":
            a = 1
            b = .25
        break;
        case "Perfect":
            a = 1
            b = .25
        break;
    }
    return {pass:a,mrtime:b}
}


export const convertSheetSize = (size) => {
let newsize = Math.floor(size)
return size > newsize  ?  size : newsize
}


