import React, { useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Scroll, Selectbox, Textauto, Wrapper } from '../../../utilities/Shared'
import axios from 'axios';
import Collapse from '../../../utilities/Collapse';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { RiDeleteBack2Fill } from "react-icons/ri";
import { colorchange } from '../../../utilities/Convertions';

const moment = require("moment");
const PrepressTicket = ({jobinfo,forms,reset,setReset,job}) => {





  const[operations, setOperations] = useState([])
  const[newforms, setnewForms] = useState([])
  const[imposition, setImposition] = useState([])
  const[proofstotal, setProofstotal] = useState({hours:0,minutes:0})
 




  const[operationitems, setOperationitems] = useState([])
  const[prepress, setPrespress] = useState([])
  const[onprepresstimes, setOnprepresstimes] = useState({})
  const[offprepresstimes, setOffprepresstimes] = useState({})

  const[onplatingtimes, setOnplatingtimes] = useState({})
  const[offplatingtimes, setOffplatingtimes] = useState({})
  const[proofin, setProofin] = useState(false)
  const[proofout, setProofout] = useState(false)
  
  const [value, onChange] = useState(new Date());
  const [onTimes, setOnTimes] = useState(null);
  const [offTimes, setOffTimes] = useState(null);

  
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };

  useEffect(() => {

    let r = {
        job:job,
    }
    const getops = async() => {
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
    .then(res => {setOperations(res.data);})
    .catch(err => console.log(err))
    }
    const getquote = async() => {
      let r = {
        quote:jobinfo?.quote,
      }
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getimposition.php`,r,config)
     .then(res => {setImposition(res.data);})
     .catch(err => console.log(err))
     }
    getops()
    getquote()
  },[reset])

  useEffect(() => {
    setPrepressValues()
   
  },[operations])



const setPrepressValues = () => {

  let imp
let proofing = []
  forms.map((item,index) => {
   
    let pressins = []
    let pressouts = []
    let pressindis = []
    let proofins = []
    let proofouts = []
    let proofindis = []
    let plateins = []
    let plateouts = []
    let plateindis = []
    let partofid  
    let partof

     imp = imposition?.filter(filter => filter.component === item.originalform)

   operations?.filter(filter => filter.comp === item.name).map((op,index) => {
    let diff = 0
    if(op.name === "On Prepress"){pressins.push({id:op.id,time:op.changedon,status:"On Prepress"})}
    if(op.name === "Off Prepress"){pressouts.push({id:op.id,time:op.changedon,status:"Off Prepress"})}  
    if(op.name === "Incomplete Prepress"){pressouts.push({id:op.id,time:op.changedon,status:"Incomplete Prepress"})}
    if(op.name === "AA Changes Prepress"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Prepress"})} 
    if(op.name === "PE Changes Prepress"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Prepress"})}
    if(op.name === "Prepress On Hold"){pressouts.push({id:op.id,time:op.changedon,status:"Prepress On Hold"})}

    if(op.name === "On Proofing"){proofins.push({id:op.id,time:op.changedon,status:"On Proofing"})}
    if(op.name === "Off Proofing"){proofouts.push({id:op.id,time:op.changedon,status:"Off Proofing"})}  
    if(op.name === "Incomplete Proofing"){proofouts.push({id:op.id,time:op.changedon,status:"Incomplete Proofing"})}
    if(op.name === "AA Changes Proofing"){proofouts.push({id:op.id,time:op.changedon,status:"AA Changes Proofing"})} 
    if(op.name === "PE Changes Proofing"){proofouts.push({id:op.id,time:op.changedon,status:"PE Changes Proofing"})}
    if(op.name === "Proofing On Hold"){proofouts.push({id:op.id,time:op.changedon,status:"Proofing On Hold"})}

    if(op.name === "On Plating"){plateins.push({id:op.id,time:op.changedon,status:"On Plating"})}
    if(op.name === "Off Plating"){plateouts.push({id:op.id,time:op.changedon,status:"Off Plating"})}  
    if(op.name === "Incomplete Plating"){plateouts.push({id:op.id,time:op.changedon,status:"Incomplete Plating"})}
    if(op.name === "AA Changes Plating"){plateouts.push({id:op.id,time:op.changedon,status:"AA Changes Plating"})} 
    if(op.name === "PE Changes Plating"){plateouts.push({id:op.id,time:op.changedon,status:"PE Changes Plating"})}
    if(op.name === "Plating On Hold"){plateouts.push({id:op.id,time:op.changedon,status:"Plating On Hold"})}
    
    })

   
   pressindis=(getdiff(pressins,pressouts))
   proofindis=(getdiff(proofins,proofouts))
   plateindis=(getdiff(plateins,plateouts))
   


    const y = {
      id:item.id,
      item:item.item,
        name:item.name,
        formid:item.formid,
        partofid:item.partofid, 
        partof:item.item,
        processtext:item.processtext,
        prepress:
          {'ins':pressins,
            'out':pressouts,
            'indiv':pressindis,
            'estimatedtime':setesttime(imp,'prepress'),
          
    },
    proofing:
      {'ins':proofins,
        'out':proofouts,
        'indiv':proofindis,
        'estimatedtime':setesttime(imp,'proofing'),
      
},
plating:
  {'ins':plateins,
    'out':plateouts,
    'indiv':plateindis,
    'estimatedtime':setesttime(imp,'plating'),
  
}
    }
    proofing.push(y)
})

setnewForms(proofing)
}






const getdiff = (inTimes,outTimes) => {

let tot =[]
let u


inTimes?.map((item,index) => {

      let time
        let intime = new Date(item?.time)
        let outtime = new Date(outTimes[index]?.time)
     time = Math.abs(outtime - intime)


   
  let y =timeConvert(time/1000/60)
  if(isNaN(y.hours)){y.hours = 0}
  if(isNaN(y.minutes)){y.minutes = 0}
   u = {hours:y.hours,minutes:y.minutes}
  tot.push(u)
})
return(tot)
}

const setesttime = (imp,item) => {
  let newitem

switch(item){
case "prepress":
  if(parseInt(imp[0]?.numforms) > 1){
      let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
   
      newitem = (r / parseInt(imp[0]?.numforms))
  } 
break;
case "proofing":
  if(parseInt(imp[0]?.numforms) > 1){
      let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
     
      newitem = (r / parseInt(imp[0]?.numforms))
  } 
break;
case "plating":
  if(parseInt(imp[0]?.numforms) > 1){
      let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
 
      newitem = (r / parseInt(imp[0]?.numforms))
  } 
break;
}

let y = converttime(newitem)
return y
}



function timeConvert(n) { 
   
  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);
  
 // if(isNaN(rhours)){rhours = 0}
  //if(isNaN(rminutes)){rminutes = 0}
  let t = {hours:proofstotal.hours + rhours,minutes:proofstotal.minutes + rminutes}

  return{hours:rhours,minutes:rminutes}
  } 

  const converttime = (item) => {

    let hours = 0
        let minutes = 0
        let myArray = []
    if(item !== undefined){
         myArray = item.toString()?.split(".");
       hours = parseInt(myArray[0])
       minutes = parseInt(myArray[1])

        let check = minutes/100
        if(check > 1){
         minutes = minutes % 100
       
        }
    }
   return({hours,minutes})
}


const getDiff = (intime,outtime,item) => {
   intime = new Date(intime)
   outtime = new Date(outtime)
     let  time = Math.abs(outtime - intime)

     let y = (timeConvert(time/1000/60))


     return `${y.hours}:${y.minutes}`
}







const setchangevalues = (item) => {
   
  let d
  let s
  let p
  let push
switch(item){
  case "On Prepress":
d = 0
s = 1
p = 2
push=0
break;
case "Off Prepress":
d = 0
s = 0
p = 2
push=0
break;
case "Incomplete Prepress":
d = 0
s = 0
p = 2
push=0
break;
case "On Proofing":
d = 0
s = 1
p = 2
push=0
break;
case "Off Proofing":
d = 0
s = 0
p = 2
push=0
break;
case "Incomplete Proofing":
d = 0
s = 0
p = 2
push=0
break;
case "On Plating":
  d = 1
  s = 1
  p = 3
  push=0
  break;
  case "Off Plating":
  d = 1
  s = 0
  p = 3
  push=0
  break;
  case "Incomplete Plating":
  d = 1
  s = 0
  p = 3
  push=0
  break;
  case "AA Changes Plating":
d = 1
s = 0
p = 3
push=0
break;
case "PE Changes Plating":
d = 1
s = 0
p = 2
push=0
break;
case "AA Changes Prepress":
d = 0
s = 0
p = 2
push=0
break;
case "PE Changes Prepress":
d = 0
s = 0
p = 2
push=0
break;
case "AA Changes Proofing":
d = 0
s = 0
p = 2
push=0
break;
case "PE Changes Proofing":
d = 0
s = 0
p = 2
push=0
break;
case "Prepress On Hold":
d = 0
s = 1
p = 0
push=0
break;
case "Plating On Hold":
d = 0
s = 1
p = 0
push=0
break;
case "Proofing On Hold":
d = 0
s = 1
p = 0
push=0
break;
}
return {d,s,p,push}
}


const setpushes = (e,form) => {

let set = {}
  if(e.target.value === "Off Proofing"){
switch(form.processtext){
case "Digital":
  set.name = "Ready Digital";
  set.dept = 2
  break;
  case "Colormax":
  set.name = "Ready Colormax";
  set.dept = 2
  break;
  default:
    set.name = "Ready Plating"
    set.dept = 1
    break;
}            
set.push = 1
set.process=3
  }
  if(e.target.value === "Off Plating"){
    set.dept = 3
    set.push = 0
    set.name = "Plated"
    set.process=0
      }
  const z = {
    job:job,
    item:form.item,
    partof:form.partof,
    partofid:form.partofid,
    process:set.process,
    comp:form.name,
    changedby:sessionStorage.getItem('eid'),
  name:set.name,
  dept:set.dept,
  status:0,
  push:set.push
  }

  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )

 
  }
  savit()
}


const changestatus = (e,form,index) => {

let values = setchangevalues(e.target.value)

const z = {
  job:job,
  item:form.item,
  partof:form.partof,
  partofid:form.partofid,
  process:values.p,
	comp:form.name,
	changedby:sessionStorage.getItem('eid'),
name:e.target.value,
dept:values.d,
status:values.s,
push:values.push
}


const savit = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
  .then(res => {setpushes(e,form)} )
  .catch(err => toast.error('Something went wrong and process was not saved') )
}
savit()
}







const changetime = (e,form) => {

  const z = {
    changedon:e.target.value,
id:form.id 
  }
  
  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateoperation.php`,z,config)
    .then(res => {setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )
  }
 savit()
}


  return (
    <Wrapper style={{height:"70vh",position:"relative",color:"black"}}>
    
  
    <Scroll height="100%">
{/*
/////////////////////////////////////////////////////////////////////////////////////////////////////////Pre-Press
*/}
    <div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" fontWeight="bold" style={{textDecoration:"underline"}} padding="5px" bgColor="var(--plsBlue)" color="white"> Pre-Press</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px">
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.prepress.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.prepress.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.prepress.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.prepress.out[index].status)}} type="datetime-local" value={form.prepress.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.prepress.indiv[index].hours}:{form.prepress.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.prepress.estimatedtime.hours}:{form.prepress.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Prepress">On Prepress</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Prepress">Off Prepress</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Prepress">Incomplete Prepress</option>
 <option value="AA Changes Prepress">AA Changes</option>
 <option value="PE Changes Prepress">PE Changes</option>
 <option value="Prepress On Hold">Prepress On Hold</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             <Flexed borderBottom="1px solid grey"><Flex>{form.digital ? 'Toner' : 'Inks'} Used: <Input /> </Flex><Flex>Stock Used: <Input /> </Flex><Flex flex="5">Notes:<br /><Textauto minHeight="25px"/></Flex></Flexed><br />
           </>
    }) 
  }
</div>

{/*
/////////////////////////////////////////////////////////////////////////////////////////////////////////Proofing
*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" fontWeight="bold" style={{textDecoration:"underline"}} padding="5px" bgColor="var(--plsBlue)" color="white"> Proofing</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px">
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.proofing.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.proofing.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.proofing.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.proofing.out[index].status)}} type="datetime-local" value={form.proofing.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.proofing.indiv[index].hours}:{form.proofing.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.proofing.estimatedtime.hours}:{form.proofing.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Proofing">On Proofing</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Proofing">Off Proofing</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Proofing">Incomplete Proofing</option>
 <option value="AA Changes Proofing">AA Changes Proofing</option>
 <option value="PE Changes Proofing">PE Changes Proofing</option>
 <option value="Proofing On Hold">Proofing On Hold</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             <Flexed borderBottom="1px solid grey"><Flex>{form.digital ? 'Toner' : 'Inks'} Used: <Input /> </Flex><Flex>Stock Used: <Input /> </Flex><Flex flex="5">Notes:<br /><Textauto minHeight="25px"/></Flex></Flexed><br />
           </>
    }) 
  }
</div>

{/*
/////////////////////////////////////////////////////////////////////////////////////////////////////////Proofing
*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" fontWeight="bold" style={{textDecoration:"underline"}} padding="5px" bgColor="var(--plsBlue)" color="white"> Plating</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px">
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.plating.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.plating.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.plating.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.plating.out[index].status)}} type="datetime-local" value={form.plating.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.plating.indiv[index].hours}:{form.plating.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.plating.estimatedtime.hours}:{form.plating.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Plating">On Plating</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Plating">Off Plating</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Plating">Incomplete Plating</option>
 <option value="AA Changes Plating">AA Changes Plating</option>
 <option value="PE Changes Plating">PE Changes Plating</option>
 <option value="Plating On Hold">Plating On Hold</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             <Flexed borderBottom="1px solid grey"><Flex>{form.digital ? 'Toner' : 'Inks'} Used: <Input /> </Flex><Flex>Stock Used: <Input /> </Flex><Flex flex="5">Notes:<br /><Textauto minHeight="25px"/></Flex></Flexed><br />
           </>
    }) 
  }
</div>
<br /> <br /> <br /> <br />
    </Scroll>
    

    
    </Wrapper>
  )
}

export default PrepressTicket




