import styled from 'styled-components'


export const TableContainer = styled.table`
table-layout:fixed;
width:100%;
border-collapse:collapse;
border:1px solid red;

thead{

   tr{
    background-color:rgb(14,66,90,1);
    color:white;

}
        
    
    
}
th,td{
    border:1px solid #dddddd;
    text-align:left;
    padding:3px 6px;
}
tbody{
 
        tr:nth-child(even){
background-color:rgb(0,0,0,.1);
        }

        tr:hover{
            background-color:var(--plsGreen);
            color:white;
            cursor:pointer;
                
            }
}

  
`;

