import React, { useEffect, useState } from 'react'
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import axios from 'axios'
import PrintComponent from '../../Printing/Locations'
import BlankComponent from '../../Printing/Blank'
import Collapse from '../../utilities/Collapse'
import { IoMdAddCircle } from 'react-icons/io'
import { sortit, sortnum} from '../../utilities/Sort'


const Locations = () => {


    const [digital, setDigital] = useState()
    const [colormax, setColormax] = useState()
    const [webrolls, setWebrolls] = useState()
    const [custom, setCustom] = useState()
    const [wholelist, setWholelist] = useState([])
    const [customlist, setCustomlist] = useState([])
    const [paperareas, setPaperareas] = useState()
    const [areas, setAreas] = useState([])
    const [floor, setFloor] = useState()
    const [fid, setFid] = useState([])
    const [binslist, setBinslist] = useState()
    const [order, setOrder] = useState("ASC")
useEffect(() => {

    const getlist = async() => {
await axios.get('https://highgroundapi.herokuapp.com/inv/items/byname')
.then(res => {
  setWholelist(res.data)
  console.log(res.data)
  setDigital(res.data.filter(item => item.location === "Digital"))  
  setColormax(res.data.filter(item => item.location === "Colormax"))
  setWebrolls(res.data.filter(item => item.location === "Web Rolls"))
  setPaperareas(res.data.filter(item => item.location === "Paper Areas"))
  setFloor(res.data.filter(item => item.location === "Floor"))
  setFid(res.data.filter(item => item.location === "Fidelity"))
setareaitems(res.data)
getlocations()})
.catch(err => console.log(err))
}
getlist()

},[])

const setareaitems = (items) => {
let h=[]
let p 
items.map(item => {
if(item.bin !== p){
h.push(item.bin)
p = item.bin
}
setAreas(h)
})

}

useEffect(() => {
  console.log(wholelist)
setCustomlist(wholelist.filter(item => item.bin === custom))
},[custom])





var getlocations = async() =>  {
        await axios.get('https://highgroundapi.herokuapp.com/inv/locations')
        .then(res => {setBinslist(res.data)})
        .catch(err => console.log(err))
  };


  useEffect(() => {
    paperareas?.forEach(function (element) {
      let t = 0
        element.forjobs.map(item => {
          t += parseInt(item?.quantity)
          })
          element.forjobs.length === 0 ? element.available = element.currentInventory : element.available = element.currentInventory - t        
});
  
  },[paperareas])

console.log(custom)



  return (
    <MainWrap><h1 >Inventory Locations</h1><br /> <br />
     <Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}><Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
     <Flex flex="0" textAlign="left"><PrintComponent  poinfo={digital} title="Digital Printout" /></Flex>
     <Flex  textAlign="left" >Digital</Flex></Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
<Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,digital,setDigital,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,digital,setDigital,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,digital,setDigital,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,digital,setDigital,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,digital,setDigital,setOrder)}>Current</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{digital?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}}>{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>



<Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}><Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
<Flex flex="0" textAlign="left"><PrintComponent  poinfo={colormax} title="Colormax Printout" /></Flex>
<Flex  textAlign="left" >ColorMax</Flex></Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,colormax,setColormax,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,colormax,setColormax,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,colormax,setColormax,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,colormax,setColormax,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,colormax,setColormax,setOrder)}>Current</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{colormax?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} >{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} >{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>

<Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}><Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
<Flex flex="0" textAlign="left"><PrintComponent  poinfo={webrolls} title="Web Rolls Printout" /></Flex>
<Flex textAlign="left" >Web Rolls</Flex></Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
<Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,webrolls,setWebrolls,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,webrolls,setWebrolls,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,webrolls,setWebrolls,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,webrolls,setWebrolls,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,webrolls,setWebrolls,setOrder)}>Current</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{webrolls?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}}>{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>

<Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}>
  <Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
  <Flex flex="0" textAlign="left"><PrintComponent  poinfo={paperareas} title="Paper Areas Printout" /></Flex>
  <Flex  textAlign="left" >Paper Areas</Flex>
  </Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
<Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,paperareas,setPaperareas,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,paperareas,setPaperareas,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,paperareas,setPaperareas,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,paperareas,setPaperareas,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,paperareas,setPaperareas,setOrder)}>On-Hand</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,paperareas,setPaperareas,setOrder)}>Available</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{paperareas?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}}>{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.available}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>


<Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}><Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
<Flex flex="0" textAlign="left"><PrintComponent  poinfo={floor} title="Floor Printout" /></Flex>
<Flex textAlign="left" >Floor</Flex></Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
<Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,floor,setFloor,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,floor,setFloor,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,floor,setFloor,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,floor,setFloor,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,floor,setFloor,setOrder)}>Current</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{floor?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}}>{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>


<Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}><Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex><Flex flex="0" textAlign="left"><PrintComponent  poinfo={fid} title="Fidelity Printout" /></Flex><Flex  textAlign="left" >Fidelity</Flex></Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
<Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,fid,setFid,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,fid,setFid,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,fid,setFid,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,fid,setFid,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,fid,setFid,setOrder)}>On-Hand</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,fid,setFid,setOrder)}>Available</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{fid?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}}>{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.available}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>


<Flexed style={{fontSize:"1.3rem",textAlign:"left",alignItems:"center"}}>
<Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)",opacity:"0"}} /></Flex>
<Flex flex="0"><BlankComponent  poinfo={floor} title="Floor Printout" /></Flex>
  <Flex >Blank</Flex></Flexed>



<Collapse margin="0" title={<Flexed justifyContent="center" style={{fontSize:"1.3rem",textAlign:"center",alignItems:"center"}}><Flex flex="0"><IoMdAddCircle  style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
<Flex flex="0" textAlign="left"><PrintComponent  poinfo={customlist} title="Colormax Printout" /></Flex>
<Flex flex=".1"  textAlign="left" >Custom:</Flex>
<Flex  textAlign="left" > 
  <select style={{top:"-5px",padding:"0",width:"80%"}}onChange={e => setCustom(e.target.value)}>
  <option value="null">Select Area</option>
    {
      areas.map(item => {
        return  <option value={item}>{item}</option>
      })
    }

  
  </select>
  </Flex>
  <Flex></Flex></Flexed>}>    
<Flexed style={{border:"1px solid black",borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
    <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('bin',order,customlist,setColormax,setOrder)}>Bin</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('invid',order,customlist,setColormax,setOrder)}>Inv ID </Flex>
    <Flex flex="4" style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('name',order,customlist,setColormax,setOrder)}>Item</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortit('size',order,customlist,setColormax,setOrder)}>Size</Flex>
    <Flex style={{borderRight:"1px solid black",padding:"3px"}} onClick={e => sortnum('currentInventory',order,customlist,setColormax,setOrder)}>Current</Flex>
   <Flex style={{padding:"3px"}}>Actual</Flex>

   </Flexed>
{customlist?.map(item => {
   return  <Flexed style={{border:"1px solid black",padding:"3px",color:"black"}} >
   <Flex flex="1.3" style={{borderRight:"1px solid black",padding:"3px"}} >{item.bin}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} >{item.invid}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}} flex="4">{item.name}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.size}</Flex>
   <Flex style={{borderRight:"1px solid black",padding:"3px"}}>{item.currentInventory}</Flex>
  <Flex style={{padding:"3px"}}></Flex>
  </Flexed>
})}
</Collapse>


    </MainWrap>
  )
}

export default Locations