import React, { useState } from 'react'
import { Label,Input, Flex, Flexed, Button, PhoneNumber, Textarea } from '../../../utilities/Shared'
import axios from 'axios'
import States from '../../../utilities/States'

const EditVendor = ({vendorinfo,reset,setReset,onCancel}) => {

    console.log(vendorinfo)
const [info, setInfo] = useState({
    id:vendorinfo.company?.company_id,
    name:vendorinfo.company?.name,
    address1:vendorinfo.address?.address1,
    address2:vendorinfo.address?.address2,
    city:vendorinfo.address?.city,
    state:vendorinfo.address?.state,
    zip:vendorinfo.address?.zip,
    fax:vendorinfo.numbers?.fax,
    phone:vendorinfo.numbers?.main,
    notes:vendorinfo.notes,
    website:vendorinfo.website,
    contact:vendorinfo.contact
})


console.log(info)
const submit = (e) => {
    e.preventDefault()
 
    const addVendor = async() => {

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };   
          const tr = {
            id:info.id,
             name:info.name,
             address1:info.address1,
             address2:info.address2,
             city:info.city,
             state:info.state,
             zip:info.zip,
             fax:info.fax,
             phone:info.phone,
             notes:info.notes,
             website:info.website,
             
      }
        console.log(tr)
       
    
 
       await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatevendor.php`,tr,config)
       .then(res => {
        console.log(res.data);
        const t = async() => {
            const r = {
                company:{
                    name:info.name,
                    dba:info.name,
                    company_id:info.id
                },
                address:{
                    address1:info.address1,
                    address2:info.address2,
                    city:info.city,
                    state:info.state,
                    zip:info.zip,
                },
                numbers:{
        main:info.phone,
        fax:info.fax
                },
                website:info.website,
                contact:info.contact,
                notes:info.notes,
                id:vendorinfo._id
            }
          await axios.post(`https://highgroundapi.herokuapp.com/vendor/update`,r)
          .then(res => {setReset(!reset);onCancel()})  //
           .catch(err => console.log(err))
         console.log(r)
        }
    t()
})
       .catch(err => console.log(err))
      
    }
   
   addVendor()

}



  return (
    <form onSubmit={submit}>
    <Flexed>
        <Flex flex="2"><Label>Vendor Name</Label><br /><Input type="text" value={info.name} onChange={e => setInfo({...info,name:e.target.value})} required/></Flex>
        <Flex><Label>Phone</Label><br /><Input type="text" value={PhoneNumber(info.phone)} onChange={e => setInfo({...info,phone:e.target.value})}/></Flex>
        <Flex><Label>Fax</Label><br /><Input type="text" value={PhoneNumber(info.fax)} onChange={e => setInfo({...info,fax:e.target.value})} /></Flex>
        
    </Flexed>
        <Flexed>
        <Flex flex="2"><Label>Address 1</Label><br /><Input type="text" value={info.address1} onChange={e => setInfo({...info,address1:e.target.value})} /></Flex>
        <Flex flex="2"><Label>Address 2</Label><br /><Input type="text" value={info.address2} onChange={e => setInfo({...info,address2:e.target.value})} /></Flex>
        <Flex flex="2" ><Label>City</Label><br /><Input type="text" value={info.city} onChange={e => setInfo({...info,city:e.target.value})}/></Flex>
        <Flex><Label>State</Label><br /><States type="text" value={info.state} onChange={e => setInfo({...info,state:e.target.value})}/></Flex>
        <Flex><Label>Zip</Label><br /><Input type="number" value={info.zip} onChange={e => setInfo({...info,zip:e.target.value})}/></Flex>
    </Flexed>
    <Flexed>
        <Flex><Label>Website</Label><br /><Input type="website" value={info.website} onChange={e => setInfo({...info,website:e.target.value})} /></Flex>
        <Flex><Label>Contact</Label><br /><Input type="text" value={info.contact} onChange={e => setInfo({...info,contact:e.target.value})} /></Flex>
      
     
    </Flexed>
    <Flexed alignItems="flex-end">
    <Flex flex="4"><Label>Notes</Label><br /><Textarea rows="5" type="text"value={info.notes} onChange={e => setInfo({...info,notes:e.target.value})}  /></Flex>
    <Flex textAlign="right" padding="20px 0px 0px 0px"><Button type="button" onClick={onCancel}>Cancel</Button></Flex>
        <Flex textAlign="right" padding="20px 0px 0px 0px"><Button>Update Vendor</Button></Flex></Flexed>
    </form>
  )
}

export default EditVendor