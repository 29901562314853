import React, { useContext, useEffect, useState } from 'react'
import { MainWrap,Flexed,Flex,Wrap, Input, currency } from '../../utilities/Shared'
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import axios from 'axios';
import { IoMdAddCircle } from 'react-icons/io'
import { LuEdit } from 'react-icons/lu'
import { MdDelete } from 'react-icons/md'
import AddProduct from './AddProduct';
import Modal from '../../modals/Modal';
import EditProduct from './EditProduct';
import DeleteConfirm from '../../utilities/DeleteConfirm';
import Editpaper from './Editpaper';
import Addpaper from './Addpaper';
import UserContext from '../../Context/UserContext';
import Editcfpproduct from './Editcfpproduct';



const Supplies = () => {

const [companyname, setCompanyname] = useState(localStorage.getItem('companyheader'))
const [binderylist, setBinderylist] = useState([])
const [custodiallist, setCustodiallist] = useState([])
const [shippinglist, setShippinglist] = useState([])
const [inklist, setInklist] = useState([])
const [envlist, setEnvlist] = useState([])
const [prepresslist, setPrepresslist] = useState([])
const [presslist, setPresslist] = useState([])
const [boxlist, setBoxlist] = useState([])
const [maintenancelist, setMaintenancelist] = useState([])
const [papers, setPapers] = useState([])
const [rolls, setRolls] = useState([])
const [search, setSearch] = useState("")
const [section, setSection] = useState("")
const [openAddProduct, setOpenAddProduct] = useState(false)
const [deletecom, setDeleteCom] = useState(false)
const [deletecomcfp, setDeleteComcfp] = useState(false)
const [deletepaper, seDeletepaper] = useState(false)
const [editProduct, setEditProduct] = useState(false)
const [editcfpproduct, setEditcfpproduct] = useState(false)
const [editpaper, setEditpaper] = useState(false)
const [editinfo, setEditinfo] = useState()
const [sortfeild, setSortfeild] = useState("name")
const [sortdirection, setSortdirection] = useState('desc')
const [productlist, setProductlist] = useState([])


const {userInfo,spin,setSpin } = useContext(UserContext)


const [reset, setReset] = useState()

useEffect(() => {
localStorage.setItem('companyheader',companyname)
},[companyname])


useEffect(() => {
setSpin(true)
 let y
const getlist = async() => { 
const r = axios.get('https://highgroundapi.herokuapp.com/supplies')
.then(res => {

  setBinderylist(res.data.filter(item => item.category === "Bindery"));
 setCustodiallist(res.data.filter(item => item.category === "Custodial"))
 setInklist(res.data.filter(item => item.category === "Ink"))
 setPrepresslist(res.data.filter(item => item.category === "Prepress"))
 setPresslist(res.data.filter(item => item.category === "Press"))
 setShippinglist(res.data.filter(item => item.category === "Shipping"))
 setBoxlist(res.data.filter(item => item.category === "Boxes"))
 setMaintenancelist(res.data.filter(item => item.category === "Maintenance"))
})
.catch(err => console.log(err))
setSpin(false)
}

const getitems = async() =>{
  let y
await axios.get(' https://cfpapi-6ab062006fa0.herokuapp.com/products/getallproducts')
.then(res => {console.log(res.data);setProductlist(res.data)})              
.catch(err => console.log())
}
getitems()


const getpaper = async() => { 
  await axios.get('https://highgroundapi.herokuapp.com/paper/all')
  .then(res => {
    setPapers(res.data.filter(item => item.kind === "Sheet Stock"))
    setEnvlist(res.data.filter(item => item.kind === "Envelope"))
    setRolls(res.data.filter(item => item.kind === "Roll Stock"))
  })
  .catch(err => console.log(err))
  }
  getpaper() 
getlist()


switch(localStorage.getItem('default2')){
  case '1' :
  setSection("Rolls");
  break;
case '2' :
  setSection("Envelopes");
  break;
  case '3' :
  setSection("Inks");
  break;
  case '4' :
  setSection("Bindery");
  break;
  case '5' :
  setSection("Prepress");
  break;
  case '6' :
  setSection("Press");
  break;
  case '7' :
  setSection("Shipping");
  break;
  case '8' :
  setSection("Boxes");
  break;
  case '9' :
  setSection("Custodial");
  break;
  case '10' :
    setSection("Maintenance");
    break;
  default:
    setSection("Stock");
    break;
}
},[reset,sortfeild]) 
 

const Deleteitem = async() => {

    let newinfo = {"id":editinfo._id}
  await axios.post('https://highgroundapi.herokuapp.com/supplies/remove',newinfo)
  .then(res => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    };
    const tr = {
      item:editinfo?.stockid,
    }
axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removeProduct.php`,tr,config);
  setReset(!reset);
  setDeleteCom(false)
})
  .catch(err => console.log(err))

}


const Deleteitemcfp = async() => {

  let newinfo = {"id":editinfo._id}
await axios.post('https://cfpapi-6ab062006fa0.herokuapp.com/products/remove',newinfo)
.then(res => {
  setReset(!reset);
  setDeleteComcfp(false)
})
.catch(err => console.log(err))

}


const Deletepaper = async() => {

  let newinfo = {"id":editinfo._id}
//await axios.post('https://highgroundapi.herokuapp.com/paper/delete/one',)
await axios.post('https://highgroundapi.herokuapp.com/paper/delete/one',newinfo)
.then(res => {
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };
  const tr = {
    item:editinfo?.stockid,
  }
//axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removepaper.php`,tr,config);
axios.post(`https://highgroundsoftware.com/dash/dashboard/php/removepaper.php`,tr,config);
setReset(!reset);
seDeletepaper(false)
})
.catch(err => console.log(err))

}

const sortit = () => {

}


  return (
    <MainWrap>
      <Modal show={openAddProduct} header={localStorage.getItem('default2') === '0' || localStorage.getItem('default2') === '1' || localStorage.getItem('default2') === '2' ? 'Add New Papers' : "Add New Product"} onCancel={() => {setOpenAddProduct(false)}} >{localStorage.getItem('default2') === '0' || localStorage.getItem('default2') === '1' || localStorage.getItem('default2') === '2' ? <Addpaper onCancel={() => {setOpenAddProduct(false)}} reset={reset} setReset={setReset} /> :<AddProduct onCancel={() => {setOpenAddProduct(false)}} reset={reset} setReset={setReset} type={editinfo?.category}/>}</Modal>
      <Modal show={editProduct} header={`Edit Product`} onCancel={() => {setEditProduct(false)}} ><EditProduct onCancel={() => {setEditProduct(false)}} editinfo={editinfo} reset={reset} setReset={setReset}/></Modal>
      <Modal show={editcfpproduct} header={`CFP Edit Product`} onCancel={() => {setEditcfpproduct(false)}} ><Editcfpproduct onCancel={() => {setEditcfpproduct(false)}} editinfo={editinfo} reset={reset} setReset={setReset}/></Modal>

      <Modal show={deletecom} header={`Confirm Delete Item id ${editinfo?.stockid}`} onCancel={() => {setDeleteCom(false)}} ><DeleteConfirm editinfo={editinfo} reset={reset} setReset={setReset} handleChange={Deleteitem} onCancel={() => {setDeleteCom(false)}} /></Modal>

      <Modal show={deletecomcfp} header={`Confirm Delete Item  ${editinfo?.name}`} onCancel={() => {setDeleteComcfp(false)}} ><DeleteConfirm editinfo={editinfo} reset={reset} setReset={setReset} handleChange={Deleteitemcfp} onCancel={() => {setDeleteComcfp(false)}} /></Modal>

      <Modal show={deletepaper} header={`Confirm Delete Stock id ${editinfo?.stockid}`} onCancel={() => {seDeletepaper(false)}} ><DeleteConfirm editinfo={editinfo} reset={reset} setReset={setReset} handleChange={Deletepaper} onCancel={() => {seDeletepaper(false)}} />e</Modal>
      <Modal show={editpaper} header={`Edit Stock`} onCancel={() => {setEditpaper(false)}} ><Editpaper editinfo={editinfo} reset={reset} setReset={setReset}  onCancel={() => {setEditpaper(false)}} /></Modal>
      <Flexed padding="20px 0px" fontWeight="500" fontSize="calc(1.3rem + .4vw)"><Flex textAlign="center">{companyname} Control Panel</Flex></Flexed>
       <Tabs defaultIndex={localStorage.getItem("default1")}>
    <TabList>
      <Tab onClick={e =>  {setCompanyname('PLS Print Stock / Supplies');localStorage.setItem("default1",0)}}>PLS Print</Tab>
      <Tab onClick={e =>  {setCompanyname('CFP Supplies');localStorage.setItem("default1",1)}}>CFP</Tab>
      <Tab onClick={e =>  {setCompanyname('MPS Supplies');localStorage.setItem("default1",2)}}>MPS</Tab>
    </TabList>

    <TabPanel>
      <br />
      <Flexed><Flex><Input type="text" value={search} placeholder={`Search for item in ${section} section`} onChange={e => setSearch(e.target.value)} /></Flex>
      <Flex flex=".3" textAlign="right" ><IoMdAddCircle onClick={e => setOpenAddProduct(true)} style={{fontSize:"calc(1.7rem + .4vw)",color:"var(--plsGreen)"}} /></Flex>
      </Flexed>
    <Tabs defaultIndex={localStorage.getItem("default2")}>
      <br />
    <TabList>

      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Stock');localStorage.setItem("default2",0);localStorage.setItem("companyheder",'')}}>Sheet Stock<br />{papers.length > 0 ? `${papers.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Stock');localStorage.setItem("default2",1);localStorage.setItem("companyheder",'')}}>Roll Stock<br />{rolls.length > 0 ? `${rolls.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Envelopes');localStorage.setItem("default2",2)}}>Envelopes<br />{envlist.length > 0 ? `${envlist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Inks');localStorage.setItem("default2",3)}}>Inks<br />{inklist.length > 0 ? `${inklist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Bindery');localStorage.setItem("default2",4)}}>Bindery<br />{binderylist.length > 0 ? `${binderylist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Prepress');localStorage.setItem("default2",5)}}>Prepress<br />{prepresslist.length > 0 ? `${prepresslist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Press');localStorage.setItem("default2",6)}}>Press<br />{presslist.length > 0 ? `${presslist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Shipping');localStorage.setItem("default2",7)}}>Shipping<br />{shippinglist.length > 0 ? `${shippinglist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Boxes');localStorage.setItem("default2",8)}}>Boxes<br />{boxlist.length > 0 ? `${boxlist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Custodial');localStorage.setItem("default2",9)}}>Custodial<br />{custodiallist.length > 0 ? `${custodiallist.length}` : 0}</Tab>
      <Tab style={{textAlign:"center"}} onClick={e => {setSection('Maintenance');localStorage.setItem("default2",10)}}>Maintenance<br />{maintenancelist.length > 0 ? `${maintenancelist.length}` : 0}</Tab>
    </TabList>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".8" >Stock Id</Flex>
      <Flex flex="3" >Description</Flex>
      <Flex flex="1.5">Vendor</Flex>
      <Flex>Category</Flex>
      <Flex>Kind</Flex>
      <Flex flex=".5">Cwt</Flex>
     
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
    
      {papers?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search.toLowerCase()) || item.stockid?.toString().includes(search)}).map((customer,index) =>{
            return <>
      
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey" >
            <Flex flex=".8">{customer.stockid}</Flex>
            <Flex flex="3" >
            {customer.name}
              </Flex>
             <Flex flex="1.5">{customer.vendor?.company?.name}</Flex>
             <Flex>{customer.kind}</Flex>
             <Flex>{customer.category}</Flex>
        
             <Flex flex=".5">{isNaN(customer.cwt) ? "" : currency(customer.cwt) }</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditpaper(true)}} /></Flex>
             <Flex flex=".5"> <MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)",zIndex:"10"}} onClick={e => {setEditinfo(customer);seDeletepaper(true)}} /></Flex>
            </Flexed>
           
      
            </>
         })}
                
        
  </Wrap>
    </TabPanel>

    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".8">Stock Id</Flex>
      <Flex flex="3" onClick={e => sortit()} >Description</Flex>
      <Flex flex="1.5">Vendor</Flex>
      <Flex>Category</Flex>
      <Flex>Kind</Flex>
      <Flex flex=".5">Cwt</Flex>
     
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
    
      {rolls?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search.toLowerCase()) || item.stockid?.toString().includes(search)}).map((customer,index) =>{
            return <>
      
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey" >
            <Flex flex=".8">{customer.stockid}</Flex>
            <Flex flex="3" >
            {customer.name}
              </Flex>
             <Flex flex="1.5">{customer.vendor?.company?.name}</Flex>
             <Flex>{customer.category}</Flex>
             <Flex>{customer.kind}</Flex>
             <Flex flex=".5">{isNaN(customer.cwt) ? "" : currency(customer.cwt) }</Flex>
    
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditpaper(true)}} /></Flex>
             <Flex flex=".5"> <MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)",zIndex:"10"}} onClick={e => {setEditinfo(customer);seDeletepaper(true)}} /></Flex>
            </Flexed>
           
      
            </>
         }) }
                
        
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".8">Stock Id</Flex>
      <Flex flex="3">Description</Flex>
      <Flex flex="1.5">Vendor</Flex>
      <Flex>Category</Flex>
      <Flex>Kind</Flex>
      <Flex flex=".5">Cwt</Flex>
     
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
    
      {envlist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) =>{
            return <>
      
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey" >
            <Flex flex=".8">{customer.stockid}</Flex>
            <Flex flex="3" >
            {customer.name}
              </Flex>
             <Flex flex="1.5">{customer.vendor?.company?.name}</Flex>
             <Flex>{customer.category}</Flex>
             <Flex>{customer.kind}</Flex>
             <Flex flex=".5">{isNaN(customer.cwt) ? null : currency(customer.cwt) }</Flex>
     
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditpaper(true)}} /></Flex>
             <Flex flex=".5"> <MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)",zIndex:"10"}} onClick={e => {setEditinfo(customer);seDeletepaper(true)}} /></Flex>
            </Flexed>
           
      
            </>
         })
      
         }
                
        
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
      {inklist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
             <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
             <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
      {binderylist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
      {prepresslist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
      {presslist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
      </Flexed >
      {shippinglist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
      </Flexed >
      {boxlist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
      </Flexed >
      {custodiallist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
      </Flexed >
      {maintenancelist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((customer,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{customer.stockid}</Flex>
            <Flex flex="2" >
            {customer.name}
              </Flex>
             <Flex>  {customer.size}</Flex>
                <Flex>  {isNaN(customer.price) ? null : currency(customer.price)}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(customer);setEditProduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(customer);setDeleteCom(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
  </Tabs>
    </TabPanel>
    <TabPanel>
    <Wrap border="1px solid black">
    <Flexed borderBottom="1px solid black">
    <Flex flex=".5">Stock Id</Flex>
      <Flex flex="2">Description</Flex>
      <Flex>Size</Flex>
      <Flex>Price</Flex>
      <Flex>Unit</Flex>
      <Flex flex=".5"></Flex>
      <Flex flex=".5"></Flex>
  
      </Flexed >
      {productlist?.filter(item => {return search.toLowerCase() === '' ? item : item.name?.toLowerCase().includes(search) || item.stockid?.toString().includes(search)}).map((product,index) => {
            return <>
            <Flexed key={index} className="hand" margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey"  >
            <Flex flex=".5">{product.itemid}</Flex>
            <Flex flex="2" >
            {product.name}
              </Flex>
             <Flex>  {product.size}</Flex>
                <Flex>  {isNaN(product.unitprice) ? null : currency(product.unitprice)}</Flex>
                <Flex>  {product.priceunit}</Flex>
             <Flex flex=".5"><LuEdit style={{fontSize:"calc(.7rem + .4vw)",color:"var(--plsGreen)",display:"inline-block"}} onClick={e => {setEditinfo(product);setEditcfpproduct(true)}} /></Flex>
      <Flex flex=".5"><MdDelete style={{fontSize:"calc(.7rem + .4vw)",color:"rgb(255,0,0,.7)"}} onClick={e => {setEditinfo(product);setDeleteComcfp(true)}} /></Flex>
            </Flexed>
            
            </>
         })}
  </Wrap>
    </TabPanel>
    <TabPanel>
    
    </TabPanel>
  </Tabs>
    </MainWrap>
  )
}

export default Supplies

