import React, { useEffect, useState } from 'react'
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import { useParams, useSearchParams } from 'react-router-dom'
import axios from 'axios'
import { getsalesrepinfo } from '../../utilities/InfoStuff'
import { toast } from 'react-toastify'
import CustomerSearch from './CustomerSearch'
import JobSearch from './JobSearch'
import OldjobSearch from './Oldjobsearch'
import Posearch from './Posearch'
import VendorSearch from './VendorSearch'

import QuoteSearch from './QuoteSearch'
import RFQSearch from './RFQSearch'
const Search = () => {

  const [searchParams] = useSearchParams();
  const query = searchParams.get('search')

    const params = useParams()

 
    const [salesreps, setSalesreps] = useState([])


    const [csrreps, setCsrreps] = useState()
    useEffect(() => {
        const getsalesreps = async() => {
            await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
            .then(res => setSalesreps(res.data))
            .catch(err => toast.error(err))
          }

          const getcsrreps = async() => {
            await axios.get('https://highgroundapi.herokuapp.com/employee/csr')
            .then(res => setCsrreps(res.data))
            .catch(err => toast.error(err))
          }
        getcsrreps()
        getsalesreps()  
    },[params])

console.log(query)
console.log(params.term)
  return (
    <MainWrap>
    
       <CustomerSearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>
       <JobSearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>
       <OldjobSearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>
       <Posearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>
       <VendorSearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>
       <QuoteSearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>
       <RFQSearch term={query ? query : params.term} csrreps={csrreps} salesreps={salesreps}/>

    </MainWrap>
  )
}
export default Search  