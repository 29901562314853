import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Flexed, Img } from '../utilities/Shared';
import F from '../Assets/default.png'
import axios from 'axios';

const Wrap = styled.div`
position:relative;
width:100%;
height:60px;
background-color:#2963B3;
border-bottom:1px solid #002B88;
padding:5px;
color:white;
margin:0px 0px;

&:hover{
    background-color:#002B88; 
}
`;

const Card = ({info,convo,setCurrentchat,setCurrentperson,unread,users}) => {



const [unreadd, setUnreadd] = useState([])


useEffect(() => {
let r = unread?.filter(item => item.read === false && item.messagefrom === info._id)
setUnreadd(r)

},[unread])





 

  

  return (
    <>
    
    <Wrap className="hand" style={{display:"flex",alignItems:"center",padding:"30px 20px"}} onClick={() => {setCurrentperson(info)}} >
      <div style={{display:"flex",alignItems:"center"}}>  <Img src={F} width="45px" borderRadius="50%" /></div>
        
        <div style={{display:"flex",alignItems:"flex-start",flexDirection:"column",gap:"10px",padding:"0px 0px 0px 20px",}}> <div style={{textAlign:"left",fontSize:"calc(.9rem + .2vw)",letterSpacing:"1px",color:`${users.some(item => item.userId === info._id) ? "#7CFC00" : "white"}`}}>{info.preferredName} {info.lastName}</div>  <div style={{textAlign:"left",fontSize:"calc(.9rem + .2vw)",letterSpacing:"1px"}}><span style={{color:`${unreadd?.length > 0 ? "yellow" : "grey"}`,fontWeight:`${unreadd?.length > 0 ? "bold" : "normal"}`}}> {unreadd?.length} Unread Message(s)</span></div></div>

        </Wrap>
        </>
  )
}

export default Card