import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, MainWrap, Scroll, currency } from '../../utilities/Shared'
import UserContext from '../../Context/UserContext'
import SalesChart from '../../components/Chart/SalesChart'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'
import Salesdetails from './Salesdetails'
import { Link, useNavigate } from 'react-router-dom'
import Estimatedaily from './Estimatedaily'
import LastWeekEstimatesDaily from './LastWeekEstimatesDaily'


const AdminDash = () => {

  const {userInfo } = useContext(UserContext)
  const[activejobs, setActivejobs] = useState([])
  const[jobstoday, setJobstoday] = useState([])
  const[jobstodaytotal, setJobstodaytotal] = useState()
  const[jobsyesturday, setJobsyesturday] = useState([])
  const[jobsyesturdaytotal, setJobsyesturdaytotal] = useState()
  const[postoday, setPostoday] = useState([])
  const[postodaytotal, setPostodaytotal] = useState()
  const[posyesturday, setPosyesturday] = useState([])
  const[posyesturdaytotal, setPosyesturdaytotal] = useState()
  const[order, setOrder] = useState("ASC")
  const[salesreps, setSalesreps] = useState([])
  const[polist, setPolist] = useState([])
  const[cfpjobs, setCFPJobs] = useState([])

  
  const nav = useNavigate()

  console.log(cfpjobs)


  useEffect(() => {
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    };
    const getactive = async() => { 
await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getallactivejobs.php',config)
.then(res => {setActivejobs(res.data)})
.catch(error => { console.log(error)})
    }
    const getjobstoday = async() => { 
      await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getjobstoday.php',config)
      .then(res => {setJobstoday(res.data)})
      .catch(error => { console.log(error)})
          }
          const getjobsyesturday = async() => { 
            await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getjobsyesturday.php',config)
            .then(res => {setJobsyesturday(res.data)})
            .catch(error => { console.log(error)})
                }
                const getpostoday = async() => { 
                  await axios.get('https://highgroundapi.herokuapp.com/po/today/po')
                  .then(res => {setPostoday(res.data)})
                  .catch(error => { console.log(error)})
                }
 const getAllCFPJobs = async() => {
      await axios.get(`https://cfpapi-6ab062006fa0.herokuapp.com/jobs/get/open/jobs`)
      .then( res => setCFPJobs(res.data))
      .catch(err => console.log(err))
  }
                const getposyesturday = async() => { 
const start = new Date();
const end = new Date();

start.setHours(0,0,0,0);
end.setHours(23,59,59,999);
if(new Date().getDay() === 1){
  start?.setDate(start.getDate() - 3)
  end?.setDate(end.getDate() -3)
 }else{
   start?.setDate(start.getDate() - 1)
   end?.setDate(end.getDate() - 1)
 }

 const dates = {
  start,end
 }
                await axios.post('https://highgroundapi.herokuapp.com/po/yesturday/po',dates)
                //await axios.post('http://localhost:5003/po/yesturday/po',dates)
                  .then(res => {setPosyesturday(res.data)})
                  .catch(error => { console.log(error)})
                }

                const getsales = async() => {
                  await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
                  .then(res => {setSalesreps(res.data)})
                  .catch(err => console.log(err))
              }
              
              getsales()

          getactive()
          getjobstoday()
          getjobsyesturday()
          getpostoday()
          getposyesturday()
          getactivesales()
          getpolist()
          getAllCFPJobs()
  },[])

useEffect(() => {
let ee = 0
jobstoday.map(job =>  ee += parseFloat(job.currentprice))
setJobstodaytotal(ee)
},[jobstoday])

useEffect(() => {
  let ee = 0
    jobsyesturday.map(job =>  ee += parseFloat(job.currentprice))
  setJobsyesturdaytotal(ee)
  },[jobsyesturday])

  useEffect(() => {
    let ee = 0
    postoday?.map(po => po.items.map(item => ee += parseFloat(item.extprice))) 
    setPostodaytotal(ee)
    },[postoday])
  
   useEffect(() => {
      let ee = 0
      posyesturday?.map(po => po.items.map(item => ee += parseFloat(item.extprice))) 
      setPosyesturdaytotal(ee)
      },[posyesturday])

   const getemployeebyoldid = (id) => {
   const r = salesreps.filter(rep => rep.empId === id)
   return r[0]?.firstName+' '+r[0]?.lastName.substr(0,1)
       }

       const getpolist = async() => { 
        await axios.post('https://highgroundapi.herokuapp.com/po/search')
        //await axios.post('http://localhost:5003/po/search')
             .then(res => {setPolist(res.data.po)})
             .catch(err => {console.log(err);})
         }

       const getactivesales = () => {
        let r = 0
        activejobs.map(item => {
            r += parseFloat(item.currentprice)
        })
        return r
       }


       const getcfpactivesales = () => {
        let r = 0
        cfpjobs.map(item => {
          console.log(item.totaltotal)
          item.totaltotal === undefined ? item.totaltotal = 0 : item.totaltotal = item.totaltotal
            r += parseFloat(item.totaltotal)
        })
        return r
       }


       const getopenpototal = () => {
        let r = 0
        polist.map(item => {
          item.items.map(it => {
            r += parseFloat(it.extprice)
          })
           
        })
        return r
       }



       const getpocost = (id) => {
        let y = 0
        const r = polist.filter(po => po.ponum === id).map(item => {
        item.items.map(it => {
          y += parseFloat(it.extprice)
        })
        })


    
       return y
       }

const getitemstotal = (item) => {
let ee = 0
item.items.map(ite => ee += parseFloat(ite.extprice))

  return ee
}

const getDuedateMinusDay = (date) => {

console.log(date)
let new_date = new Date(date);
return new_date
}

  return (
    <MainWrap> 
<Flexed padding="10px 0px">
  <Flex fontSize="var(--headerMedium)">Welcome Back {userInfo.preferredName} </Flex>
</Flexed>
<Flexed margin="30px 0px 0px 0px">
  <Flex border="1px solid #303030">
  {sessionStorage.getItem('page') === "Administration" && <SalesChart />}
  </Flex>
</Flexed>

<Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">PLS Active Jobs - {activejobs?.length}</Flex><Flex fontSize="calc(1.1rem + .4vw)" textAlign="right">${currency(getactivesales())}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">

  <Flex flex=".5" fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>Job#</Flex>
  
  <Flex fontSize="var(--headerMedium)" onClick={e => sortit('custpo',order,activejobs,setActivejobs,setOrder)}>Cust PO#</Flex>
  <Flex flex="1.5" fontSize="var(--headerMedium)" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Customer</Flex>
  <Flex  flex="3" fontSize="var(--headerMedium)" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Job Name</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Quantity</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('dateentered',order,activejobs,setActivejobs,setOrder)}>Entered</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('duedate',order,activejobs,setActivejobs,setOrder)}>Due</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('salesrep',order,activejobs,setActivejobs,setOrder)}>Sales Rep</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('currentprice',order,activejobs,setActivejobs,setOrder)}>Sales $</Flex>
  <Flex flex=".1"></Flex>
</Flexed>
<Scroll  height="350px" border="1px solid #303030">
{
 activejobs?.map(job => {
    return <Flexed className="normalrow hand" padding="5px" >
      <Flex flex=".5"><Link to={`/jobticket/${job.job}`}>{job.job} </Link></Flex>
      <Flex >{job.custpo}</Flex>
      <Flex flex="1.5">{job.customer}</Flex>
      <Flex flex="3">{job.jobname}</Flex>
      <Flex>{job.quantity === "0" ? job.origquantity : job.quantity}</Flex>
      <Flex><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Flex>
      <Flex><Moment format="MM/DD/YYYY">{getDuedateMinusDay(job.duedate)}</Moment></Flex>
      <Flex>{getemployeebyoldid(job.salesrep)}</Flex>
      <Flex>${currency(job.currentprice)}</Flex>
      </Flexed>
 
  })
}
</Scroll>


{/*///////////////////////////////////////////////////////////////////////////////////////////CFP ACTIVE JOBS ?????????????????????????????????????*/}
<Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">CFP Active Jobs - {cfpjobs?.length}</Flex><Flex fontSize="calc(1.1rem + .4vw)" textAlign="right">${currency(getcfpactivesales())}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">

  <Flex flex=".5" fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>Job#</Flex>
  
  <Flex fontSize="var(--headerMedium)" onClick={e => sortit('custpo',order,activejobs,setActivejobs,setOrder)}>Cust PO#</Flex>
  <Flex flex="1.5" fontSize="var(--headerMedium)" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Customer</Flex>
  <Flex  flex="3" fontSize="var(--headerMedium)" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Job Name</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Quantity</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('dateentered',order,activejobs,setActivejobs,setOrder)}>Entered</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('duedate',order,activejobs,setActivejobs,setOrder)}>Due</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('salesrep',order,activejobs,setActivejobs,setOrder)}>Sales Rep</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('currentprice',order,activejobs,setActivejobs,setOrder)}>Sales $</Flex>
  <Flex flex=".1"></Flex>
</Flexed>
<Scroll  height="200px" border="1px solid #303030">
{
 cfpjobs?.map(job => {
    return <Flexed className="normalrow hand" padding="5px" >
      <Flex flex=".5"><Link to={`https://cfp.highgroundsoftware.com/jobs/${job._id}`}>{job.jobid} </Link></Flex>
      <Flex ></Flex>
      <Flex flex="1.5">{job.customername}</Flex>
      <Flex flex="3">{job.description}</Flex>
      <Flex>{job.quantity === "0" ? job.totalquantity : job.totalquantity}</Flex>
      <Flex><Moment format="MM/DD/YYYY">{job.lastdate}</Moment></Flex>
      <Flex></Flex>
      <Flex></Flex>
      <Flex>{job.totaltotal ? `$${currency(job.totaltotal)}` : ""}</Flex>
      </Flexed>
 
  })
}
</Scroll>
{/*////////////////////////////////////////////////////////////////////////////////////////////////////////////////*/}
<Flexed gap="30px">
  <Flex flex=".9">
  <Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Jobs Logged Today - {jobstoday?.length}</Flex><Flex textAlign="right"  fontSize="var(--headerMedium)">{'$'+currency(jobstodaytotal)}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
  <Flex  fontSize="14px" onClick={e => sortnum('job',order,jobstoday,setJobstoday,setOrder)}>Job#</Flex>
  <Flex fontSize="14px" flex="1.5"  onClick={e => sortit('customer',order,jobstoday,setJobstoday,setOrder)}>Customer</Flex>
  <Flex fontSize="14px"  flex="3"  onClick={e => sortit('jobname',order,jobstoday,setJobstoday,setOrder)}>Job Name</Flex>
  <Flex fontSize="14px"   onClick={e => sortnum('origquantity',order,jobstoday,setJobstoday,setOrder)}>Quantity</Flex>
  <Flex fontSize="14px"   onClick={e => sortdate('dateentered',order,jobstoday,setJobstoday,setOrder)}>Price</Flex>
</Flexed>
<Scroll  height="250px" border="1px solid #303030">
{

 jobstoday?.map(job => {
 
    return <Flexed className="normalrow hand" padding="5px">
      <Flex  fontSize="12px"><Link to={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${job.job}`}>{job.job}</Link></Flex>
      <Flex fontSize="12px" flex="1.5">{job.customer}</Flex>
      <Flex fontSize="12px" flex="3">{job.jobname}</Flex>
      <Flex fontSize="12px">{job.quantity}</Flex>
      <Flex fontSize="12px">{job.currentprice}</Flex>
    
      </Flexed>
  })
}
</Scroll>
<Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Jobs Logged {new Date().getDay() === 1 ? <>Friday</> : <>Yesterday</>} - {jobsyesturday?.length}</Flex><Flex textAlign="right"  fontSize="var(--headerMedium)">{'$'+currency(jobsyesturdaytotal)}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
  <Flex  fontSize="14px" onClick={e => sortnum('job',order,jobstoday,setJobstoday,setOrder)}>Job#</Flex>
  <Flex fontSize="14px" flex="1.5"  onClick={e => sortit('customer',order,jobstoday,setJobstoday,setOrder)}>Customer</Flex>
  <Flex fontSize="14px"  flex="3"  onClick={e => sortit('jobname',order,jobstoday,setJobstoday,setOrder)}>Job Name</Flex>
  <Flex fontSize="14px"   onClick={e => sortnum('origquantity',order,jobstoday,setJobstoday,setOrder)}>Items</Flex>
  <Flex fontSize="14px"   onClick={e => sortdate('dateentered',order,jobstoday,setJobstoday,setOrder)}>Price</Flex>
</Flexed>
<Scroll  height="250px" border="1px solid #303030">
{
 jobsyesturday?.map(job => {
    return <Flexed className="normalrow hand" padding="5px">
           <Flex  fontSize="12px"><Link to={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${job.job}`}>{job.job}</Link></Flex>
      <Flex fontSize="12px" flex="1.5">{job.customer}</Flex>
      <Flex fontSize="12px" flex="3">{job.jobname}</Flex>
      <Flex fontSize="12px">{job.origquantity}</Flex>
      <Flex fontSize="12px">{job.currentprice}</Flex>
      </Flexed>
  })
}
</Scroll>

<Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">PO's Logged Today - {postoday?.length}</Flex><Flex textAlign="right"  fontSize="var(--headerMedium)">{'$'+currency(postodaytotal)}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
  <Flex fontSize="14px" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>PO#</Flex>
  <Flex flex="3" fontSize="14px" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Vendor</Flex>
  <Flex  flex="1" fontSize="14px" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Ordered</Flex>

  <Flex  fontSize="14px" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Cost</Flex>


</Flexed>
<Scroll border="1px solid #303030"  height="250px">
{
   
 postoday?.map((po,index) => {
let ee
    return <Flexed key={index} className="normalrow hand" padding="5px" >
      <Flex fontSize="12px" ><Link to={`pos/ponew/${po._id}`}>{po.ponum}</Link></Flex>
      <Flex fontSize="12px" flex="3">{po.vendor?.company?.name}</Flex>
      <Flex fontSize="12px" flex="1">{po.items?.length}</Flex>
 
      <Flex fontSize="12px">{currency(getitemstotal(po))}</Flex>

      </Flexed>
  })
}

</Scroll>

<Flexed   margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">PO's Logged {new Date().getDay() === 1 ? <>Friday</> : <>Yesterday</>} - {posyesturday?.length}</Flex><Flex textAlign="right"  fontSize="var(--headerMedium)">{'$'+currency(posyesturdaytotal)}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
<Flex fontSize="14px" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>PO#</Flex>
  <Flex flex="3" fontSize="14px" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Vendor</Flex>
  <Flex  fontSize="14px" >Company</Flex>
  <Flex  flex="1" fontSize="14px" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Ordered</Flex>

  <Flex  fontSize="14px" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Cost</Flex>
</Flexed>
<Scroll  height="250px" border="1px solid #303030">
{
 posyesturday?.map(po => {
    return <Flexed className="normalrow hand" padding="5px">
          <Flex fontSize="12px" ><Link to={`pos/ponew/${po._id}`}>{po.ponum}</Link></Flex>
      <Flex fontSize="12px" flex="3">{po.vendor?.company?.name}</Flex>
      <Flex fontSize="12px" flex="1">PLS</Flex>
      <Flex fontSize="12px" flex="1">{po.items?.length}</Flex>
      <Flex fontSize="12px">{currency(getitemstotal(po))}</Flex>
      </Flexed>
  })
}
</Scroll>


  </Flex>


  <Flex>
 
{userInfo.auth?.includes("600") || userInfo.departments?.find(x => x.value === "116") ? <Salesdetails salesreps={salesreps} setSalesreps={setSalesreps} jobstoday={jobstoday}/>:""}
<>{userInfo.auth?.includes("600") || userInfo.departments?.find(x => x.value === "116") ? <Estimatedaily salesreps={salesreps} setSalesreps={setSalesreps} jobstoday={jobstoday}/>:""}
{userInfo.auth?.includes("600") || userInfo.departments?.find(x => x.value === "116") ? <LastWeekEstimatesDaily salesreps={salesreps} setSalesreps={setSalesreps} jobstoday={jobstoday}/>:""}
  <Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Open PO's {polist?.length}</Flex><Flex textAlign="right"  fontSize="var(--headerMedium)">{'$'+currency(getopenpototal())}</Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand" >
  <Flex  flex=".7" fontSize="14px" onClick={e => sortnum('ponum',order,polist,setPolist,setOrder)}>PO#</Flex>
  <Flex  flex="2" fontSize="14px" onClick={e => sortnum('job.vendor?.company?.name',order,polist,setPolist,setOrder)}>Vendor</Flex>
  <Flex flex=".7" fontSize="14px"   onClick={e => sortit('createdAt',order,polist,setPolist,setOrder)}>Entered</Flex>
  <Flex flex=".7" fontSize="14px"   onClick={e => sortit('dueDate',order,polist,setPolist,setOrder)}>Due</Flex>
  <Flex flex=".7" fontSize="14px"  onClick={e => sortit('jobname',order,polist,setPolist,setOrder)}>Cost</Flex>
</Flexed>
<Scroll  height="700px" border="1px solid #303030">
{

polist?.map(job => {
 
    return <Flexed className="normalrow hand" padding="5px" onClick={() => nav(`/pos/ponew/${job._id}`)}>
      <Flex  flex=".7" fontSize="12px">{job.ponum}</Flex>
      <Flex flex="2"  fontSize="12px">{job.vendor?.company?.name}</Flex>
      <Flex flex=".7" fontSize="12px" ><Moment format= "MM/DD/YYYY">{job.createdAt}</Moment></Flex>
      <Flex flex=".7" fontSize="12px" ><Moment format= "MM/DD/YYYY">{job.dueDate}</Moment></Flex>
      <Flex flex=".7" fontSize="12px" >${currency(getpocost(job.ponum))}</Flex>

    
      </Flexed>
  })
}
</Scroll>
</>
  </Flex>
</Flexed>
<br /> <br />
    </MainWrap>
  )
}

export default AdminDash