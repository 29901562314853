import React, { useRef,useEffect ,useState, useContext} from "react";
import Moment from 'react-moment';
import { ImFilePdf } from "react-icons/im";

import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Button, Img, Label, Selectbox, currency } from "../../utilities/Shared";




export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;

text-transform:capitalize;
font-family: Arial, Helvetica, sans-serif;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
gap:${props => props.gap || ""};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border || ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};
border-bottom: ${props => props.borderBottom || ""};
align-items:${props => props.alignItems || "flex-start"};
flex-wrap:wrap;

h2{
    margin:0;
    padding:0:
}
`;



export const Textarea = styled(TextareaAutosize)`
font-size:1.4rem;
width:100%;
border:none;
padding:10px 10px 40px 10px;
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function Taxexem({file}) {
  let componentRef = useRef();
const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)
const date = new Date()


console.log(file)


  return (
    <>
  
      <Flex id="print_component" style={{textAlign:"right",paddingTop:"15px"}}>
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <ImFilePdf style={{color:"#8498AC",fontSize:"calc(3rem + .2vw)",height:"100%",position:"relative",top:"-10px"}}/>}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint file={file} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);

   console.log(props)
  }

  render() {
    return (

       <iframe src={`https:highgroundsoftware.com/dash/dashboard/production/taxIds/${this.props.file}`} title="none" style={{height:"100vh",width:"100%"}}></iframe>
      

  
    );
  }
}





