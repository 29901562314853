import React, { useEffect, useState } from 'react'
import { Flex, Flexed } from '../../utilities/Shared'
import { useParams } from 'react-router-dom'
import axios from 'axios'
import { toast } from 'react-toastify'
import { sortit, sortnum } from '../../utilities/Sort'

const JobSearch = ({term,csrreps,salesreps}) => {

const [newjobs, setNewjobs] = useState([])
const [oldjobs, setOldjobs] = useState([])
const [newerjobs, setNewerjobs] = useState([])
const [order, setOrder] = useState("ASC")
useEffect(() => {
   
    const getjobsbynameolder  = async() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }};    
          const tr = {
            searchitem:term
      }
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getjobsbynameolder.php`,tr,config)
     .then(res => {setOldjobs(res.data)})
     .catch(err => toast.error(err))   
    }

    getjobsbynameolder()
 
},[])


   
console.log(oldjobs)

  return (
    <>
  {oldjobs?.length > 0 &&  <div style={{border:"1px solid #303030",margin:"30px 0px"}}>
    <Flexed ><Flex  bgColor="#0E425A" color="white" fontSize="calc(1.5rem + .4vw)">OLDER JOB RESULTS &nbsp; &nbsp;{oldjobs.length}</Flex></Flexed>
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" className="hand">
        <Flex fontSize="var(--headerMedium)" flex=".5" onClick={() => sortnum('job',order,newerjobs,setNewerjobs,setOrder)}>Job#</Flex>
        <Flex fontSize="var(--headerMedium)" flex=".7" onClick={() => sortnum('quotenum',order,newerjobs,setNewerjobs,setOrder)}>Quote#</Flex>
        <Flex fontSize="var(--headerMedium)" flex="2.5" onClick={() => sortit('customer',order,newerjobs,setNewerjobs,setOrder)}>Customer</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortnum('quantity',order,newerjobs,setNewerjobs,setOrder)}>Quantity</Flex>
        <Flex fontSize="var(--headerMedium)" flex="2.5" onClick={() => sortit('jobname',order,newerjobs,setNewerjobs,setOrder)}>Job Name</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortit('po',order,newerjobs,setNewerjobs,setOrder)}>PO#</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortit('salesrep',order,newerjobs,setNewerjobs,setOrder)}>Salesrep</Flex>
        <Flex fontSize="var(--headerMedium)" onClick={() => sortit('csr',order,newerjobs,setNewerjobs,setOrder)}>CSR</Flex>
    </Flexed>
    
    {oldjobs?.map(job => {
     return <Flexed className="normalrow" >
    <Flex  flex=".5" > <a href={`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${job.quotenum}&c=${job.customerid}&j=${job.job}`} style={{fontSize:"var(--headerMedium)"}} >{job.job}</a></Flex>
     <Flex  flex=".7" ><a style={{fontSize:"var(--headerMedium)"}} href={`https://highgroundsoftware.com/dash/dashboard/?des=2065&j=${job.quotenum}`} >{job.quotenum}</a></Flex>
     <Flex  flex="2.5"><a style={{fontSize:"var(--headerMedium)"}}  href={`/customers/hgcustomer/${job.customerid}`} >{job.customer}</a></Flex>
     <Flex ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${job.quotenum}&c=${job.customerid}&j=${job.job}`} style={{fontSize:"var(--headerMedium)"}} >{job.quantity}</a></Flex>
     <Flex fontSize="var(--headerMedium)" flex="2.5"><a href={`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${job.quotenum}&c=${job.customerid}&j=${job.job}`} style={{fontSize:"var(--headerMedium)"}} >{job.jobname}</a></Flex>
     
     <Flex fontSize="var(--headerMedium)"><a href={`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${job.quotenum}&c=${job.customerid}&j=${job.job}`} style={{fontSize:"var(--headerMedium)"}} >{job.po}</a></Flex>
     <Flex ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${job.quotenum}&c=${job.customerid}&j=${job.job}`} style={{fontSize:"var(--headerMedium)"}} >{salesreps?.filter(rep => rep.empId === job.salesrep).map(rep =>{return rep.firstName+" "+rep.lastName.substr(0,1)})}</a></Flex>
     <Flex ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=777&q=${job.quotenum}&c=${job.customerid}&j=${job.job}`} style={{fontSize:"var(--headerMedium)"}} >{csrreps?.filter(rep => rep.empId === job.csr).map(rep =>{return rep.firstName+" "+rep.lastName.substr(0,1)})}</a></Flex>
    
    </Flexed>
    })
       
    }
    </div>}
    </>
  )
}

export default JobSearch