import React, { useState } from 'react'
import { Button, Flex, Flexed, Input } from '../../utilities/Shared'
import { toast } from 'react-toastify'
import axios from 'axios'

const ChangePassword = ({id,employyeid,onCancel}) => {

    const [pass, setPass] = useState()
    const [pass2, setPass2] = useState()


    const change = (e) => {
        e.preventDefault()

        if(pass !== pass2){
return toast.error('Passwords Do Not Match')
        }

        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };   
          const tr = {
            pass:pass,
            id:employyeid,
      } 
      axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatepassword.php`,tr,config)
    .then(res => {
        console.log(res.data);
        const t = {
            id:id,
            password:pass
        }
        axios.post(`http://localhost:5003/employee/update/password`,t)
        .then(res => {console.log(res.data);toast.success('Password Changed');onCancel()})
       .catch(err => toast.error(err))
    
    
    })
   .catch(err => toast.error(err))
      console.log(tr)
    }

  return (
    <form onSubmit={change}>
    <Flexed padding="10px 50px">
        <Flex></Flex>
<Flex>New Password<br /><Input type="password" onChange={e => setPass(e.target.value)}/> </Flex>

<Flex>Confrirm Password<br /><Input type="password" onChange={e => setPass2(e.target.value)}/> </Flex>
<Flex></Flex>
 </Flexed>
 <Flexed>
    <Flex textAlign="right"><Button type="submit">Update Password</Button></Flex>
 </Flexed>
 </form>
  )
}

export default ChangePassword





/*$2y$10$NBkxHOSzhhykJ9nXZOa8J.SzMI5e9huqT51.JYzVYSU7JtXtT.M.a
  $2y$10$2jFnFqsGPlFCFu/jHwXWSe07zkJ3gVbBw.JsEWyOXSHL81vMynh4a
  
  $2y$10$JLMOBDdFB9rNSf6z6/Q8wOWPDxJ0rUbkUj5ARbASkG/FBi6oJ2qGa
  */