import axios from "axios";
import { useState } from "react";
import { v4 as uuidv4 } from 'uuid';




export const getoldwhitesheet = (job,setReset,reset,saveshipping) => {
let arr =[]
    let uuu 
    const getcustomer = async() => {
       
    await axios.get(`https://highgroundapi.herokuapp.com/customers/hg/item/${job?.customerid}`)
      //await axios.get(`http://localhost:5003/customers/hg/item/${job?.customerid}`)
       .then(res => {
    
        if(job?.currentprice > ""){
            let item= {
                statuscode:"Billable",
                description:job?.jobname,
                quantity:job?.currentquantity,
                cost:job?.currentprice,
                unitprice:job?.currentper,
                itemid:uuidv4()
            }
arr.push(item)

saveshipping(arr,'billable')

        }
   
        //uuu = res.data[0];
       })
       // setTempmainlocation(res.data[0]?.address?.filter(filt => filt.itemid === res.data[0].mainlocation))})
       .catch(err => console.log(err))
      }
      getcustomer()
}
    


   

   export const getshippingitemsitem = async(job,setReset,reset,saveshipping,customerinfo,setShippingitems) => {


        let h = []
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          }; 
    let t ={
        job:job?.job
    }
        await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getlineitems.php/`,t,config)
        .then(res => {
            if(res.data.length > 0){
          let ids = []
        
            res.data.map(item => ids.includes(parseInt(item.shiptoid)) ? "" : ids.push(parseInt(item.shiptoid)))

            ids.map(item => {
               let addy = customerinfo?.address?.filter(filt => parseInt(filt.itemid) === parseInt(item) || parseInt(filt.old) === parseInt(item))
              let list = res.data.filter(filter => parseInt(filter.shiptoid) === parseInt(item))
           let itemslist = []
              list.map(ite => {
                console.log(ite)
let t ={ 
shipmethod:ite.method,
itemid:ite.method,
name:job?.jobname,
requested:ite.quantity,
shipdate:ite.shipdate,
addedid:uuidv4(),
}



itemslist.push(t)
              })
         
              let y = {
                
locationid:addy[0]?.itemid,
items:itemslist,
oldid:addy[0]?.old,
locationname:addy[0]?.name,
address1:addy[0]?.address1,
address2:addy[0]?.address2,
city:addy[0]?.city,
state:addy[0]?.state,
zip:addy[0]?.zip,
              }
h.push(y)
           

            })
  
 //


 const sendit = async(h) => {
    let Y ={
        id:job?._id,
        field:'shippingitems',
        value:h
      }

    await axios.post('https://highgroundapi.herokuapp.com/jobs/update',Y)
    .then(res => {console.log(res.data);setShippingitems(h)})
    .catch(err => console.log(err))
 }
 

   sendit(h)         
            }else{
                setShippingitems([])   
            }
    })
        .catch(err => {console.log(err)})

       
    }
 
   