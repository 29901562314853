import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Scroll, Selectbox, Textauto, Wrapper } from '../../../utilities/Shared'
import axios from 'axios';
import Collapse from '../../../utilities/Collapse';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { RiDeleteBack2Fill } from "react-icons/ri";
import JobticketContext from '../../../Context/JobticketContext';
import { colorchange } from '../../../utilities/Convertions';

const moment = require("moment");
const BinderyTicket = ({jobinfo,forms,reset,setReset,job}) => {

  const[operations, setOperations] = useState([])
  const[newforms, setnewForms] = useState([])
  const[imposition, setImposition] = useState([])
  const[proofstotal, setProofstotal] = useState({hours:0,minutes:0})
 




  const[operationitems, setOperationitems] = useState([])
  const[prepress, setPrespress] = useState([])
  const[onprepresstimes, setOnprepresstimes] = useState({})
  const[offprepresstimes, setOffprepresstimes] = useState({})

  const[onplatingtimes, setOnplatingtimes] = useState({})
  const[offplatingtimes, setOffplatingtimes] = useState({})
  const[proofin, setProofin] = useState(false)
  const[proofout, setProofout] = useState(false)
  
  const [value, onChange] = useState(new Date());
  const [onTimes, setOnTimes] = useState(null);
  const [offTimes, setOffTimes] = useState(null);




  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };


  useEffect(() => {

    let r = {
        job:job,
    }
    const getops = async() => {
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
    .then(res => {setOperations(res.data);})
    .catch(err => console.log(err))
    }
    const getquote = async() => {
      let r = {
        quote:jobinfo?.quote,
      }
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getimposition.php`,r,config)
     .then(res => {setImposition(res.data);})
     .catch(err => console.log(err))
     }
    getops()
    getquote()
  },[reset])

  useEffect(() => {
    setPrepressValues()
  },[operations])


  console.log(forms)
const setPrepressValues = () => {
  let imp
  let ops = []
let proofing = []
  forms.map((item,index) => {
    let cuttingins = []
    let  cuttingouts = []
    let  cuttingindis = []
    let foldingins = []
    let  foldingouts = []
    let  foldingindis = []
    let diecuttingins = []
    let  diecuttingouts = []
    let diecuttingindis = []
    let gluerins = []
    let  gluerouts = []
    let gluerindis = []
    let handworkins = []
    let  handworkouts = []
    let handworkindis = []
    let handstitchins = []
    let  handstitchouts = []
    let handstitchindis = []
    let partofid
    let partof
    console.log(operations)
     imp = imposition?.filter(filter => filter.component === item.originalform)
     console.log(imp)

operations?.filter(filter => filter.comp === item.name).map((op,index) => {
  let diff = 0
    if(op.name === "On Cutting"){cuttingins.push({id:op.id,time:op.changedon,status:"On Cutting"})}
   if(op.name === "On Folding"){foldingins.push({id:op.id,time:op.changedon,status:"On Folding"})}
    if(op.name === "On Die Cutting"){diecuttingins.push({id:op.id,time:op.changedon,status:"On Die Cutting"})}
    if(op.name === "On Gluer"){gluerins.push({id:op.id,time:op.changedon,status:"On Gluer"})}
    if(op.name === "On Handwork"){handworkins.push({id:op.id,time:op.changedon,status:"On Handwork"})}
    if(op.name === "On Hand Stitching"){handstitchins.push({id:op.id,time:op.changedon,status:"On Hand Stitching"})}


    if(op.name === "Off Cutting"){cuttingouts.push({id:op.id,time:op.changedon,status:"Off Cutting"})} 
  if(op.name === "Off Folding"){foldingouts.push({id:op.id,time:op.changedon,status:"Off Folding"})}
  if(op.name === "Off Die Cutting"){diecuttingouts.push({id:op.id,time:op.changedon,status:"Off Die Cutting"})}
  if(op.name === "Off Gluer"){gluerouts.push({id:op.id,time:op.changedon,status:"Off Gluer"})}
  if(op.name === "Off Handwork"){handworkouts.push({id:op.id,time:op.changedon,status:"Off Handwork"})}
  if(op.name === "Off Hand Stitching"){handstitchouts.push({id:op.id,time:op.changedon,status:"Off Hand Stitching"})}


    if(op.name === "Incomplete Cutting"){cuttingouts.push({id:op.id,time:op.changedon,status:"Incomplete Cutting"})}
    if(op.name === "Incomplete Folding"){foldingouts.push({id:op.id,time:op.changedon,status:"Incomplete Folding"})}
    if(op.name === "Incomplete Die Cutting"){diecuttingouts.push({id:op.id,time:op.changedon,status:"Incomplete Die Cutting"})} 
    if(op.name === "Incomplete Gluer"){gluerouts.push({id:op.id,time:op.changedon,status:"Incomplete Gluer"})}
    if(op.name === "Incomplete Handwork"){handworkouts.push({id:op.id,time:op.changedon,status:"Incomplete Handwork"})}
    if(op.name === "Incomplete Hand Stitching"){handstitchouts.push({id:op.id,time:op.changedon,status:"Incomplete Hand Stitching"})}
   // if(op.name === "AA Changes Press"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Press"})} 
   // if(op.name === "PE Changes Press"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Press"})}
   // if(op.name === "AA Changes Digital"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Digital"})} 
   // if(op.name === "PE Changes Digital"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Digital"})}
   // if(op.name === "AA Changes Colormax"){pressouts.push({id:op.id,time:op.changedon,status:"AA Changes Colormax"})} 
   // if(op.name === "PE Changes Colormax"){pressouts.push({id:op.id,time:op.changedon,status:"PE Changes Colormax"})}

    partofid = op.partofid || `${op.partof}${index}`
    partof = op.partof || op.item
    })

   
    cuttingindis=(getdiff(cuttingins,cuttingouts))
    foldingindis=(getdiff(foldingins,foldingouts))
    diecuttingindis=(getdiff(diecuttingins,diecuttingouts))
    gluerindis=(getdiff(gluerins,gluerouts))
    handworkindis=(getdiff(handworkins,handworkouts))
    handstitchindis=(getdiff(handstitchins,handstitchouts))


    const y = {
      id:item.id,
      item:item.item,
        name:item.name,
        formid:item.formid,
        partofid,
        partof,
        processtext:item.processtext,
        cutting:
          {'ins':cuttingins,
            'out':cuttingouts,
            'indiv':cuttingindis,
            'estimatedtime':setesttime(imp,'cutting'),
          
    },
    folding:
    {'ins':foldingins,
      'out':foldingouts,
      'indiv':foldingindis,
      'estimatedtime':setesttime(imp,'folding'),
    
},
diecutting:
{'ins':diecuttingins,
  'out':diecuttingouts,
  'indiv':diecuttingindis,
  'estimatedtime':setesttime(imp,'diecutting'),

},
gluer:
{'ins':gluerins,
  'out':gluerouts,
  'indiv':gluerindis,
  'estimatedtime':setesttime(imp,'gluer'),

},
handwork:
{'ins':handworkins,
  'out':handworkouts,
  'indiv':handworkindis,
  'estimatedtime':setesttime(imp,'handwork'),

},
handstitching:
{'ins':handstitchins,
  'out':handstitchouts,
  'indiv':handstitchindis,
  'estimatedtime':setesttime(imp,'handstitching'),

}
    }
    proofing.push(y)
})
setnewForms(proofing)
}



const getdiff = (inTimes,outTimes) => {

let tot =[]
let u


inTimes?.map((item,index) => {

      let time
        let intime = new Date(item?.time)
        let outtime = new Date(outTimes[index]?.time)
     time = Math.abs(outtime - intime)



   
  let y =timeConvert(time/1000/60)
  if(isNaN(y.hours)){y.hours = 0}
  if(isNaN(y.minutes)){y.minutes = 0}
   u = {hours:y.hours,minutes:y.minutes}
  tot.push(u)
})
return(tot)
}

const setesttime = (imp,item) => {
  let newitem

switch(item){
case "press":
  if(parseInt(imp[0]?.numforms) > 1){
      let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
  
      newitem = (r / parseInt(imp[0]?.numforms))
  } 
break;
}

let y = converttime(newitem)

return y
}



function timeConvert(n) { 

  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

 // if(isNaN(rhours)){rhours = 0}
  //if(isNaN(rminutes)){rminutes = 0}
  let t = {hours:proofstotal.hours + rhours,minutes:proofstotal.minutes + rminutes}

  return{hours:rhours,minutes:rminutes}
  } 

  const converttime = (item) => {

    let hours = 0
        let minutes = 0
        let myArray = []
    if(item !== undefined){
         myArray = item.toString()?.split(".");
       hours = parseInt(myArray[0])
       minutes = parseInt(myArray[1])

        let check = minutes/100
        if(check > 1){
         minutes = minutes % 100
       
        }
    }
   return({hours,minutes})
}


const getDiff = (intime,outtime,item) => {
   intime = new Date(intime)
   outtime = new Date(outtime)
     let  time = Math.abs(outtime - intime)

     let y = (timeConvert(time/1000/60))


     return `${y.hours}:${y.minutes}`
}








const setchangevalues = (item) => {
   
  let d
  let s
  let p
  let push
switch(item){
  case "On Cutting":
d = 4
s = 1
p = 1
push=0
break;
case "Off Cutting":
d = 4
s = 0
p = 1
push=0
break;
case "Incomplete Cutting":
d = 4
s = 0
p = 1
push=0
break;
case "On Folding":
    d = 4
    s = 1
    p = 2
    push=0
    break;
    case "Off Folding":
    d = 4
    s = 0
    p = 2
    push=0
    break;
    case "Incomplete Folding":
    d = 4
    s = 0
    p = 2
    push=0
    break;
    case "On Die Cutting":
        d = 4
        s = 1
        p = 3
        push=0
        break;
        case "Off Die Cutting":
        d = 4
        s = 0
        p = 3
        push=0
        break;
        case "Incomplete Die Cutting":
        d = 4
        s = 0
        p = 3
        push=0
        break;
        case "On Gluer":
            d = 4
            s = 1
            p = 4
            push=0
            break;
            case "Off Gluer":
            d = 4
            s = 0
            p = 4
            push=0
            break;
            case "Incomplete Gluer":
            d = 4
            s = 0
            p = 4
            push=0
            break;
            case "On Handwork":
                d = 4
                s = 1
                p = 6
                push=0
                break;
                case "Off Handwork":
                d = 4
                s = 0
                p = 6
                push=0
                break;
                case "Incomplete Handwork":
                d = 4
                s = 0
                p = 6
                push=0
                break;
                case "On Hand Stitching":
                    d = 4
                    s = 1
                    p = 7
                    push=0
                    break;
                    case "Off Hand Stitching":
                    d = 4
                    s = 0
                    p = 7
                    push=0
                    break;
                    case "Incomplete Hand Stitching":
                    d = 4
                    s = 0
                    p = 7
                    push=0
                    break;

}
return {d,s,p,push}
}



const setpushes = (e,form) => {
  console.log(e.target.value)
let set = {}
  if(e.target.value === "Off Press"){
set.dept = 4           
set.push = 0
set.name = "Ready Bindery"
set.process=0
  }
  const z = {
    job:job,
    item:form.item,
    partof:form.partof,
    partofid:form.partofid,
    process:set.process,
    comp:form.name,
    changedby:sessionStorage.getItem('eid'),
  name:set.name,
  dept:set.dept,
  status:0,
  push:set.push
  }
  console.log(z)
  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )

 
  }
  savit()
}





const changestatus = (e,form,index) => {
console.log(form)
let values = setchangevalues(e.target.value)

const z = {
  job:job,
  item:form.item,
  partof:form.item,
  partofid:form.partofid,
  process:values.p,
	comp:form.name,
name:e.target.value,
dept:values.d,
status:values.s,
changedby:sessionStorage.getItem('eid'),
push:values.push
}
console.log(z)
const savit = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
  .then(res => {setpushes(e,form)} )
  .catch(err => toast.error('Something went wrong and process was not saved') )
}
savit()
}







const changetime = (e,form) => {

  const z = {
    changedon:e.target.value,
id:form.id
  }

  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )
  }
 savit()
}


console.log(newforms)

  return (
    <Wrapper style={{height:"70vh",position:"relative",color:"black"}}>
    
  
    <Scroll height="100%">

    <div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Cutting</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.cutting.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.cutting.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.cutting.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.cutting.out[index].status)}} type="datetime-local" value={form.cutting.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.cutting.indiv[index].hours}:{form.cutting.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.cutting.estimatedtime.hours}:{form.cutting.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Cutting">On Cutting</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Cutting">Off Cutting</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Cutting">Incomplete Cutting</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Folding//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Folding</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.folding.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.folding.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.folding.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.folding.out[index].status)}} type="datetime-local" value={form.folding.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.folding.indiv[index].hours}:{form.folding.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.folding.estimatedtime.hours}:{form.folding.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Folding">On Folding</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Folding">Off Folding</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Folding">Incomplete Folding</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Die Cutting//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Die Cutting</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.diecutting.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.diecutting.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.diecutting.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.diecutting.out[index].status)}} type="datetime-local" value={form.diecutting.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.diecutting.indiv[index].hours}:{form.diecutting.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.diecutting.estimatedtime.hours}:{form.diecutting.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Die Cutting">On Die Cutting</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Die Cutting">Off Die Cutting</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Die Cutting">Incomplete Die Cutting</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Gluer//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Gluer</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.gluer.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.gluer.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.gluer.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.gluer.out[index].status)}} type="datetime-local" value={form.gluer.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.gluer.indiv[index].hours}:{form.gluer.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.gluer.estimatedtime.hours}:{form.gluer.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Gluer">On Gluer</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Gluer">Off Gluer</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Gluer">Incomplete Gluer</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Handwork//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Handwork</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.handwork.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.handwork.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.handwork.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.handwork.out[index].status)}} type="datetime-local" value={form.handwork.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.handwork.indiv[index].hours}:{form.handwork.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.handwork.estimatedtime.hours}:{form.handwork.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Handwork">On Handwork</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Handwork">Off Handwork</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Handwork">Incomplete Handwork</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Handwork//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Hand Stitching</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.handstitching.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.handstitching.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.handstitching.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.handstitching.out[index].status)}} type="datetime-local" value={form.handstitching.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.handstitching.indiv[index].hours}:{form.handstitching.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.handstitching.estimatedtime.hours}:{form.handstitching.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Hand Stitching">On Hand Stitching</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Hand Stitching">Off Hand Stitching</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Hand Stitching">Incomplete Hand Stitching</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>
<br /> <br /> <br /> <br />
    </Scroll>
    

    
    </Wrapper>
  )
}

export default BinderyTicket




