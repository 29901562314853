import React from 'react'
import { MainWrap, Scroll } from '../../utilities/Shared'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import PressEdit from './PressEdit'

import Employees from '../Employees/Employees'
import Cutters from './Cutters'
import Folders from './Folders'
import Stitching from './Stitching'

const Sitesettings = () => {
  return (
    <MainWrap>

        <Tabs style={{marginTop:"20px"}}>
    <TabList >
    <Tab className="react-tabs__tab c">Employees</Tab>
      <Tab className="react-tabs__tab c">Press</Tab>
      <Tab className="react-tabs__tab c">Cutter</Tab>
      <Tab className="react-tabs__tab c">Folder</Tab>
      <Tab className="react-tabs__tab c">Stitching</Tab>
    </TabList>
    <TabPanel><Employees /></TabPanel> 
    <TabPanel><PressEdit /></TabPanel> 
    <TabPanel><Cutters /></TabPanel> 
    <TabPanel><Folders /></TabPanel> 
    <TabPanel><Stitching /></TabPanel> 
    </Tabs>
    </MainWrap>
  )
}

export default Sitesettings