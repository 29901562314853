import React, { useContext, useEffect, useState } from 'react'
import UserContext from '../Context/UserContext';
import AdminDash from '../pages/Admin/AdminDash'
import SalesDash from '../pages/Sales/SalesDash'
import Production from './Production';
import Prepress from './Prepress/Prepress';
import Inhouse from './inventory/Inhouse';
import Digital from '../pages/Schedules/digital/Digital';
import JobTicket1 from './jobs/Jobticket1';
import Shipping from './shipping/Shipping';










const Main = () => {

const { userInfo } = useContext(UserContext)
const [newpage, setNewpage] = useState()







  return (
    <> 
   
{sessionStorage.getItem('page') === "Sales" && <SalesDash />}
{sessionStorage.getItem('page') === "Administration" && <AdminDash />}
{sessionStorage.getItem('page') === "Shipping" && <Shipping />}
{sessionStorage.getItem('page') === "Management" && <AdminDash />}
{sessionStorage.getItem('page') === "Pre-Press" && <Prepress />}
{sessionStorage.getItem('page') === "Receiving" && <Inhouse />}
{sessionStorage.getItem('page') === "Production" && <JobTicket1 />}
{sessionStorage.getItem('page') === "Digital" && <Digital />}

    </>
  )
}

export default Main