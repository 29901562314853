import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Scroll, Wrap, Wrapper } from '../../../utilities/Shared'
import PressEst from './PressEst'
import PrepressEst from './PrepressEst'
import BinderyEst from './BinderyEst'
import ProofingEst from './ProofingEst'
import Collapse from '../../../utilities/Collapse'
import { RxDropdownMenu } from "react-icons/rx";
import PlatingEst from './PlatingEst'
import Actualversusestimate from '../../../Context/Actualversusestimate'
import S44S2Est from './S44S2Est'

const EstVact = ({}) => {


 return (

  <Wrapper>
    <Flexed color="white"  padding="6px 0px" bgColor="#0E425A" style={{borderTop:"1px solid black"}} alignItems="center">
      <Flex fontSize="calc(1rem + .2vw)" >Pre-Press</Flex>
     </Flexed>
    <Flexed color="black" padding="10px 0px">
    <Flex ></Flex>
        <Flex fontSize="calc(1rem + .2vw)">Est Mat</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Act Mat</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Mat Diff</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Per/HR</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Est Hrs</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Est Labor</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Act Hrs</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Act Labor</Flex>
        <Flex fontSize="calc(1rem + .2vw)">Labor Diff</Flex>
    </Flexed>
   
    <S44S2Est />
    <Flexed color="black" padding="10px 0px">
        <Flex fontSize="calc(1rem + .2vw)">Proofing</Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)">N/A</Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
    </Flexed>
    <Flexed color="black" padding="10px 0px">
        <Flex fontSize="calc(1rem + .2vw)">Plating</Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)">$60</Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
    </Flexed>
    <Flexed color="black" padding="10px 0px" bgColor="rgb(0,0,0,.2)">
        <Flex fontSize="calc(1rem + .2vw)">Totals</Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)">$0.00</Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
        <Flex fontSize="calc(1rem + .2vw)"></Flex>
    </Flexed>


    {/*
    Press
    */}
     <Flexed color="white"  padding="6px 0px" bgColor="#0E425A" style={{borderTop:"1px solid black"}} alignItems="center">
      <Flex fontSize="calc(1rem + .2vw)" >Press</Flex>
     </Flexed>
 </Wrapper>

  )
}

export default EstVact








/*
 const [prepressesttime, setPrepressesttime] = useState(0)
  const [prepressacttime, setPrepressacttime] = useState(0)
  const[prepressesttotal, setPrepressesttotal] = useState(0)
  const [proofingesttime, setProofingesttime] = useState(0)
  const [proofingacttime, setProofingacttime] = useState(0)
  const [platingesttime, setPlatingesttime] = useState(0)
  const [platingacttime, setPlatingacttime] = useState(0)
  const[platingesttotal, setPlatingesttotal] = useState(0)
  const[allesttimettotal, setAllesttimettotal] = useState(0)
  const[allacttimettotal, setAllacttimettotal] = useState(0)
  const[allestcosttotal, setAllestcosttotal] = useState(0)
  const[allactcosttotal, setAllactcosttotal] = useState(0)
  console.log(forms)

  useEffect(() => {
setAllesttimettotal((parseFloat(prepressesttime) + parseFloat(platingesttime)).toFixed(2))
setAllacttimettotal((parseFloat(prepressacttime) + parseFloat(proofingacttime) + parseFloat(platingacttime)).toFixed(2))
setAllestcosttotal((parseFloat(prepressesttotal) + parseFloat(platingesttotal)))
setAllactcosttotal((parseFloat(prepressesttotal) + parseFloat(platingesttotal)))
  },[prepressesttime,proofingacttime,platingesttime,prepressesttotal,platingesttotal])


  return (
    <Wrap >
      <Scroll style={{height:"70vh"}}>

  <div>
    <Flexed color="white"  padding="6px 0px" bgColor="#0E425A" style={{borderTop:"1px solid black"}} alignItems="center">

   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" flex="1.5">Pre-Press</Flex>  
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Est Time:&nbsp;</Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >Act Time:&nbsp;</Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Est Cost: </Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Act Cost:  </Flex>
</Flexed> 


    <Collapse  title={
    <Flexed color="black"  padding="6px 0px" bgColor="rgb(122,187,67,.3)" style={{borderTop:"1px solid black"}} alignItems="center">
    <Flex flex=".5"><RxDropdownMenu  style={{fontSize:"calc(1.8rem + .2vw)"}}/></Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Pre-Press</Flex>  
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">{prepressesttime}</Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >{prepressacttime}</Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"> ${prepressesttotal}</Flex>
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">${prepressesttotal} </Flex>
</Flexed> 
}>
      <PrepressEst jobinfo={jobinfo} operations={operations} forms={forms} setPrepressesttime={setPrepressesttime} setPrepressacttime={setPrepressacttime} setPrepressesttotal={setPrepressesttotal}/>
      </Collapse>

<div style={{marginTop:"10px"}}>
      <Collapse  title={
     <Flexed color="black"  padding="6px 0px" bgColor="rgb(122,187,67,.3)"  alignItems="center">
     <Flex flex=".5"><RxDropdownMenu  style={{fontSize:"calc(1.8rem + .2vw)"}}/></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Proofing</Flex>  
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">N/A</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >{proofingacttime}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"> $0.00</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">$0.00 </Flex>
 </Flexed> 
}>
  <ProofingEst jobinfo={jobinfo} operations={operations} forms={forms} setProofingesttime={setProofingesttime} setProofingacttime={setProofingacttime}/> 
      </Collapse>
   </div>  

   <div style={{marginTop:"10px"}}>
      <Collapse  title={
     <Flexed color="black"  padding="6px 0px" bgColor="rgb(122,187,67,.3)"  alignItems="center">
     <Flex flex=".5"><RxDropdownMenu  style={{fontSize:"calc(1.8rem + .2vw)"}}/></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Plating</Flex>  
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">N/A</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >{platingacttime}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"> ${platingesttotal.toFixed(2)}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">${platingesttotal.toFixed(2)}</Flex>
 </Flexed> 
}>
  <PlatingEst jobinfo={jobinfo} operations={operations} forms={forms} setPlatingesttime={setPlatingesttime} setPlatingacttime={setPlatingacttime} setPlatingesttotal={setPlatingesttotal} /> 
      </Collapse>
   </div> 
   <div >
   <Flexed color="black"  padding="6px 0px" bgColor="rgb(14,66,90,.3)"  alignItems="center">
   <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Pre-Press Totals</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" flex=".5"></Flex> 
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">{allesttimettotal}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >{allacttimettotal}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"> ${allestcosttotal.toFixed(2)}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">${allactcosttotal.toFixed(2)}</Flex>
 </Flexed> 
 </div> 
 </div>



//PRESS ///////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////

<div style={{marginTop:"10px"}}>
<Flexed color="white"  padding="6px 0px" bgColor="#0E425A" style={{borderTop:"1px solid black"}} alignItems="center">

<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" flex="1.5">Press</Flex>  
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Est Time:&nbsp;</Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >Act Time:&nbsp;</Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Est Cost: </Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Act Cost:  </Flex>
</Flexed> 


<Collapse  title={
<Flexed color="black"  padding="6px 0px" bgColor="rgb(122,187,67,.3)" style={{borderTop:"1px solid black"}} alignItems="center">
<Flex flex=".5"><RxDropdownMenu  style={{fontSize:"calc(1.8rem + .2vw)"}}/></Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">Pre-Press</Flex>  
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">{prepressesttime}</Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold" >{prepressacttime}</Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"flex="2"></Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold"> ${prepressesttotal}</Flex>
<Flex fontSize="calc(1.2rem + .2vw)" fontWeight="bold">${prepressesttotal} </Flex>
</Flexed> 
}>
  <PrepressEst jobinfo={jobinfo} operations={operations} forms={forms} setPrepressesttime={setPrepressesttime} setPrepressacttime={setPrepressacttime} setPrepressesttotal={setPrepressesttotal}/>
  </Collapse>


</div>


</Scroll>
</Wrap>*/ 