import React, { useContext, useEffect, useState } from 'react'
import { Button, Datepick, Flex, Flexed, Input, Label, MainWrap, Textarea } from '../../utilities/Shared'
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios'
import Moment from 'react-moment'
import { v4 as uuidv4 } from 'uuid';
import { IoRemoveCircleOutline } from "react-icons/io5";
import { useRef } from 'react';
import WhitesheetPrint from '../../Printing/WhitesheetPrint'
import { TbFloatLeft } from 'react-icons/tb'
import { toast } from 'react-toastify'
import { getoldwhitesheet,getshippingitemsitem } from './Getoldwhitesheetinfo'
import Setupshipment from '../shipping/Setupshipment'
import Modal from '../../modals/Modal'
import { LiaClipboardListSolid } from "react-icons/lia";
import Collapse from '../../utilities/Collapse'
import UserContext from '../../Context/UserContext'
import Shipreprint from '../../Printing/Shipreprint'

const Whitesheet = () => {
  const param = useParams()
  const [search, setSearch] = useState("")
  const [jobinfo, setJobinfo] = useState()
  const [customerinfo, setCustomerinfo] = useState()
  const[tempmainlocation, setTempmainlocation] = useState({})
  const[mainlocation, setMainlocation] = useState({})
  const [billable, setBillable] = useState([])
  const [tempbillable, setTempbillable] = useState({statuscode:"Billable"})
  const[tempaddress, setTempaddress] = useState({})
  const[tempitem, setTempitem] = useState({shipmethod:"PLS Delivery"})
  const[tempitems, setTempitems] = useState([])
  const[tempitemselected, setTempitemselected] = useState({})
  const[maincalcs, setMaincalcs] = useState({quantity:0,perm:0,unitprice:0,price:0})
  const [shippingitems, setShippingitems] = useState([])
  const [locations, setLocations] = useState([])
  const [reset, setReset] = useState(false)
  const [resetship, setResetship] = useState(false)
  const [setship, setSetship] = useState(false)
  const [sentitems, setSentitems] = useState([])
  const buttonRef = useRef();
  const [currentShipItem, setCurrentShipItem] = useState({})
  const {userInfo} = useContext(UserContext)
  const [jobid, setJobid] = useState()

   const nav = useNavigate()
  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  }; 


useEffect(() => {

  const getjob = async() => {
    let r = {'jobnum':param?.job.replace(/\D/g, "")}
    await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
    .then(res => {
  
      setJobinfo(res.data[0]);
      getcustomer(res.data[0]?.customerid)
    
      setJobid(res.data[0]._id)
      getsentitems(res.data[0]?.job)
    })
    .catch(err => console.log(err))
  }

  const getcustomer = async(customerid) => {
    await axios.get(`https://highgroundapi.herokuapp.com/customers/hg/item/${customerid}`)
    //await axios.get(`http://localhost:5003/customers/hg/item/${customerid}`)
   .then(res => {

    setCustomerinfo(res.data[0]);
           setMainlocation(res.data[0]?.address?.filter(filt => filt.itemid === res.data[0].mainlocation))  
    setTempmainlocation(res.data[0]?.address?.filter(filt => filt.itemid === res.data[0].mainlocation))
    getlocations(res.data[0]?.company_id)
  
  })
   .catch(err => console.log(err))
  }


  const getlocations = async(company) => {

let rr ={
  id:company
}
    await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getoldlocation.php',rr,config)
    .then(res => {setLocations(res.data)})
    .catch(err => console.log(err))
  }
  getjob()
  
},[reset])



const getsentitems = async(jobnum) => {
  console.log(jobnum)
  let r ={
    jobnum:jobnum
  }
  console.log(r)
 // await axios.get(`https://highgroundapi.herokuapp.com/customers/hg/item/${customerid}`)
  await axios.post(`https://highgroundapi.herokuapp.com/ship/getitems`,r)
 .then(res => {
  console.log(res.data)
  setSentitems(res.data)
})
 .catch(err => console.log(err))
}


useEffect(() => {
  updatemaincalcs()
setReset(!reset)
},[setship])

useEffect(() => {
  updatemaincalcs()

},[billable])

useEffect(() => {
  currentShipItem?.address1 > "" ? setSetship(true) : setSetship(false)
},[currentShipItem])


useEffect(() => {
 jobinfo?.billable?.length  === 0 ? getoldwhitesheet(jobinfo,setReset,reset,saveshipping) : setBillable(jobinfo?.billable)
 jobinfo?.shippingitems?.length  === 0 ? getshippingitemsitem(jobinfo,setReset,reset,saveshipping,customerinfo,setShippingitems) : setShippingitems(jobinfo?.shippingitems)
},[customerinfo,reset])

 console.log(jobinfo)

 console.log(currentShipItem)


const addbillable = () => {
  let u = billable
  if(tempbillable.quantity > 0){
    let a = tempbillable.cost / tempbillable.quantity
    tempbillable.unitprice = a
    tempbillable.itemid = uuidv4()
    buttonRef.current.focus()

    u.push(tempbillable)

   // setBillable([...billable,tempbillable])
   
  }

saveshipping(u,'billable')
setTempbillable({description:"",quantity:0,cost:0,unitprice:0,statuscode:"Billable"})
}






const settemplocationdropdown =(e,node,field) => {

  var inputValue = e.target.value;
  // setSelectedItem({...selectedItem, itemname: e.target.value})
   let id;
   let dd;

       var options = document.getElementById(node).childNodes;

       for (var i = 0; i < options.length; i++) {
         if (options[i].value === inputValue) {
          let addressdata = options[i].dataset
    
           dd ={
            ...addressdata,
            addedid:uuidv4()
          }
         }} 

setTempaddress(dd)
}




const settempitemdropdown =(e,node,field) => {

  var inputValue = e.target.value;
  // setSelectedItem({...selectedItem, itemname: e.target.value})
   let id;

       var options = document.getElementById(node).childNodes;
  
       for (var i = 0; i < options.length; i++) {
         if (options[i].value === inputValue) {
         id = (options[i].dataset.item)
         }} 

         setTempitem({...tempitem,itemid:id,itemname:e.target.value})
}



const addtotempitemsdropdown =(e) => {

  var inputValue = e.target.value;
  // setSelectedItem({...selectedItem, itemname: e.target.value})
   let id;
   
       var options = document.getElementById('tempitem').childNodes;

       for (var i = 0; i < options.length; i++) {
         if (options[i].value === inputValue) {
         id = (options[i].dataset.itemid)

         }} 

         setTempitem({...tempitem,itemid:id,name:e.target.value})
}


const addtotempitems = () => {
  console.log(tempitem)
tempitem.addedid = uuidv4()
tempitem.itemid === undefined ? tempitem.itemid = uuidv4() : tempitem.itemid = tempitem.itemid
setTempitems([...tempitems,tempitem])

setTempitem({oldid:"",addedid:"",itemid:"",name:"",requested:"",shipdate:tempitem.shipdate,shipmethod:tempitem.shipmethod})

}




const addshippingitem = () => {
console.log(tempaddress)
  if(shippingitems?.some(som => som.locationid === tempaddress.locationid)){ 
    let uu = shippingitems?.findIndex(fin => fin.locationid === tempaddress.locationid )  
    tempitems?.map(item => {
  !shippingitems[uu].oldid ? console.log('no') :  console.log('yes')
      shippingitems[uu]?.items.push(item)
    })
   setShippingitems(shippingitems)
  setResetship(!resetship)
  }else{
   
    let hh = {...tempaddress,items:tempitems}
 console.log(hh)
 console.log(shippingitems)

 if(shippingitems.length > 0){
  shippingitems.push(hh)
  setShippingitems(shippingitems)
  setResetship(!resetship)
 }else{
  setShippingitems([hh])
setResetship(!resetship)
 }

  }

setTempitem({addedid:"",itemid:"",name:"",requested:"",shipdate:"",shipmethod:""})
setTempaddress({...tempaddress,addedid:"",address1:"",city:"",locationid:"",locationname:"",oldid:"",state:"",zip:"",location:""})
setTempitems([])
  }

useEffect(() => {
  savenewshipping()
},[resetship])


  console.log(shippingitems)

const getoldlocationid = (name) => {
  let hh =locations.filter(filt => filt.name === name)
  return hh[0]?.id
}



const updatemaincalcs = () => {
  let quantity = 0
  let unitprice = 0
  let price = 0
  let perm = 0

billable?.map(item => {
 if(parseFloat(item.cost) > 0){
  quantity += parseInt(item.quantity)
  price += parseFloat(item.cost)
 }

})
let uu = (parseFloat(price) / parseInt(quantity))

uu > 0 ? uu = uu.toFixed(8) :uu = 0

let y = {
  quantity:parseInt(quantity),
   unitprice:uu,
  price:parseFloat(price)?.toFixed(2),
  perm
}
setMaincalcs(y)

}



const removeitem = (index,location,item) => {

let newfilt = location.items?.filter(filt => filt.addedid !== item.addedid)

shippingitems[index].items = newfilt
saveshipping(shippingitems,'shippingitems')
updatemaincalcs()
// saveshippingtoold(shippingitems)
}

const removeaddress = (item) => {


let newfilt = shippingitems?.filter(filt => filt.locationid !== item.locationid)
   console.log(newfilt)
   saveshipping(newfilt,'shippingitems')
 setShippingitems(newfilt)
  }


const removebillingitem = (e,item) => {
let newbillable = billable.filter(filt => filt.itemid !== item)
saveshipping(newbillable,'billable')

  }
  

const savesheet = () => {


}


const savenewshipping = async() => {
console.log(shippingitems)
if(shippingitems?.length > 0){
  let Y ={
    id:jobinfo?._id,
    field:'shippingitems',
    value:shippingitems
  }
   console.log(Y)
   await axios.post('https://highgroundapi.herokuapp.com/jobs/update',Y)
  .then(res => {console.log(res.data);saveshippingtoold(shippingitems)})
  .catch(err => console.log(err))
}


}


const saveshipping = async(items,field) => {

  let Y ={
    id:jobinfo?._id,
    field,
    value:items
  }
   console.log(Y)
  if(field === "shippingitems"){
  //  saveshippingtoold(items)
  await axios.post('https://highgroundapi.herokuapp.com/jobs/update',Y)
  .then(res => {saveshippingtoold(items)})
  .catch(err => console.log(err))
  }else{
   //await axios.post('http://localhost:5003/jobs/update',Y)
   await axios.post('https://highgroundapi.herokuapp.com/jobs/update',Y)
  .then(res => {setBillable(res.data?.billable)})
  .catch(err => console.log(err))

  }

}



const saveshippingtoold = async(items) => {

  let rr = []

console.log(items)
  items?.map(item => {
    let h
    item.items.map(ite => {
      h  = {
        description:ite.name,
        job:jobinfo.job,
        method:ite.shipmethod,
        notes:ite.notes || "",
        per:"",
        quantity:ite.requested,
        sent:0,
        contact:ite.contact,
        shipto:item.locationname,
        shiptoid:item.oldid || getoldlocationid(item?.locationname)
  
      }
      rr.push(h)
    })

  })
console.log(rr)
  await axios.post('https://highgroundsoftware.com/dash/dashboard/php/addshipping.php',rr,config)
  .then(res => {console.log(res.data);toast.success('Shipping Info Saved')})
  .catch(err => console.log(err))
}


const setshipitem = async(item) => {

setCurrentShipItem(item)


}



const removepacklist = async(it) => {
  let rr ={
    id:it._id
  }
  await axios.post('https://highgroundapi.herokuapp.com/ship/romovepacklist',rr)
  .then(res => {toast.success('Packlist Removed');setReset(!reset)})
  .catch(err => console.log(err))

}


console.log(customerinfo)
console.log(sentitems)
  return (
    <MainWrap>
        <Modal show={setship}  width="100%" headerbgcolor="#8498AC" headercolor="#144259" header={`Create Packslip`} onCancel={() => {setCurrentShipItem(null)}} ><Setupshipment onCancel={() => {setCurrentShipItem(null)}}  reset={reset} setReset={setReset} item={currentShipItem} customerinfo={customerinfo} jobinfo={jobinfo} setJobinfo={setJobinfo} /></Modal>

     


      <Flexed>
        <Flex style={{paddingTop:"20px"}} textAlign="right">
          {
            userInfo.departments?.some(cat => cat.value === "112") ?
            <Button fontSize="calc(1.3rem + .2vw)" onClick={e => nav(`/shipping`)}>Close</Button> :
            <Button fontSize="calc(1.3rem + .2vw)" onClick={e => nav(`/jobticket/good/${jobinfo?.job}`)}>Close</Button>
          }
          </Flex>
      </Flexed>

        <Flexed gap="20px" margin="20px 0px 0px 0px" padding="0px">
            <Flex padding="20px 20px" >
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)">Bill To: <Input type="text" value={customerinfo?.name}  /> </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".3"><br /> <Input type="text" value={customerinfo?.company_id}  /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)" flex="2"><Input type="text" value={tempmainlocation[0]?.address1}  />  </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.address2}  /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.city}  />  </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.state}  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Input type="text" value={tempmainlocation[0]?.zip}  placeholder="Address2"/></Flex>
</Flexed>
<br /><br /><br />
<Flexed padding="0px 0px 15px 0px">
<Flex flex="2" fontSize="calc(1.2rem + .2vw)">Main Contact<br /><Input type="text" value={customerinfo?.contacts?.filter(filt => filt.contactid === customerinfo.maincontact).map(item => {
  return item.name
  
})}  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Billing Term<br /><Input type="text" value={customerinfo?.billingterms}  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Tax Exempt<br /><Input type="text" value={customerinfo?.taxexempt}  /></Flex>
</Flexed>
<br />
<br />
<Flexed padding="0px 0px 15px 0px"> 
<Flex fontSize="calc(1.2rem + .2vw)"><Label>Quantity</Label><br /><Input type="text"  value={maincalcs?.quantity} /></Flex>
  

  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Per / M</Label><br /><Input type="text"  value={maincalcs?.perm > 0 ? maincalcs.perm : 0} /></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Unit Price</Label><br /><Input type="text" value={maincalcs?.unitprice}  /></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Price</Label><br /><Input type="text" value={maincalcs?.price}  /></Flex>
</Flexed>
<Flexed fontSize="calc(1.2rem + .2vw)" padding="10px">
  {jobinfo?.jobname}
</Flexed>
            </Flex>



            <Flex padding="20px 20px" >
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)">Job#: <Input type="text"  value={param?.job.replace(/\D/g, "")}  /> </Flex>
    <Flex flex=".2"></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Entered: <br /><Moment style={{fontSize:"calc(1.2rem + .2vw)",border:"1px solid rgb(0,0,0,.3)",padding:"6px",position:"relative",top:"5px",borderRadius:"5px"}} format="MM/DD/YYYY">{jobinfo?.dateentered}</Moment> </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Date Due:<br /> <Moment style={{fontSize:"calc(1.2rem + .2vw)",border:"1px solid rgb(0,0,0,.3)",padding:"6px",position:"relative",top:"5px",borderRadius:"5px"}} format="MM/DD/YYYY">{jobinfo?.duedate}</Moment> </Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
<Flex fontSize="calc(1.2rem + .2vw)">Quote#: <Input type="text" value={jobinfo?.quote}  /> </Flex>
<Flex fontSize="calc(1.2rem + .2vw)">PO#: <Input type="text" value={jobinfo?.custpo}  /> </Flex>
<Flex fontSize="calc(1.2rem + .2vw)">Prev Job#: <Input type="text" value={jobinfo?.prevjob}  /> </Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)" flex=".2">Samples:<br /><Input type="text" value="5"  />  </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Send Samples To:<br /><Input type="text" value=""  />  </Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px"> 
    <Flex fontSize="calc(1.2rem + .2vw)">Permit #<br /><Input type="text" value=""  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Permit Location<br /><Input type="text" value=""  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Permit Style<br /><Input type="text" value=""  /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px">
    <Flex fontSize="calc(1.2rem + .2vw)">Postage Quantity<br /><Input type="text" value=""  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Postage Cost<br /><Input type="text" value=""  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"> Total Postage $<br /><Input type="text" value=""  /></Flex>
</Flexed>
<Flexed padding="0px 0px 15px 0px"> 
    <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">Drop Shipment Cost<br /><Input type="text" value=""  /></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Supplier<br /><Input type="text" value=""  /></Flex>
   
</Flexed>
            </Flex>
            </Flexed>

<Flexed margin="-15px 0px 0px 0px">
  <Flex flex="4"></Flex>
  <Flex textAlign="right" padding="0px 20px 0px 0px"></Flex>
  <Flex textAlign="right" flex="0"><WhitesheetPrint customerinfo={customerinfo} mainlocation={mainlocation[0]} jobinfo={jobinfo} billable={billable} maincalcs={maincalcs} /></Flex>
</Flexed>

  <div  style={{padding:"20px 20px",border:"1px solid lightgrey",margin:"10px 0px 0px 0px"}}>
    <Flexed bgColor="rgb(14,66,90,.5)" padding="5px" >
      <Flex fontSize="calc(1.5rem + .2vw)">  <Label fontSize="calc(1.3rem + .2vw)"> Add Billing Item(s)</Label></Flex>
    </Flexed>
 
  
<Flexed padding="10px 0px 0px 0px">
  <Flex fontSize="calc(1.2rem + .2vw)" flex="4"><Label>Description</Label><br /><Input type="text" value={tempbillable?.description}  onChange={e => setTempbillable({...tempbillable,description:e.target.value})} /></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Quantity</Label><br /><Input type="text" value={tempbillable?.quantity}  onChange={e => setTempbillable({...tempbillable,quantity:e.target.value})} /></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Cost</Label><br /><Input type="text" value={tempbillable?.cost}  onChange={e => setTempbillable({...tempbillable,cost:e.target.value})} /></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">
    Status<br />
    <select value={tempbillable?.statuscode} onChange={e => setTempbillable({...tempbillable,statuscode:e.target.value})}>
    <option value="Billable">Billable</option>
    <option value="Non Billable">Non Billable</option>
    </select></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" ><br /><Button ref={buttonRef} onClick={e => addbillable()}>Add Item</Button></Flex>
</Flexed>

{billable?.length > 0 && <div style={{padding:"10px"}}>
<Flexed bgColor="rgb(0,0,0,.2)" padding="5px">
  <Flex>
  <Label >Current Items</Label>
  </Flex>
</Flexed>

<Flexed padding="10px 0px 0px 0px" borderBottom="1px solid lightgrey">
  <Flex fontSize="calc(1.2rem + .2vw)" flex="3"><Label>Description</Label></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Quantity</Label></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Cost</Label></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Per Unit</Label></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">Status</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
<br />
{billable?.map((item,index) => {
  return <Flexed padding="0px 0px 0px 0px" borderBottom="1px solid lightgrey">
  <Flex fontSize="calc(1.2rem + .2vw)" flex="3">{item.description}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{item.quantity}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{item?.cost}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{parseFloat(item?.unitprice)?.toFixed(8)}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"> {item?.statuscode}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" textAlign="center"><IoRemoveCircleOutline onClick={e => removebillingitem(e,item.itemid)} style={{fontSize:"1.6rem",color:"red"}} /></Flex>
</Flexed>
})}

</div>}
  </div>


  <div  style={{padding:"20px 20px",border:"1px solid lightgrey",marginTop:"10px"}}>
  
   <Flexed bgColor="rgb(14,66,90,.5)" padding="5px" >
      <Flex fontSize="calc(1.5rem + .2vw)">   <Label fontSize="calc(1.3rem + .2vw)">Add Shipping Info</Label></Flex>
    </Flexed>
<Flexed padding="10px 0px 0px 0px">
  <Flex fontSize="calc(1.2rem + .2vw)" flex="4"><Label>Location</Label><br />
  <Input type="text"  placeholder="Select Address" value={tempaddress?.location} onChange={e => settemplocationdropdown(e,"tempaddy")} list="tempaddy" />
   <datalist id="tempaddy">
    <option value="">Select Item or add one</option>
    <option data-oldid="128" data-locationname="Tampa BMEU" data-address1="5433 W Sligh Ave"  data-city="Tampa" data-state="FL" data-zip="33634" value={`Tampa BMEU - 5433 W Sligh Ave Tampa FL 33634`} />
{

    customerinfo?.address?.map((item,index) => {


     return   <option key={index} data-oldid={item?.old || getoldlocationid(item?.name)} data-locationname={item?.name} data-address1={item?.address1} data-address2={item?.address2} data-city={item?.city} data-state={item?.state} data-zip={item?.zip} data-locationid={item.itemid} value={`${item.name} - ${item.address1} ${item.address2 !== undefined ? item.address2 : ""} ${item.city} ${item.state} ${item.zip} - ${item?.old || getoldlocationid(item?.name)}`}/> 
    })
}
   </datalist>
  
  </Flex>

</Flexed>

<Flexed>
<Flex fontSize="calc(1.2rem + .2vw)" flex="2"><Label>Item</Label><br /> <Input type="text"  placeholder="Select Item" value={tempitem.name} onChange={e => addtotempitemsdropdown(e,"tempitem",'itemid')} list="tempitem" />
   <datalist id="tempitem">
{
    billable?.map((item,index) => {
     return   <option key={index} data-item={item.itemid} value={`${item.description}`}/> 
    })
}

   </datalist></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)"><Label>Requested</Label><br />
  <Input type="text" value={tempitem.requested} onChange={e => setTempitem({...tempitem,requested:e.target.value})} /></Flex>
  <Flex fontSize="calc(1.2rem + .2vw)" ><Label>Ship Date</Label><br /><Datepick selected={tempitem?.shipdate}  onChange={(date) => setTempitem({...tempitem,shipdate:date})}/> </Flex>
  <Flex fontSize="calc(1.2rem + .2vw)" flex="2"><Label>Ship Method</Label><br /> <Input type="text" value={tempitem.shipmethod} onChange={e => setTempitem({...tempitem,shipmethod:e.target.value})} placeholder="Select Item"  list="shipmethod" />
   <datalist id="shipmethod">
   <option key={0} data-item="PLS Delivery" value="PLS Delivery"/> 
   <option key={1} data-item="UPS" value="UPS"/> 
   </datalist></Flex>
    
    <Flex fontSize="calc(1.2rem + .2vw)" onClick={e => addtotempitems()}><br /><Label>Add Item</Label></Flex>
</Flexed>
{tempitems.length > 0 &&
  <>
<Flexed style={{marginTop:"10px"}} bgColor="rgb(0,0,0,.2)" padding="10px 5px" alignItems="center">
  <Flex ><Label>Current Items This Address</Label></Flex>
  <Flex textAlign="right"><Button onClick={e => addshippingitem()} bgcolor="var(--plsBlue)" color="white" borderRadius="5px">Save Address Info</Button></Flex>
</Flexed>
<Flexed style={{borderBottom:"1px solid black"}}>
  <Flex fontSize="calc(1.2rem + .2vw)">Item</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">Requested</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Date </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Method</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
{
tempitems.map(item => {
 return <Flexed className="normalrow">
  <Flex fontSize="calc(1.2rem + .2vw)">{item.name}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{item.requested}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Moment style={{fontSize:"calc(1.2rem + .2vw)"}} format="MM/DD/YYYY">{new Date(item.shipdate)}</Moment></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">{item.shipmethod}</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"><IoRemoveCircleOutline style={{fontSize:"1.6rem",color:"red"}} /></Flex>
</Flexed>
})
}
</>
}

  </div >


  <div style={{padding:"20px 20px",border:"1px solid lightgrey",marginTop:"10px"}} >
  <Flexed bgColor="rgb(14,66,90,.5)" padding="5px" margin="0px 0px 10px 0px">
  <Flex fontSize="calc(1.5rem + .2vw)">   <Label fontSize="calc(1.3rem + .2vw)">Shipping Information</Label></Flex>
  <Flex textAlign="right"></Flex>
</Flexed>



{
  
shippingitems?.map((item,index) => {
  return <div  style={{border:"1px solid rgb(0,0,0,.3)",marginBottom:"10px"}}>
  <Flexed padding="5px" bgColor="rgb(0,0,0,.3)">
  <Flex fontSize="calc(1.1rem + .2vw)">{item.locationname} - {item.address1 ? item.address1 : ""} {item.address2 ? item.address2 : ""} {item.city ? item.city : ""} {item.state ? item.state : ""} {item.zip ? item.zip : ""}</Flex>
  <Flex flex="0"><LiaClipboardListSolid  style={{fontSize:"1.9rem",color:"#0E425A"}} onClick={e => {setshipitem(item)}}/></Flex>
  <Flex flex=".01"></Flex>
  <Flex flex="0"><IoRemoveCircleOutline style={{fontSize:"1.6rem",color:"red"}} onClick={e => removeaddress(item)}/></Flex>
 
</Flexed>
<Flexed style={{borderBottom:"1px solid black"}}>
  <Flex fontSize="calc(1.2rem + .2vw)" flex="2.5">Item</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">Requested</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship By </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">Ship Method</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"></Flex>
</Flexed>
{
item?.items?.map(ite => {
 return <Flexed className="normalrow" borderBottom="1px solid #909090">
  <Flex fontSize="calc(1.2rem + .2vw)" flex="2.5">{ite.name}</Flex>
  <Flex fontSize="calc(1.2rem + .2vw)">{ite.requested}</Flex>
    <Flex fontSize="calc(1.2rem + .2vw)"><Moment style={{fontSize:"calc(1.2rem + .2vw)"}} format="MM/DD/YYYY">{new Date(ite.shipdate)}</Moment></Flex>
    <Flex fontSize="calc(1.2rem + .2vw)">{ite.shipmethod}</Flex>
    <Flex flex=".1" fontSize="calc(1.2rem + .2vw)"><IoRemoveCircleOutline style={{fontSize:"1.6rem",color:"red"}} onClick={e => removeitem(index,item,ite)}/></Flex>
</Flexed>
})
}

{
  
  sentitems.filter(filt => filt.shiptoid === item.locationid).map(it => {
   return  <Collapse title={<Flexed  fontWeight="bold" padding="3px 0px" fontSize="calc(1rem + .2vw)" bgColor="#9BB0C1" margin="3px 0px" className="hand">
    <Flex>{it.shipid}</Flex>
    <Flex><Shipreprint  shipped={it} mainlocation={mainlocation} jobinfoo={jobinfo} /></Flex>
    <Flex flex="0"></Flex>
    <Flex flex=".1"></Flex>
  </Flexed>}>
  <Flexed color="#0E425A" borderBottom="1px solid grey" padding="6px 0px">
     <Flex >Item</Flex>
     <Flex flex=".4">Sent</Flex>
     <Flex>Description</Flex>
    
     <Flex flex=".4">Ship Via</Flex>
     <Flex flex=".4">Ship Date</Flex>
   </Flexed>
    {
    it.items.map(itemm => {
     return <>  
     
     <Flexed color="#0E425A" padding="6px 0px">
    <Flex>{itemm.name}</Flex>
    <Flex flex=".4">{itemm.sending}</Flex>
    <Flex><Textarea style={{border:"none"}}>{itemm.description}</Textarea></Flex>
    <Flex flex=".4">{it.method}</Flex>
    <Flex flex=".4"><Moment format={"MM/DD/YYYY"} >{it.dateshipped}</Moment></Flex>
  </Flexed>
  </>
    })
  }
    </Collapse>
  })
}
</div> 
})

}


</div>
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br /> <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br />
  <br /> <br />
  <br />
  <br />
  <br />
  <br />

    </MainWrap>
  )
}

export default Whitesheet

