import React, { useContext } from 'react'
import { Flex, Flexed, Input, MainWrap, Wrap } from '../../utilities/Shared'
import PressContext from '../../Context/PressContext'

const Cutters = () => {
    const {cutter,updateit} = useContext(PressContext)
  return (
    <Wrap>
        <Flexed bgColor="#0E425A" padding="6px 0px " margin="0px 0px 10px 0px" color="white" position="sticky" top="0px" style={{zIndex:"100"}}>
            <Flex>Run Rate</Flex>
            <Flex>Minimum</Flex>
            <Flex>Cover Weight</Flex>
            <Flex>Text Weight</Flex>
            <Flex>M110</Flex>
        </Flexed>
        <Flexed>
            {
                cutter.map(cut => {
                  return <> <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={cut.runRate} onBlur={e => updateit(e,cut,'runRate','cuts')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={cut.minimum} onBlur={e => updateit(e,cut,'minimum','cuts')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={cut.factorCover} onBlur={e => updateit(e,cut,'factorCover','cuts')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={cut.factorText} onBlur={e => updateit(e,cut,'factorText','cuts')} /></Flex>
                    <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={cut.m110} onBlur={e => updateit(e,cut,'m110','cuts')} /></Flex>

                    </>
                })
            }
           
        </Flexed>
    </Wrap>
  )
}

export default Cutters