import React, { useEffect, useState } from 'react'
import { Flex, Flexed, Input, MainWrap, Scroll, Selectbox, currency } from '../../utilities/Shared'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import axios from 'axios'
import { Link, useNavigate } from 'react-router-dom'
import Moment from 'react-moment'

const Shipping = () => {

     const [search, setSearch] = useState("")
     const [activejobs, setActivejobs] = useState([])
     const [salesreps, setSalesreps] = useState({})
     const [reset, setReset] = useState(false)
     const[order, setOrder] = useState("ASC")
     const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
    }

    const nav = useNavigate()
useEffect(() => {
    const getactive = async() => { 
        await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getallactivejobs.php',config)
        .then(res => {setActivejobs(res.data)})
        .catch(error => { console.log(error)})
            }
            const getsales = async() => {
                await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
                .then(res => {setSalesreps(res.data)})
                .catch(err => console.log(err))
            }
            getactive()
            getsales()
},[reset])

const getemployeebyoldid = (id) => {
    const r = salesreps.filter(rep => rep.empId === id)
    return r[0]?.firstName+' '+r[0]?.lastName.substr(0,1)
        }


        console.log(activejobs)

      const setstatus = (e,job) => {
const rr = {
  table:'jobs',
  field:'shipstatus',
  value:e.target.value,
  id:job.id,
}
console.log(rr)
        const setshipstatus = async() => {
          await axios.post('https://highgroundsoftware.com/dash/dashboard/php/updatedynamic.php',rr,config)
          // await axios.get('https://highgroundapi.herokuapp.com/employee/sales')
          .then(res => {console.log(res.data);setReset(!reset)})
          .catch(err => console.log(err))
      }
      setshipstatus()


      }




  return (
    <MainWrap>
        <Flexed  padding="30px 10px">
            <Flex fontSize="calc(1.64rem + .2vw)">Shipping</Flex>
        </Flexed>
       
         <Flexed margin="0px 0px 20px 0px"><Flex flex="2"><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} /></Flex>
<Flex></Flex>
      </Flexed>


      <Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Active Jobs - {activejobs?.length}</Flex><Flex fontSize="calc(1.1rem + .4vw)" textAlign="right"></Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">

  <Flex flex=".5" fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>Job#</Flex>
  
  <Flex fontSize="var(--headerMedium)" onClick={e => sortit('custpo',order,activejobs,setActivejobs,setOrder)}>Cust PO#</Flex>
  <Flex flex="1.5" fontSize="var(--headerMedium)" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Customer</Flex>
  <Flex  flex="3" fontSize="var(--headerMedium)" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Job Name</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Quantity</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('dateentered',order,activejobs,setActivejobs,setOrder)}>Entered</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('duedate',order,activejobs,setActivejobs,setOrder)}>Due</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('salesrep',order,activejobs,setActivejobs,setOrder)}>Sales Rep</Flex>
  <Flex >Status</Flex>
</Flexed>
<Scroll  height="400px" border="1px solid #303030">
{
 activejobs?.filter(item => {return search.toLowerCase() === '' ? item : item.job?.toLowerCase().includes(search.toLowerCase())}).map(job => {
    return <Flexed className="normalrow hand" padding="5px" alignItems="center" >
      <Flex flex=".5" onClick={() => nav(`/jobticket/${job.job}`,{ replace: true })}>{job.job}</Flex>
      <Flex ><Link to={`/whitesheet/${job.job}`}>{job.custpo}</Link></Flex>
      <Flex flex="1.5"><Link to={`/whitesheet/${job.job}`}>{job.customer}</Link></Flex>
      <Flex flex="3"><Link to={`/whitesheet/${job.job}`}>{job.jobname}</Link></Flex>
      <Flex><Link to={`/whitesheet/${job.job}`}>{job.quantity === "0" ? job.origquantity : job.quantity}</Link></Flex>
      <Flex><Link to={`/whitesheet/${job.job}`}><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Link></Flex>
      <Flex><Link to={`/whitesheet/${job.job}`}><Moment format="MM/DD/YYYY">{job.duedate}</Moment></Link></Flex>
      <Flex><Link to={`/whitesheet/${job.job}`}>{getemployeebyoldid(job.salesrep)}</Link></Flex>
      <Flex>
      <Selectbox value="" onChange={e => setstatus(e,job)}>
      <option value="">Open</option>
        <option value="Shipped">Shipped</option>
        <option value="Mailed">Mailed</option>
      </Selectbox>
      </Flex>
      </Flexed>
 
  })
}
</Scroll>

<Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Outside Service Jobs - {activejobs?.length}</Flex><Flex fontSize="calc(1.1rem + .4vw)" textAlign="right"></Flex></Flexed>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">

  <Flex flex=".5" fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>Job#</Flex>
  
  <Flex fontSize="var(--headerMedium)" onClick={e => sortit('custpo',order,activejobs,setActivejobs,setOrder)}>Cust PO#</Flex>
  <Flex flex="1.5" fontSize="var(--headerMedium)" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Customer</Flex>
  <Flex  flex="3" fontSize="var(--headerMedium)" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Job Name</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Quantity</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('dateentered',order,activejobs,setActivejobs,setOrder)}>Entered</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('duedate',order,activejobs,setActivejobs,setOrder)}>Due</Flex>
  <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('salesrep',order,activejobs,setActivejobs,setOrder)}>Sales Rep</Flex>
  <Flex flex=".1"></Flex>
</Flexed>


    </MainWrap>
  )
}

export default Shipping