import { styled } from "styled-components";
import { Button, Flex, Flexed, Input, Scroll } from "../utilities/Shared";
import SidenavItem from "../components/SidenavItem/SidenavItem";
import { useState } from "react";
import { useNavigate } from "react-router-dom";


export const MainWrap = styled.div`
width:var(--sideNav);
position:relative;
padding:80px 10px 10px 10px;
min-height:100%;
position:fixed;
min-height:100vh;
background-color:var(--plsBlue);
color:white;
min-width:180px;
max-width:240px;
`;

const Sidenav = ({showchat,setShowchat,setnew}) => {

const [searchterm, setSearchterm] = useState()

const nav = useNavigate()

const submit =() => {
  nav(`/search/${searchterm}`)
}
  return (
    <MainWrap>
 
<form onSubmit={submit}>

    <Flexed flexDirection="column" padding="10px 0px">
  <Flex fontSize="var(--headerMedium)" >Search</Flex>
  <Flex><Input type="text" placeholder="Search...." onChange={e => setSearchterm(e.target.value)}/></Flex>
  <Flex><Button type="submit" fontSize="var(--headerLarge)" bgcolor="#b2b2b2"  disabled={searchterm === undefined  ? true : false}>Find</Button></Flex>
  </Flexed>
  </form>
  <div style={{padding:"0px 10px"}}>
   
<SidenavItem showchat={showchat} setShowchat={setShowchat} setnew={setnew}/>

</div>



    </MainWrap>
  )
}

export default Sidenav