import {useState,useEffect, useContext} from 'react'
import { Button, Datepick, Flex, Flexed, Input, Label, PhoneNumber, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import States from '../../utilities/States'
import {SalesReps} from '../../utilities/Reps'
import {CSRReps} from '../../utilities/Reps'
import BillingTerms from '../../utilities/BillingTerms'
import { v4 as uuidv4 } from 'uuid';
import axios from 'axios'
import { toast } from 'react-toastify'
import UserContext from '../../Context/UserContext'
import { useNavigate } from 'react-router-dom'
const NewEmployee = ({onCancel,setReset,reset}) => {

const [info, setInfo] = useState({state:"FL",status:true,hiredate:null,birthDate:null,disDate:null})
const [disalesave, setDisalesave] = useState(false)
const [addbuttongoaway, setAddbuttongoaway] = useState(false)
const {userInfo, resetUser } = useContext(UserContext)
const nav = useNavigate()



const addCustomer = async(e) => {
e.preventDefault()


await axios.post('https://highgroundapi.herokuapp.com/employee/add',info)
//axios.post('http://localhost:5003/employee/add',info)
.then(res => {console.log(res.data);nav(`/employees/${res.data.user._id}`)})
.catch(err => console.log(err))

console.log(info)
}




const updateDates = (date,field) => {
  switch(field){
      case 'birthday':
        
          setInfo({...info,birthDate:new Date(date)})
          break;
          case 'hire':
             
              setInfo({...info,hireDate:new Date(date)})
              break;
             
          default:
  }
  }

console.log(info)

  return (
    <Wrap>
        <form onSubmit={addCustomer}>
   <Flexed>
    <Flex><Label>First Name</Label><Input value={info?.firstName} onChange={e => setInfo({...info,firstName:e.target.value})} required/></Flex>
    <Flex><Label>Last Name</Label><Input value={info?.lastName} onChange={e => setInfo({...info,lastName:e.target.value})} required/></Flex>
    <Flex><Label>Middle Name</Label><Input value={info?.middleName} onChange={e => setInfo({...info,middleName:e.target.value})}/></Flex>
    <Flex><Label>Preferred Name</Label><Input value={info?.preferredName} onChange={e => setInfo({...info,preferredName:e.target.value,userName:e.target.value})} required/></Flex>
   </Flexed>
   <Flexed>
   <Flex flex="2"><Label>Addreess 1</Label><Input value={info?.address1} onChange={e => setInfo({...info,address1:e.target.value})}/></Flex>
    <Flex  flex="2"><Label>Addreess 2</Label><Input value={info?.address2} onChange={e => setInfo({...info,address2:e.target.value})}/></Flex>
    <Flex flex="1.5"><Label>City</Label><Input value={info?.city} onChange={e => setInfo({...info,city:e.target.value})}/></Flex>
    <Flex><Label>State</Label><States value={info?.state} onChange={e => setInfo({...info,state:e.target.value})}/></Flex>
    <Flex flex=".5"><Label>Zip</Label><Input value={info?.zip} onChange={e => setInfo({...info,zip:e.target.value})}/></Flex>
   </Flexed>
   <Flexed>
   <Flex><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7" margin="10px 0px">Date of Birth</Label><br /><Datepick selected={info.birthDate} onChange={e => updateDates(e,'birthday')} required/></Flex>
   
   <Flex ><Label fontSize="calc(.4rem + .5vw)" fontWeight="bold" color="#648DA7">Hire Date</Label><br /><Datepick selected={info.hireDate} onChange={e => updateDates(e,'hire')} disabled={userInfo.auth?.includes('600') ? false : true} required/></Flex>
   <Flex><Label>Status</Label>   <Selectbox value={info.status} onChange={e => setInfo({...info,status:e.target.value})} disabled={userInfo.auth?.includes('600') || userInfo.auth?.includes('106')? false : true}>
    <option value={true}>Active</option>
    <option value={false}>Inactive</option>
   </Selectbox></Flex>
    
   <Flex flex="2"><Label>Main Number</Label><Input value={PhoneNumber(info?.cell)} onChange={e => setInfo({...info,cell:e.target.value})} /></Flex>

   <Flex><Label>Gender</Label>   <Selectbox value={info.sex} onChange={e => setInfo({...info,sex:e.target.value})} disabled={userInfo.auth?.includes('600') || userInfo.auth?.includes('106')? false : true} required>
   <option value=""></option>
    <option value="m">Male</option>
    <option value="f">Female</option>
   </Selectbox></Flex>
    
   </Flexed>
<Flexed justifyContent="flex-end"><Button type="button" margin="20px 30px 0px 0px" onClick={onCancel}>Cancel</Button><Button margin="20px 0px 0px 0px" disabled={disalesave} disaled={addbuttongoaway}>Save Employee</Button></Flexed>
</form>
   </Wrap>

  )
}

export default NewEmployee