import React, { useEffect, useState } from 'react'
import { Flex, Flexed, Input, MainWrap, PhoneNumber, currency } from '../../../utilities/Shared'
import { IoMdAdd, IoMdAddCircle } from 'react-icons/io'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useNavigate,useParams } from 'react-router-dom'
import axios from 'axios'
import EditVendor from './EditVendor'
import Modal from '../../../modals/Modal'
import {useCollapse} from 'react-collapsed';
import {LiaMinusSolid} from 'react-icons/lia'
import Collapse from '../../../utilities/Collapse'
import Moment from 'react-moment'




const Vendor = () => {
    const [isExpanded, setExpanded] = useState(false)
    const { getCollapseProps, getToggleProps } = useCollapse();
    const [vendorinfo, setVendorinfo] = useState()
    const [editVendor, setEditVendor] = useState(false)
    const [reset, setReset] = useState(false)
    const [poslist, setPolist] = useState([])
    const [positems, setItems] = useState([])
    const [search, setSearch] = useState("")
    const [newlist, setNewlist] = useState([])
    const nav = useNavigate()
const param = useParams()


useEffect(() => {
const y = () => {
axios.get(`https://highgroundapi.herokuapp.com/vendor/vendor/${param.id}`)
.then(res => {setVendorinfo(res.data)})
.catch(err => console.log(err))
}
const z = () => {
    const t = {"id":param.id}
    //axios.get(`https://highgroundapi.herokuapp.com/vendor/vendor/${param.id}`)
    axios.post(`https://highgroundapi.herokuapp.com/po/vendor/get/all`,t)
    .then(res => {console.log(res.data);setPolist(res.data)})
    .catch(err => console.log(err))
    }
y()
z()
},[reset])


  return (
    <MainWrap>
            <Modal show={editVendor} header={`Edit Vendor`} onCancel={() => {setEditVendor(false)}} ><EditVendor onCancel={() => {setEditVendor(false)}}  reset={reset} setReset={setReset} vendorinfo={vendorinfo}/></Modal>

    <Flexed color="#418DA7" margin="0px" fontWeight="600">
    <Flex fontSize="calc(.7rem + .4vw)" flex="3">Vendor # {vendorinfo?.company.company_id}</Flex>
    <Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="2">Main Address</Flex>
    <Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Main Phone</Flex>
    <Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)">Contact</Flex>
   {/* <Flex padding="0px 0px 0px 0px" fontSize="calc(.7rem + .4vw)" flex="1.5">Email</Flex>*/}
    </Flexed>
    
    <Flexed color="rgb(0,0,0,.7)" padding="0px" >
    <Flex flex="3" fontSize="calc(1.4rem + .4vw)" fontWeight="600" >{vendorinfo?.company.name}<br /><span className="hand"  onClick={e => {setEditVendor(true)}} style={{bottom:"0px",width:"100%",position:"relative",fontWeight:"400",fontSize:"calc(.4rem + .4vw)",color:"limegreen",display:"block",}}>
    <GiHamburgerMenu style={{fontSize:"1rem",position:"relative",bottom:"-3px"}} className="hand" />&nbsp;Edit Details</span></Flex>
    <Flex fontWeight="600" flex="2" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">
    <div>{vendorinfo?.address?.address1}</div>
{vendorinfo?.address?.address2 > "" && <div>{vendorinfo?.address?.address2}</div>}
<div>{vendorinfo?.address?.city && vendorinfo?.address?.city+', '}{vendorinfo?.address?.state}&nbsp;{vendorinfo?.address?.zip}</div>

    </Flex>
    <Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{PhoneNumber(vendorinfo?.numbers?.main)}</Flex>
    <Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)">{vendorinfo?.contact}</Flex>
   {/*} <Flex fontWeight="600" padding="6px 0px 0px 0px" fontSize="calc(.6rem + .4vw)" flex="1.5"><span className="hand" onClick={() => window.location = `mailto:${vendorinfo?.email}`}>{vendorinfo?.email}</span></Flex>*/}
    </Flexed>
    
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="25px 0px 0px 0px">
        <Flex textAlign="center">Today</Flex>
        <Flex textAlign="center">This Week</Flex>
        <Flex textAlign="center">Last Week</Flex>
        <Flex textAlign="center">This Month</Flex>
        <Flex textAlign="center">QTD</Flex>
        <Flex textAlign="center">YTD</Flex>
    </Flexed>
    <Flexed padding="3px 0px" fontWeight="bold"  color="rgb(0,0,0,.7)" bgColor="white">
        <Flex textAlign="center">{currency(0)}</Flex>
        <Flex textAlign="center">{currency(0)}</Flex>
        <Flex textAlign="center">{currency(0)}</Flex>
        <Flex textAlign="center">{currency(0)}</Flex>
        <Flex textAlign="center">{currency(0)}</Flex>
        <Flex textAlign="center">{currency(0)}</Flex>
    </Flexed>
    

  
    
    <Flexed bgColor="#8398AB" padding="3px 0px" fontWeight="bold" color="white" margin="50px 0px 10px 0px">
        <Flex >P.O.'s&nbsp; &nbsp;&nbsp;{poslist.length}</Flex><IoMdAddCircle onClick={() => nav('/pos/new')}/>
    </Flexed>

    <Flexed margin="0px 0px 20px 0px"><Input type="text" value={search} placeholder="Search" onChange={e => setSearch(e.target.value)} />
      </Flexed>

    <Flexed style={{borderTop:"1px solid black",padding:"2px",backgroundColor:"white",color:"var(--plsBlue)",fontWeight:"bold"}}>
<Flex>Po #</Flex>
<Flex>Quantity</Flex>
<Flex flex="3">Description</Flex>
<Flex>Job #</Flex>
<Flex>Ordered</Flex>
<Flex>Unit Cost</Flex>
<Flex>Total</Flex>
</Flexed>
    {
/*
poslist.map((element) => {
    return {...element, subElements: element.items.filter((subElement) => subElement.toString().includes(search))}
  })
*/
poslist.filter(product => product.items?.some(cat => cat.name?.toLowerCase().includes(search.toLowerCase())) || product.items.some(cat => cat.jobnum?.toLowerCase().includes(search.toLowerCase())) || product.ponum?.toString().includes(search))
//poslist?.filter(ite => {return search?.toLowerCase() === '' ? ite : ite.ponum.toString().includes(search) || poslist.filter(product => product.items.some(cat => cat.jobnum === "90366"))} )
.map(item => {
return   <>

 {item.items.length > 1 ?  <div  className="hand" style={{background:"#CFCDCC",marginBottom:"15px",border:"1px solid #c0c0c0",padding:"4px 0px"}} ><Collapse  margin="0" title={<><IoMdAdd style={{paddingTop:"5px",fontSize:"calc(1rem + .4vw)",color:"green",fontWeight:"bold"}} />&nbsp;&nbsp;<span onClick={() => nav(`/pos/ponew/${item?._id}`)}>{item.ponum}</span>   --  {item.items?.length} <span >item(s)</span></>} >
      <Flexed margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}}>
      <Flex></Flex>
<Flex>Quantity</Flex>
<Flex flex="3">Description</Flex>
<Flex>Job #</Flex>
<Flex>Ordered</Flex>
<Flex>Unit Cost</Flex>
<Flex>Total</Flex>
      </Flexed>
    
  {item.items.map(ite => {
      return   <Flexed  margin="0" style={{borderTop:"1px solid black",padding:"4px",background:"white",color:"var(--plsBlue)",}}> 
      <Flex></Flex>
<Flex>{ite.quantity}</Flex>
<Flex flex="3">{ite.name}</Flex>
<Flex onClick={e => alert()}>{ite.jobnum}</Flex>
<Flex ><Moment format = "MM/DD/YYYY">{ite.orderdate}</Moment></Flex>
<Flex>{ite.cost}&nbsp;{ite.orderby}</Flex>
<Flex>{ite.extprice}</Flex>
      </Flexed>
     /* <Flexed style={{border:"1px solid red"}}><Flex >
                  <Flex style={{border:"1px solid red"}}>{ite.jobnum}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.quantiy}</Flex>
                  <Flex style={{border:"1px solid red"}} flex="3">{ite.itemname}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.unitprice}</Flex>
                  <Flex style={{border:"1px solid red"}}>{ite.total}</Flex>
                  </Flex>
                  </Flexed> */
     }) }
</Collapse> </div>  :
 <> 
 <Flexed style={{padding:"4px",marginBottom:"15px"}} bgColor="#CFCDCC" className="hand">
 <Flex onClick={() => nav(`/pos/ponew/${item?._id}`)}>{item.ponum}</Flex>
                  <Flex>{item.items[0].quantity}</Flex>
                  <Flex flex="3">{item.items[0].name}</Flex>
                  <Flex>{item.items[0].jobnum}</Flex>
                  <Flex><Moment format = "MM/DD/YYYY">{item.items[0].orderdate}</Moment></Flex>
                  <Flex>{item.items[0].unitprice}&nbsp;{item.items[0].priceby}</Flex>
                  <Flex>{currency(item.items[0].extprice)}</Flex>
      </Flexed>
      </>
}





</>
}).reverse()
/*
poslist.reverse().map(item => {
      item.items.length > 1  ?  <Flex className="hand" style={{background:"rgb(0,0,0,.4)",padding:"4px",color:"white",marginBottom:"8px"}} ><Collapse   title={<>{item.ponum}   --  {item.items?.length} <span style={{textTransform:"lowercase"}}>item(s)</span></>} >
      <Flexed style={{borderTop:"1px solid black",padding:"4px"}}>
          <Flex>Jobnum</Flex>
          <Flex>Quantity</Flex>
          <Flex flex="3">Description</Flex>
          <Flex>Unit Cost</Flex>
          <Flex>Per</Flex>
          <Flex>Total</Flex>
      </Flexed>
    
  {item.items.length > 1 ?  item.items.map(ite => {
      return     <Flexed style={{background:"lightgrey",padding:"4px",color:"black"}} >
                  <Flex>{ite.jobnum}</Flex>
                  <Flex>{ite.quantiy}</Flex>
                  <Flex flex="3">{ite.itemname}</Flex>
                  <Flex>{ite.unitprice}</Flex>
                  <Flex>{ite.total}</Flex>
                  </Flexed>
     }) :   <Flexed style={{background:"lightgrey",padding:"4px",color:"black"}}>
                 <Flex>{item.items[0].jobnum}</Flex>
                 <Flex>{item.items[0].quantiy}</Flex>
                 <Flex flex="3">{item.items[0].itemname}</Flex>
                 <Flex>{item.items[0].unitprice}</Flex>
                 <Flex>{item.items[0].pricingunit}</Flex>
                 <Flex>{item.items[0].total}</Flex>
                 </Flexed>}
</Collapse>  </Flex> : ""
    
    })
*/
    }

 
  


    {/*
   
     <label>
        <input type="checkbox"/> Use dark theme
    </label>
   
    })  }</Flex>
    
    
    
    
    
    
    
    
    
    
    
    
    allpos?.length > 0 && allpos?.map(item => {
    return(
     <Flexed onClick={() => nav(`/po/${item._id}`)} bgColor="white" padding="3px 0px"  color="rgb(0,0,0,.7)"  margin="0px" className="hand" borderBottom="1px solid rgb(0,0,0,.5)"  >
        <Flex textAlign="left" flex=".1">{item.poid}</Flex>
        <Flex textAlign="left">
        {item.items?.map(ite => {return (
        <>{ite.name}<br />
        </>
        )})
    }
        
        </Flex>
        
    </Flexed>
    )
    })*/}
    </MainWrap>
  )
}

export default Vendor