import {useState,useEffect} from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import States from '../../utilities/States'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify'
import { CSRReps, SalesReps } from '../../utilities/Reps'
import { cssTransition ,Flip} from 'react-toastify';
import { ImFilePdf } from "react-icons/im";
import Taxexem from './Taxexem';
import Modal from '../../modals/Modal';
import { FaRegFilePdf } from "react-icons/fa";
import { FaRegImage } from "react-icons/fa6";
import Moment from 'react-moment';



const EditCustomer = ({onCancel,customer,reset,setReset}) => {

const [customerinfo, setCustomerinfo] = useState()
const [main, setMain] = useState(1)
const [old, setOld] = useState("")
const [taxex, setTaxex] = useState(false)





const bounced = cssTransition({
  enter: "animate__animated animate__bounceIn",
  exit: "animate__animated animate__bounceOut"
});



    useEffect(() => {  
        const y = async() => {
            await axios.get(`https://highgroundapi.herokuapp.com/customers/item/${customer?._id}`)
        .then(res => {console.log(res.data);setCustomerinfo(res.data)})
        .catch(err => console.log(err))
        }
        const z = () => {
 
            //axios.get(`https://highgroundapi.herokuapp.com/vendor/vendor/${param.id}`)
            //axios.post(`https://highgroundapi.herokuapp.com/po/vendor/get/all`,t)
            //.then(res => {console.log(res.data)})
            //.catch(err => console.log(err))
            }
        y()
        //z()
        },[])

        useEffect(() => {
      const t = () => {
        let y= customerinfo?.address?.filter(item => item.itemid === customerinfo?.mainlocation)

      y && setOld(y[0]?.name)
      }
      
t()
},[customerinfo?.mainlocation])




        const saveedit = async(e) => {
            e.preventDefault()

 
         

            let r = {
                id:customerinfo._id,
                name:customerinfo.name,
         website:customerinfo.website,
         mainphone:customerinfo.mainphone,
         fax:customerinfo.fax,
         billingterms:customerinfo.terms,
         taxexempt:customerinfo.taxexempt,
         taxid:customerinfo.taxid,
         notes:customerinfo.notes,
         maincontact:customerinfo.maincontact,
         mainlocation:customerinfo.mainlocation,
         salesrep:customerinfo.salesrep,
         custrep:customerinfo.custrep,
         pros:customerinfo.pros,
         dba:customerinfo.dba,
         overs:customerinfo.overs,
         unders:customerinfo.unders,
         paymentmethod:customerinfo.paymentmethod,
         issusespos:customerinfo.issusespos

            }
            console.log(r)
        await axios.post(`https://highgroundapi.herokuapp.com/customers/update/customer`,r)
         //await axios.post(`http://localhost:5003/customers/update/customer`,r)

        .then(res => {slip(res.data)})
         .catch(err => console.log(err))
        }

        const slip = async(info) => {
          let ma
const r ={id:customerinfo.mainlocation}
          await axios.post(`https://highgroundapi.herokuapp.com/customers/getmainlocation`,r)
          .then(res => {
           ma =  res.data.address?.filter(item => item.itemid === customerinfo.mainlocation)
            })
           .catch(err => console.log(err))
          
            const config = {
                headers: {
                  'Content-Type': 'application/json',
                  'Access-Control-Allow-Origin':'*' 
                }
              };   
              
         
              const tr = {
                companyid:info.company_id,
                name:info.name,
                phone:info.mainphone,
                 notes:info.notes,
                 website:info.website,
                 custrep:info.custrep,
                 salesrep:info.salesrep,
                 taxexempt:info.taxexempt,
                 billingterm:info.billingterms,
                 pros:info.pros,
                 nickname:info.dba
              //  address1:ma[0]?.address1,
              //  address2:ma[0]?.address2,
              //  city:ma[0]?.city,
              //  state:ma[0]?.state,
              //  zip:ma[0]?.zip,
          }
        
         
          
          console.log(tr)
         axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatecustomer.php`,tr,config)
         .then(res => {console.log(res.data);console.log(res.data);setmain()})
         .catch(err => toast.error(err))
        }


        const setmain = async() => {
    const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };   
          
          const tr = {
         company:customerinfo.company_id,
           id:old,
      }
      console.log(tr)

       await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatemainlocation.php`,tr,config)
          .then(res => {console.log(res.data);toast.success('Saved', {
            transition: Flip
          });setReset(!reset);onCancel()})
          .catch(err => toast.error(err))

        }






console.log(customerinfo)
  return (
    <Wrap bgcolor="white" >
        <Modal show={taxex} headerbgcolor="#8498AC" width="75%" headercolor="#144259" header={`Edit Details`} onCancel={() => {setTaxex(false)}} ><Taxexem onCancel={() => {setTaxex(false)}}  reset={reset} setReset={setReset} taxform={customer.dr13}/></Modal>
        <form onSubmit={saveedit}>

<Flexed  style={{paddingTop:"10px"}}>
  <Flex flex="1.3">
  <Flexed color="#237C9C" style={{paddingTop:"10px",paddingRight:"100px"}} gap="20px" >
    <Flex ><Label margin="0px 0px 8px 0px"  color="#237C9C" fontSize="calc(.9rem + .2vw)">Customer Name</Label><br /><Input fontSize="calc(.9rem + .2vw)" value={customerinfo?.name} onChange={e => setCustomerinfo({...customerinfo,name:e.target.value})} /></Flex>
    <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">DBA</Label><Input fontSize="calc(.9rem + .2vw)" value={customerinfo?.dba} onChange={e => setCustomerinfo({...customerinfo,dba:e.target.value})} /></Flex>
   </Flexed>

<Flexed color="#237C9C" style={{paddingTop:"10px",paddingRight:"100px"}}>
<Flex >
<Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">Main Location</Label><br />
<Selectbox value={customerinfo?.mainlocation} onChange={e => setCustomerinfo({...customerinfo,mainlocation:e.target.value})} >
<option value="">Select Main Location</option>
 {customerinfo?.address?.map((item,index) => {
return   <option value={item.itemid}>{item.name} - {item.address1} {item.address2} {item.city} {item.state} {item.zip}</option>
})
}</Selectbox>
</Flex>
</Flexed>
<Flexed color="#237C9C" style={{paddingTop:"10px",paddingRight:"100px"}}>
<Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Main Contact</Label>  <Selectbox value={customerinfo?.maincontact} onChange={e => setCustomerinfo({...customerinfo,maincontact:e.target.value})} >
<option value="">Select Main Contact</option>
 {customerinfo?.contacts.map((item,index) => {
return   <option value={item.contactid}>{item.name}</option>
})
}</Selectbox></Flex>
<Flex ></Flex>
</Flexed>
<Flexed color="#237C9C" style={{paddingTop:"10px"}}>

  <Flex flex=".7"><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Notes</Label> <br /><Textarea fontSize="calc(.9rem + .2vw)" rows="7" value={customerinfo?.notes} onChange={e => setCustomerinfo({...customerinfo,notes:e.target.value})}></Textarea></Flex> 
  <Flex flex="1.2" >
<Flexed fontSize="calc(.9rem + .2vw)" padding="15px 0px 4px 0px">
  <Flex flex=".6"> Billing Terms</Flex>
  <Flex color="black">{customer?.billingterms}</Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" padding="4px 0px">
  <Flex flex=".6">FEIN #</Flex>
  <Flex color="black">{customer?.fein}</Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" padding="4px 0px">
  <Flex flex=".6">Tax Exempt #</Flex>
  <Flex color="black">{customer?.taxid ? customer?.taxid : ''}</Flex>
</Flexed>
<Flexed fontSize="calc(.9rem + .2vw)" padding="4px 0px">
  <Flex flex=".6">Business Type</Flex>
  <Flex color="black">{customer?.type}</Flex>
</Flexed >
<Flexed fontSize="calc(.9rem + .2vw)" padding="4px 0px">
  <Flex flex=".6">Cust. Form Agrmt</Flex>
  <Flex color="black">{customer?.signaturedate && <><Moment format="MM/DD/YYYY" style={{fontSize:"calc(.9rem + .2vw)"}}>{customer?.signaturedate}</Moment> :: {customer?.signature}</>}</Flex>
</Flexed>
  
      </Flex>
 
</Flexed>


  <div  style={{paddingTop:"10px"}} onClick={()=> window.open(`https://highgroundsoftware.com/dash/dashboard/production/taxIds/${customer.dr13}`, "_blank")} >
{

customer.dr13?.split('.').pop() === "pdf" ?  <FaRegFilePdf style={{fontSize:"3rem",color:"#0E425A"}} />  : <>&nbsp;&nbsp;<FaRegImage style={{fontSize:"3rem",color:"#0E425A"}} /></> 
    
}

  </div>

  

  


</Flex>


  <Flex style={{paddingLeft:"20px"}}>
    <Flexed color="#237C9C" style={{paddingTop:"10px"}} gap="20px">
      <Flex >
        <Flexed color="#237C9C" >
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Payment Method</Label>  <Selectbox value={customerinfo?.paymentmethod} onChange={e => setCustomerinfo({...customerinfo,paymentmethod:e.target.value})} >
        <option value="">Select Method</option>
        <option value="Credit Card">Credit Card</option>
	<option value="Check">Check</option>
    <option value="ACH/Wire">ACH/Wire</option>
	<option value="Cash">Cash</option>
 </Selectbox></Flex>
        </Flexed>
        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Issues Purchase Orders</Label>  <Selectbox value={customerinfo?.issusespos} onChange={e => setCustomerinfo({...customerinfo,issusespos:e.target.value})} >
        <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
 </Selectbox></Flex>
        </Flexed>
        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Accepts Overs</Label>  <Selectbox value={customerinfo?.overs} onChange={e => setCustomerinfo({...customerinfo,overs:e.target.value})} >
        <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
 </Selectbox></Flex>
        </Flexed>
        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Accepts Unders</Label>  <Selectbox value={customerinfo?.unders} onChange={e => setCustomerinfo({...customerinfo,unders:e.target.value})} >
        <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
 </Selectbox></Flex>
        </Flexed>
        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)"> Tax Exempt</Label>  <Selectbox value={customerinfo?.taxexempt} onChange={e => setCustomerinfo({...customerinfo,taxexempt:e.target.value})} >
        <option value="">Select Option</option>
    <option value="No">No</option>
	<option value="Yes">Yes</option>
 </Selectbox></Flex>
        </Flexed>



      </Flex>






      <Flex >
        <Flexed color="#237C9C" >
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">Sales Rep</Label><SalesReps value={customerinfo?.salesrep?._id} onChange={e => setCustomerinfo({...customerinfo,salesrep:e.target.value})}/></Flex>
        </Flexed>

        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">CSR</Label><CSRReps value={customerinfo?.custrep?._id} onChange={e => setCustomerinfo({...customerinfo,custrep:e.target.value})}/></Flex> 
        </Flexed>

        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">&nbsp;</Label><br /><br /><br /></Flex> 
        </Flexed>
        
     
        <Flexed color="#237C9C" style={{paddingTop:"12px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">Status</Label><Selectbox value={customerinfo?.pros} onChange={e => setCustomerinfo({...customerinfo,pros:e.target.value})} >
        <option value="0">Prospect</option>
    <option value="1">Customer</option>

 </Selectbox></Flex> 
        </Flexed>
        <Flexed color="#237C9C" style={{paddingTop:"10px"}}>
        <Flex ><Label margin="0px 0px 8px 0px"   color="#237C9C" fontSize="calc(.9rem + .2vw)">Tax Exempt #</Label><br /><Input type="text" value={customerinfo?.taxid} onChange={e => setCustomerinfo({...customerinfo,taxid:e.target.value})} /></Flex> 
        </Flexed>

      </Flex>
    </Flexed>
  </Flex>
</Flexed>



<Flexed color="#237C9C" style={{padding:"10px"}} justifyContent="flex-end"> <Button  hcolor="white" color="#237C9C"  padding="7px 30px" fontSize="calc(.9rem + .2vw)"  type="button" margin="0px 20px 0px 0px" onClick={onCancel}>Cancel</Button><Button hcolor="white" color="#237C9C"  padding="7px 30px" fontSize="calc(.9rem + .2vw)" >Save</Button></Flexed>
</form>
   </Wrap>

  )
}

export default EditCustomer