
import { createContext, useState, useEffect, useContext } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client'
import moment from "moment";
import { useParams } from "react-router-dom";
import PressContext from "./PressContext";
import { convertprintstyle } from "../utilities/Convertions";
import { v4 as uuidv4 } from 'uuid';
import {sortnum} from '../utilities/Sort'


export const EstimateContext = createContext();

export const EstimateProvider = ({children}) => {

    const [reset, setReset] = useState()
    const [jobinfo, setJobinfo] = useState({})
    const [pressEstTotal, setPressEstTotal] = useState(0)
    const [quote, setQuote] = useState({})
    const [forms, setForms] = useState([])
    const [parentSizes, setParentSizes] = useState([])
    const [origrfq, setOrigrfq] = useState({})
    const [papers, setPapers] = useState([])
    const param = useParams()
    const [quantities, setQuantities] = useState([])
    const config = {
      headers: {
        'Content-Type': 'application/json',
        'Access-Control-Allow-Origin':'*' 
      }
    }; 

   const  parentsizes=[
      {id:0,
        width:28,
        length:40
      },
      {id:0,
        width:28,
        length:40
      },
      {id:0,
        width:28,
        length:40
      },
      {id:0,
        width:28,
        length:40
      },
      {id:0,
        width:28,
        length:40
      },
      {id:0,
        width:28,
        length:40
      }

    ]





    useEffect(() => {
      const getquote = () => {
        let r = {
          quote:param.quote
        }
      axios.post(`http://localhost:5003/rfq/getrfq`,r)
      .then(res => {
        if(res.data.length === 0){
         getoldquote()
        }else{
          setQuote(res.data[0])
        }
      })
      .catch(err => {console.log(err);})
      }
      const getPapers = async() => {
       // await axios.get(`https://highgroundapi.herokuapp.com/paper/all`)
        await axios.get(`http://localhost:5003/paper/all`)
        
        .then(res => {setPapers(res.data)})
        .catch(err => console.log(err))
        }
        getPapers()
      getquote()
      },[])
      
      const getoldquote = () => {
        let stuff= []
        const t = {
          quote:param.quote
      }
   
        axios.post('https://highgroundsoftware.com/dash/dashboard/php/getrfqquote.php',t,config)
        .then(res => { 
          let quan = res.data.quantity.split(',');

         if(res.data.comp1){stuff.push(convertcomp(res.data.comp1,quan))}
         if(res.data.comp2){stuff.push(convertcomp(res.data.comp2,quan))}
         if(res.data.comp3){stuff.push(convertcomp(res.data.comp3,quan))}
         if(res.data.comp4){stuff.push(convertcomp(res.data.comp4,quan))}
         if(res.data.comp5){stuff.push(convertcomp(res.data.comp5,quan))}
         if(res.data.comp6){stuff.push(convertcomp(res.data.comp6,quan))}
         if(res.data.comp7){stuff.push(convertcomp(res.data.comp7,quan))}
         if(res.data.comp8){stuff.push(convertcomp(res.data.comp8,quan))}
         if(res.data.comp9){stuff.push(convertcomp(res.data.comp9,quan))}
         if(res.data.comp10){stuff.push(convertcomp(res.data.comp10,quan))}
  
console.log(stuff)
  
  setQuote({...res.data,forms:stuff,quantities:quan.sort()})
  // setQuantities(quan.sort())
  })
        .catch(err => {console.log(err);})
       
}

   const convertpaper  = (item) => {
    let yy = {label:item,value:""}
    let y = papers.filter(paper => paper.name === item)
if(y[0]){yy = {label:y[0].name,value:y[0]._id}}

return yy
   }


  const convertcomp = (info,quan) => {
  let arr = info.split(':');
         
  let y = {
    paper:convertpaper(arr[1]),
    componentName:arr[0],
    flatWidth:arr[4],
    flatLength:arr[5],
    pieceWidth:arr[6],
    pieceLength:arr[7],
    finks:arr[8],
    binks:arr[9],
    faq:arr[12],
    baq:arr[13],
    fbleed:arr[10],
    bbleed:arr[11],
    runon:arr[20],
    score:arr[21],
    perf:arr[22],
    diecut:arr[23],
    foldStyle:arr[24],
    pages:arr[25],
    addid:uuidv4(),
quantity:quan
  }
  return y
  }


    const updateformitem = (e,item,field) => {
    const newArray = [...forms];
    let currentIndex = newArray.findIndex((form)=>form.addid === item.addid);
    newArray[currentIndex][field] = e;
    setForms(newArray);
    }

    const updatequantity = (e,index) => {
let q = [...quote.quantities]
q[index] = e.target.value;
setQuote({...quote,quantities:q});
    }

    const removequantity = (quantity) => {
      const quans = [...quote.quantities];
      let a = quans.indexOf(quantity)
      quans.splice(a, 1)
      setQuote({...quote,quantities:quans});
      setQuantities(quans);
          }

    const addquantity = (value) => {
      const quans = [...quote.quantities];
     quans.push(value)
      setQuote({...quote,quantities:quans.sort()});
      setQuantities(quans.sort());
      }


   








  return (
    <EstimateContext.Provider value={{updateformitem,papers,quote,updatequantity,removequantity,quantities,addquantity}}>
        {children}
    </EstimateContext.Provider>
  )
}

export default EstimateContext



// card: component name
// 70# Husky Offset: paper
// 76:
// 70:
// 18: flat w
// 9: flat l
// 9: finish w
// 9: finish l
// C,M,Y,K: front
// C,M,Y,K: back
// Yes: f bleed
// Yes:b bleed
// SG: f coating
// SG:b coating
// AQ:
// AQ:
// :
// :
// :
// :
// Web:run on
// Yes: score
// Yes: perf
// Yes: die cut
// Roll Fold: folding
// 32: pages
