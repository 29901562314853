import React, { useEffect, useState } from 'react'
import { Flex, Flexed, MainWrap, Scroll } from '../../utilities/Shared'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'

const Prepress = () => {

const [activejobs, setActivejobs] = useState()
const [order, setOrder] = useState("ASC")
    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
          const getactive = async() => { 
      await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getallactivejobs.php',config)
      .then(res => {setActivejobs(res.data)})
      .catch(error => { console.log(error)})
          }
          getactive()
    },[])
  return (
    <MainWrap>
    <Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Active Jobs - {activejobs?.length}</Flex></Flexed>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
    
      <Flex fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,activejobs,setActivejobs,setOrder)}>Job#</Flex>
      <Flex flex="1.5" fontSize="var(--headerMedium)" onClick={e => sortit('customer',order,activejobs,setActivejobs,setOrder)}>Customer</Flex>
      <Flex  flex="3" fontSize="var(--headerMedium)" onClick={e => sortit('jobname',order,activejobs,setActivejobs,setOrder)}>Job Name</Flex>
      <Flex  fontSize="var(--headerMedium)" onClick={e => sortnum('origquantity',order,activejobs,setActivejobs,setOrder)}>Quantity</Flex>
      <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('dateentered',order,activejobs,setActivejobs,setOrder)}>Entered</Flex>
      <Flex  fontSize="var(--headerMedium)" onClick={e => sortdate('duedate',order,activejobs,setActivejobs,setOrder)}>Due</Flex>
      <Flex  fontSize="var(--headerMedium)" onClick={e => sortit('salesrep',order,activejobs,setActivejobs,setOrder)}>Sales Rep</Flex>
    
    </Flexed>
    <Scroll  height="78vh" border="1px solid #303030">
    {
     activejobs?.map(job => {
        return <Flexed className="normalrow hand" padding="5px">
          
          <Flex ><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Flex>
          <Flex flex="1.5">{job.customer}</Flex>
          <Flex flex="3">{job.jobname}</Flex>
          <Flex>{job.origquantity}</Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.duedate}</Moment></Flex>
          <Flex></Flex>
        
          </Flexed>
      })
    }
    </Scroll>
    </MainWrap>
  )
}

export default Prepress