import React from 'react'
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import { useParams } from 'react-router-dom'

const Quoteletter = () => {

const quote = useParams()
console.log(quote.id)


  return (
    <MainWrap>
      <Flexed style={{height:"70vh"}}>
        <Flex style={{height:"90vh"}}>
        <iframe  style={{width:"100%",height:"100%"}} src={`https://highgroundsoftware.com/dash/dashboard/estimate/quoteletter.php?job=${quote.id}`}></iframe>
        </Flex>
      </Flexed>
    </MainWrap>
  )
}

export default Quoteletter