import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Label, MainWrap } from '../../../utilities/Shared'
import axios from 'axios';
import Moment from 'react-moment'
import { useNavigate } from 'react-router-dom';
import { getemployeebynamefromempid } from '../../../utilities/Convertions';
import UserContext from '../../../Context/UserContext';

const Estimates = () => {
const [approvals, setApprovals] = useState()
const [openquotes, setOpenquotes] = useState()

const nav = useNavigate()

const {employees} = useContext(UserContext)
const today = new Date()
const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };


useEffect(() => {
 
    const getopen = async() => {
        let r = {status:"0"}   
await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getopenrfq',r,config)         
//await axios.post('http://localhost:5003/rfq/getall',r)  
.then(res => {
   setOpenquotes([res.data])
    })
.catch(err => console.log(err))
    }
    const getapprovals = async() => {
        let r = {status:"1"}       
await axios.post('http://localhost:5003/rfq/getall',r)  
.then(res => {
    setApprovals(res.data)
    })
.catch(err => console.log(err))
    }
    getapprovals()
    getopen()
},[])



console.log(openquotes)




  return (
    <MainWrap>
<Flexed padding="20px" >
      <Flex>  <Label fontSize="calc(1.8rem + .2vw)">Estimates </Label></Flex>
    </Flexed>
   
<Flexed flexDirection="column" style={{minHeight:"50vh"}}>
    <Flex>
    <Flexed bgColor="rgb(14,66,90,.5)" padding="5px" >
      <Flex fontWeight="bold" color="white">  <Label fontSize="calc(1.1rem + .2vw)"> RFQ's Ready For Approval ({approvals?.length || 0})</Label></Flex>
    </Flexed>
    <div style={{border:"1px solid rgb(0,0,0,.5)"}}>
<Flexed fontWeight="bold" padding="5px 0px" >
    <Flex>RFQ</Flex>
    <Flex>Project</Flex>
    <Flex>Customer</Flex>
    <Flex>Sales Rep</Flex>
    <Flex>CSR</Flex>
    <Flex>Enter Date</Flex>
    <Flex>Due Date</Flex>
    <Flex>Status</Flex>
    <Flex>Estimator</Flex>
</Flexed>

{
    approvals?.map(quote => {
return <Flexed  padding="5px 0px" bgColor="#ECF0F1">
    <Flex>{quote.quote}</Flex>
    <Flex>Project</Flex>
    <Flex>{quote.customer?.name}</Flex>
    {/* <Flex>{quote.salesrep?.firstName} {quote.salesrep?.lastName.substr(0,1)}</Flex>
    <Flex>{quote.csrrep?.firstName} {quote.csrrep?.lastName.substr(0,1)}</Flex> */}
    <Flex><Moment format="MM/DD/YYYY">{quote.createdAt}</Moment></Flex>
    <Flex><Moment format="MM/DD/YYYY">{quote?.duedate || today.setDate(today.getDate() + 1)}</Moment></Flex>
    <Flex>Awaiting Approval</Flex>
    <Flex>Pam B</Flex>
</Flexed>
    })
}
</div>

    </Flex>
{/*
OPEN RFQS
*/}
    <Flex>
    <Flexed bgColor="rgb(14,66,90,.5)" padding="5px" >
      <Flex fontWeight="bold" color="white">  <Label fontSize="calc(1.1rem + .2vw)"> Open RFQ's ({openquotes?.length})</Label></Flex>
    </Flexed>
    <div style={{border:"1px solid rgb(0,0,0,.5)"}}>
<Flexed fontWeight="bold" padding="5px 0px" >
    <Flex>RFQ</Flex>
    <Flex>Project</Flex>
    <Flex>Customer</Flex>
    <Flex>Sales Rep</Flex>
    <Flex>CSR</Flex>
    <Flex>Enter Date</Flex>
    <Flex>Quote Due</Flex>
    <Flex>Status</Flex>
    <Flex>Estimator</Flex>
</Flexed>
{
    openquotes?.map(quote => {
return <Flexed className="hand" padding="5px 0px" bgColor="#ECF0F1" onClick={e => nav(`/estimate/${quote.quote}`)}>
    <Flex>{quote.quote}</Flex>
    <Flex>Project</Flex>
    <Flex>{quote.customer?.name}</Flex>
    <Flex>{getemployeebynamefromempid(quote?.salesrep,employees)}
        {/* {quote.salesrep?.firstName} {quote.salesrep?.lastName.substr(0,1)} */}
        </Flex>
    <Flex>{getemployeebynamefromempid(quote?.csr,employees)}
        {/* {quote.csrrep?.firstName} {quote.csrrep?.lastName.substr(0,1)} */}
        </Flex>
    <Flex><Moment format="MM/DD/YYYY">{quote.createdAt}</Moment></Flex>
    <Flex><Moment format="MM/DD/YYYY">{quote?.duedate || today.setDate(today.getDate() + 1)}</Moment></Flex>
    <Flex>Open</Flex>
    <Flex>Pam B</Flex>
</Flexed>
    })
}
</div>
    </Flex>
</Flexed>



    </MainWrap>
  )
}

export default Estimates