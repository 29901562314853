import { useNavigate } from "react-router-dom"
import { Item, ItemTop, Items, Itemwrap } from "./SidenavItemStyles"
import Modal from "../../modals/Modal"
import NewCustomer from "../../pages/Customers/NewCustomer"
import { useContext, useEffect, useState } from "react"
import UserContext from "../../Context/UserContext"
import ChatContext from "../../Context/ChatContext"
import axios from "axios"
import Newjob from "../../pages/jobs/Newjob"
import { Scroll } from "../../utilities/Shared"




const SidenavItem = ({showchat,setShowchat,setnew}) => {
  const {unread}  = useContext(ChatContext)
    const [newcustomer, setNewCustomer] = useState(false)
const { userInfo } = useContext(UserContext)
const [chatopen, setChatopen] = useState(false)
const [checkMessages, setCheckMessages] = useState(null)
const [newjob, setNewjob] = useState(null)
const [arriv, setArriv] = useState(null)
    const nav = useNavigate()


  return (
  
    <Itemwrap style={{paddingBottom:"60px"}}>
     
             <Modal  show={newcustomer} header={`Add New Prospect`} width="50%" onCancel={() => {setNewCustomer(false)}} ><NewCustomer  onCancel={() => {setNewCustomer(false)}}  /></Modal>
             <Modal  show={newjob} header={`Start New Job`} width="50%"  onCancel={() => {setNewjob(false)}} ><Newjob  onCancel={() => {setNewjob(false)}}  /></Modal>
        <ItemTop>Quick Access</ItemTop>
        <Scroll height="60vh">
            <Items>
            {userInfo.departments?.some(cat => cat.value !== "300") && <>
            {userInfo.empId === "1710" && <Item className="hand" onClick={() => nav('/sales')}>Kelly</Item>}
          <Item className="hand"><a href={`https://highgroundsoftware.com/dash/dashboard/?des=101&salesrep=${userInfo?.empId}`}>Sales Dash</a></Item> 
          {userInfo.departments?.some(cat => cat.label === "Elections") && <Item className="hand" onClick={() => nav('/elect')}>Elections</Item>}
          
                <Item className="hand"><a href="https://highgroundsoftware.com/dash/dashboard/?des=10300">New RFQ</a></Item>
             <Item className="hand"><a href="https://highgroundsoftware.com/dash/dashboard/estimate/rolljob.php" target="new">New Job</a></Item>
                <Item  className="hand" onClick={() => {setNewCustomer(true);localStorage.setItem('defaultCustomer',"2");}}>Add Prospect</Item>
            {userInfo.departments?.some(cat => cat.label === "Unlink Job") || userInfo.auth?.includes('600')   ? <Item  className="hand"><a href="https://highgroundsoftware.com/dash/dashboard/CSR/unlink.php" target="new">Unlink Job</a></Item> : ""}  

            {userInfo.auth?.includes("106") || userInfo.auth?.includes("600")  ? <Item  className="hand" onClick={() => nav('/reports')}>Reports</Item> : ""} 

            {userInfo.empId === "1648" ? <Item  className="hand" onClick={() => {setNewjob(true)}} >New Job 2</Item> : ""}   
            {userInfo.departments?.some(cat => cat.label === "Chat") && <Item  className="hand" background={`${unread.length > 0 ? "red" : ""}`} onClick={() => setShowchat(!showchat)}>Chat</Item>}</> } 
            {userInfo.auth?.includes("106") || userInfo.auth?.includes("600") || userInfo.departments?.some(cat => cat.label === "Production") || userInfo.departments?.some(cat => cat.label === "Pre-Press")?<Item className="hand" onClick={() => nav('/jobticket/new/')}>Job Ticket</Item> : null}
            </Items>
            </Scroll>
    </Itemwrap>

  )
}

export default SidenavItem



