import { createContext, useState, useEffect, useContext } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client'
import moment from "moment";
import { useParams } from "react-router-dom";
import PressContext from "./PressContext";
import { convertprintstyle } from "../utilities/Convertions";
import { v4 as uuidv4 } from 'uuid';
import {sortnum} from '../utilities/Sort'


export const Actualversusestimate = createContext();

export const ActualversusestimateProvider = ({children}) => {

    const [jobinfo, setJobinfo] = useState([])
    const [reset, setReset] = useState(false)


    const { presslist } = useContext(PressContext)

    const prepresshourly = 60



const param = useParams()

    useEffect(() => {

const getcurrent = async() => {
    let r = {'jobnum':param.job.replace(/\D/g, "")}
    console.log(r)
   await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
    //await axios.post(`https://highgroundapi.herokuapp.com/jobs/find`,r)
   .then(res => { 
    setJobinfo(res.data);
   })
   .catch(err => console.log(err))
   }
   getcurrent()
    },[reset])


useEffect(() => {
  console.log(jobinfo)
jobinfo.forms?.map(form => {

})
},[jobinfo])



const estprepresstime = () => {

}

const proofsup = (proofcost,method) => {
    let a
      switch(method){
    case "Sheetwise":
      a = 2 * proofcost
      break;
      default:
        a = 1 * proofcost;
        break
      }
    return a
    }


    console.log(jobinfo)
  return (
    <Actualversusestimate.Provider value={{}}>
        {children}
    </Actualversusestimate.Provider>
  )
}

export default Actualversusestimate



// card: component name
// 70# Husky Offset: paper
// 76:
// 70:
// 18: flat w
// 9: flat l
// 9: finish w
// 9: finish l
// C,M,Y,K: front
// C,M,Y,K: back
// Yes: f bleed
// Yes:b bleed
// SG: f coating
// SG:b coating
// AQ:
// AQ:
// :
// :
// :
// :
// Web:run on
// Yes: score
// Yes: perf
// Yes: die cut
// Roll Fold: folding
// 32: pages
