import {useEffect, useState} from 'react'
import { Flex, Flexed, Scroll, currency } from '../../utilities/Shared'
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
import Moment from 'react-moment'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'

const JobsPanel = ({list,search,rowclass,setList,order,setOrder}) => {



const [sales, setSales] = useState([])

const nav = useNavigate()
useEffect(() => {
    const getSales = async() => {
        const r = await axios.get(`http://localhost:5003/employee/sales`)
        .then(res => setSales(res.data))
        .catch(err => console.log(err))
}
getSales()
},[])

const salesrep = () => {


}


  return (
    <>
       <p> {list.length} Jobs</p>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}} className="hand">

    <Flex flex=".5" onClick={() => sortnum('job',order,list,setList,setOrder)}>Job</Flex>
      <Flex flex=".5"  >PO#</Flex>
      <Flex flex="3" onClick={() => sortit('jobname',order,list,setList,setOrder)}>Description</Flex>
      <Flex flex=".6" onClick={() => sortnum('salesrep',order,list,setList,setOrder)}>Sales Rep</Flex>
      <Flex flex="2"  onClick={() => sortit('customer',order,list,setList,setOrder)}>Customer</Flex>
      <Flex onClick={() => sortit('quantity',order,list,setList,setOrder)} >Quantity</Flex>
      <Flex flex=".7"  onClick={() => sortdate('dateentered',order,list,setList,setOrder)}>Entered</Flex>
      <Flex flex=".7" onClick={() => sortdate('duedate',order,list,setList,setOrder)} >Due</Flex>
      <Flex flex=".5" >Price</Flex>
      <Flex flex=".5" >Status</Flex>
  
   
      </Flexed >
     
      <Scroll height="50vh">
      {list?.filter(item => {return search.toLowerCase() === '' ? item : item.jobname?.toLowerCase().includes(search.toLowerCase()) || item.job?.toLowerCase().includes(search.toLowerCase()) || item.customer?.toLowerCase().includes(search.toLowerCase())}).map((job,index) =>{
            return <>
      <a href={`https://highgroundsoftware.com/dash/dashboard/?des=800&job=${job.job}`} >
            <Flexed className={`mainrow ${rowclass}`} key={index} margin="0" padding="5px 0px"  borderBottom="1px solid lightgrey" >
            <Flex className="" flex=".5" >{job.job}</Flex>
            <Flex className="" flex=".5" >{job.po} </Flex>
             <Flex className="" flex="3" >{job.jobname} </Flex>
             <Flex className="" flex=".6">{sales?.filter(emp => emp.empId === job.salesrep).map(item => {return <>{item.firstName} {item.lastName.substr(0,1)}</>})}</Flex>
             <Flex className=""flex="2" >{job.customer}</Flex>
             <Flex className=""  flex="">{job.quantity}</Flex>
             <Flex className=""  flex=".7"><Moment format="MM/DD/YYYY">{job.dateentered}</Moment></Flex>
             <Flex className=""  flex=".7"><Moment format="MM/DD/YYYY">{job.duedate}</Moment></Flex>
             <Flex className="" flex=".5"  >{currency(job.currentprice)} </Flex>
             <Flex className="" flex=".5"  ></Flex>
            </Flexed>
            </a>
      
            </>
                 })}
                 </Scroll>
                 </>
  )
}

export default JobsPanel