import axios from 'axios'
import React, { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Selectbox, Textarea, Wrap } from '../../utilities/Shared'
import { ToastContainer, toast } from 'react-toastify';
  import 'react-toastify/dist/ReactToastify.css';

const AddProduct = ({editinfo,onCancel,reset,setReset}) => {

   
const [info, setInfo] = useState({})





const submit = async(e) => {
 e.preventDefault()

 const newinfo = {
    name:info?.name,
    vendor:info?.vendor,
    price:info?.price,
    defaultorderby:info?.defaultorderby,
    defaultpriceunit:info?.defaultpriceunit,
    category:info?.category,
    size:info?.size,
    notes:info?.notes
 }
 console.log(info)

await axios.post('https://highgroundapi.herokuapp.com/supplies/add',newinfo)
.then(res => {
    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };
      const tr = {
    item:res.data.stockid,
description:info?.name,
size:info?.size,
type:info?.category, 
averageprice:info?.price,
  }
    const re = async() => {
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addproduct.php`,tr,config)
        .then(res => {setReset(!reset);onCancel()})
      .catch(err => console.log(err))
    }
    re()
}
)
.catch(err => console.log(err))
}


console.log(info)
  return (
    <Wrap>

        <ToastContainer position="bottom-center" theme="dark"/>
        <form onSubmit={submit}>
    <Flexed gap="15px">
<Flex textAlign="left"  flex="2">  <Label>Product</Label><br /><Input type="text" placeholder="Product Name" value={info?.name} onChange={e => setInfo({...info,name:e.target.value})} required/></Flex>
<Flex textAlign="left"  flex="1.5">  <Label></Label><br />{info?.stockid}</Flex>

    </Flexed >



    <Flexed gap="15px" padding="20px 0px 0px 0px" >
    <Flex textAlign="left"  flex="2">
<Label>Category</Label><br />
    <Selectbox defaultValue={info?.category} onChange={e => setInfo({...info,category:e.target.value})} required>
    <option value="" disabled={true} selected>Select Category</option>
    <option value="Ink">Inks</option>
        <option value="Bindery">Bindery</option>
        <option value="Prepress">Prepress</option>
        <option value="Press">Press</option>
        <option value="Shipping">Shipping</option>
        <option value="Boxes">Boxes</option>
        <option value="Custodial">Custodial</option>
        <option value="Maintenance">Maintenance</option>
        </Selectbox>
</Flex>
        <Flex>
        <Label>Size</Label><br />
    <Input value={info?.size} onChange={e => setInfo({...info,size:e.target.value})} />
    </Flex>
    <Flex>
    <Label>Price</Label><br />
    <Input value={info?.price} onChange={e => setInfo({...info,price:e.target.value})} required/>
    </Flex>
    <Flex flex="2"></Flex>
    </Flexed>


<Flexed padding="20px 20px 0px 0px" justifyContent="space-bwtween" ><Flex textAlign="right"><Button style={{marginRight:"30px"}} type="button" onClick={onCancel} >Cancel</Button><Button type="submit">Add Product</Button></Flex></Flexed>

    </form>
    </Wrap>
  )
}

export default AddProduct
