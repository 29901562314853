import React, { useContext, useEffect, useRef, useState } from 'react'
import { Button, Flex, Flexed, MainWrap, Scroll, TBody, THead, Table, Td, Th, Tr, Wrap } from '../../utilities/Shared'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import Moment from 'react-moment'
import { FaCopy } from "react-icons/fa";
import { colorchange, getemployeebynamefromempid } from '../../utilities/Convertions'

import { toast } from 'react-toastify';
import UserContext from '../../Context/UserContext'
import Employees from '../Employees/Employees'
import Taskcard from '../../utilities/TaskCard/Taskcard'
import { DropZone } from '../../utilities/TaskCard/TaskCardStyles'
import Drop from '../../utilities/TaskCard/Drop'


 const list = [
  {
      id:1,
      title:"item 1"
  },
  {
      id:2,
      title:"Item 2"
  }
]



const Unassigned = () => {

  const [activecard, setActivecard] = useState()
console.log(list)

console.log(activecard)


return (
<Wrap>

  <Flexed>
    <Flex>
      
    {list.map((item,index) => {
  return <Flexed><Flex>
  <Taskcard item={item} setActivecard={setActivecard} index={index}/>
  <Drop />
  </Flex></Flexed>
})
}
    </Flex>
   

    <Flex>
      <Flexed flexDirection="column">
        <Flex></Flex>
        <Flex>50</Flex>
        <Flex>m110</Flex>
        <Flex>digi</Flex>
      </Flexed>
    </Flex>
  </Flexed>


</Wrap>
  )
}

export default Unassigned