import React, { useRef,useEffect ,useState} from "react";
import Moment from 'react-moment';

import LOGO from '../Assets/plsprint.png'
import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label, currency } from "../utilities/Shared";


export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;
font-weight:bold;
text-transform:capitalize;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size: ${props => props.fontSize || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || ""};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border ? "1px solid black" : ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size: ${props => props.fontSize || ""};
whiteSpace:${props => props.whiteSpace || "nowrap"};
h2{
    margin:0;
    padding:0:
}
`;




export const Textarea = styled(TextareaAutosize)`

min-height:${props =>props.minHeight || "50px"};
width:${props =>props.width || "100%"};
outline:none;
text-align:center;
border:1px solid #c8c8c8;
font-size:calc(.7rem + .5vw);
padding:${props =>props.padding || "8px"};
margin:${props =>props.margin || "0"};
resize:none;
font-weight:bold;
border-bottom:1px solid black
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;


export default function PrintComponent({list}) {
  let componentRef = useRef();

  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
      
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint list={list}  ref={(el) => (componentRef = el)}/>
        </Flex>
      </Flex>
    </>
  );
}




// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
 
  
  }

  render() {
    return (
      
     <Wrapper style={{fontSize:"2rem",display: 'block',padding:"10px 0px",pageBreakAfter: "auto"}}>
        <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white"}} className="hand">
  <Flex fontSize="var(--headerMedium)" >InvId#</Flex>
  <Flex flex="2" fontSize="var(--headerMedium)" >Item</Flex>
  <Flex   fontSize="var(--headerMedium)" >Size</Flex>
  <Flex >Job(s)</Flex>
  <Flex  fontSize="var(--headerMedium)" >OnHand</Flex>
  <Flex >Alloted</Flex>
  <Flex  fontSize="var(--headerMedium)" >Available</Flex>


</Flexed>
<div style={{border:"1px solid black"}}>
{
 this.props.list.map(job => {
    return <><Flexed className="normalrow hand" fontSize="calc(.8rem + .4vw)">
      <Flex fontSize="calc(.8rem + .4vw)">{job.invid}</Flex>
      <Flex  fontSize="calc(.8rem + .4vw)"flex="2">{job.name}</Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{job.size}</Flex>
      <Flex ></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{job.currentInventory}</Flex>
      <Flex ></Flex>
      <Flex ></Flex>
   
      </Flexed>

      {job.forjobs.map(item => {return  <Flexed padding="10px 0px">
      <Flex fontSize="calc(.8rem + .4vw)" flex="4"></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{item.job}</Flex>
      <Flex ></Flex>
      <Flex fontSize="calc(.8rem + .4vw)">{item.quantity}</Flex>
      <Flex ></Flex>
      </Flexed>})}
     
      </>
  })
}
</div>
<br /><br /><br />

    </Wrapper>

  
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
