import React, { useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Wrap } from '../../utilities/Shared'
import axios from 'axios'
import { toast } from 'react-toastify'

const Editsaleschart = ({salesreport,onCancel,sid,reset,setReset}) => {

   

   const [newsales, setNewsales] = useState(salesreport.filter((item,index) => index !== 0))


   const setsales = (e,index) => {
   let i = e.target.name
   let items = [...newsales];
   let item = [...items[i]];
   item[1] = parseFloat(e.target.value);
   items[i] = item;
   setNewsales(items)
 //

   }


   const savesales = async() => {
    let ynewsales = [salesreport[0], ...newsales]

    const rt = {
        id:sid,
        sales:ynewsales
    }

await axios.post('https://highgroundapi.herokuapp.com/sales/update',rt)
.then(res => {toast.success('Sales Updated');setReset(!reset);onCancel()})
.catch(err => console.log(err))

//week 43 110667.05
   }





  return (
    <Wrap >
        <Flexed style={{display:"flex",flexWrap: "wrap",alignContent:"flex-start",gap:"10px",justifyContent:"flex-start",width:"100%",gap:"20px"}}>
  {newsales.map((item,index) => {
  return <Flex><Label style={{fontWeight:"bold"}}>{item[0]}</Label><br /><Input name={index} width="100px" type="number" value={newsales[index][1]} onChange={e => setsales(e,index)}/></Flex>


  }) }     
  <Flex flex="7"></Flex>
  <Flex ><Button onClick={savesales}>Update</Button></Flex>
  </Flexed>
                

                 </Wrap>
  )
}

export default Editsaleschart