import React, { useContext, useState } from 'react'
import { Button, Flex, Flexed, Input, Wrap } from '../../utilities/Shared'
import POContext from '../../Context/POContext'
import { AiOutlineDelete } from "react-icons/ai";
const ForJobs = ({onCancel,reset,setReset,selected,setSelected,updatehistory}) => {

    console.log(selected)
    const [q, setQ] = useState()
    const [j, setJ] = useState()


    const additem = () => {
        console.log(selected)
        const l = {
            job:j,quantity:q
        }
       
selected.forjobs.push(l);
updatehistory("Allocated "+q+" for job "+j,selected.currentInvntory,selected.currentInvntory - q,`- ${q}`,selected.bin)
onCancel()
    }


    console.log(selected)
    const removeitem = (itemid) => {
   let newlist = selected.forjobs?.filter(item => item.job !== itemid)
   console.log(newlist)
     setSelected({...selected,forjobs:newlist})
    }
  return (
    <Wrap bgcolor="#0E425A">
        <Flexed alignItems="flex-end">
            <Flex>Job:<br /><Input onChange={e => setJ(e.target.value)}/></Flex>
            <Flex>Quantity:<br /><Input onChange={e => setQ(e.target.value)}/></Flex>
            <Flex flex="3"><Button onClick={additem}>Add Job</Button></Flex>
        </Flexed>


        <Flexed alignItems="flex-end"><Flex textAlign="right"><Button onClick={onCancel}>Cancel</Button></Flex></Flexed>
    </Wrap>
  )
}

export default ForJobs