import { useEffect, useRef, useState } from "react";
import { Flex, Flexed, Scroll } from "../../utilities/Shared";
import { range } from "./utils";
import Moment from "react-moment";
const MONTHS = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"]
const DAYS = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"]

const Gl40 = ({jobs,dragItem,dragOverItem,handleSort,est}) => {

console.log(jobs)
    const [currentDate, setCurrentDate] = useState(new Date())
    const [currentMonth, setCurrentMonth] = useState(new Date().getMonth() +1)
    const [currentYear, setCurrentYear] = useState(new Date().getFullYear())
    const [currentDay, setCurrentDay] = useState(new Date().getDay())
    const [daysInMonth, setDaysInMonth] = useState(0)
    const [newjobs, setNewjobs] = useState([])
   
    const getDaysInMonth = (year,month) => {
      console.log(year)
      console.log(month)
      return new Date(year, month, 0).getDate();
        }

        useEffect(() => {
         const element = document?.getElementById(new Date().getDate())
         setview(element)


        },[daysInMonth])

        
        const setview = (element) => {
let tempjobs = []
        if(currentMonth -1  === new Date().getMonth()){
          element?.scrollIntoView()
        }
       
        jobs?.map(job => {
          if(job?.scheduledpressday){
            tempjobs.push(job)
          }
          setNewjobs(tempjobs)
        })
        
          
         }

console.log(newjobs)


useEffect(() => {

  setDaysInMonth(getDaysInMonth(currentYear,currentMonth))

},[])
  

        const getDayOfTheWeek = (day,month,year) => {
      
let newdate = new Date(`${year}-${month}-${day}`)
return newdate
        }

      

        const nextMonth = () => {
                  if(currentMonth < 11){
                    let y = currentMonth + 1
                    setDaysInMonth(getDaysInMonth(currentYear,y))
                      setCurrentMonth(prev => prev +1)
                 
                  }else{
                      setCurrentMonth(0)
                      let y = currentYear + 1
                      setCurrentYear(prev => prev +1)
                      setDaysInMonth(getDaysInMonth(y,0))
                  }
              }
              


return (

<>
<Flexed><div onClick={nextMonth}>></div></Flexed>
<Flexed textAlign="left" padding="3px"  fontSize="calc(1rem + .3vw)" fontWeight="bold" color="#38761D" gap="6px">
  <Flex>{MONTHS[currentMonth - 1] } {currentYear}</Flex>
  </Flexed>
  <Scroll height="51vh">  
 {
  range(daysInMonth)?.map((day,index) => {
  
    return<>
     <Flexed textAlign="left"  id={index +1} borderTop="2px solid black"
       onDragEnter={() => {(dragOverItem.current = index)}}
       onDragOver={(e) => {e.preventDefault();console.log(dragItem.current)}}
       onDragEnd={handleSort}
     >
      <Flex fontSize="calc(1rem + .3vw)" bgColor="#A4C2F4" flex=".175" padding="3px">
      {DAYS[getDayOfTheWeek(day,currentMonth,currentYear).getDay()]} <Moment style={{fontSize:"calc(1rem + .3vw)"}} format="MM/DD/YY">{getDayOfTheWeek(day,currentMonth,currentYear)}</Moment>
      </Flex>
      <Flex></Flex>
      </Flexed>
      <Flexed>
        <Flex>&nbsp;</Flex>
      </Flexed>
      <Flexed>
        <Flex>&nbsp;</Flex>
      </Flexed>
      </>
  })
}

</Scroll>
</>
  )
}

export default Gl40


// import Moment from "react-moment";
// import { Button, Flex, Flexed, MainWrap, Scroll,Input } from "../../utilities/Shared";
// import { colorchange, convertSheetSize } from "../../utilities/Convertions";

// const Gl40 = ({jobs,dragItem,dragOverItem,handleSort,est}) => {

// console.log(jobs)
//   const setpreview = (job) => {
//     let uu
//     let yy = est.filter(filt => filt.quote === job.quote && filt.component === job.component)
//     console.log(yy)
//     if(parseInt(yy[0]?.press) > 7 ){
//       console.log(yy[0]?.digitaltime1)
//       console.log(yy[0]?.numforms)
//       uu = parseFloat(yy[0]?.digitaltime1) / parseInt(yy[0]?.numforms)
//     }else{
//       console.log(yy[0]?.presstime1)
//       console.log(yy[0]?.presstime2)
//       console.log(yy[0]?.numforms)
//     uu =  (parseFloat(yy[0]?.presstime1) + parseFloat(yy[0]?.presstime2)) / parseInt(yy[0]?.numforms)
//     }
  
//  return uu.toFixed(2)
//    }


//          console.log(jobs)
//   return (
//     <Flexed style={{minHeight:"54vh",border:"1px solid red"}}>
//          <Flex border="1px solid lightgrey" >&nbsp;
//          <Flexed padding="5px" fontSize="calc(1rem + .3vw)" fontWeight="bold" color="#38761D" gap="6px" draggable>
//              <Flex whiteSpace="normal" textAlign="left" flex="1.3">Job</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="2">Customer</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="1.5">Form</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="1">Size</Flex>
//              <Flex whiteSpace="normal" textAlign="left">Hrs</Flex>
//              <Flex whiteSpace="normal" textAlign="left">QTY</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="2.3">Method</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="5">Description</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="1.7">Sales Rep</Flex>
//              <Flex whiteSpace="normal" textAlign="left"> In</Flex>
//              <Flex whiteSpace="normal" textAlign="left">Due</Flex>
//              <Flex whiteSpace="normal" textAlign="left" flex="2">Status</Flex>
//              <Flex whiteSpace="normal" textAlign="left"></Flex>
//          </Flexed>
//          <Scroll height="54vh" textAlign="left">
//          {
//           jobs?.filter(filt => filt.schedulepress === "GL440").map((item, index) => {
//             return <Flexed padding="5px" fontSize="calc(1.1rem + .2vw)" alignItems="center" fontWeight="bold" color="#38761D" gap="6px" border="1px solid lightgrey"
//             onDragStart={() => (dragItem.current = index)}
//             onDragEnter={() => (dragOverItem.current = index)}
//             onDragEnd={handleSort}
//             onDragOver={(e) => e.preventDefault()} draggable>
//             <Flex flex="1.3" fontSize="calc(.9rem + .2vw)" fontWeight="normal" color="black">{item?.job}</Flex>
//             <Flex flex="2" fontSize="calc(.9rem + .2vw)" fontWeight="normal" color="black" whiteSpace="normal">{item?.customer}</Flex>
//             <Flex flex="1.5"><Input border="0" type="text" value={item?.name} /></Flex>
//             <Flex flex="1" fontSize="calc(.9rem + .2vw)" fontWeight="normal" color="black" whiteSpace="normal">{convertSheetSize(item.sheetl)} X {convertSheetSize(item.sheetw)}</Flex>
//             <Flex><Input border="0" type="text" value={setpreview(item)} /></Flex>
//             <Flex><Input border="0" type="text" value={item?.quantity} /></Flex>
//             <Flex flex="2.3"><Input border="0" type="text" value={item?.method} /></Flex>
//             <Flex flex="5"><Input border="0" type="text"  value={item?.impp[0]?.jobname} /></Flex>
//             <Flex flex="1.7"><Input border="0" type="text" value={item?.salesrep} /></Flex>
//             <Flex padding="5px 0px 0px 0px" ><Moment style={{fontSize:"calc(.9rem + .2vw)",color:"black",fontWeight:"normal"}} format="MM/DD/YY">{item?.dateentered}</Moment> </Flex>
//             <Flex padding="5px 0px 0px 0px" ><Moment style={{fontSize:"calc(.9rem + .2vw)",color:"black",fontWeight:"normal"}} format="MM/DD/YY">{item?.datedue}</Moment></Flex>
//             <Flex flex="2" fontSize="calc(.9rem + .2vw)" padding="2px 0px" fontWeight="normal" color="black" whiteSpace="normal" bgColor={colorchange(item.stat)}>{item.stat}</Flex>
//             <Flex></Flex>
//         </Flexed>
//           })
//          }
//          </Scroll>
//          </Flex>
//        </Flexed>
// //     <Wrap padding="0"> 
// //         <Flexed  margin="5px 0px 0px 0px" bgColor="#79BB43" color="white"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">GL40 Active Jobs - {jobs?.length}</Flex>
// //         <Flex textAlign="right"><Button bgcolor="orange" onClick={e => CopyAllToClipboard()}>Copy Table</Button></Flex>
// //         </Flexed>
// //         <Scroll height="70vh">  
// // <Table id="gl40">
// // <THead>
// // <Tr>
// // <Th flex=".8" onClick={e => sortnum('job',order,jobs,setJobs,setOrder)} className="hand">Job #</Th>
// // <Th flex="3" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)}>Customer</Th>
// // <Th flex="1.5" className="hand" onClick={e => sortit('customer',order,jobs,setJobs,setOrder)} >Component</Th>

// // <Th  flex="1.5" onClick={e => sortnum('parentsheetl',order,jobs,setJobs,setOrder)}  className="hand">Sheet Size</Th>
// // <Th  className="hand" flex="1.2">Est Time</Th>

// // <Th onClick={e => sortnum('newquantity',order,jobs,setJobs,setOrder)}  className="hand">Quantity</Th>
// // <Th flex="1.5" className="banned">Run Type</Th>
// // <Th flex="3" className="banned">Description</Th>
// // <Th onClick={e => sortit('salesrep',order,jobs,setJobs,setOrder)}  className="hand">Sales Rep</Th>
// // <Th flex="1.5" onClick={e => sortdate('dateentered',order,jobs,setJobs,setOrder)} className="hand">Enter Date</Th>
// // <Th flex="1.5" onClick={e => sortdate('dateentered',order,jobs,setJobs,setOrder)}  className="hand">Due Date</Th>
// // <Th flex="1.5" onClick={e => sortit('stat',order,jobs,setJobs,setOrder)}  className="hand">Status</Th>

// // </Tr></THead>

// //  <TBody  hbgcolor="#79BB43">

// //  {jobs?.map((job,index) => {
// //  return <Tr id={`gl4o${index}`} className="hand" draggable>
// // <Td flex=".8"><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Td>
// // <Td flex="3">{job?.impp[0]?.customer}</Td>
// // <Td flex="1.5">{job.name}</Td>

// // <Td flex="1.5" >{job.sheetl}X{job.sheetw}</Td>
// // <Td flex="1.2">{setpreview(job)}</Td>
// // <Td >{Math.ceil(job.newquantity)}</Td>
// // <Td flex="1.5">{job.method}</Td>
// // <Td flex="3">{job?.impp[0]?.jobname}</Td>
// // <Td >{getemployeebynamefromempid(job?.impp[0]?.salesrep,employees)}</Td>
// // <Td flex="1.5"><Moment format="MM/DD/YYYY">{job.impp[0]?.dateentered}</Moment></Td>
// // <Td flex="1.5"><Moment format="MM/DD/YYYY">{job.impp[0]?.duedate}</Moment></Td>
// // <Td flex="1.5" bgColor={colorchange(job.stat)}>
// //   <span style={{padding:"3px",display:"flex",alignItems:"center"}}>{job.stat}</span></Td>
// // </Tr>
// //  })
// // }
// // <Tr>
// // <Td flex=".6"></Td>
// // <Td flex=".8"></Td>
// // <Td flex="3"></Td>
// // <Td flex="1.5"></Td>
// // <Td ></Td>
// // <Td > </Td>
// // <Td ></Td>
// // <Td flex="1.5"></Td>
// // <Td flex="3"></Td>
// // <Td ></Td>
// // <Td flex="1.5"></Td>
// // <Td flex="1.5"></Td>
// // <Td flex="1.5"></Td>
// // </Tr>

// //  </TBody>

// //  </Table>
// //  </Scroll>
   
// //     </Wrap>
//   )
// }

// export default Gl40



// import React from 'react'
// import { Flex, Flexed } from '../../utilities/Shared'
// import { ListItem } from './utils'

// const Gl440 = () => {




//   return (
//     <Flexed style={{minHeight:"54vh",border:"1px solid red"}}>
//     <Flex border="1px solid lightgrey" >&nbsp;
//     <Flexed padding="5px" fontSize="calc(1.1rem + .2vw)" fontWeight="bold" color="#38761D" draggable>
//         <Flex>Job</Flex>
//         <Flex>Customer</Flex>
//         <Flex>Forms</Flex>
//         <Flex>Sheet Size</Flex>
//         <Flex>Hours</Flex>
//         <Flex>QTY</Flex>
//         <Flex>Run Type</Flex>
//         <Flex>Description</Flex>
//         <Flex>Sales Rep</Flex>
//         <Flex>Date In</Flex>
//         <Flex>Due Date</Flex>
//         <Flex>Status</Flex>
//         <Flex></Flex>
//     </Flexed>



//     </Flex>
//   </Flexed>
//   )
// }

// export default Gl440








