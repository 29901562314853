import React, { useContext, useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Label, Scroll, Selectbox, Textauto, Wrapper } from '../../../utilities/Shared'
import axios from 'axios';
import Collapse from '../../../utilities/Collapse';
import Datetime from 'react-datetime';
import { toast } from 'react-toastify';
import { RiDeleteBack2Fill } from "react-icons/ri";
import JobticketContext from '../../../Context/JobticketContext';
import { colorchange } from '../../../utilities/Convertions';
import StitchingContext from '../../../Context/StitchingContext';

const moment = require("moment");
const StitchingTicket = ({jobinfo,forms,reset,setReset,job}) => {

  const[operations, setOperations] = useState([])
  const[newforms, setnewForms] = useState([])
  const[imposition, setImposition] = useState([])
  const[proofstotal, setProofstotal] = useState({hours:0,minutes:0})
 




  const[operationitems, setOperationitems] = useState([])
  const[prepress, setPrespress] = useState([])
  const[onprepresstimes, setOnprepresstimes] = useState({})
  const[offprepresstimes, setOffprepresstimes] = useState({})

  const[onplatingtimes, setOnplatingtimes] = useState({})
  const[offplatingtimes, setOffplatingtimes] = useState({})
  const[proofin, setProofin] = useState(false)
  const[proofout, setProofout] = useState(false)
  
  const [value, onChange] = useState(new Date());
  const [onTimes, setOnTimes] = useState(null);
  const [offTimes, setOffTimes] = useState(null);




  const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };



  useEffect(() => {

    let r = {
        job:job,
    }
    const getops = async() => {
     await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,r,config)
    .then(res => {setOperations(res.data);})
    .catch(err => console.log(err))
    }
    const getquote = async() => {
      let r = {
        quote:jobinfo?.quote,
      }
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getimposition.php`,r,config)
     .then(res => {setImposition(res.data);})
     .catch(err => console.log(err))
     }
    getops()
    getquote()

  },[reset])

  useEffect(() => {
    setPrepressValues()
  },[operations])


  console.log(forms)
const setPrepressValues = () => {
  let imp
  let ops = []
let proofing = []

    let stitchingins = []
    let  stitchingouts = []
    let  stitchingindis = []
    let drillingins = []
    let  drillingouts = []
    let  drillingindis = []
    let coilbindingins = []
    let  coilbindingouts = []
    let coilbindingindis = []
    let shrinkwrapins = []
    let  shrinkwrapouts = []
    let shrinkwrapindis = []
    let bandingins = []
    let  bandingouts = []
    let bandingindis = []
    let partofid
    let partof
    console.log(operations)
    //  imp = imposition?.filter(filter => filter.component === item.originalform)
    //  console.log(imp)

operations?.map((op,index) => {
  let diff = 0
    if(op.name === "On Stitching"){stitchingins.push({id:op.id,time:op.changedon,status:"On Stitching"})}
   if(op.name === "On Drilling"){drillingins.push({id:op.id,time:op.changedon,status:"On Drilling"})}
   if(op.name === "On Coil Binding"){coilbindingins.push({id:op.id,time:op.changedon,status:"On Coil Binding"})}
    if(op.name === "On Shrink Wrap"){shrinkwrapins.push({id:op.id,time:op.changedon,status:"On Shrink Wrap"})}
    if(op.name === "On Banding"){bandingins.push({id:op.id,time:op.changedon,status:"On Banding"})}


    if(op.name === "Off Stitching"){stitchingouts.push({id:op.id,time:op.changedon,status:"Off Stitching"})} 
  if(op.name === "Off Drilling"){drillingouts.push({id:op.id,time:op.changedon,status:"Off Drilling"})}
  if(op.name === "Off Coil Binding"){coilbindingouts.push({id:op.id,time:op.changedon,status:"Off Coil Binding"})}
  if(op.name === "Off Shrink Wrap"){shrinkwrapouts.push({id:op.id,time:op.changedon,status:"Off Shrink Wrap"})}
  if(op.name === "Off Banding"){bandingouts.push({id:op.id,time:op.changedon,status:"Off Banding"})}



    if(op.name === "Incomplete Stitching"){stitchingouts.push({id:op.id,time:op.changedon,status:"Incomplete Stitching"})}
    if(op.name === "Incomplete Drilling"){drillingouts.push({id:op.id,time:op.changedon,status:"Incomplete Drilling"})}
    if(op.name === "Incomplete Coil Binding"){coilbindingouts.push({id:op.id,time:op.changedon,status:"Incomplete Coil Binding"})}
    if(op.name === "Incomplete Shrink Wrap"){shrinkwrapouts.push({id:op.id,time:op.changedon,status:"Incomplete Shrink Wrap"})}
    if(op.name === "Incomplete Banding"){bandingouts.push({id:op.id,time:op.changedon,status:"Incomplete Banding"})}


    partofid = op.partofid || `${op.partof}${index}`
    partof = op.partof || op.item
    })

   
    stitchingindis=(getdiff(stitchingins,stitchingouts))
    drillingindis=(getdiff(drillingins,drillingouts))
    coilbindingindis=(getdiff(coilbindingins,coilbindingouts))
    shrinkwrapindis=(getdiff(shrinkwrapins,shrinkwrapouts))
    bandingindis=(getdiff(bandingins,bandingouts))


    

    const y = {
      id:jobinfo.id,
      item:jobinfo?.id,
        name:jobinfo?.name,
        formid:jobinfo?.formid,
        partofid,
        partof,
        processtext:jobinfo?.processtext,
        stitching:
          {'ins':stitchingins,
            'out':stitchingouts,
            'indiv':stitchingindis,
          'estimatedtime':0//getstitchingtime(jobinfo.quantity,jobinfo.stitchup,jobinfo.stitchpockets),
          
    },
    drilling:
    {'ins':drillingins,
      'out':drillingouts,
      'indiv':drillingindis,
      'estimatedtime':{hours:0,minutes:0},
    
},
coilbinding:
{'ins':coilbindingins,
  'out':coilbindingouts,
  'indiv':coilbindingindis,
  'estimatedtime':{hours:0,minutes:0},

},
shrinkwrap:
{'ins':shrinkwrapins,
  'out':shrinkwrapouts,
  'indiv':shrinkwrapindis,
  'estimatedtime':{hours:0,minutes:0},

},
banding:
{'ins':bandingins,
  'out':bandingouts,
  'indiv':bandingindis,
  'estimatedtime':{hours:0,minutes:0},

}
    }
proofing.push(y)
setnewForms(proofing)
}



const getdiff = (inTimes,outTimes) => {

let tot =[]
let u


inTimes?.map((item,index) => {

      let time
        let intime = new Date(item?.time)
        let outtime = new Date(outTimes[index]?.time)
     time = Math.abs(outtime - intime)



   
  let y =timeConvert(time/1000/60)
  if(isNaN(y.hours)){y.hours = 0}
  if(isNaN(y.minutes)){y.minutes = 0}
   u = {hours:y.hours,minutes:y.minutes}
  tot.push(u)
})
return(tot)
}

const setesttime = (imp,item) => {
  let newitem

switch(item){
case "stitching":
//   if(parseInt(imp[0]?.numforms) > 1){
//       let r = parseFloat(imp[0]?.presstime1) + parseFloat(imp[0]?.presstime2)
  
//       newitem = (r / parseInt(imp[0]?.numforms))
//   } 
break;
}

let y = converttime(newitem)

return y
}



function timeConvert(n) { 

  var num = n;
  var hours = (num / 60);
  var rhours = Math.floor(hours);
  var minutes = (hours - rhours) * 60;
  var rminutes = Math.round(minutes);

 // if(isNaN(rhours)){rhours = 0}
  //if(isNaN(rminutes)){rminutes = 0}
  let t = {hours:proofstotal.hours + rhours,minutes:proofstotal.minutes + rminutes}

  return{hours:rhours,minutes:rminutes}
  } 

  const converttime = (item) => {

    let hours = 0
        let minutes = 0
        let myArray = []
    if(item !== undefined){
         myArray = item.toString()?.split(".");
       hours = parseInt(myArray[0])
       minutes = parseInt(myArray[1])

        let check = minutes/100
        if(check > 1){
         minutes = minutes % 100
       
        }
    }
   return({hours,minutes})
}


const getDiff = (intime,outtime,item) => {
   intime = new Date(intime)
   outtime = new Date(outtime)
     let  time = Math.abs(outtime - intime)

     let y = (timeConvert(time/1000/60))


     return `${y.hours}:${y.minutes}`
}








const setchangevalues = (item) => {
   
  let d
  let s
  let p
  let push
switch(item){
  case "On Stitching":
d = 4
s = 1
p = 20
push=0
break;
case "Off Stitching":
d = 4
s = 0
p = 20
push=0
break;
case "Incomplete Stitching":
d = 4
s = 0
p = 20
push=0
break;
case "On Drilling":
    d = 4
    s = 1
    p = 21
    push=0
    break;
    case "Off Drilling":
    d = 4
    s = 0
    p = 21
    push=0
    break;
    case "Incomplete Drilling":
    d = 4
    s = 0
    p = 21
    push=0
    break;
    case "On Coil Binding":
        d = 4
        s = 1
        p = 22
        push=0
        break;
        case "Off Coil Binding":
        d = 4
        s = 0
        p = 22
        push=0
        break;
        case "Incomplete Coil Binding":
        d = 4
        s = 0
        p = 22
        push=0
        break;
        case "On Shrink Wrap":
            d = 4
            s = 1
            p = 24
            push=0
            break;
            case "Off Shrink Wrap":
            d = 4
            s = 0
            p = 24
            push=0
            break;
            case "Incomplete Shrink Wrap":
            d = 4
            s = 0
            p = 24
            push=0
            break;
                case "On Banding":
                    d = 4
                    s = 1
                    p = 25
                    push=0
                    break;
                    case "Off Banding":
                    d = 4
                    s = 0
                    p = 25
                    push=0
                    break;
                    case "Incomplete Banding":
                    d = 4
                    s = 0
                    p = 25
                    push=0
                    break;

}
return {d,s,p,push}
}



const setpushes = (e,form) => {
  console.log(e.target.value)
let set = {}
  if(e.target.value === "Off Press"){
set.dept = 4           
set.push = 0
set.name = "Ready Bindery"
set.process=0
  }
  const z = {
    job:job,
    item:form.item,
    partof:form.partof,
    partofid:form.partofid,
    process:set.process,
    comp:form.name,
    changedby:sessionStorage.getItem('eid'),
  name:set.name,
  dept:set.dept,
  status:0,
  push:set.push
  }
  console.log(z)
  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )

 
  }
  savit()
}





const changestatus = (e,form,index) => {

let values = setchangevalues(e.target.value)

const z = {
  job:job,
  item:form.item,
  partof:form.partof,
  partofid:form.partofid,
  process:values.p,
	comp:"Stitch",
name:e.target.value,
dept:values.d,
status:values.s,
changedby:sessionStorage.getItem('eid'),
push:values.push
}
console.log(z)
const savit = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/addoperation.php`,z,config)
  .then(res => {setpushes(e,form)} )
  .catch(err => toast.error('Something went wrong and process was not saved') )
}
savit()
}







const changetime = (e,form) => {

  const z = {
    changedon:e.target.value,
id:form.id
  }

  const savit = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updateoperation.php`,z,config)
    .then(res => {toast.success('Process Saved');setReset(!reset)} )
    .catch(err => toast.error('Something went wrong and process was not saved') )
  }
 savit()
}


console.log(newforms)

  return (
    <Wrapper style={{height:"70vh",position:"relative",color:"black"}}>
    
  
    <Scroll height="100%">

    <div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Stitching</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.stitching.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.stitching.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.stitching.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.stitching.out[index].status)}} type="datetime-local" value={form.stitching.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.stitching.indiv[index].hours}:{form.stitching.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.stitching.estimatedtime}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Stitching">On Stitching</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Stitching">Off Stitching</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Stitching">Incomplete Stitching</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Drilling//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Drilling</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.drilling.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.drilling.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.drilling.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.drilling.out[index].status)}} type="datetime-local" value={form.drilling.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.drilling.indiv[index].hours}:{form.drilling.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.drilling.estimatedtime.hours}:{form.drilling.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Drilling">On Drilling</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Drilling">Off Drilling</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Drilling">Incomplete Drilling</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Coil Binding//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Coil Binding</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>
coilbind
    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.coilbinding.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.coilbinding.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.coilbinding.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.coilbinding.out[index].status)}} type="datetime-local" value={form.coilbinding.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.coilbinding.indiv[index].hours}:{form.coilbinding.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.coilbinding.estimatedtime.hours}:{form.coilbinding.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Coil Binding">On Coil Binding</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Coil Binding">Off Coil Binding</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Coil Binding">Incomplete Coil Binding</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>

{/*/////////////////////////////////////////////////////////Shrink Wrapping//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Shrink Wrapping</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.shrinkwrap.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.shrinkwrap.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.shrinkwrap.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.shrinkwrap.out[index].status)}} type="datetime-local" value={form.shrinkwrap.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.shrinkwrap.indiv[index].hours}:{form.shrinkwrap.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.shrinkwrap.estimatedtime.hours}:{form.shrinkwrap.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Shrink Wrap">On Shrink Wrap</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Shrink Wrap">Off Shrink Wrap</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Shrink Wrap">Incomplete Shrink Wrap</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>


{/*/////////////////////////////////////////////////////////Banding//////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid black",padding:"10px"}}>  
    <Flexed>
<Flex fontSize="calc(1.3rem + .2vw)" bgColor="#0E425A" color="white" fontWeight="bold" padding="5px 0px 5px 5px"> Banding</Flex>
   </Flexed>
   <Flexed padding="5px 0px 10px 0px">
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)">Form:</Flex>
<Flex flex="4">
      <Flexed>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Start:</Flex>
<Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" minWidth="20%">Finish / Incomplete:</Flex>

<Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Actual:</Flex>

</Flexed>

    </Flex>
    <Flex flex=".5" style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Estimated:</Flex>
    <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >Status Change</Flex>
   </Flexed>

    {newforms.map((form,index) => {
      return <> <div style={{background:"rgb(0,0,0,.3)",marginBottom:"12px"}} ><Flexed padding="4px 0px" borderBottom="1px solid grey" margin="0px 0px 15px 0px" >
        <Flex fontSize="calc(.9rem + .2vw)" top="8px">{form.name}</Flex>
        <Flex flex="4">
          {form.banding.ins?.map((ite,index) => {
 return <Flexed padding="4px 0px">
 <Flex  minWidth="20%"><input onChange={e => changetime(e,ite)} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(ite.status)}} type="datetime-local" value={ite.time}/></Flex>
 <Flex  minWidth="20%">{form.banding.out[index] && <><RiDeleteBack2Fill style={{fontSize:"calc(1.1rem + .2vw)",position:"relative",top:"2.5px"}}/> <input onChange={e => changetime(e,form.banding.out[index])} style={{fontSize:"calc(1rem + .2vw)",width:"90%",background:colorchange(form.banding.out[index].status)}} type="datetime-local" value={form.banding.out[index].time}/></>}</Flex>
 <Flex flex=".5" fontSize="calc(1.2rem + .2vw)">{form.banding.indiv[index].hours}:{form.banding.indiv[index].minutes}</Flex>
 </Flexed>
          })}
            </Flex>
            <Flex flex=".5" fontSize="calc(1.2rem + .2vw)" style={{position:"relative",top:"2.5px"}}>{form.banding.estimatedtime.hours}:{form.banding.estimatedtime.minutes}</Flex>
            <Flex style={{textDecoration:"underline"}} fontSize="calc(1.1rem + .2vw)" >
 <Selectbox onChange={e => changestatus(e,form,index)}>
 <option value="" selected >Select Status</option>
 <optgroup label="On Status">
 <option value="On Banding">On Banding</option>
 </optgroup>
 <optgroup label="Off Status">
 <option value="Off Banding">Off Banding</option>
 </optgroup>
 <optgroup label="Changes">
 <option value="Incomplete Banding">Incomplete Banding</option>
 </optgroup>
 </Selectbox>
 </Flex>
           </Flexed>
      
             
             </div> </>
    })
  }

 
</div>





<br /> <br /> <br /> <br />
    </Scroll>
    

    
    </Wrapper>
  )
}

export default StitchingTicket




