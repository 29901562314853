import styled from "styled-components";



export const Card = styled.div`
border:1px solid red;
cursor:grab;


&:active{
    border:1px solid black;  
  
}
`;










///////////////////////////////////////DROPZONE/////////////////////////////////////////////////////////



export const Drop = styled.div`
width:100%;
border:1px solid red;
height:100px;


.dropzone{
border:1px solid black;
width:100%;
height:100px;
columns:#dcdcdc;
border:1px solid dashed #dcdcdc;
border-radius:10px;
padding:15px;
margin-bottom:15px;
opacity:1;
transition:.3s all ease-in-out;
color:red;
}

.hide{
opacity:0;
color:red;
}
`;