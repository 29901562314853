import React, { useEffect, useState } from 'react'
import { MainWrap } from '../../../utilities/Shared'
import axios from 'axios'
import Moment from 'react-moment'

const Versus = () => {
    const[alloperations, setAlloperations] = useState([])

    const config = {
        headers: {
          'Content-Type': 'application/json',
          'Access-Control-Allow-Origin':'*' 
        }
      };
      const tr = {
        job:102572
      }

    useEffect(() => {
        const getalloperations = async() => {
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalloperations.php`,tr,config)
            .then(res => setAlloperations(res.data))
            .catch(err => console.log(err))
          }
          getalloperations()
          
    },[])
 

    useEffect(() => {
prepress()
    },[alloperations])

console.log(alloperations)



const getforms = () => {
    const groupByCategory = Object.groupBy(alloperations, product => {
        return product.comp;
      });
      
      console.log(groupByCategory); 
}

getforms()


const prepress = () => {
   let a  = (alloperations.filter(filt => filt.name === "On Prepress"))
   console.log(a)
   let b  = (alloperations.filter(filt => filt.name === "Off Prepress"))
   console.log(b)
}

  return (
    <MainWrap>versus</MainWrap>
  )
}

export default Versus