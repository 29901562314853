import React from 'react'
import {MainWrap} from './Shared'
import DraggableGrid from 'ruuri';
const TEST = () => {
  return (
    <MainWrap>

<div style={{width:"960px",height:"672px",border:"1px solid red"}} >



</div>
  
    </MainWrap>
  )
}

export default TEST