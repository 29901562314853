
import { createContext, useState, useEffect } from "react"
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import io from 'socket.io-client'



export const PressContext = createContext();

export const PressProvider = ({children}) => {
const [presslist, setPresslist] = useState([]);
const [presscurve, setPresscurve] = useState([]);
const [cutter, setCutter] = useState([]);
const [folder, setFolder] = useState([]);
const [stitching, setStitching] = useState([]);
const [paperlist, setPaperslist] = useState([]);
const [reset, setReset] = useState(false);

const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };

  let r = {
    job:""
  }

useEffect(() => {
  const paper = async() => {
    //await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    await axios.get('https://highgroundapi.herokuapp.com/paper/all')
    .then(res => {setPaperslist(res.data)})
    .catch(err => console.log(err)) 
}
    const getpresses = async() => {
        await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getpresses.php`,r,config)
        .then(res => {
            setPresslist(res.data); 
        })
        .catch(err => console.log(err))
    }
    const getpresscurves = async() => {
        await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getpresscurves.php`,r,config)
        .then(res => {
            setPresscurve(res.data); 
        })
        .catch(err => console.log(err))
    }
    const getcutter = async() => {
      await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getcutter.php`,r,config)
      .then(res => {
        setCutter(res.data); 
      })
      .catch(err => console.log(err))
  }
  const getfolder = async() => {
    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getfolders.php`,r,config)
    .then(res => {
      setFolder(res.data); 
    })
    .catch(err => console.log(err))
}
const getstitching = async() => {
  await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getstitching.php`,r,config)
  .then(res => {
    setStitching(res.data); 
  })
  .catch(err => console.log(err))
}

getstitching()
getfolder()
    getpresses()
    getpresscurves()
    getcutter()
    paper()
//     setPresslist([
//  {"id":0,"name":"",itemid:0},
// {"id":9,"name":"Colormax",itemid:0},
// {"id":4,"name":"GL440",itemid:0},
// {"id":3,"name":"GL540",itemid:0},
// {"id":5,"name":"M110 - Folding",itemid:0},
// {"id":6,"name":"M110 - Sheeterg",itemid:0},
// {"id":7,"name":"Ricoh B&W",itemid:0},
// {"id":8,"name":"Ricoh Color",itemid:0},
// {"id":10,"name":"Ricoh Oversized",itemid:0}])    
},[reset])


const updateit = async(e,item,field,table) => {

let r = {
    table,
    field,
    value:e.target.value, 
    id:item.id
}

    await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatedynamic.php`,r,config)
    .then(res => {
      setReset(!reset)
    })
    .catch(err => console.log(err))
  
  }



  return (
    <PressContext.Provider value={{presslist,updateit,presscurve,cutter,folder,stitching,paperlist}}>
      
        {children}
    </PressContext.Provider>
  )
}

export default PressContext