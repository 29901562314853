
import { Flex, Flexed, MainWrap } from '../../utilities/Shared'
import Adminboxes from '../../utilities/Adminboxes'
import Navlinks from '../../utilities/Navlinks'
import { Link } from 'react-router-dom'
import {paperColumns}  from '../../components/tables/Columns'

import axios from 'axios'
import { useEffect, useState } from 'react'
import Table from '../../components/tables/Table'

const Admin = () => {

const [papers, setPapers] = useState([])

useEffect(() => {
const getpapers = async() => {
  await axios.get('https://highgroundapi.herokuapp.com/paper/all')
  .then(res => {
    setPapers(res.data.filter(item => item.category !== "Envelope"))
   // setEnvlist(res.data.filter(item => item.category === "Envelope"))
  })
  .catch(err => console.log(err))
}
getpapers()
},[])
 



 
  return (
    <MainWrap>
      <Table columns={paperColumns} data={papers} />
      <Flexed style={{flexWrap:"wrap",marginTop:"25px",fontSize:"calc(1rem + .4vw)"}} gap="15px">
        <Flex flex="0" ><Adminboxes header="Employees">
        <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Stock");localStorage.setItem('default2',0)}} >Active Employees</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Envelope");localStorage.setItem('default2',1)}} >Non-Active Employees</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Supplies");localStorage.setItem('default2',3)}} >Add Employee</Link>
            </Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="Supplies ">
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Stock");localStorage.setItem('default2',0)}} > Stock</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Envelope");localStorage.setItem('default2',1)}} > Envelopes</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Inks");localStorage.setItem('default2',2)}} > Inks</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Bindery");localStorage.setItem('default2',3)}} > Bindery</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Prepress");localStorage.setItem('default2',4)}} > Prepress</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Press");localStorage.setItem('default2',5)}} > Press</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Shipping");localStorage.setItem('default2',6)}} > Shipping</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Boxes");localStorage.setItem('default2',7)}} > Boxes</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Custodial");localStorage.setItem('default2',8)}} > Custodial</Link>
           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Maintenance");localStorage.setItem('default2',9)}} > Maintenance</Link>
            </Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="Vendors">
        <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Stock");localStorage.setItem('default2',0)}} >Active Vendors</Link>

           <Link style={{color:"#404040",padding:"3px 5px",display:"block",textAlign:"left"}} to="/supplies" onClick={e => {localStorage.setItem('default1',0);localStorage.setItem('companyheader',"PLS Print Supplies");localStorage.setItem('default2',3)}} >Add Vendor</Link>
              </Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="Inventory"></Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="&nbsp;"></Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="&nbsp;"></Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="&nbsp;&nbsp;"></Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="&nbsp;"></Adminboxes></Flex>
        <Flex flex="0"><Adminboxes header="Reports"></Adminboxes></Flex>


        </Flexed>  
        
        
        
      </MainWrap> 
  )
}

export default Admin