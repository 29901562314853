import React, { useContext, useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import { CSSTransition } from "react-transition-group";
import { Wrapper } from '../modals/ModalStyles'
//import Backdrop from "./Backdrop";
import "../modals/Modal.css";
import { Flex, Flexed, Input, Scroll } from "../utilities/Shared";
import Card from "./Card";
import axios from 'axios'
import { FaPaperclip } from "react-icons/fa6";
import { MdOutlineCloseFullscreen } from "react-icons/md";
import { v4 as uuidv4 } from 'uuid';
import UserContext from "../Context/UserContext";
import ScrollToBottom from 'react-scroll-to-bottom';
import ChatContext from "../Context/ChatContext";
import Conversations from "./Conversations";


const ModalOverlay = (props) => {



  const [unread, setUnread] = useState([])
  const [currentperson, setCurrentperson] = useState()
  const [currentmessage, setCurrentmessage] = useState()
  const [peeps, setPeeps] = useState()
  const [currentchat, setCurrentchat] = useState([])

    

const {userInfo} = useContext(UserContext)
const { socket,users,sendsinglemessage,newmessagecount}  = useContext(ChatContext)
const [messages, setMessages] = useState()
const [arrival, setArrival] = useState(null)
const [checkMessages, setCheckMessages] = useState(null)



const getAllMessages = async() => {
  const info = {
    id:userInfo._id
  }
const getmessages = await axios.post('https://highgroundapi.herokuapp.com/chat/all/messages',info)
.then(res => setUnread(res.data.filter(item => item.read === false)))
.catch(err => console.log(err))
}

const geteployeess = async() => {
  const getemps = await axios.get('https://highgroundapi.herokuapp.com/employee/active')
  .then(res => setPeeps(res.data))
  .catch(err => console.log(err))
  }

  const getUserMessages = async() => {
    const uy = {from:userInfo?._id,to:currentperson?._id}
    const getmessages = await axios.post('https://highgroundapi.herokuapp.com/chat/get/messages',uy)
    .then(res => {setCurrentchat(res.data);setCurrentchat(res.data)})
    .catch(err => console.log(err))
    }
   

    
useEffect(() => {
  geteployeess()
 
},[])

useEffect(() => {
  getUserMessages()
  updatenewmessages()

},[currentperson,userInfo])



useEffect(() => {
if(arrival && currentperson?._id === arrival?.sender){
  getUserMessages()
} else{
  getAllMessages()
}
  },[arrival])

  useEffect(() => {
      getAllMessages()
   
  },[checkMessages])


  

const updatenewmessages = async() => {
  const ty = {
    from:currentperson?._id,
    to:userInfo?._id
  }
  await axios.post('https://highgroundapi.herokuapp.com/chat/update/unread',ty)
.then(res => {newmessagecount(currentperson?._id,currentmessage)})
.catch(err => console.log(err))
}


const newmessage = async(e) => {
e.preventDefault()

const message = {
  messageto:currentperson._id,
  messagefrom:userInfo._id,
  message:currentmessage,
  attached:null,
  messageid:uuidv4(),
  read:false
}
await axios.post('https://highgroundapi.herokuapp.com/chat/new/message',message)
.then(res => {getUserMessages();//if(users.some(item => item.userId === currentperson._id)){
 let u =  users.filter(tre => tre.userId === currentperson._id)

 sendsinglemessage(currentperson._id,currentmessage)
 newmessagecount(currentperson._id,currentmessage)
 updatenewmessages()
 setCurrentmessage("")
//}
}
)
.catch(err => console.log(err))
}

useEffect(() => {
  socket.current.on('getMessage', data => {
      setArrival({
        sender: data.senderId,
        message: data.message,
        time: Date.now() 
      })
  })

  socket.current.on('newMessages', data => {
    setCheckMessages({
      sender: data.senderId,
      message: data.message,
      time: Date.now() 
    })
})
  },[])


  const content = (
    <Wrapper width={`${props.width || "90%"}`} style={{zIndex:"10000",background:"#2963B3",padding:"0px",margin:"0",height:"700px",minWidth:"900px",border:"1px solid #ADADAD"}} >
   <Flexed style={{height:"100%"}}>
    
    <Flex flex=".7" padding="0" >
    <div style={{margin:"30px 20px",textAlign:"center",position:"relative",fontSize:"calc(1.2rem + .2vw)",color:`${unread?.length > 0 ? "yellow" : "grey"}`,fontWeight:`${unread?.length > 0 ? "bold" : "normal"}`}} >{unread.length} Unread Message(s)</div>
    <Scroll style={{background:"#2963B3",padding:"0px",margin:"0",borderRadius:"8px", maxHeight:"550px"}}>
     
    

{
peeps?.filter(item => item?.departments?.some(cat => cat.label === "Chat") && item._id !== sessionStorage.getItem('trackuser')).map(item => {
                         
 return <Card  info={item} users={users} setCurrentperson={setCurrentperson} setCurrentchat={setCurrentchat} unread={unread}/>
})
} 
   
      </Scroll>
      </Flex>
  




    <Flex flex="2" bgColor="white" height="100%" padding="0" style={{borderRadius:"8px"}}>
<div style={{position:"relative",height:"80px",padding:"30px 20px",fontSize:"calc(1.4rem + .4vw)",background:"#EFEFEF",color:"#002B88",borderBottom:"2px solid #ADADAD",borderTop:"2px solid #ADADAD"}}>{currentperson?.preferredName} {currentperson?.lastName}<MdOutlineCloseFullscreen style={{position:"absolute",top:"30px",right:"30px",fontSize:"calc(2rem + .2vw)"}} onClick={() => props.setShowchat(false)} />
</div>

<Scroll style={{height:"75%",zIndex:"10000"}}>
<Conversations currentchat={currentchat} />
</Scroll>
<form onSubmit={newmessage}>
<div style={{height:"80px",position:"absolute",bottom:"0",width:"100%",padding:"30px 20px",fontSize:"calc(1.4rem + .2vw)",background:"#EFEFEF",color:"#002B88",borderBottom:"2px solid #ADADAD",borderTop:"2px solid #ADADAD",display:"flex",alignItems:"center"}}><div style={{padding:"0px 20px",margin:"0px 10px 0px -20px"}}><FaPaperclip style={{fontSize:"2rem"}} /></div><Input placeholder="Type a Message..." type="text" height="40px" fontSize="calc(1.3rem + .2vw)" disabled={!currentperson?._id ? true : false} value={currentmessage} onChange={e => setCurrentmessage(e.target.value)} /> </div>
</form>
    </Flex>
   </Flexed>
    <footer className={`modal__footer ${props.footerClass}`}>
      {props.footer}
    </footer>
    </Wrapper>
  );
  return ReactDOM.createPortal(content, document.getElementById("modal"));
};

const Chat = (props) => {
  return (
    <React.Fragment>
      {props.show }
      <CSSTransition
        in={props.show}
        mountOnEnter
        unmountOnExit
        timeout={200}
        classNames="modal"
      >
        <ModalOverlay {...props} />
      </CSSTransition>
    </React.Fragment>
  );
};

export default Chat;











/*
  
*/





