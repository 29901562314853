import React, { useContext, useEffect, useState } from 'react'
import { Button, Datepick, Flex, Flexed, Img, Input, Label, MainWrap, Selectbox, Textarea, Textauto } from '../../utilities/Shared'
import {CSRReps, customersList, CustomersList, SalesReps} from '../../utilities/Reps'
import Widthlength from '../../Assets/widthlength.png'
import axios from 'axios'
import UserContext from '../../Context/UserContext'
import Select from '../../utilities/Select'
import Component from './Component'
const RFQ = () => {


const [selectedCustomer, setSelectedCustomer] = useState()
const [selectedContact, setSelectedContact] = useState("")
const [contactlist, setContactlist] = useState([])
const [customerList, setCustomerList] = useState([])
const [usesize, setUsesize] = useState(false)
const [rfqinfo, setRfqinfo] = useState({enterdate:new Date(),quoteduedate:new Date((new Date()).valueOf() + 1000*3600*24),projectstyle:""})
const {employees,customers} = useContext(UserContext)
const [quantities, setQuantities] = useState([])
const [components, setComponents] = useState([{}])
useEffect(() => {
    const getcustlist = async() => {
        await axios.get('http://localhost:5003/customers/all')
        .then(res => {setCustomerList(res.data)})
        .catch(err => console.log(err))
    }
    getcustlist()
},[])





const setcustomer = (e,node) => {
    let inputValue = e.target.value
    console.log(inputValue)
    let customerdata
    let  dd
    var options = document.getElementById(node).childNodes;
    setSelectedCustomer({salesrep:"",custrep:"",contact:""})
    setContactlist([])
    setSelectedContact("")

    for (var i = 0; i < options.length; i++) {
      if (options[i].value.toLowerCase() === inputValue.toLowerCase()) {
        dd = options[i].dataset
        customerdata ={
         ...dd, 
       }
       let newcontacts = customerList?.filter(filt => filt._id === dd.itemid)
       console.log(newcontacts)

       let newcontact = newcontacts[0].contacts?.filter(filt => filt.contactid === dd.contact)
  

     setSelectedContact(newcontact[0]?.name)
       setSelectedCustomer(dd)
       setContactlist(newcontacts[0]?.contacts)
      }
} 
  //get new contacts
  


}

const setsalesrep = (e) => {
    let u = employees.filter(filt => filt._id === e)
   setSelectedCustomer({...selectedCustomer,salesrep:u[0]._id})
}

const csrrep = (e) => {
    let u = employees.filter(filt => filt._id === e.target.value)
    setSelectedCustomer({...selectedCustomer,custrep:u[0]._id})
}


const setquans = (e,index) => {
    let h = quantities
    h[index] = e.target.value
    setQuantities(h)

}



  return (
    <MainWrap>
<Flexed>
    <Flex fontSize="calc(1.6rem + .2vw)" padding="20px 20px 20px 0px">NEW RFQ</Flex>
</Flexed>
<Flexed>
    <Flex fontSize="calc(1rem + .2vw)" padding="5px" bgColor="#8398AB" color="white" fontWeight="bold" >CUSTOMER INFO</Flex>
</Flexed>
<Flexed gap="20px" padding="10px 0px" color="#418DA7">
    <Flex flex="2"><Label>Customer</Label><br />
    <Input  type="text"    list="customers"  onChange={e => setcustomer(e,"customers")} required/>
    <datalist id="customers">
{
    customers?.map((item,index) => {
     return   <option key={index}   data-name={item?.name} data-contact={item?.maincontact} data-itemid={item?._id} data-salesrep={item?.salesrep?._id} data-custrep={item?.custrep?._id} value={`${item?.name}`}/> 
    })
}
   </datalist>
    </Flex>
    <Flex><Label>Contact</Label><br /> <Input  type="text"  value={selectedContact || ""}   list="contacts"  onChange={e => setcustomer(e,"customers")} required/>
    <datalist id="contacts">
{
    contactlist?.map((item,index) => {
     return   <option key={index} value={`${item?.name}`}/> 
    })
}
   </datalist></Flex>
    <Flex><Label>Sales Rep</Label><br /><SalesReps  onChange={setsalesrep} value={selectedCustomer?.salesrep}/></Flex>
    <Flex><Label>CSR Rep</Label><br /><CSRReps onChange={csrrep} value={selectedCustomer?.custrep}/></Flex>
   
</Flexed>
<Flexed padding="10px 0px" color="#418DA7">
<Flex><Label>Prev Quote #</Label><br /><Input style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} onChange={e => setRfqinfo({...rfqinfo,prevquote:e.target.value})} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Label>Prev Job #</Label><br /><Input style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} onChange={e => setRfqinfo({...rfqinfo,prevjob:e.target.value})} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Label>Enter Date</Label><br /><Datepick  selected={rfqinfo?.enterdate} onChange={date => setRfqinfo({...rfqinfo,enterdate:new Date(date)})}/></Flex>
    <Flex><Label>Quote Due Date</Label><br /><Datepick  selected={rfqinfo?.quoteduedate} onChange={date => setRfqinfo({...rfqinfo,quoteduedate:new Date(date)})} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
    <Flex><Label>Job Due Date</Label><br /><Datepick selected={rfqinfo?.duedate} onChange={date => setRfqinfo({...rfqinfo,duedate:new Date(date)})} border="1px solid rgba(122, 187, 67, .5)" /></Flex>
</Flexed>
<Flexed style={{marginTop:"10px"}}>
    <Flex fontSize="calc(1rem + .2vw)" padding="5px" bgColor="#8398AB" color="white" fontWeight="bold" >PROJECT INFO</Flex>
</Flexed>

<Flexed style={{marginTop:"10px"}} gap="20px" color="#418DA7">
    <Flex flex="2" ><Label>Project Name</Label></Flex>
    <Flex ><Label>Product Style</Label> </Flex>
    <Flex flex=".1"></Flex>
    <Flex ><Label>Flat Size</Label></Flex>
    <Flex ><Label>Folded / Page Size</Label></Flex>
</Flexed>
<Flexed style={{marginTop:"10px"}} gap="20px" color="#418DA7">
    <Flex  flex="2"><Input style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} value={rfqinfo.projectname} onChange={e => setRfqinfo({...rfqinfo,projectname:e.target.value})}  border="1px solid rgba(122, 187, 67, .5)"/></Flex>
    <Flex >
    <Selectbox name="projectstyle" id="" tabindex="<?echo $tab;$tab++;?>" value={rfqinfo.projectstyle} onChange={e => setRfqinfo({...rfqinfo,projectstyle:e.target.value})} required>
					<option value="" disabled>Select Product Style</option>
<option value="Brochures">Brochures</option>
<option value="Postcards" >Postcards</option>
<option value="Stitched Books" >Stitched Books</option>
<option value="Pocket Folder" >Pocket Folder</option>
<option value="Perfect Bound Books" >Perfect Bound Books</option>
<option value="Coil Books" >Coil Books</option>
<option value="Flyers | Pamphlets | Inserts" >Flyers | Pamphlets | Inserts</option>
<option value="Letterhead" >Letterhead</option>
<option value="Business Cards" >Business Cards</option>
<option value="Poster" >Poster</option>
<option value="Envelope">Envelope</option>
<option value="News Letter">News Letter</option>
<option value="Mailing">Mailing</option>
<option value="Match Mailing" >Match Mailing</option>
<option value="Annual Report" >Annual Report</option>
<option value="Paradise Labels">Paradise Labels</option>
<option value="Trividia Books" >Trividia Books</option>
<option value="Promotional Products" >Promotional Products</option>
<option value="Special Product">Special Product</option>
<option value="Inventory Pull" >Inventory Pull</option>
<option value="Outside Only">Outside Only</option>
<option value="Mailing Only">Mailing Only</option>
<option value="Design Only" >Design Only</option>
<option value="Bindery Only">Bindery Only</option>
					</Selectbox>
    </Flex>
    <Flex flex=".1"></Flex>
    <Flex >
        <Flexed>
        <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">W</Flex>
        <Flex><Input  value={rfqinfo.flatw} onChange={e => setRfqinfo({...rfqinfo,flatw:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">L</Flex>
        <Flex><Input value={rfqinfo.flatl} onChange={e => setRfqinfo({...rfqinfo,flatl:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        </Flexed></Flex>

    <Flex ><Flexed>
    <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">W</Flex>
        <Flex><Input value={rfqinfo.finishw} onChange={e => setRfqinfo({...rfqinfo,finishw:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        <Flex flex="0" margin="8px 0px 0px 0px" fontWeight="bold"  fontSize="calc(1rem + .2vw)">L</Flex>
        <Flex><Input value={rfqinfo.finishl} onChange={e => setRfqinfo({...rfqinfo,finishl:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
        </Flexed></Flex>
</Flexed>
<Flexed>
    <Flex>
<Flexed padding="20px 0px" color="#418DA7">
<Flex flex=".5"><Label>Pages</Label><br /><Input  type="number" min="2" step="2" value={rfqinfo.pages} onChange={e => setRfqinfo({...rfqinfo,pages:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex flex=".5"><Label>Versions</Label><br /><Input  type="number" min="1" step="1" value={rfqinfo.versions} onChange={e => setRfqinfo({...rfqinfo,versions:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex flex=".5"></Flex>
<Flex><Label>Cover Style</Label><br />
<Selectbox name="projectstyle" id="" value={rfqinfo.coverstyle} tabindex="11" onChange={e => setRfqinfo({...rfqinfo,coverstyle:e.target.value})} required="">
					<option value="">Select Product Style</option>
<option value="Brochures">Brochures</option>
<option value="Postcards">Postcards</option>
<option value="Stitched Books">Stitched Books</option>
<option value="Pocket Folder">Pocket Folder</option>
<option value="Perfect Bound Books">Perfect Bound Books</option>
<option value="Coil Books">Coil Books</option>
<option value="Flyers | Pamphlets | Inserts">Flyers | Pamphlets | Inserts</option>
<option value="Letterhead">Letterhead</option>
<option value="Business Cards">Business Cards</option>
<option value="Poster">Poster</option>
<option value="Envelope">Envelope</option>
<option value="News Letter">News Letter</option>
<option value="Mailing">Mailing</option>
<option value="Match Mailing">Match Mailing</option>
<option value="Annual Report">Annual Report</option>
<option value="Paradise Labels">Paradise Labels</option>
<option value="Trividia Books">Trividia Books</option>
<option value="Promotional Products">Promotional Products</option>
<option value="Special Product">Special Product</option>
<option value="Inventory Pull">Inventory Pull</option>
<option value="Outside Only">Outside Only</option>
<option value="Design Only">Design Only</option>
<option value="Bindery Only">Bindery Only</option>
					</Selectbox>

</Flex>
     <Flex><Label>Press Check</Label><br /><Input  value={rfqinfo.presscheck} onChange={e => setRfqinfo({...rfqinfo,presscheck:e.target.value})} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
</Flexed>

<Flexed color="#418DA7">
    <Flex><Label>Quantites</Label></Flex>
</Flexed>
<Flexed padding="5px 0px 5px 0px">
<Flex><Input  defaultValue={quantities[0] || ""} onBlur={e => setquans(e,0)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[1] || ""} onBlur={e => setquans(e,1)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[2] || ""} onBlur={e => setquans(e,2)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[3] || ""} onBlur={e => setquans(e,3)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[4] || ""} onBlur={e => setquans(e,4)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
</Flexed>
<Flexed padding="5px 0px 20px 0px">
<Flex><Input  defaultValue={quantities[5] || ""} onBlur={e => setquans(e,5)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[6] || ""} onBlur={e => setquans(e,6)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[7] || ""} onBlur={e => setquans(e,7)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[8] || ""} onBlur={e => setquans(e,8)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
<Flex><Input  defaultValue={quantities[9] || ""} onBlur={e => setquans(e,9)} style={{borderLeft:"20px solid rgba(122, 187, 67, .5)"}} border="1px solid rgba(122, 187, 67, .5)"/></Flex>
</Flexed>
<Flexed color="#418DA7">
    <Flex>
    <Label>Job / Version Details</Label><br />
    <Textauto value={rfqinfo?.jobnotes} />

    
    </Flex>

</Flexed>

    </Flex>
    <Flex textAlign="left" flex=".6">

<Flexed>
<Flex textAlign="right" top="20px" ><Button zIndex="10" fontSize="calc(1rem + .2vw)" >Use Size Thru Out</Button></Flex>
</Flexed>
<Flexed>
<Flex > <Img src={Widthlength}   maxWidth="95%" /></Flex>
</Flexed>
    </Flex>
</Flexed>
{/*
/////////////////////////Components
*/}
<Flexed style={{marginTop:"10px"}} gap="0">
    <Flex fontSize="calc(1rem + .2vw)" padding="5px" bgColor="#8398AB" color="white" fontWeight="bold" >Components</Flex>
    <Flex fontSize="calc(1rem + .2vw)" padding="5px" bgColor="#8398AB" color="white" fontWeight="bold"  onClick={e => setComponents([...components,{name:""}])}>++</Flex>
</Flexed>

{
    components?.map(compnent => {
        return <Component />
    })
}

{/*
/////////////////////////Bindery
*/}
<Flexed style={{marginTop:"10px"}}>
    <Flex fontSize="calc(1rem + .2vw)" padding="5px" bgColor="#8398AB" color="white" fontWeight="bold" >Bindery</Flex>
</Flexed>




{/*
/////////////////////////Mailing
*/}
<Flexed style={{marginTop:"10px"}}>
    <Flex fontSize="calc(1rem + .2vw)" padding="5px" bgColor="#8398AB" color="white" fontWeight="bold" >Mailing</Flex>
</Flexed>





    </MainWrap>
  )
}

export default RFQ









