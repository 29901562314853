import React from 'react'
import { Button, Flex, Flexed, Input, Label, PhoneNumber, Selectbox } from '../../utilities/Shared'
import { useState } from 'react'
import axios from 'axios'
import { v4 as uuidv4 } from 'uuid';
import States from '../../utilities/States';
import { toast } from 'react-toastify'
import DeleteConfirm from '../../utilities/DeleteConfirm';
import Modal from '../../modals/Modal';

const EditLocation = ({onCancel,customer,setReset,reset}) => {

    const[ newinfo, setNewinfo] = useState(customer)
    const[ deletelocation, setDeletelocation] = useState(false)
  
    

    const slip = async(info,id) => {

      console.log(info)
      const config = {
          headers: {
            'Content-Type': 'application/json',
            'Access-Control-Allow-Origin':'*' 
          }
        };   
        

        const tr = {
          company:info.company || "",
          name:info.name || "",
          address1:info.address1 || "",
          address2:info.address2 || "",
          city:info.city || "",
          state:info.state || "",
          zip: info.zip || "",
          attn:info.attn || "",
          phone:info.phone || "",
          locationid:info.locationid || "",
          old:info.old || ""     
    } 
    if(newinfo.old){tr.old = newinfo.old}else{tr.old=null}
    if(newinfo.itemid){tr.locationid = newinfo.itemid}else{tr.locationid=null}
    console.log(tr)
axios.post(`https://highgroundsoftware.com/dash/dashboard/php/updatelocation.php`,tr,config)
  .then(res => {console.log(res.data);toast.success('Saved');setReset(!reset);onCancel()})
.catch(err => toast.error(err))
  }


    const addNewLocation = async(e) => {
e.preventDefault()
const y = {
    info:newinfo
}
console.log(y)
       await axios.post(` https://highgroundapi.herokuapp.com/customers/update/location`,y)
      .then(res => {slip(newinfo,newinfo.itemid)})
      .catch(err  => console.log(err))
      
    }


    const deleteLocation = async(locationid) => {
     let  y =  {
        locationid:locationid
      }
     await axios.post(` https://highgroundapi.herokuapp.com/customers/delete/location`,y)
      .then(res => {setReset(!reset);onCancel();})
      .catch(err  => console.log(err))
    }



    console.log(newinfo)
  return (

    <form onSubmit={e => addNewLocation(e)}>

       <Modal show={deletelocation} header={`Confirm you are deleting location -- ${newinfo?.name}`} onCancel={() => {setDeletelocation(false)}} ><DeleteConfirm  reset={reset} setReset={setReset} handleChange={e => deleteLocation(newinfo.itemid)} onCancel={() => {setDeletelocation(false)}} /></Modal>
    <Flexed gap="20px">
      
        <Flex><Label>Address 1</Label><Input type="text" value={newinfo?.address1} onChange={e => setNewinfo({...newinfo,address1:e.target.value})}/></Flex>
        <Flex><Label>Address2</Label><Input type="text" value={newinfo?.address2} onChange={e => setNewinfo({...newinfo,address2:e.target.value})}/></Flex>
        <Flex><Label>Location Name</Label><Input type="text" value={newinfo?.name} onChange={e => setNewinfo({...newinfo,name:e.target.value})} required/></Flex>
        <Flex flex=".8"><Label>Phone</Label><Input type="text" maxLength="16" value={PhoneNumber(newinfo?.phone)} onChange={e => setNewinfo({...newinfo,phone:e.target.value})}/></Flex>
       
    </Flexed>
        <Flexed gap="20px" alignItems="flex-end">
        <Flex flex="1.5"><Label>City</Label><Input type="text" value={newinfo?.city} onChange={e => setNewinfo({...newinfo,city:e.target.value})}/></Flex>
        <Flex flex=""><Label>State</Label><States value={newinfo?.state} onChange={e => setNewinfo({...newinfo,state:e.target.value})} /></Flex>
        <Flex ><Label>Zip</Label><Input type="text" value={newinfo?.zip} onChange={e => setNewinfo({...newinfo,zip:e.target.value})}/></Flex>
        <Flex><Label>Attn</Label><Input type="text" value={newinfo?.attn} onChange={e => setNewinfo({...newinfo,attn:e.target.value})}/></Flex>
        <Flex display="flex" justifyContent="flex-end" ><Button type="button" height="30px" onClick={onCancel}>Close</Button></Flex>
        <Flex display="flex" justifyContent="flex-end" ><Button height="30px">Edit Location</Button></Flex>
    </Flexed>

    <Flexed padding="25px 0px 0px 0px">
      <Flex><Button bgcolor="#ff0000" type="button" onClick={e => setDeletelocation(true)}>DELETE</Button></Flex>
      </Flexed>
    </form>
    
 
  
  )
}

export default EditLocation


