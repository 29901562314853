import styled from "styled-components";

export const Wrapper = styled.div`
z-index: 5000;
position: fixed;
top: 100px;
width: ${props => props.width || "90%" };
min-height20vh;
max-height:${props => props.maxHeight || "80vh" };

background: #8498AC;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
border-radius: 8px;
left:calc((100% - ${props => props.width || "90%" }) / 2);


.modal__content {
    padding:.5rem 1.5rem 1rem 1.5rem;
    background:${props => props.contentbgcolor || "#8498AC" };
    color:white;
  }
`;

export const Header = styled.div`
h2{
  color:${props => props.headercolor || "black" };
  padding:${props => props.padding || "20px 0px 10px 20px" };
  font-size:${props => props.fontSize || "calc(1.4rem + .2vw)" };
  text-align:${props => props.headertextAlign || "" };
  background-color:${props => props.headerbgcolor || "" };
}

`;