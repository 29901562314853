import React, { useContext } from 'react'
import { Flex, Flexed, Input, MainWrap, Scroll, Wrap } from '../../utilities/Shared'
import PressContext from '../../Context/PressContext'
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs'
import UserContext from '../../Context/UserContext'

const PressEdit = () => {

    const {presslist,updateit,presscurve} = useContext(PressContext)
const {userInfo} = useContext(UserContext)






  return (
    <Wrap>

<Tabs style={{padding:"10px 0px",margin:"0px"}}>
    <TabList >
     {userInfo.empId === "1537" || userInfo.empId === "1648" ? <Tab className="react-tabs__tab c">Press Details</Tab> :""}
     {userInfo.empId === "1537" || userInfo.empId === "1648" ? <Tab className="react-tabs__tab c">Press Curves</Tab> :""}
    </TabList>

    {userInfo.empId === "1537" || userInfo.empId === "1648" ?  <TabPanel>
    <Scroll height="70vh">
    <Flexed >
    <Flex flex=".15" margin="0px 0px 10px 0px">
        <Flexed flexDirection="column" margin="0px 0px 10px 0px">
            <Flex fontSize="calc(1rem + .2vw)"><Input width="0px" style={{opacity:"0"}}/>&nbsp;</Flex>
            <Flex  ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/>Description</span></Flex>
            <Flex  ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Min-Width</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Min-Length</span></Flex>
            <Flex  ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Max-Width</span></Flex>
            <Flex  ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Max-Length</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Caliper</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Gripper</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Speed</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Plate Cost</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Press Type</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> M/R Time</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Max Inks</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Hourly Cost</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/>Wash / Unit</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Perfector</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Coater</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Active</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Per Click</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> B&W Click</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Over Click</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Over B&W Click</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Staples</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Coat Blanket</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Norm Blanket</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> S44</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Job Percent</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> S2</span></Flex>
            <Flex ><span style={{height:"30px",fontSize:"calc(1rem + .2vw)"}}><Input width="0px" style={{opacity:"0"}}/> Clear Coat</span></Flex>
        </Flexed>
    </Flex>

    <Flex>
        <Flexed bgColor="#0E425A" padding="6px 0px " margin="0px 0px 10px 0px" color="white" position="sticky" top="0px" style={{zIndex:"100"}}>
        {
    presslist.map( press => {
     return <Flex fontSize="calc(1rem + .2vw)">{press.pname}</Flex>
         
    }) 
 }
        </Flexed>

<Flexed>
{presslist.map( press => {
     return  <Flex>
        <Flexed flexDirection="column">
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.description} onBlur={e => updateit(e,press,'description','press')} /> </Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.minwidth} onBlur={e => updateit(e,press,'minwidth','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.minlength} onBlur={e => updateit(e,press,'minlength','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.maxwidth} onBlur={e => updateit(e,press,'maxwidth','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.maxlength} onBlur={e => updateit(e,press,'maxlength','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.maxcaliper} onBlur={e => updateit(e,press,'maxcaliper','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.gripper} onBlur={e => updateit(e,press,'gripper','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.speed} onBlur={e => updateit(e,press,'speed','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.platecost} onBlur={e => updateit(e,press,'platecost','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.presstype} onBlur={e => updateit(e,press,'presstype','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.mreadytime} onBlur={e => updateit(e,press,'mreadytime','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.maxinks} onBlur={e => updateit(e,press,'maxinks','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.hourlycost} onBlur={e => updateit(e,press,'hourlycost','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.washtimeunit} onBlur={e => updateit(e,press,'washtimeunit','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.perfector} onBlur={e => updateit(e,press,'perfector','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.coater} onBlur={e => updateit(e,press,'coater','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.active} onBlur={e => updateit(e,press,'active','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.perclick} onBlur={e => updateit(e,press,'perclick','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.bwclick} onBlur={e => updateit(e,press,'bwclick','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.oversizeclick} onBlur={e => updateit(e,press,'oversizeclick','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.oversizebw} onBlur={e => updateit(e,press,'oversizebw','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.staples} onBlur={e => updateit(e,press,'staples','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.coaterblanket} onBlur={e => updateit(e,press,'coaterblanket','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.normalblanket} onBlur={e => updateit(e,press,'normalblanket','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.proofone} onBlur={e => updateit(e,press,'proofone','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.jobpercent} onBlur={e => updateit(e,press,'jobpercent','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.s2} onBlur={e => updateit(e,press,'s2','press')}  /></Flex>
            <Flex><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.clearcoat} onBlur={e => updateit(e,press,'clearcoat','press')}  /></Flex>

           

        </Flexed>
        </Flex>
         
    }) 
 }
</Flexed>
        
    
       

    </Flex>

</Flexed>
</Scroll>
    </TabPanel> :""}



    {userInfo.empId === "1537" || userInfo.empId === "1648" ?  <TabPanel>
    <Scroll height="70vh">
  
        
    <Flexed bgColor="#0E425A" padding="6px 0px" margin="0px 0px 10px 0px" color="white" position="sticky" top="0px" style={{zIndex:"100"}}>
         <Flex fontSize="calc(1rem + .2vw)">Press</Flex>
         <Flex fontSize="calc(1rem + .2vw)">Quantity From</Flex>
         <Flex fontSize="calc(1rem + .2vw)">Quantity To</Flex>
         <Flex fontSize="calc(1rem + .2vw)">Speed</Flex>
          </Flexed>   

     {presslist.map(item => {
return <><Flexed bgColor="rgb(0,0,0,.3)">
    <Flex fontSize="calc(1.3rem + .2vw)"  padding="6px 0px">{item.pname}</Flex>
</Flexed>
{presscurve.filter(filt => filt.press === item.id).map(press => {
    return   press.press > 0 && press.press && <Flexed padding="6px 0px" className="normalrow">
            <Flex fontSize="calc(1rem + .2vw)"></Flex> 
            <Flex fontSize="calc(1rem + .2vw)"><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.qfrom} onBlur={e => updateit(e,press,'qfrom','presscurve')} />
          </Flex>
            <Flex fontSize="calc(1rem + .2vw)"><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.qto} onBlur={e => updateit(e,press,'qto','presscurve')} /></Flex>
            <Flex fontSize="calc(1rem + .2vw)"><Input fontSize="calc(.9rem + .2vw)" type="text" defaultValue={press.speed} onBlur={e => updateit(e,press,'speed','presscurve')} /></Flex>
             </Flexed>
})  
     }
       
    </>
     }) 
    }   

        

        
       


        
  

</Scroll>
    </TabPanel> :""}
    </Tabs>

       
<br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br /><br />

    </Wrap>
  )
}

export default PressEdit

