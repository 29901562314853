import React, { useRef,useEffect ,useState} from "react";

import LOGO from '../Assets/pls-logo.png'

import {AiFillPrinter} from 'react-icons/ai';
import styled from 'styled-components'
import ReactToPrint from "react-to-print";
import TextareaAutosize from 'react-textarea-autosize';
import { Img, Label } from  '../utilities/Shared';
import Moment from 'react-moment'
import axios from 'axios'

export const Wrapper = styled.div`
padding:20px 40px;
font-size:.8rem;

text-transform:capitalize;
font-family: Arial, Helvetica, sans-serif;
`;
export const Input = styled.input`

position: ${props => props.position || "relative"};

width:${props => props.width || "90%"};
padding:${props => props.padding || "5px 3px 0px 3px"};
max-width:${props => props.maxWidth || "100%"};
margin:${props => props.margin || "0px 0px 0px 0px"};
height: ${props => props.height || ""};
font-size:${props => props.fontSize || ".8rem"};
text-align: ${props => props.textalign || "center"};
font-weight: ${props => props.fontWeight || "bold"};

outline:none;
border:none;
border-bottom:1px solid grey;
border-radius:5px;
:focus{
  border:2px solid var(--plsGreen);
}
`;
export const Flexed = styled.div`
width:${props => props.width || "100%"};
gap:${props => props.gap || ""};
display:flex;
justify-content:${props => props.justifyContent || "flex-end"};
align-items:${props => props.alignItems || "flex-start"};
position: ${props => props.position || "relative"};
padding: ${props => props.padding || "3px 0px"};
bottom: ${props => props.bottom || ""};
font-size:${props => props.fontSize || ""};
margin:${props => props.margin || ""};
border-bottom: ${props => props.borderBottom || ""};
border-top: ${props => props.borderTop || ""};
border: ${props => props.border || ""};
background-color: ${props => props.bgColor || ""};
`;

export const Flex = styled.div`
width:${props => props.width || "100%"};
position: ${props => props.position || "relative"};
flex:${props => props.flex || "1"};
white-space: ${props => props.whiteSpace || "nowrap"};
flex: ${props => props.flex || "1"};
border-bottom: ${props => props.border || ""};
text-align:${props => props.textAlign || "left"};
padding: ${props => props.padding || "0"};
font-size:${props => props.fontSize || ""};
border-bottom: ${props => props.borderBottom || ""};
align-items:${props => props.alignItems || "flex-start"};

h2{
    margin:0;
    padding:0:
}
`;



export const Textarea = styled(TextareaAutosize)`
font-size:1.4rem;
width:100%;
border:none;
padding:10px 10px 40px 10px;
resize:none;
:focus{
    border:2px solid var(--plsGreen);
  }

`;
export const Headerspan = styled.div`
width:100%;
text-align:center;
font-size:1.1rem;
`;
export const currency = (num) => {
    return  "$"+parseFloat(num).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

export default function PrintComponent({elector,quantity,jobnum,duedate,reset,setReset,cost}) {
  let componentRef = useRef();

const [message, setMessage] = useState("")
const [total, setTotal] = useState(0)
const date = new Date()

  useEffect(() => {
const getpoinfo = async() => {

}

getpoinfo()
  })
  function percentage(partialValue, totalValue) {
    const t = (partialValue / 100) * totalValue
    return t + totalValue
 }      




  return (
    <>
      <Flex id="print_component">
        {/* button to trigger printing of target component */}
        <ReactToPrint
      /*  onBeforePrint={() => {const addit = async() => {
            const y = {
            id:elector.id,
                name:elector.name,
                jobname:elector.jobname,
                numup:elector.numup,
                mr:elector.mr,
                stock:elector.stock,
                description1:elector.description1,
                description2:elector.description2,
                description3:elector.description3,
                address:elector.adress,
                foldtype:elector.foldtype,
                cost:elector.cost,
                quantity,
                duedate,
                job:jobnum
            }
await axios.post(`https://supervoter-0eae7d1dca36.herokuapp.com/elect/addelect`,y)
.then(res => console.log())
.catch(err => console.log(err))
        };addit()}}*/
          trigger={() => <AiFillPrinter style={{fontSize:"calc(1.4rem + .5vw)"}} />}
          content={() => componentRef}
          onAfterPrint={() => setReset(!reset)}
        />

        {/* component to be printed */}
        <Flex style={{ display: "none" }}>
          <ComponentToPrint quantity={quantity} date={date} cost={cost} elector={elector} jobnum={jobnum} duedate={duedate} ref={(el) => (componentRef = el)} />
        </Flex>
      </Flex>
    </>
  );
}
//setReset(!reset)

// component to be printed
class ComponentToPrint extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
        date: new Date().toDateString()
      };
      console.log(props)
  }


  render() {
    return (

      <Wrapper style={{background:"white"}}>

<Flexed margin="25px 0px 0px 0px">
<Flex flex="1.6" style={{fontSize:"2.8rem",textAlign:"left",top:"10px",fontStyle:"italic",padding:"7px 150px 7px 20px"}}>Production Ticket</Flex>
<Flex flex="auto" textAlign="right"><Img src={LOGO} alt="Logo" style={{width:"180px",left:"0px",}}/></Flex>
    <Flex padding="0px 0px 0px 30px" fontSize="1.4rem" textAlign="right">Generated <Moment style={{fontWeight:"bold",fontSize:"1.4rem"}} format="MM/DD/YYYY">{this.props.date}</Moment><br />at {this.props.date.getHours()}:{this.props.date.getMinutes()} by<br />{sessionStorage.getItem('fullName')}</Flex>
  
</Flexed>

<Flexed style={{marginTop:"15px"}}>
    <Flex  whiteSpace="wrap" flex="2" style={{fontSize:"1.2rem",paddingTop:"10px"}}>Customer<br/><span style={{fontSize:"3.5rem",fontWeight:"bold"}}>{this.props.elector.name}</span></Flex>
    <Flex style={{fontSize:"1.2rem",textAlign:"left",paddingTop:"10px"}}>Date Due<br/><Moment format="MM/DD/YYYY" style={{fontSize:"3.7rem"}}>{this.props.elector?.duedate}</Moment></Flex>
    <Flex flex=".4" style={{fontSize:"6.7rem",fontWeight:"bold"}}>{this.props.jobnum}</Flex>
</Flexed>

<Flexed border="1px solid black" padding="5px 0px">
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Salesperson<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>Nate B</span></Flex>
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>CSR<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>Kelly A</span></Flex>
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Quote#<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>34807</span></Flex>
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Job Type<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}>New</span></Flex>
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Prev Job#<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}></span></Flex>
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>PO#<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}></span></Flex>
<Flex  flex="1" style={{fontSize:"1.2rem",paddingLeft:"10px"}}>Date Entered<br/><span style={{fontSize:"1.8rem",fontWeight:"bold",paddingLeft:"10px"}}><Moment format="MM/DD/YYYY" style={{fontSize:"1.8rem",paddingLeft:"10px"}}>{new Date()}</Moment></span></Flex>
</Flexed>


<Flexed>
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}}>Quantity<br /><span style={{fontSize:"3rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props?.quantity}</span></Flex>
    <Flex flex="5" style={{fontSize:"1.2rem",paddingLeft:"10px",borderRight:"1px solid black"}} whiteSpace="normal">Job Name <br /><span style={{fontSize:"3rem",fontWeight:"bold",paddingLeft:"10px"}}>{this.props.elector?.jobname}</span></Flex>
</Flexed>

<Flexed border="1px solid black" style={{backgroundColor:"rgb(0,0,0,.1)"}}>
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Description</Flex>
</Flexed>
<Flexed gap="10px" padding="10px" border="1px solid black" >
    <Flex><div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{this.props.elector?.description1}</div></Flex>
    <Flex><div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{this.props.elector?.description2}</div> </Flex>
    <Flex><div style={{fontSize:"1.4rem",width:"100%",whiteSpace:"pre-wrap",padding:"0px 20px"}}>{this.props.elector?.description}</div> </Flex>
</Flexed>

<div style={{border:"1px solid rgb(0,0,0, .3)"}}>
<Flexed border="1px solid black" style={{backgroundColor:"rgb(0,0,0,.1)",marginTop:"15px"}}>
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Pre-Press</Flex>
</Flexed>
<Flexed gap="10px" padding="10px" border="1px solid black" style={{borderTop:"0px",}}>
    <Flex><Textarea style={{border:"1px solid red"}} value={this.props.elector?.prepressnotes} /></Flex>
</Flexed>

</div>


{/*/////////////////////////////////////////////////PRESS////////////////////////////////////////////////////////////////////////////////////////*/}

<div style={{border:"1px solid rgb(0,0,0, .3)"}}>
<Flexed border="1px solid black" style={{backgroundColor:"rgb(0,0,0,.1)"}}>
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Press Components</Flex>
</Flexed>
{/*this.props.imposition.map((item,index) => {
return <>
<div style={{pageBreakBefore:"auto",pageBreakAfter:"auto"}} className="normalrows">
  {this.props.forms.filter(filt => filt.item == item.id).map((newitem,index) => {
return <>
<div className="normalrows">
   <Flexed padding="5px" >
  <Flex top="21px" fontSize="1.4rem" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.4rem" >{newitem.versions} Ver(s)</Flex>
  <Flex  fontSize="1.4rem" >{newitem.pages} Page(s)</Flex>
  <Flex  fontSize="1.4rem" >{newitem.numup} Up</Flex>
 
  <Flex fontSize="1.4rem">{this.props.methods.filter(filt => filt.name === newitem.method).map(item => {
           return <>{item.name}</>
           })
}</Flex>


    <Flex flex=".7" fontSize="1.4rem">{this.props.presses.filter(filt => parseInt(filt.id) === parseInt(newitem.pressid)).map(item => {
           return <>{item.name}</>
           })
}</Flex>

  </Flexed>
  <Flexed  padding="8px"  >
 <Flex  flex="1.8" fontSize="1.4rem" >{newitem.paper} </Flex>
 
    
      <Flex flex="1" fontSize="1.4rem" padding="0px 5px 0px 0px">Front:{newitem.fink} {newitem.faq > "" ? `+ ${item.faq}` : ""}</Flex>
      <Flex flex=".3"></Flex>
     <Flex flex="1" padding="0px 5px 0px 0px" fontSize="1.4rem">Back:{newitem.bink} {newitem.baq > "" ? `+ ${item.baq}` : ""}</Flex>
     <Flex flex=".3"></Flex>
     </Flexed>
  <Flexed borderBottom="1px solid grey">
  <Flex flex="1.2" fontSize="1.4rem">
      {newitem.length} X {newitem.width}
      </Flex>
    <Flex flex=".8"></Flex>
     <Flex flex="1"  fontSize="1.4rem" textAlign="center">To Press: {newitem.topress.to} </Flex>
       <Flex flex="1"  fontSize="1.4rem" textAlign="center">M/R: {newitem.topress.mr}</Flex>
       <Flex flex="1"  fontSize="1.4rem" textAlign="center">Off Press: {newitem.topress.off} </Flex>
       <Flex flex=".3"></Flex>
       <Flex flex="1"  borderBottom="1px solid black" fontSize="1.4rem" textAlign="left">Yeild: </Flex>
     </Flexed>
</div>
</>

}
  )}

  </div>

</>

}
)}
*/}
  <Flexed gap="10px" borderBottom="1px solid black">
    <Flex><Textarea  value={this.props.elector?.pressnotes || "No notes currently"} /></Flex>
</Flexed>
{/*/////////////////////////////////////////////////STOCK////////////////////////////////////////////////////////////////////////////////////////*/}
<div style={{pageBreakAfter:"auto",pageBreakBefore:"auto",padding:"0px 5px"}}>
<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.1)"  margin="20px 0px 0px 0px" >
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Stock</Flex>  
    <Flex fontSize="1.4rem" padding="0px 10px 0px 0px">Stock Status: Allocated</Flex>
   <Flex fontSize="1.4rem">Pull / Cut:  Robert H
   </Flex>
</Flexed>


<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.4rem" flex="3" >Stock</Flex>
  <Flex fontSize="1.4rem"> ID</Flex>
  <Flex fontSize="1.4rem">LBS</Flex>
  <Flex fontSize="1.4rem">PO #</Flex>
  <Flex fontSize="1.4rem" flex="1.5">Amount</Flex>
  <Flex fontSize="1.4rem">#Out</Flex>
  <Flex fontSize="1.4rem" flex="1.8">Size</Flex>
  <Flex fontSize="1.4rem" flex="2">Supplier</Flex>
  
  <Flex fontSize="1.4rem">Ordered</Flex>
  <Flex fontSize="1.4rem">Due</Flex>

</Flexed>
{/*this.props.imposition.map(item => {
return <Flexed padding="4px 0px" style={{borderBottom:".5px solid black"}}>
<Flex fontSize="1.3rem" flex="3" whiteSpace="wrap">{item.paper}</Flex>
<Flex fontSize="1.3rem">{item.paperid}</Flex>
<Flex fontSize="1.3rem"></Flex>
<Flex fontSize="1.3rem">{this.props.po?.map(pur => {return <>{pur?.ponum}<br /></>})}</Flex>
<Flex fontSize="1.3rem" flex="1.5">{parseInt(item.needed) + parseInt(item.additionalmr) + parseInt(item.mroverride)}</Flex>
<Flex fontSize="1.3rem">{item.parentout}</Flex>
<Flex fontSize="1.3rem" flex="1.8">{item.parentsheetl} X {item.parentsheetw}</Flex>
<Flex fontSize="1.3rem" flex="2">{this.props.po?.map(pur => {return <>{pur.vendor.company?.name}<br /></>})}</Flex>

<Flex fontSize="1.3rem">{this.props.po?.map(pur => {return <><Moment format="MM/DD/YY" style={{fontSize:"1.3rem"}}>{pur.createdAt}</Moment><br /></>})}</Flex>
<Flex fontSize="1.3rem">{this.props.po?.map(pur => {return <><Moment format="MM/DD/YY" style={{fontSize:"1.3rem"}}>{pur.dueDate}</Moment><br /></>})}</Flex>
{/*<Flex fontSize="1.3rem">{this.props.po?.map(purr => purr.items.filter(fil => parseInt(fil.stockid) === parseInt(item.paperid) && parseInt(fil.jobnum) === parseInt(this.props.elector?.job)).map(it => {return it.recievedstatus ? "Arrived" : "Awaiting"}))}</Flex>*/}
{/*</Flexed>

})*/
}
<Flexed gap="10px" padding="10px" borderBottom="1px solid black">
    <Flex><Textarea  value={this.props.elector?.papernotes || "No notes currently"} /></Flex>
</Flexed>
</div>

{/*/////////////////////////////////////////////////Bindery////////////////////////////////////////////////////////////////////////////////////////*/}

</div>
<div div style={{pageBreakInside:"avoid"}} >
<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)"  margin="20px 0px 0px 0px">
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Bindery</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>


{/*this.props.imposition.map((item,index) => {
return <>
<Flexed bgColor="rgb(14,66,90,.4)" padding="3px" alignItems="center" >
  <Flex fontSize="1.4rem" fontWeight="bold" flex="7.5">{item.component} &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{item.numforms} Forms&nbsp; - &nbsp;{item.versions} version &nbsp;&nbsp; {item.pages}Pg / &nbsp;&nbsp; {item.numforms} - {item.pages / item.numforms} page(s) </Flex>
  {parseInt(item.numforms) > 1 &&   <>
       <Flex  fontSize="1.4rem">To Bindery: {(parseInt(item.needed) + parseInt(item.additionalmr) + parseInt(item.mroverride) - parseInt(item.mroverride)) * parseInt(item.versions)}</Flex></>}
   </Flexed>
  {this.props.forms.filter(filt => filt.item == item.id).map((newitem,index) => {
return <>
   <Flexed  padding="3px" style={{marginBottom:"15px",borderBottom:"1px solid grey"}}>
  <Flex top="21px" fontSize="calc(1rem + .2vw)" fontWeight="bold" flex="1" >{newitem.name}</Flex>
  <Flex  fontSize="1.4rem" flex=".3">Pages:<br /> {parseInt(newitem.pages)}</Flex>
    <Flex  fontSize="1.4rem" flex=".3">Forms:<br /> {parseInt(newitem.numforms)}</Flex>
    <Flex  fontSize="1.4rem" flex=".3">Ver's:<br /> {newitem.versions}</Flex>
       <Flex fontSize="1.4rem">  Sheet Size:<br />{newitem.length} X {newitem.width}</Flex>
       <Flex fontSize="1.4rem">Piece Size:<br />{item.finishsizel} X {item.finishsizew}</Flex>
      <Flex  fontSize="1.4rem" flex=".3">Cuts:<br /> {parseInt(item.cuts)}</Flex>
      <Flex  fontSize="1.4rem" >Fold Up:<br />{parseInt(item.foldsup)} </Flex>
      <Flex  fontSize="1.4rem" >Fold Style:<br /> </Flex>
      <Flex  fontSize="1.4rem" flex=".5">Die Cut:<br /> {parseInt(item.diecut) == 1 ? "Yes" : "No"}</Flex>
       
  </Flexed>
</>
}
  )}
</>
}
)*/}

</div>
<div style={{pageBreakInside:"avoid"}}>
<Flexed style={{marginTop:"-5px"}}>
<Textarea value={this.props.elector?.binderynotes} placeholder="Bindery Notes" minRows="1" rows="1"/>
</Flexed>
</div>
<Flexed gap="20px" style={{marginTop:"25px",pageBreakInside:"avoid"}}>
 
  <Flex >
    <Flexed border="1px solid black" >
      <Flex  fontSize="1.4rem" style={{backgroundColor:"rgb(0,0,0,.2)"}}  padding="5px 0px 5px 5px" >Bindery Counts</Flex>
    </Flexed>
    <Flexed border="1px solid black">
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>Form</Flex>
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>Cutting</Flex>
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>Folding</Flex>
      <Flex fontSize="1.4rem" padding="5px 0px 5px 5px">Stitching</Flex>
    </Flexed>
    {/*
  this.props.forms.map(ite => {
   return   <Flexed border="1px solid black">
   <Flex fontSize="1.4rem" padding="5px 0px 5px 5px" style={{borderRight:"1px solid black",display:"flex",alignItams:"center"}}>{ite.name}</Flex>
   <Flex fontSize="1.4rem"  padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>&nbsp;</Flex>
   <Flex fontSize="1.4rem"  padding="5px 0px 5px 5px" style={{borderRight:"1px solid black"}}>&nbsp;</Flex>
   <Flex fontSize="1.4rem" padding="5px 0px 5px 5px">&nbsp;</Flex>
 </Flexed>
  })
*/}
  </Flex>
</Flexed>




<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)"  margin="20px 0px 0px 0px">
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Mailing</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>
<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.4rem" flex="2.5">Mail Processing</Flex>
  <Flex fontSize="1.4rem"> Ink Jet</Flex>
  <Flex fontSize="1.4rem">Tabbing</Flex>
  <Flex fontSize="1.4rem">Inserting</Flex>
  <Flex fontSize="1.4rem">Mail Match</Flex>
  <Flex fontSize="1.4rem">Scratch-Off</Flex>
  <Flex fontSize="1.4rem">Political</Flex>
</Flexed>



{/*this.props.osp?.map(item => {
return <Flexed padding="7px 0px" borderBottom="1px solid black">
    <Flex fontSize="1.1rem" flex="4" >{item.description}</Flex>
<Flex fontSize="1.2rem" >
{item.quan1}
</Flex>
<Flex fontSize="1.2rem" flex="2">{item.vendor}</Flex>
<Flex fontSize="1.2rem" >{item.cusquote}</Flex>
<Flex fontSize="1.2rem" flex=".5">{item.quote}</Flex>


</Flexed>

})
*/
}


{/*//////////////////////////////////////////////////////SHIPPING///////////////////////////////////////////////////////////////////////////////*/}
<Flexed padding="7px 0px"  bgColor="rgb(0,0,0,.2)"  margin="20px 0px 0px 0px">
    <Flex style={{fontSize:"1.2rem",paddingLeft:"10px",fontWeight:"bold",padding:"1.5px"}}>Shipping</Flex>  <Flex display="flex" justifyContent="flex-end"></Flex>
</Flexed>
<Flexed bgColor="#92A4AB" padding="4px 0px">
  <Flex fontSize="1.4rem" flex="2.5">Description</Flex>
  <Flex fontSize="1.4rem"> Quantity</Flex>
  <Flex fontSize="1.4rem">Ship Via</Flex>
  <Flex fontSize="1.4rem">Address</Flex>
  <Flex fontSize="1.4rem">Due Date</Flex>
  <Flex fontSize="1.4rem">Attn</Flex>
</Flexed>


<Flexed style={{pageBreakBefore:"always",background:"white",paddingTop:"20px"}}>
    <Flex fontSize="1.4rem" flex=".7"><Img src={LOGO} style={{width:"250px",top:"-20px",position:"relative"}}/></Flex>
    <Flex fontSize="1.4rem">4250 118th Ave N<br />Clearwater, FL 33762
    <br />Phone: 727-573-1763<br />Fax:727573-1763<br />www.plsprint.com</Flex>
    <Flex fontSize="2rem" textAlign="center" >Job <br />Acknowledgement</Flex>
</Flexed>
<Flexed>
    <Flex fontSize="1.4rem" flex="1.5" style={{whiteSpace:"pre-wrap"}}><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>SOLD TO</span><br />{this.props.elector.name}<br />{this.props.elector.address}</Flex>
    <Flex fontSize="1.4rem"><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Sales Rep</span><br />Nate Blair<br /><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Billing Terms</span><br />Net 30</Flex>
    <Flex fontSize="1.4rem" flex="0" style={{whiteSpace:"nowrap"}}><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Job #</span><br />{this.props.jobnum}<br /><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Due Date</span><br /><Moment style={{fontSize:"1.4rem"}} format="MM/DD/YYYY">{this.props.duedate}</Moment></Flex>
</Flexed>
<Flexed margin="60px 0px 0px 0px">
    <Flex fontSize="1.4rem" ><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Job Name:</span> {this.props.elector.jobname}</Flex>
    <Flex></Flex>
    <Flex fontSize="1.4rem"><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Date Entered:</span> <Moment style={{fontSize:"1.4rem"}} format="MM/DD/YYYY">{new Date()}</Moment></Flex>

</Flexed>

<Flexed style={{background:"rgb(0,0,0,.2)",padding:"3px 10px"}}>
<Flex flex="3" ><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Item</span></Flex>
<Flex ><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Quantity</span></Flex>
<Flex ><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Unit Cost</span></Flex>
<Flex ><span style={{fontSize:"1.4rem",fontWeight:"bold"}}>Total</span></Flex>
</Flexed>
<Flexed padding="3px 10px">
<Flex fontSize="1.4rem" flex="3">{this.props.elector.jobname}</Flex>
<Flex fontSize="1.4rem">{this.props.quantity}</Flex>
<Flex fontSize="1.4rem">{currency(this.props.cost)}</Flex>
<Flex fontSize="1.4rem">{currency(this.props.quantity * this.props.cost)}</Flex>
</Flexed>
{/*/////////////////////////////////////////////////////////OSP///////////////////////////////////////////////////////////////////////////////////////////*/}



    </Wrapper>
      /*
     <Wrapper padding="20px 40px">
    
     <Flexed alignItems="flex-start" >
    <Flex flex="1.2" ><div display="flex" alignItems="center" style={{background:"rgb(0,0,0,.2)",fontSize:"1.3rem",padding:"3px 10px"}}><i>Production Ticket</i></div></Flex>
    <Flex flex=".9"></Flex>
    <Flex flex="0" textAlign ="right"><Img src={LOGO} style={{height:"55px",top:"-10px",right:"10px"}}/></Flex>
    <Flex whitespace="nowrap" flex="0" textAlign ="right">Generated <Moment format="MM/DD/YYYY">{new Date()}</Moment> by<br />Pam Brodeur</Flex>
      </Flexed>


      <Flexed alignItems="flex-start" style={{top:"-10px"}}>
    <Flex  padding="0px 40px 0px 0px" style={{borderRight:"1px solid black"}} flex="2">Customer<br/><span style={{fontSize:"1.3rem",fontWeight:"bold"}}>{this.props.elector.name}</span></Flex>
    <Flex  padding="0px 0px 0px 30px">Date Due<br /><span style={{fontSize:"1.3rem",fontWeight:"bold"}}><Moment format="MM/DD/YYYY">{this.props.duedate}</Moment></span></Flex>
    <Flex textAlign="right" fontSize="2.8rem" fontWeight="bold" style={{top:"0"}}><span style={{position:"absolute",top:"-15px",right:"0"}}>{this.props.jobnum}</span></Flex>
  </Flexed>

<Flexed alignItems="flex-start" border="1px solid black" justifyContent="space-between" padding="0">
<Flex textAlign="center" borderright="1px solid black" >Salesperson<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}>Nate B</span></Flex>
<Flex textAlign="center" borderright="1px solid black">CSR<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}>Kelly A</span></Flex>
<Flex textAlign="center" borderright="1px solid black">Est #<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}>35128</span></Flex>
<Flex textAlign="center" borderright="1px solid black">Job Type<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}>New</span></Flex>
<Flex textAlign="center" borderright="1px solid black">Prev Job #<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}>&nbsp;</span></Flex>
<Flex textAlign="center" borderright="1px solid black">PO #<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}>&nbsp;</span></Flex>
<Flex textAlign="center">Date Entered<br /><span style={{fontSize:".9rem",fontWeight:"bold"}}><Moment format="MM/DD/YYYY">{new Date()}</Moment></span></Flex>
</Flexed>
<Flexed alignItems="flex-start">
<Flex flex=".2"  borderright="1px solid black">Quantity<br /><span style={{fontSize:"1.3rem",fontWeight:"bold"}}>{this.props.quantity}</span></Flex>
<Flex padding="0px 0px 0px 20px" >Job Name<br /><span style={{fontSize:"1.3rem",fontWeight:"bold"}}>{this.props.elector.jobname}</span></Flex>
</Flexed>
<div style={{width:"100%",position:"relative",border:"1px solid black"}}>
<Flexed alignItems="flex-start" padding="0" >
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Description</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem"}}>
<Flex style={{textAlign:"left",padding:"10px",whiteSpace:"pre-wrap"}}>{this.props.quantity} - {this.props.elector.jobname}<br/>
<br/>
{this.props.elector.description1}
</Flex>
<Flex style={{textAlign:"left",padding:"10px",whiteSpace:"pre-wrap"}}>{this.props.elector.description2}</Flex>
<Flex style={{textAlign:"left",padding:"10px",whiteSpace:"pre-wrap"}}>Deliver to Tampa BMEU ASAP!</Flex>
</Flexed>
</div>


<div style={{width:"100%",position:"relative",border:"1px solid black",marginTop:"10px"}}>
<Flexed alignItems="flex-start" padding="0" >
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Pre-Press Notes</Flex>
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Proofs: 1st Off</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem"}}>
<Flex style={{textAlign:"left",padding:"10px"}}>Files On Imcoming<br />Proofs: 1st Off to Pam<br/></Flex>
</Flexed>
</div>


<div style={{width:"100%",position:"relative",border:"1px solid black",marginTop:"10px"}}>
<Flexed alignItems="flex-start" padding="0" >
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Press Components</Flex>
</Flexed>
<Flexed alignItems="flex-start" padding="0" >
<Flex style={{background:"rgb(0,0,0,.3)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>FORM 1form(s)</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box"}} padding="8px 10px" >
<Flex >2 Page(s)</Flex>
<Flex >{this.props.elector.numup} Up</Flex>
<Flex >1 Version(s)</Flex>
<Flex >Perfect</Flex>
<Flex >12.500 X 19</Flex>
<Flex  flex="1.2">Running on Ricoh Color</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box"}} padding="8px 10px">
<Flex >{this.props.elector.stock}</Flex>
<Flex >Inks = 4/4</Flex>
<Flex >Front = C,M,Y,K</Flex>
<Flex >Back = C,M,Y,K</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem"}} padding="8px 10px">
<Flex></Flex>
<Flex>To Press: {((Math.ceil(parseInt(this.props.quantity) / this.props.elector.numup) + this.props.elector.mr))}</Flex>
<Flex>M/R: {this.props.elector.mr}</Flex>
<Flex>Off Press: {(Math.ceil(parseInt(this.props.quantity) / this.props.elector.numup))}</Flex>
<Flex>Total:</Flex>
</Flexed>
</div>


<div style={{width:"100%",position:"relative",border:"1px solid black",marginTop:"10px"}}>
<Flexed alignItems="flex-start" padding="0" >
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Stock</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box",background:"rgb(0,0,0,.3)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} padding="8px 10px" >
<Flex >Form</Flex>
<Flex flex="2" >Paper</Flex>
<Flex >Quantity</Flex>
<Flex >Parent Size</Flex>
<Flex >Press Size</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box",background:"rgb(0,0,0,.3)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} padding="8px 10px" >
<Flex >P.O.</Flex>
<Flex >#LBS</Flex>
<Flex flex="2"></Flex>
<Flex >Order Date</Flex>
<Flex >Due Date</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box"}} padding="8px 10px" >
<Flex >Form</Flex>
<Flex flex="2">{this.props.elector.stock}</Flex>
<Flex >{((Math.ceil(parseInt(this.props.quantity) / this.props.elector.numup) + this.props.elector.mr))}</Flex>
<Flex >12.500 X 19</Flex>
<Flex >12.500 X 19</Flex>
</Flexed>
<Textarea style={{boxSizing:"border-box",textAlign:"left",fontSize:".8rem"}}></Textarea>
</div>

<div style={{width:"100%",position:"relative",border:"1px solid black",marginTop:"10px",pageBreakAfter:"always"}}>
<Flexed alignItems="flex-start" padding="0" >
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Bindery</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box",background:"rgb(0,0,0,.3)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} padding="8px 10px" >
<Flex ><u>Form</u></Flex>
<Flex ><u>Finish Size</u></Flex>
<Flex ><u>Cutting</u></Flex>
<Flex ><u>Folding</u></Flex>
<Flex ><u>Score</u></Flex>
<Flex ><u>Perf</u></Flex>
<Flex ><u>Die Cut</u></Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box"}} padding="8px 10px" >
<Flex >Form</Flex>
<Flex ></Flex>
<Flex >Yes</Flex>
<Flex >{this.props.elector.foldtype}</Flex>
<Flex ></Flex>
<Flex ></Flex>
<Flex ></Flex>
</Flexed>
<Flexed gap="30px">
    <Flex>
        <Flexed padding="8px 10px" border="1px solid black">
            <Flex borderright="1px solid black">Customer Samples</Flex>
            <Flex flex="2" padding="0px 0px 0px 10px">5 Samples</Flex>
        </Flexed>
        <Flexed  padding="8px 10px" border="1px solid black">
            <Flex borderright="1px solid black">Job Ticket Samples</Flex>
            <Flex flex="2" padding="0px 0px 0px 10px">5 Samples</Flex>
        </Flexed>
        <Flexed  padding="8px 10px" border="1px solid black">
            <Flex borderright="1px solid black">Bundle In</Flex>
            <Flex flex="2" padding="0px 0px 0px 10px"></Flex>
        </Flexed>
        <Flexed  padding="8px 10px" border="1px solid black">
            <Flex borderright="1px solid black">Bind On</Flex>
            <Flex flex="2" padding="0px 0px 0px 10px"></Flex>
        </Flexed>
    </Flex>
    <Flex >
    <Flexed alignItems="flex-start" padding="0"  >
<Flex style={{background:"rgb(0,0,0,.2)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} borderbottom>Bindery Counts</Flex>
</Flexed>
<Flexed style={{fontSize:".8rem",borderBottom:"1px solid grey",boxSizing:"border-box",background:"rgb(0,0,0,.3)",fontSize:".8rem",padding:"2px 10px",fontWeight:"bold"}} padding="8px 10px" >
<Flex ><u>Form</u></Flex>
<Flex ><u>Cutting</u></Flex>
<Flex ><u>Folding</u></Flex>
<Flex ><u>Stitching</u></Flex>
</Flexed>
<Flexed  padding="8px 10px"  border="1px solid black" style={{boxSizing:"border-box"}}>
<Flex borderright="1px solid black">Form</Flex>
<Flex borderright="1px solid black">&nbsp;</Flex>
<Flex borderright="1px solid black">&nbsp;</Flex>
<Flex ></Flex>
</Flexed>
    </Flex>
</Flexed>
<Textarea style={{boxSizing:"border-box",textAlign:"left",fontSize:".8rem"}}>{this.props.elector.description1}</Textarea>
</div>
<Wrapper padding="20px 40px">

</Wrapper >
<Flexed>
    <Flex flex=".7"><Img src={LOGO} style={{height:"90px"}}/></Flex>
    <Flex>4250 118th Ave N<br />Clearwater, FL 33762
    <br />Phone: 727-573-1763<br />Fax:727573-1763<br />www.plsprint.com</Flex>
    <Flex textAlign="center" fontSize="1.3rem">Job <br />Acknowledgement</Flex>
</Flexed>
<Flexed>
    <Flex flex="1.5" style={{whiteSpace:"pre-wrap"}}><span style={{fontSize:"1.1rem",fontWeight:"bold"}}>SOLD TO</span><br />{this.props.elector.name}<br />{this.props.elector.address}</Flex>
    <Flex><span style={{fontSize:"1.1rem",fontWeight:"bold"}}>Sales Rep</span><br />Nate Blair<br /><span style={{fontSize:"1.1rem",fontWeight:"bold"}}>Billing Terms</span><br />Net 30</Flex>
    <Flex flex="0" style={{whiteSpace:"nowrap"}}><span style={{fontSize:"1.1rem",fontWeight:"bold"}}>Job #</span><br />{this.props.jobnum}<br /><span style={{fontSize:"1.1rem",fontWeight:"bold"}}>Due Date</span><br /><Moment format="MM/DD/YYYY">{this.props.duedate}</Moment></Flex>
</Flexed>
<Flexed margin="60px 0px 0px 0px">
    <Flex><b>Job Name:</b> {this.props.elector.jobname}</Flex>
    <Flex></Flex>
    <Flex><b>Date Entered:</b> <Moment format="MM/DD/YYYY">{new Date()}</Moment></Flex>

</Flexed>

<Flexed style={{background:"rgb(0,0,0,.2)",fontSize:"1.3rem",padding:"3px 10px"}}>
<Flex flex="3" fontSize=".9rem"><b>Item</b></Flex>
<Flex fontSize=".9rem"><b>Quantity</b></Flex>
<Flex fontSize=".9rem"><b>Unit Cost</b></Flex>
<Flex fontSize=".9rem"><b>Total</b></Flex>
</Flexed>
<Flexed padding="3px 10px">
<Flex flex="3">{this.props.elector.jobname}</Flex>
<Flex>{this.props.quantity}</Flex>
<Flex>{currency(this.props.cost)}</Flex>
<Flex>{currency(this.props.quantity * this.props.cost)}</Flex>
</Flexed>
    </Wrapper>

*/
    );
  }
}
/////////////////////////////////////////////////////////////////////////////////////////////////////
