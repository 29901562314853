import React, { useEffect, useState } from 'react'
import { Flex, Flexed, MainWrap, Scroll, Wrap } from '../../../utilities/Shared'
import axios from 'axios'
import { sortdate, sortit, sortnum } from '../../../utilities/Sort'
import Moment from 'react-moment'
import { colorchange } from '../../../utilities/Convertions'

const Prepress = () => {

const [activejobs, setActivejobs] = useState()
const [imp, setImp] = useState([])
const [newer, setNewer] = useState([])
const [ops, setOps] = useState([])
const [digops, setDigops] = useState([])
const [order, setOrder] = useState("ASC")
const [reset, setReset] = useState(false)
const config = {
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin':'*' 
    }
  };
    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
          let r = {
            job:"123456"
          }
          const getactive = async() => { 
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getactivejobs.php`,r,config)
      .then(res => {setActivejobs(res.data);getimp()})
      .catch(error => { console.log(error)})
          }

          const getimp = async() => { 
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getdigital.php`,r,config)
      .then(res => {setNewer(res.data);getops()})
      .catch(error => { console.log(error)})
          }
     
          const getops= async() => { 
            let f = {
                dept:2,
                push:42
            }
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getoperationsbydept.php`,f,config)
        .then(res => {setOps(res.data);setReset(true) })
        .catch(error => { console.log(error)})
          }
          
          const getdigops = async() => { 
            await axios.post(`https://highgroundsoftware.com/dash/dashboard/php/getalldigitaloperations.php`,r,config)
      .then(res => {setDigops(res.data);getops()})
      .catch(error => { console.log(error)})
          }

          getactive()
          getdigops()
          
    },[])

      
    const getstatus = (job,partof) => {
        let r = ""
let f = {
    job:job,
    partof:partof
}
let y = digops.filter(filt => parseInt(filt.partof) === parseInt(partof)).reverse()
return(y[0]?.name || "Incoming")
    }

    useEffect(() => {
        setup()
    },[reset])
  
    const setup = () => {
        let yy = []
 let yu = []
 let yt = []

 activejobs?.map(imp => {
    newer?.filter(filt => parseInt(filt.job) === parseInt(imp.job)).map(item => 
        yy.push({...item,imp}))
 })  


 let y =[]
 let n = []
 yy.map(job => {
    let u = ops.filter(filt => parseInt(filt.job) === parseInt(job.job) && parseInt(filt.item) === parseInt(job.id))
u.length === 0 && y.push(job)
 })



 y?.map(item => {
    let i = {
        ...item,
        status:getstatus(parseInt(item.job),parseInt(item.id))
    }
    yt.push(i)
 })

 setImp(yt)
    }  
  




  return (
    <Wrap padding="0">
       
    <Flexed  margin="5px 0px 0px 0px" bgColor="#FFC0CB" color="black"  padding="5px"><Flex fontSize="calc(1.1rem + .4vw)">Digital Active Jobs - {imp?.length}</Flex></Flexed>
    <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
    
      <Flex fontSize="var(--headerMedium)" onClick={e => sortnum('job',order,imp,setImp,setOrder)}>Job#</Flex>
      <Flex flex="1.5" fontSize="var(--headerMedium)" >Customer</Flex>
      <Flex  flex="3" fontSize="var(--headerMedium)" >Job Name</Flex>
      <Flex  fontSize="var(--headerMedium)">Quantity</Flex>
      <Flex  fontSize="var(--headerMedium)" >Entered</Flex>
      <Flex  fontSize="var(--headerMedium)" >Due</Flex>
      <Flex  fontSize="var(--headerMedium)">Sales Rep</Flex>
    
    </Flexed>
    {imp.length === 0  ? <>LOADING</> : <>
    <Scroll  height="78vh" border="1px solid #303030">
    {
     imp?.map(job => {
        return <Flexed className="normalrows hand" padding="10px" hoverbgcolor="#FFC0CB" hovercolor="rgb(0,0,0,.7)">
          
          <Flex ><a className="hand" href={`/jobticket/${job.job}`} >{job.job}</a></Flex>
          <Flex flex="1.5">{job.imp?.customer}</Flex>
          <Flex flex="3">{job.component}</Flex>
          <Flex>{job.topress}</Flex>
          <Flex><Moment format="MM/DD/YYYY">{job.imp?.dateentered}</Moment></Flex>
          <Flex><Moment format="MM/DD/YYYY">{new Date(job.imp?.duedate)}</Moment></Flex>
          <Flex bgColor={colorchange(job.status)}>{job.status}</Flex>
        
          </Flexed>
      }).reverse()
    }
    </Scroll>
    </> }
    </Wrap>
  )
}

export default Prepress