import React, { useContext, useEffect, useState } from 'react'
import { Flex, Flexed, Scroll, currency } from '../../utilities/Shared'
import { sortdate, sortit, sortnum } from '../../utilities/Sort'
import axios from 'axios'
import { NavLink, useNavigate } from 'react-router-dom'
import Collapse from '../../utilities/Collapse'



const LastWeekEstimatesDaily = ({salesreps,setSalesreps,jobstoday}) => {
    const[quotes, setQuotes] = useState([])
    const[order, setOrder] = useState("ASC")
    const[thisweek, setThisweek] = useState(0)
    const[newlist, setNewlist] = useState([])
    const nav = useNavigate()


    const dt = new Date();
    dt.setDate(dt.getDate() - 7);
    const day = dt.getDay()
    const diff = dt.getDate() - day + (day === 0 ? -6 : 1) -1;
    const start = new Date(dt.setDate(diff));



 
    const ismonday =  new Date(start.setDate(start.getDate() + 1))
 
    const istuesday =  new Date(start.setDate(ismonday.getDate() + 1))
   
    const iswednesday = new Date(start.setDate(istuesday.getDate() +1))
    const isthursday = new Date(start.setDate(iswednesday.getDate() +1))
    const isfriday = new Date(start.setDate(isthursday.getDate() +1))
   



    useEffect(() => {
        const config = {
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin':'*' 
            }
          };
  
        const getjobs = async() => {
            await axios.post('https://highgroundsoftware.com/dash/dashboard/php/getallquotesfordaily.php',config)
            .then(res => {setQuotes(res.data)})
            .catch(error => { console.log(error)})
        }
        getjobs()
       
    },[])



    useEffect(() => {


      let y
      let t = []
      const you = () => {
        salesreps.map(rep => {
           y = {'name':rep.firstName+' '+rep.lastName.substr(0,1),'monday':monday(rep),'tuesday':tuesday(rep),'wednesday':wednesday(rep),'thursday':thursday(rep),'friday':friday(rep),'id':rep.empId}
        
    t.push(y)

        })
        setNewlist(t)
      }
    you()
      
    },[quotes])



    const monday = (rep) => {
      let y = []
        let ee = 0
       quotes.filter(quote => quote.salesrep === rep.empId && new Date(quote.entered).toLocaleDateString() === ismonday.toLocaleDateString()).map(item => {   
         
ee += parseFloat(item.currentprice)
y.push(item)
        })
      return(y)
      
    }

    const tuesday = (rep) => {
      let y = []
      let ee = 0
      quotes.filter(quote => quote.salesrep === rep.empId && new Date(quote.entered).toLocaleDateString() === istuesday.toLocaleDateString()).map(item => {       
ee += parseFloat(item.currentprice)
y.push(item)
       })
     return(y)
          }

          const wednesday = (rep) => {
            let y = []
            let ee = 0
            quotes.filter(quote => quote.salesrep === rep.empId && new Date(quote.entered).toLocaleDateString() === iswednesday.toLocaleDateString()).map(item => {       
      ee += parseFloat(item.currentprice)
      y.push(item)
             })
           return(y)
                }

                const thursday = (rep) => {
                  let y = []
                  let ee = 0
                  quotes.filter(quote => quote.salesrep === rep.empId && new Date(quote.entered).toLocaleDateString() === isthursday.toLocaleDateString()).map(item => {       
            ee += parseFloat(item.currentprice)
         
            y.push(item)
                   })
                 return(y)
                      }

                      const friday = (rep) => {
                        let y = []
                        let ee = 0
                        quotes.filter(quote => quote.salesrep === rep.empId && new Date(quote.entered).toLocaleDateString() === isfriday.toLocaleDateString()).map(item => {       
                  ee += parseFloat(item.currentprice)
                  y.push(item)
                         })
                       return(y)
                            }
  

                     





  return (
    <>
    <Flexed  margin="30px 0px 0px 0px" bgColor="#8398AB" color="white"  padding="5px 0px"><Flex fontSize="calc(1.1rem + .4vw)">Last Week Daily Estimates</Flex></Flexed>
  <Flexed>
      <Flex fontSize="14px" flex="1.5" onClick={e => sortit('name',order,newlist,setNewlist,setOrder)}>Sales Rep</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('today',order,newlist,setNewlist,setOrder)}>Monday</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('thisweek',order,newlist,setNewlist,setOrder)}>Tuesday</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('lastweek',order,newlist,setNewlist,setOrder)}>Wednesday</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('MTD',order,newlist,setNewlist,setOrder)}>Thursday</Flex>
      <Flex fontSize="14px" onClick={e => sortnum('QTD',order,newlist,setNewlist,setOrder)}>Friday</Flex>

    </Flexed>
    {newlist.length > 1 ?
    <>
    {
     newlist?.map(item => {
        return     <Collapse  margin="0" title={<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"white"}} className="hand">
        <Flex fontSize="14px" flex="1.5" >{item.name}</Flex>
        <Flex fontSize="14px" >{item.monday?.length > 0 && item.monday?.length}</Flex>
        <Flex fontSize="14px" >{item.tuesday?.length > 0 && item.tuesday?.length}</Flex>
        <Flex fontSize="14px" >{item.wednesday?.length > 0 && item.wednesday?.length}</Flex>
        <Flex fontSize="14px" >{item.thursday?.length > 0 && item.thursday?.length}</Flex>
        <Flex fontSize="14px" >{item.friday?.length > 0 && item.friday?.length}</Flex>
  
      </Flexed>}>
        <Flexed className=" hand" padding="5px" color="black">
        <Flex fontSize="14px" flex="1.5" padding="4px 0px"></Flex>
  <Flex fontSize="12px"  padding="4px 0px" >
    {
     item.monday?.map(ite => {
      return  <span ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=15002&q=${ite.quote}`}>{ite.quote}</a><br /></span>
      })
    }
  </Flex>
  <Flex fontSize="12px" padding="4px 0px">
  {
      item.tuesday?.map(ite => {
      return  <span ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=15002&q=${ite.quote}`}>{ite.quote}</a><br /></span>
      })
    }
  </Flex>
  <Flex fontSize="12px" padding="4px 0px">  {
      item.wednesday?.map(ite => {
      return  <span ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=15002&q=${ite.quote}`}>{ite.quote}</a><br /></span>
      })
    }</Flex>
      <Flex fontSize="12px" padding="4px 0px">  {
      item.thursday?.map(ite => {
      return  <span ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=15002&q=${ite.quote}`}>{ite.quote}</a><br /></span>
      })
    }</Flex>
      <Flex fontSize="12px" padding="4px 0px">  {
      item.friday?.map(ite => {
      return  <span ><a href={`https://highgroundsoftware.com/dash/dashboard/?des=15002&q=${ite.quote}`}>{ite.quote}</a><br /></span>
      })
    }</Flex>
  </Flexed>


          </Collapse>

      })  
      
    }
    </> 

    : <p style={{padding:"20px 0px",fontSize:"var(--headerLarge)",color:"rgb(255,0,0,.6)"}}>LOADING...</p>
}

    <Flexed className="normalrow hand"  bgColor="#8398AB" color="white"  padding="5px 0px">
          <Flex fontSize="12px"  flex="1.5"></Flex>
          <Flex fontSize="12px"></Flex>
          <Flex fontSize="12px" ></Flex>
          <Flex fontSize="12px"> </Flex>
          <Flex fontSize="12px"></Flex>
          <Flex fontSize="12px"></Flex>
          <Flex fontSize="12px"></Flex>
          </Flexed>
       
    </>
  )
}

export default LastWeekEstimatesDaily



