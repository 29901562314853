import { useContext, useEffect, useState } from 'react'
import {Button, Flex, Flexed, Input, MainWrap, Selectbox} from '../../utilities/Shared'
import { CSVLink, CSVDownload } from "react-csv";
import { RiFileExcel2Fill } from 'react-icons/ri'
import axios from 'axios';
import { CSRReps, CSRRepsByEmpid, SalesReps, SalesRepsByEmpid } from '../../utilities/Reps';
import Select from '../../utilities/Select'
import { CiCircleRemove,CiCircleMinus } from "react-icons/ci";
import { useParams } from 'react-router-dom';
import Moment from 'react-moment';
import { Tab, TabList, TabPanel, Tabs } from 'react-tabs';
import { IoAddCircleOutline } from "react-icons/io5";
import PressPanel from './PressPanel';
import EstimateContext from '../../Context/EstimateContext';


const Estimater = () => {
  const [showaddquantity, setShowaddquantity] = useState(false)
  const param = useParams()

  const {forms,updateformitem,quote,updatequantity,removequantity,quantities,addquantity} = useContext(EstimateContext)

const addquan = (e) => {
  console.log(e.target.key)
  if (e.key === 'Enter') {
    addquantity(e.target.value);
    setShowaddquantity(false)
  }
}





console.log(quote)


  return (
    <MainWrap style={{fontSize:"calc(1.2rem + .2vw)",color:"#418DA7"}}>

<Flexed padding="20px" gap="10px" borderBottom="1px solid">
  <Flex flex="1.5" fontSize="calc(1.5rem + .2vw)" ><span style={{fontSize:"calc(1.2rem + .2vw)"}} >Quote: <b style={{fontSize:"calc(1.5rem + .2vw)"}}>{param.quote}</b></span> </Flex>
  <Flex flex="2">Date Entered: <Moment format="MM/DD/YYYY" style={{fontSize:"calc(1.5rem + .2vw)",fontWeight:"bold"}}>01-15-2024</Moment> </Flex>
 
  <Flex flex="1"></Flex>
  <Flex textAlign="right"><Button>Delete</Button></Flex>
  <Flex textAlign="right"><Button>Copy</Button></Flex>
</Flexed>

<Flexed  gap="10px" padding="15px 10px 5px 10px">
  <Flex flex="2">Customer:<br /><Selectbox  border="1px solid rgba(122, 187, 67, .5)">
<option></option>
<option></option>
    </Selectbox>
  </Flex>
  <Flex>Contact:<br /><Selectbox  border="1px solid rgba(122, 187, 67, .5)">
<option></option>
<option></option>
    </Selectbox></Flex>
  <Flex>Sales Rep:<br /><SalesRepsByEmpid value={"1537" || ""}  /></Flex>
  <Flex>CSR Rep:<br /><CSRRepsByEmpid value="1710" /></Flex>
</Flexed>

<Flexed  gap="10px" padding="5px 10px 15px 10px" borderBottom="1px solid">
  <Flex flex="2">Prev Job #:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex>Prev Quote #:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex>Estimator:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" value="" /></Flex>
  <Flex>Priority:<br /><Selectbox  border="1px solid rgba(122, 187, 67, .5)">
<option value="Standard" >Standard</option>
<option style={{background:"pink"}} value="ASAP">ASAP</option>
<option value="Hot" style={{background:"red",color:"white"}}>Hot</option>
    </Selectbox></Flex>
</Flexed >

<Flexed  gap="10px" padding="15px 10px 5px 10px">
  <Flex flex="2">Project:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex>Project Style:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex flex=".5">Press Check:<br /><Selectbox  border="1px solid rgba(122, 187, 67, .5)">
<option value="No">No</option>
<option value="Yes">Yes</option>
    </Selectbox></Flex>
</Flexed>
<Flexed  gap="10px" padding="15px 10px 5px 10px">
  <Flex >Flat W:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex>Flat L:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex >Finish W:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex >Finish L:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex >Pages:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex >Versions:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
  <Flex >Cover Style:<br /><Input type="text"  border="1px solid rgba(122, 187, 67, .5)" /></Flex>
</Flexed >
<Flexed  gap="10px"  padding="15px 10px 10px 10px" alignItems="center" borderBottom="1px solid #0E425A">
  {
    showaddquantity ? <CiCircleMinus onClick={e => setShowaddquantity(false)} style={{color:"red",fontSize:"calc(1.6rem + .2vw)"}}/> :<IoAddCircleOutline  onClick={e => setShowaddquantity(true)} style={{color:"green",fontSize:"calc(1.6rem + .2vw)"}}/>
  }
  Quantites:
<Flex alignItems="center" display="flex">
{
  quantities?.map((qua,index) => {
    return <><CiCircleRemove style={{color:"red",fontSize:"calc(1.6rem + .2vw)"}} onClick={e => removequantity(qua)}/><Input type="text"  width="10%" style={{margin:"0px 25px 0px 0px "}} border="1px solid rgba(122, 187, 67, .5)" value={parseInt(qua)} /></>
  })
}
</Flex>
</Flexed>
{
  showaddquantity && <Flexed><Flex ><Input type="text" width="20%" border="1px solid rgba(122, 187, 67, .5)" placeholder="Add New Quantity" onKeyPress ={e => addquan(e)}/> </Flex></Flexed>
}

<div style={{marginTop:"20px"}}>
  <Tabs >
    <TabList >
      <Tab style={{fontSize:"1.2rem"}}>Press</Tab>
      <Tab style={{fontSize:"1.2rem"}}>Components</Tab>
      <Tab style={{fontSize:"1.2rem"}}>Bindery</Tab>
      <Tab style={{fontSize:"1.2rem"}}>Mailing</Tab>

    </TabList>
    <TabPanel>
    <PressPanel forms={quote?.forms} updateformitem={updateformitem}/>
    </TabPanel>
    <TabPanel>Components</TabPanel>
    <TabPanel>Bindery</TabPanel>
    <TabPanel>Mailing</TabPanel>
  </Tabs>
</div>



    </MainWrap>
  )
}

export default Estimater