import React, { useEffect, useState } from 'react'
import { Button, Flex, Flexed, Input, Selectbox, Wrap } from '../../utilities/Shared'
import Select from '../../utilities/Select'
import reactTextareaAutosize from 'react-textarea-autosize';
import styled from 'styled-components'
import axios from 'axios';

export const Textarea = styled(reactTextareaAutosize)`
font-size:1.4rem;
width:100%;
border:none;
padding:10px 5px 30px 5px;
overflow:hidden;
resize:none;
flex:1;
:focus{
    border:2px solid var(--plsGreen);  
  }

`;


const Addship = ({jobinfo,setTemplist,templist,customerinfo,reset, setReset,onCancel}) => {


    console.log(customerinfo)
    
    const [shipitem, setShipitem] = useState({
        type:'Box(s)',
    })

    const [tots, setTots] = useState({})
    const a = jobinfo.job.toString()
    const b = (Math.floor(100000 + Math.random() * 900000)).toString()
    const[newitem, setNewitem] = useState({
        
        id:a.concat(b),
        blind:false,
        description:jobinfo.jobname,
        job:jobinfo.job,
        method:"PLS Delivery",
        notes:"",
        per:null,
        quantity:jobinfo.currentquantity,
        shipdate:"",
        shiptoid:customerinfo?.address[0].old,
        total:null,
        unitcost:"",
        which:null,
        which1:"",

    })






useEffect(() =>{
    let a = shipitem.sending / shipitem.per;
    let b = shipitem.sending - (shipitem.per * Math.floor(a));
    let desc = Math.floor(a)+` ${shipitem.type} @ `+ shipitem.per+' Pieces\n';
    let c = shipitem.per * Math.floor(a) ;
let d = shipitem.sending - c;
d > 0 ? desc = desc+`\n 1 ${shipitem.type} @ ` +d+' Pieces' :desc = desc 
    setTots({numof:a,total:desc})
},[shipitem])
/*
let a = shipping / perbox;
	document.querySelector("#boxes"+id).value = Math.ceil(a);
	let b = shipping - (perbox * Math.floor(a));
	letdesc = Math.floor(a)+" Box(s) @ "+perbox;
let c = perbox * Math.floor(a) ;
let d = shipping - c;


(d > 0 ? letdesc = letdesc+"\n 1 Box(s) @ "+d :letdesc = letdesc );
}
*/


const changeit = (e,item,field) => {
    newitem[field] = e.target.value
   
  //  addtopack(false,item)
    let a
    let tot
    console.log(newitem)
    let b = isNaN(parseInt(newitem.sending)) ? 0 : parseInt(newitem.sending)
    let c = isNaN(parseInt(newitem.per)) ? 0 : parseInt(newitem.per) 
    a = b / c
    console.log(a)
    let d = b % c
  
    if(c > 0 && b > 0){
         tot = `${Math.floor(a)} ${shipitem.type} @ ${newitem.per} Pieces Each \n`
        if(d !== 0){
tot += `${1} ${shipitem.type} @ ${d} Pieces`
        }
    }
let icount = Math.ceil(a) 
console.log(icount)
if(icount === Infinity){icount = 0;tot = ""}
        setNewitem({...newitem,totalitem:tot,itemcount:icount})
}


const saveit = (e) => {
e.preventDefault()
let gg = templist?.shippingitems || []

gg?.push(newitem)

let y = 
{
    id:jobinfo._id,
    field:'shippingitems',
    value:gg
}
console.log(y)
const  loadshipping = async() => {
    await axios.post(`http://localhost:5003/jobs/update`,y)
   // await axios.post(`http://localhost:5003/jobs/update`,y)
    .then(resp => {setReset(!reset);onCancel();})
    .catch(err => console.log(err))
}
loadshipping()
}
    

console.log(templist)
  return (
   <Wrap style={{color:"black"}}>
    <br /><br />
    <form onSubmit={e => saveit(e)}>
    <Flexed>
<Flex fontSize="calc(1.2rem + .2vw)" flex="3">Item:<br /><Input type="text" defaultValue={jobinfo.jobname} onChange={e => setNewitem({...newitem,description:e.target.value})} required/></Flex>
<Flex fontSize="calc(1.2rem + .2vw)" flex=".5">Amount Ordered:<br /><Input type="text"  defaultValue={newitem?.quantity} onChange={e => setNewitem({...newitem,quantity:e.target.value})} required/></Flex>
</Flexed>
<Flexed padding="8px 0px">
<Flex flex=".5" fontSize="calc(1.2rem + .2vw)">Ship Type:<br />
    <Selectbox defaultValue={newitem.method} onChange={e => setNewitem({...newitem,method:e.target.value})} required>
    
    <option value="" >Select Method</option>
      <option value="PLS Delivery" >PLS Delivery</option>
      <option value="Pick Up" >Pick Up</option>
      <option value="UPS" >UPS</option>
      <option value="FED EX" >FED EX</option>
      <option value="NPL Pickup" >NPL Pickup</option>
      <option value="Outside Truck" >Outside Truck</option>
  
</Selectbox>
    </Flex>
    <Flex fontSize="calc(1.2rem + .2vw)" flex="2">Ship To:<br />
    <Selectbox value={newitem.shiptoid} onChange={e => setNewitem({...newitem,shipto:e.target.options[e.target.selectedIndex].text,shiptoid:e.target.value})} required>
       
    <option value="" >Select Address</option>
    <option value="2850">Tampa BMEU</option>
        {
          customerinfo.address?.map((addy) => {
            return <option value={addy.old} >{addy.address1} {addy.address2} {addy.city} {addy.state} {addy.zip}</option>
          })  
        }

  
</Selectbox>
    </Flex>
</Flexed>
{/* <Flexed>

<Flex fontSize="calc(1.2rem + .2vw)">Send Type:<br />
<Selectbox value={shipitem.type} onChange={e => setShipitem({...shipitem,type:e.target.value})}>
    <option value="Box(s)">Boxes</option>
    <option value="Skid(s)">Skids</option>
    <option value="Item(s)">Items</option>
</Selectbox>
</Flex>
<Flex fontSize="calc(1.2rem + .2vw)">Sending Amount:<br /><Input type="text"  defaultValue={newitem?.sending} onChange={e => changeit(e,null,'sending')} required/></Flex>
<Flex fontSize="calc(1.2rem + .2vw)">Per {shipitem.type}<br /><Input type="text"  defaultValue={newitem?.per} onChange={e => changeit(e,null,'per')} required/></Flex>
<Flex fontSize="calc(1.2rem + .2vw)"># Of {shipitem.type}<br /><Input type="text" value={Math.ceil(newitem.itemcount)}/></Flex>
<Flex fontSize="calc(1.2rem + .2vw)">Total {shipitem.type}<br /><Textarea minRows="6" defaultValue={newitem?.totalitem} onChange={e => setNewitem({...newitem,totalitem:e.target.value})} required/>  </Flex>

</Flexed> */}




<Flexed padding="20px 0px 0px 0px" textAlign="right">
{newitem?.totalitem !== "" &&<Flex><Button>Add Item</Button></Flex>}
    
</Flexed>
<br /><br /><br />

</form>
   </Wrap>
  )
}

export default Addship





    // "id": "23374",
    // "job": "102572",
    // "line": "0",
    // "description": "Greenbelt 32 Page Self Cover (9x12) test",
    // "quantity": "5300",
    // "unitcost": "1.11906",
    // "shipto": "Tampa BMEU , , FL",
    // "shiptoid": "2850",
    // "shipdate": "11/14/2023",
    // "total": "5931.00",
    // "assign": "0",
    // "method": "Best Way",
    // "which": "0",
    // "which1": "1",
    // "contact": "",
    // "shipped": "0",
    // "leftover": "0",
    // "notes": "",
    // "blind": "0",
    // "per": "0",
    // "temp": "",
    // "temp2": "0",
    // "sent": 200,
    //         "type": "Box(s)",
    //         "sending": "200",
    //         "check": false,
    //         "totalitem": "20 Box(s) @ 10 Pieces Each \n",
    //         "itemcount": 20,
    //         "shipid": "b9b11128",
    //         "date": "2024-05-13T13:28:08.711Z"


    // "blind": false,
    // "description": "Greenbelt 32 Page Self Cover (9x12) test",
    // "job": 102572,
    // "method": "PLS Delivery",
    // "notes": "",
    // "per": "10",
    // "sent": null,
    // "quantity": "",
    // "shipdate": "",
    // "shiptoid": "Box",
    // "total": null,
    // "unitcost": "",
    // "which": null,
    // "which1": "",
    // "to": "75 John Portman Blvd Ste 6N, 315 Atlanta GA 30303",
    // "toid": "2822",
    // "sending": "100",
    // "totalitem": "10 Box(s) @ 10 Pieces Each \n",
    // "itemcount": 10
