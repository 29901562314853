import axios from 'axios'
import React, { useEffect,useContext } from 'react'
import { useState } from 'react'
import {useParams,useNavigate} from 'react-router-dom'
import { Button, Flex, Flexed, Input, MainWrap, Scroll, currency } from '../../utilities/Shared'
import Dropdown from '../../utilities/Dropdown'
import { addinventorylist, adjustinventorylist, removeinventorylist } from '../../utilities/Lists'
import Addinventory from './Addinventory'
import Moment from 'react-moment'
import { toast } from 'react-toastify'
import Select from '../../utilities/Select'
import { locationareas } from '../../utilities/Lists'



const Paperitem = () => {

 
    const resetnewinfo = {
date:"",
differ:"",
isnow:"",
po:"",
was:"",
job:"",
quantity:"",
reason:"",
bin:""
    }

    const valueset = {
        id:"",
        name:""
    }

const [item, setItem] = useState({})
const [sameitem, setSameitem] = useState(null)
const [currentselection, setCurrentselection] = useState(0)
const [value , setValue] = useState({})

const [newinfo, setNewinfo] = useState({})
const [addview , setAddview] = useState(false)
const [reset , setReset] = useState(false)
const [bins, setBins] = useState({})
const [bin, setBin] = useState({})

const [cwt, setCwt] = useState()
const [mwt, setMwt] = useState()


const [locationarea, setLocationarea] = useState({id:0,name:"Paper Areas"})
const [binslist, setBinslist] = useState([])
const [theone, setTheone] = useState({})
const [papers, setPapers] = useState([])
const [paper, setPaper] = useState({})
const nav = useNavigate()
const [current, setCurrent] = useState()
    const param = useParams()

    useEffect(() => {
        
        let  o
const getitem = async() => {
    let t
    await axios.get(`https://highgroundapi.herokuapp.com/inv/item/${param.id}`)
   // await axios.get(`http://localhost:5003/inv/item/${param.id}`)
.then(res => {setItem(res.data);
    getsame(res.data.paperid,res.data.invid);
    setBins({...bins,name:res.data.bin});
    setBin({...bin,name:res.data.bin});
setTheone(res.data.po.items.filter(item => item.itemid === res.data.poitemid));
getpapers(res.data.paperid)

})
.catch(err => console.log(err))
}
const getsame = async(paperid,invid) => {
    await axios.get(`https://highgroundapi.herokuapp.com/inv/same/${paperid}`)
    //await axios.get(`http://localhost:5003/inv/same/${paperid}`)
    .then(res => {
        setSameitem(res.data.filter(item => item.invid !== invid));
      //  getbins()
   
    })
    .catch(err => console.log(err))
    } 
   
    getitem()



    },[reset])

    const getpapers= async(paperid) => {
        await axios.get('https://highgroundapi.herokuapp.com/paper/all')
       // await axios.get('http://localhost:5003/paper/all')
        
        .then(res => {
            console.log(res.data.filter(paper => paper.stockid === paperid))
            setPaper(res.data.filter(paper => paper.stockid === paperid))})
        .catch(err => console.log(err))
    }


    const getbins = async() => {
        await  axios.post(`https://highgroundapi.herokuapp.com/inv/test`)
       // await  axios.post(` http://localhost:5003/inv/test`)
       
        .then(res => {setBinslist(res.data)})
        .catch(err => console.log(err))
        }

        const getcost = (theone,quantity) => {
            theone = theone[0]
            console.log(theone)
         let a
         let b
        switch(theone?.priceby){
          
            case "CWT":
        b = (theone.mwt * quantity) /1000
            a = (theone.unitprice * b) / 100
          break;
          case "Per M":
              a = (quantity / 1000) * theone.unitprice
          break;
          case "Roll LBS":
            a = (theone.unitprice * quantity) / 100
          break;
          case "Each":
          case "LBS":
          case "Case":
          case "Box":
              a =quantity  * theone.unitprice
            break;
            case "Lot":
                    a = theone.unitprice  * 1
              break;
          default:
             a = null
            break;
          }
          console.log()
       
return a
        }

        useEffect(() => {
            setCwt(theone[0]?.type === "Roll Stock" ? theone[0]?.unitprice : paper[0]?.cwt )
            setMwt(theone[0]?.type !== "Roll Stock" ? theone[0]?.unitprice : "")
            },[paper])




    const additem = () => {     
newinfo.reason = value.name
let play = item.history.slice(-1)
newinfo.was = play[0].isnow
console.log(newinfo.was)
newinfo.isnow = (parseInt(newinfo.was) + parseInt(newinfo.quantity))
newinfo.differ = "+ "+newinfo.quantity
newinfo.date = new Date()
newinfo.bin = item.bin
const info = {
    "id":param.id,
    "newhistory":newinfo
}
       const y = async() => {
        await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
        //await axios.post(`http://localhost:5003/inv/add/to/history`,info)
        
        .then(res => {setNewinfo(resetnewinfo);toast.success('History Update');setValue(valueset);setnewquan();})
        .catch(err => console.log(err))
       }
      y()

    }
   
    const setnewquan = async() => {
        const newstuff = {
            id:param.id,
            quan:newinfo.isnow,
            bin:newinfo.bin
        }
        await axios.post(`https://highgroundapi.herokuapp.com/inv/replace`,newstuff)
        //await axios.post(`http://localhost:5003/inv/replace`,newstuff)
        
        .then(res => {console.log(res.data);setReset(!reset)})
        .catch(err => console.log(err))
    }


    const removeitem = () => {     
        newinfo.reason = value?.name
        let play = item.history.slice(-1)
        newinfo.was = play[0].isnow
        console.log(newinfo.was)
        newinfo.isnow = (parseInt(newinfo.was) - parseInt(newinfo.quantity))
        newinfo.differ = "- "+newinfo.quantity
        newinfo.date = new Date()
        newinfo.bin = item.bin
        const info = {
            "id":param.id,
            "newhistory":newinfo
        }
        console.log(info)
               const y = async() => {
                await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
                .then(res => {toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setnewquan()})
                .catch(err => console.log(err))
               }
              y()
            }

         
            
                    const updatebin = async() => {
                        const tyu = {
                            id:param.id,
                            bin: bins.name
                        }
                        await axios.post(`https://highgroundapi.herokuapp.com/inv/update/bin`,tyu)
                        .then(res => {
                            const u = {
                               reason:"Binned location changed to "+bins.name,
                               was:item.currentInventory,
                               isnow:item.currentInventory,
                               differ:0,
                               date:new Date(),
                            }
                            const info = {
                                "id":param.id,
                                "newhistory":u
                            }
                            console.log(info)
                                   const y = async() => {
                                    await axios.post(`https://highgroundapi.herokuapp.com/inv/add/to/history`,info)
                                    .then(res => {toast.success('History Update');setNewinfo(resetnewinfo);setValue(valueset);setnewquan()})
                                    .catch(err => console.log(err))
                                   }
                                  y()
                        })
                        .catch(err => console.log(err))
                       }
     
const savecost =  async() => {
let a 
    if(theone[0]?.priceby === "Per M"){
        a = {id:theone[0]?.itemid,unitprice:mwt} 
    }
    if(theone[0]?.priceby === "CWT"){
        a = {id:theone[0]?.itemid,unitprice:cwt} 
    }
    if(theone[0]?.priceby === "Roll LBS"){
        a = {id:theone[0]?.itemid,unitprice:cwt} 
    }
if(a.unitprice > 0){
    console.log(a)
    await axios.post('https://highgroundapi.herokuapp.com/po/update/unitcost',a)
  // await axios.post('http://localhost:5003/po/update/unitcost',a)
   .then(res => {console.log(res.data);setReset(!reset) })
  .catch(err => console.log(err))
}

}


 console.log(item)
console.log(theone)
console.log(papers)
console.log(locationareas)

  return (
    <MainWrap>

       <Flexed alignItems="flex-start" padding= "30px 0px 0px 0px" fontSize="calc(.8rem + .4vw)">
        <Flex flex="2.5" ><span style={{fontSize:"calc(1.5rem + .4vw)"}}>{item.name}</span><br /></Flex>
      
       <Flex><u>In Stock</u><br />{item.currentInventory}</Flex>
       <Flex><u>Size</u><br />{item.type === "Roll Stock" ? item.size.split('X')[0] : item.size || ""}</Flex>
       <Flex><u>Bin</u><br /><Button  type="button" onClick={updatebin} style={{position:"absolute",top:"0",right:"0"}}>Update Bin</Button><Select setValue={setBins} listid="bins" value={bins.name} list={binslist?.map(item => ({name:item?._id}))}/> <br />Location<br /><Select setValue={setLocationarea} listid="locats" value={locationarea.name} list={locationareas?.map(item => ({id:item?.id,name:item.name}))}/></Flex>
       </Flexed>

<Flexed padding="0px 0px 30px 0px" gap="5px">
<Flex>Inv Id:&nbsp;{item.invid}<br />Paper Id:&nbsp;{item.paperid}</Flex>
<Flex>&nbsp;&nbsp;CWT:  <Input value={cwt} onChange={e => setCwt(e.target.value)} width="60%" height="20px"/><br />Per M:  <Input value={mwt} onChange={e => setMwt(e.target.value)} width="60%" height="20px"/>
</Flex>
<Flex>Price By:&nbsp;{theone[0]?.priceby}<br />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Cost:&nbsp;{theone[0]?.unitprice}</Flex><Flex fontSize="1.1rem"><br />Current Value: {currency(getcost(theone,item.currentInventory))} </Flex>
</Flexed>


       <Flexed>
        <Flex textAlign="center" onClick={e => setCurrentselection(0)}><Button onClick={e => setValue(valueset)}>Add</Button></Flex>
        <Flex textAlign="center" onClick={e => setCurrentselection(1)}><Button onClick={e => setValue(valueset)}>Remove</Button></Flex>
    
        <Flex textAlign="center"><Button onClick={e => setValue(valueset)}>Delete</Button></Flex>
        
        <Flex textAlign="center"><Button onClick={e => savecost()}>Save</Button></Flex>
        <Flex flex="3"></Flex>
       </Flexed >
   
     {currentselection === 0 &&   <Flexed gap="30px" alignItems="center">
        <Flex flex="2">Reason for Adding<br /><Select setValue={setValue} listid="reason" value={value.name} list={addinventorylist?.map(item => ({name:item?.name,id:item?.id}))} required/></Flex>
        <Flex>PO<br /><Input value={newinfo?.po} onChange={e => setNewinfo({...newinfo,po:e.target.value})}/></Flex>
        <Flex>Job<br /><Input value={newinfo?.job} onChange={e => setNewinfo({...newinfo,job:e.target.value})}/></Flex>
        <Flex >Quantity<br /><Input value={newinfo?.quantity}  onChange={e => setNewinfo({...newinfo,quantity:e.target.value})}/></Flex>

        <Flex wordBreak="normal">{newinfo.quantity > 0 && value.name > "" && <Button onClick={additem}>Add To Inventory</Button>}</Flex>
       </Flexed>} 
       {currentselection === 1 &&   <Flexed gap="30px" alignItems="center">
       <Flex flex="2">Reason for Removing<br /><Select setValue={setValue} listid="reason" value={value.name} list={removeinventorylist?.map(item => ({name:item?.name,id:item?.id}))}/></Flex>
        <Flex>PO<br /><Input value={newinfo?.po} onChange={e => setNewinfo({...newinfo,po:e.target.value})}/></Flex>
        <Flex>Job<br /><Input value={newinfo?.job} onChange={e => setNewinfo({...newinfo,job:e.target.value})}/></Flex>
        <Flex >Quantity<br /><Input value={newinfo?.quantity}  onChange={e => setNewinfo({...newinfo,quantity:e.target.value})}/></Flex>
    
        <Flex wordBreak="normal">{newinfo.quantity > 0 && value.name > "" && <Button onClick={removeitem}>Remove From  Inventory</Button>}</Flex>
       </Flexed>}  
      
     
<hr style={{marginTop:"20px"}}/>
<p style={{fontSize:"calc(.6rem + .4vw)",fontWeight:"bold",padding:"20px 0px"}}><u >Item History</u></p>
<Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
     <Flex flex="2.5">Adjustment Reason</Flex>
     <Flex>Date</Flex>
     <Flex>Was</Flex>
     <Flex>Now</Flex>
     <Flex>Difference</Flex>
        <Flex>PO</Flex>
        <Flex>Job</Flex>
        <Flex>Cost</Flex>
  </Flexed>
  <Scroll height="200px">
{
    item.history?.map(ite => {
     return   <Flexed>
        <Flex flex="2.5">{ite.reason}</Flex>
        <Flex ><Moment format="MM/DD/YYYY">{ite.stamp}</Moment></Flex>
        <Flex>{ite.was}</Flex>
        <Flex>{ite.isnow}</Flex>
        <Flex>{ite.differ}</Flex>
        <Flex>{ite.po}</Flex>
           <Flex>{ite.job}</Flex>
           <Flex>{ite.cost}</Flex>
     </Flexed>
    }).reverse()
}
</Scroll>
       <hr />
       <p style={{fontSize:"calc(.6rem + .4vw)",fontWeight:"bold",padding:"20px 0px"}}><u >Related Stocks</u></p>
{sameitem?.length === 0 ? <p>No Related Items Could Be Found</p> : <Flexed style={{borderBottom:"1px solid black",padding:"4px",background:"#8398AB",color:"white",marginBottom:"5px"}}>
     <Flex>Inv Id</Flex>
     <Flex flex="2">Description</Flex>
        <Flex>Paper Id</Flex>
        <Flex>Size</Flex>
        <Flex>In Stock</Flex>
        <Flex>Bin</Flex>
  </Flexed>}
{sameitem?.map(item => {
  return  <Flexed  onClick={e => nav(`/inventory/paper/${item._id}`)}>
     <Flex>{item.invid}</Flex>
     <Flex flex="2">{item.name}</Flex>
        <Flex>{item.paperid}</Flex>
        <Flex>{item.size}</Flex>
        <Flex>{item.currentInventory}</Flex>
        <Flex>{item.bin}</Flex>
  </Flexed>
} )
}     
<br /><br />
    </MainWrap>
  )
}

export default Paperitem